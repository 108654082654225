<template>
  <article>
    <Title :subtitle="$t('reorder.title.main_jp')" :title="$t('reorder.title.main')"></Title>

    <validation-observer ref="observer" v-slot="{ handleSubmit }" class="content__inner" tag="div" >
      <Headline :title="$t('reorder.about_reorder_procedure')"></Headline>

      <ErrorAlert v-if="isError">
        無効な再注文キーが入力されました。
      </ErrorAlert>

      <p class="reorder__text" v-html="$sanitize($t('reorder.content'))"></p>

      <section class="mt-30 mb-40">
        <dl class="common-dl">
          <dt class="pt-0 pb-0">{{ $t('reorder.key') }}<span class="c-red">※</span></dt>
          <dd class="pt-0 pb-0">
              <validation-provider
                v-slot="{ errors }"
                :rules="`required`"
              >
                <input
                  v-model="token"
                  :name="'再注文用キー'"
                  class="form-text--100"
                  placeholder="お手元の再注文用キーを入力"
                >

                <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
              </validation-provider>
          </dd>
        </dl>
      </section>

      <Button
        :title="$t('reorder.btn.reorder_procedure')"
        isPrimary
        @onClick="handleSubmit(submit)"
      />
    </validation-observer>
  </article>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "ReorderIndex",
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button'),
    ErrorAlert: () => import('@components/base/ErrorAlert')
  },
  data() {
    return {
      token: '',
      isError: ''
    }
  },
  methods: {
    ...mapActions([
      'getRepurchase'
    ]),
    async submit() {
      const valid = await this.$refs.observer.validate()

      if (!valid) {
        return
      }

      try {
        await this.$store.dispatch('getRepurchase', {
          type: 'reorder',
          token: this.token
        })

        this.$router.push({ name: 'repurchase.reorder.attention' })
      } catch (e) {
        console.log('error', e)

        this.isError = true
      }
    }
  },
}
</script>

<style scoped>
@media (max-width: 768px) {
  .reorder__text {
    font-size: 1.2rem;
    line-height: 1.5;
  }
}

</style>
