export default {
  login_title: "Login",
  login_subtitle: "ログイン",
  login: "Login",
  submit_login: "Login",
  message: "Please enter your email address and password to log in.",
  email: "Email",
  password: "Password",
  unauthorized: "The email or password you entered is incorrect.",
  remember: "Save login status for one month",
  forgot_password: "Click here if you forget your password",
  return_to_login_page: "Return to Login page",
  unverified: "The email address has been not verified. To log in you must first verify the email address.",
  resend_verificatioh: "Resend Email Verification",
  reset_pw: {
    title: "Password reset application",
    msg_1: "Reset your password.",
    msg_2: "Please enter the date of birth and email address registered when you registered as a member.",
    msg_3: "We will send you an email to the password reset page.",
    date_of_bith: "Date Of Birth",
    year: "Year",
    month: "Month",
    day: "Day",
    send_email: "Send an email",
    email_placeholder: "Please enter your registered email address",
    invalid: "The email address and birth of date that you've entered doesn't match any account."
  },
  reset_pw_mail: {
    title: "Password reset application ・ Email transmission",
    msg: "An email for resetting the password has been sent to the specified email address.",
    msg_expire: "Please access the URL written in the email within 24 hours and reset your password.",
  },
  new_pw: {
    title: "Password reset application.",
    msg_1: "Reset your password",
    msg_2: 'Enter your desired password and click the "Reset" button.',
    new_pw: "New Password",
    confirm_pw: "Confirm Password",
    new_pw_placeholder: "Please enter new password.",
    confirm_pw_placeholder: "Please enter confirm new password.",
    reset: "Reset",
    invalid: "Your password reset link appears to be invalid or expired."
  },
  reset_pw_complete: {
    title: "Password reset application ・ Completion",
    msg_1: "Password reset is complete.",
    msg_2: "Please log in immediately and enjoy shopping."
  }
};
