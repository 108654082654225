export default {
  event_title: 'EVENT LIST',
  event_subtitle: 'イベント',
  term_of_service_title: 'Term of service',
  term_of_service_subtitle: '利用規約',
  term_of_service_message: 'Please be sure to read and consent before proceeding to product browsing / purchasing.',
  accept_term_of_service: 'Accept term of service',
  not_accept_term_of_service: 'I do not agree to the terms',
  notice: "NOTICE",
  info: "INFO",
  term_service: {
    first_msg: 'Please be sure to read and consent to it. ,',
    second_msg: 'Please proceed to product browsing / purchase.',
    third_msg: '(After consent, you can proceed to the purchase flow.)',
    check_the_term: 'Check the terms'
  },
  event: {
    recommended: 'Recommended',
    new: 'New',
    three_day_left: '3 day left',
    end_today: 'End of sale today'
  }
};