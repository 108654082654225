import BaseService from "./BaseService";

class CategoryService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  categoryPage(id, data) {
    return this.performRequest(BaseService.METHOD_GET, `categories/${id}`, data);
  }

  categoryGoodsPage(id, data) {
    return this.performRequest(BaseService.METHOD_GET, `categories-good/${id}`, data);
  }

}

export default new CategoryService('');