<template>
  <article>
    <Title :subtitle="$t('error404.subtitle')" :title="$t('error404.title')"></Title>
    <section class="content pb-120">
      <div class="content__inner">
        <section class="mb-60">
          <p class="mb-30" v-html="$sanitize($t('error404.message'))">
          </p>
          <!--<div class="al-c"><a class="btn-100" @click="onclickTopPage()">{{ $t('error404.return_top') }}</a></div>-->
        </section>
      </div>
    </section>
  </article>
</template>
<script>
import pageDisplayControlMixin from "@/helpers/pageDisplayControlMixin";
export default {
  mixins: [ pageDisplayControlMixin ],
  mounted() {
    this.$nextTick(() => {
      this.init()
    })
  },
  methods: {
    onclickTopPage() {
      this.$router.push({name: "top"}).catch(() => {
        this.$router.go()
      });
    }
  },
  components: {
    Title: () => import('@components/base/Title'),
  },

}
</script>
