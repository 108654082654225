export default {
  event_title: 'EVENT LIST',
  event_subtitle: 'イベント',
  term_of_service_title: 'Term of service',
  term_of_service_subtitle: '利用規約',
  term_of_service_message: '必ずお読みいただき、承諾後、商品閲覧、購入へお進みください。',
  accept_term_of_service: '規約を承諾する',
  not_accept_term_of_service: '規約に同意しない',
  notice: "NOTICE",
  info: "INFO",
  term_service: {
    first_msg: '必ずお読みいただき承諾後、',
    second_msg: '商品閲覧・購入にお進みください。',
    third_msg: '（承諾後、購入フローへ進むことが可能となります。）',
    check_the_term: '規約を確認する',
  },
  event: {
    recommended: 'おすすめ',
    new: 'New',
    three_day_left: '販売終了残り3日',
    end_today: '本日販売終了'
  }

}