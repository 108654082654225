<template>
  <BaseIndex
    :cart="getCart"
    @set-printed-set-popup="setPrintSetup"
    @set-is-product-out-of-stock="setIsProductOutOfStock"
    @set-is-remove-all-products-popup="setIsRemoveAllProductsPopup"
    @remove-all-products-from-cart="removeAllProductsFromCart"
    @set-is-event-sale-term-expire="setIsEventSaleTermExpired"
    @set-is-cart-data-changed="setIsCartDataChanged"
  >
    <router-view
      v-if="current_artist && ableToAccess && !showEventListAttention"
      :key="$route.path"
    />
    <artist-confirmation
      v-else-if="current_artist"
    />
  </BaseIndex>
</template>

<script>
import store from '@/store/store'
import {mapState} from 'vuex'
import ArtistConfirmation from "@pages/artist/ArtistConfirmation";
import {CONFIRMATION_STATUS} from "@constants/ArtistConfirmation";
import {CONFIRMATION_NAMES} from "../../constants/ArtistConfirmation";

/**
 * artist_confirmationが有効な状態でもアクセス可能なルート名
 * アクセス可能なルート名
 * @type {string[]}
 */
const ableToAccessPage = [
  'artist.landing_page',
]

const purchasePage = [
  'purchase.login',
  'purchase',
  'purchase.attention',
  'purchase.confirmation',
  'purchase.confirmation2',
  'purchase.credit',
];

export default {
  components: {
    ArtistConfirmation,
    BaseIndex: () => import('./base/Index'),
  },

  async beforeRouteEnter(to, from, next) {
    const slug = to.params.designate_page
    try {
      await store.dispatch('getCurrentArtistByDesignatePageProperty', slug)
      if (purchasePage.includes(to.name)) {
        const cartResult = await store.dispatch('getCartDataWithResult', slug)
        if (!cartResult.cart_categories.length) {
          next({name: 'cart.page', params: { designate_page: to.params.designate_page}})
        } else {
          next()
        }
      } else if (to.name !== 'cart.page') {
        store.dispatch('getCartDataWithResult', slug)
        next()
      } else {
        next()
      }
    } catch (e) {
      console.log(e)
      next({ name: 'static.static_page', params: { url_slug: slug } })
    }
  },
  computed: {

    ...mapState([
      'current_artist',
      'artistConfirmation',
      'showEventListAttention',
      'auth'
    ]),

    getCart() {
      return this.$store.state.cart
    },

    isConfirmed() {
      if (this.artistConfirmation === null) {
        return true
      }
      const results = Object.keys(this.artistConfirmation).map(confirmKey => {
        // EventListAttentionは評価しない = ページを表示させる
        if (confirmKey === CONFIRMATION_NAMES.EVENT_LIST_ATTENTION) return true
        switch (this.artistConfirmation[confirmKey]) {
          case CONFIRMATION_STATUS.NOT_DEMAND:
          case CONFIRMATION_STATUS.CONFIRMED:
            return true
          default:
            return false
        }
      })
      return !results.includes(false)
    },

    ableToAccess() {
      if (this.current_artist) {
        return this.isConfirmed || ableToAccessPage.includes(this.$route.name)
      } else {
        return false
      }
    },
  },
  methods: {
    setPrintSetup() {
      this.$store.dispatch('setPrintedSetPopup', false)
    },
    setIsProductOutOfStock() {
      this.$store.dispatch('setIsProductOutOfStock', false)
      this.$store.dispatch('getCartData', true)
    },
    setIsRemoveAllProductsPopup() {
      this.$store.commit('SET_IS_REMOVE_ALL_PRODUCTS_POPUP', false)
    },
    removeAllProductsFromCart() {
      this.$store.dispatch('removeAllProductsFromCart')
    },
    setIsEventSaleTermExpired() {
      this.$store.commit('SET_IS_EVENT_SALE_TERM_EXPIRED', false)
    },
    setIsCartDataChanged() {
      this.$store.commit('SET_IS_CART_DATA_CHANGED', false)
    }
  },
}
</script>
