import BaseService from "./BaseService";

class PriorEmailRegistrationService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  verifyEmailRegistration(token) {
    return this.performRequest(BaseService.METHOD_POST, `${token}`, {}, {}, { is_ignore_interceptors_response_error: true })
  }

}

export default new PriorEmailRegistrationService('/prior-email-registrations');
