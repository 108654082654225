<template>
  <div class="restore-cart-modal">
    <Modal :modal="isShowModal" width="700px" show-close @close="handleClose">
      <template #header>
        <Title title="Restore Cart" subtitle="商品選定を途中から始める"/>
      </template>
      <template #body>

        <ErrorAlert v-if="error" class="mb-15">
          <p v-html="$sanitize(error)" />
        </ErrorAlert>

        <div class="rest-shopping-content">
          <p>下記のキーを「途中から始める」ボタンを押していただくと移動する画面にご入力ください。<br />現在お選びの商品がカートに入った状態から商品の選定を行うことができます</p>
          <p>メモ帳への保存、スクリーンショットなどで保存することをお勧めいたします。</p>

          <dl class="common-dl">
            <dt>発行されたキー</dt>
            <dd>
              <input
                v-model="pubKey"
                class="form-text--100"
                maxlength="11"
                required
              >
            </dd>
          </dl>

          <Button title="途中から始める" is-primary @onClick="handleRestoreCart" />
          <Button title="閉じる" @onClick="handleClose" />
        </div>
      </template>
    </Modal>
  </div>
</template>
<script>

export default {
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      pubKey: ''
    }
  },
  components: {
    ErrorAlert: () => import('@components/base/ErrorAlert'),
    Title: () => import('@components/base/Title'),
    Button: () => import('@components/base/Button'),
    Modal: () => import('@components/base/Modal')
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    async handleRestoreCart() {
      this.$emit('restore', this.pubKey)
      this.pubKey = ''
    }
  },
}
</script>
<style lang="scss">
@import '../../../assets/scss/common/variables';
.restore-cart-modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
  }
}
.restore-cart-content {
  font-size: 13px;
  color: $C_BLUE
}
</style>
