import Vue from 'vue'
import Vuex from 'vuex'

import state from "./state"
import getters from "./getters"
import mutations from "./mutations"
import actions from "./actions"

//modules
import auth from './modules/auth';
import member from './modules/member';
import cart from './modules/cart';
import purchase from './modules/purchase'
import msg from './modules/message_when_hidden'
import repurchase from "./modules/repurchase";

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    member,
    cart,
    purchase,
    msg,
    repurchase
  },
  getters,
  mutations,
  state,
  actions,
  strict: process.env.VUE_APP_NODE_ENV !== 'production'
})
