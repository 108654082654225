<template>
  <div is="style">{{style}}</div>
</template>
<script>
import keepFontSizeStyle from "../../helpers/keepFontSizeStyle";

export default {
  props: {
    selector: {
      type: String,
      default: '.relative-font-size'
    },
    fontSize: {
      type: [ Number, String ],
      default: 13
    },
    spFontSize: {
      type: [ Number, String ],
      default: null
    },
    pcFontSizeBasePoint: {
      type: [String, Number],
      default: 1000
    },
    spFontSizeBasePoint: {
      type: [ String, Number ],
      default: 360
    },
    pcAroundWidth: {
      type: [ String, Number],
      default: 0
    },
    spAroundWidth: {
      type: [ String, Number],
      default: 0
    }
  },
  computed: {


    style() {
      return keepFontSizeStyle(
        this.selector,
        this.fontSize,
        this.spFontSize,
        this.pcFontSizeBasePoint,
        this.spFontSizeBasePoint,
        this.pcAroundWidth,
        this.spAroundWidth,
      )
    }

  }
}
</script>
