import Vue from 'vue';
import VueRouter from 'vue-router';
import defaultMiddleware from '@middlewares/default';
import redirectIfLoginMiddleware from '@middlewares/redirectIfLogin';
import store from '../store/store.js';

import Artist from './artist';
import Cart from './cart';
import Category from './category';
import Event from './event';
import Member from './member';
import MyPage from './mypage';
import Purchase from './purchase';
import Static from './static';
import ArtistInquiry from './artist_inquiry';
import ArtistStatic from './artist_static';
import ArtistMyPage from './artist_my_page';
import Inquiry from './inquiry';
import Repurchase from "./repurchase";

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {y: 0}
    }
  },
  routes: [
    {path: "/404", component: () => import('@pages/errors/404'), name: '404'},
    {
      path: '/login',
      name: 'auth.login',
      component: () => import('@pages/auth/Login'),
      meta: {
        middleware: redirectIfLoginMiddleware
      }
    },
    // Previews
    {
      path: "/event-preview/:token",
      name: "event.preview",
      component: () => import("@pages/preview/EventPreview"),
    },
    {
      path: "/category-preview/:token",
      name: "category.preview",
      component: () => import("@pages/preview/CategoryPreview"),
    },
    {
      path: "/artist-preview/:token",
      name: "artist.preview",
      component: () => import("@pages/preview/ArtistPreview"),
    },
    {
      path: "/category-goods-preview/:token",
      name: "category-goods.preview",
      component: () => import("@pages/preview/CategoryGoodsPreview"),
    },
    {
      path: "/static-page-preview/:token",
      name: "static-pages.preview",
      component: () => import("@pages/preview/StaticPagePreview"),
    },
    {
      path: "/landing-page-preview/:token",
      name: "landing-pages.preview",
      component: () => import("@pages/preview/LandingPagePreview"),
    },
    // end of Previews
    {
      path: '/password-resetting',
      name: 'auth.password_resetting',
      component: () => import('@pages/auth/ResetPW'),
    },
    {
      path: '/password-resetting/new-pw/:token',
      name: 'auth.new_pw',
      component: () => import('@pages/auth/NewPassword'),
    },
    {
      path: '/prior-email-registrations/:token',
      name: 'artist.prior_email_registrations',
      component: () => import('@pages/artist/MailRegistrationVerification'),
    },
    ...Static,
    {
      path: '',
      component: () => import('@/App.vue'),
      children: [
        {path: '/', component: () => import('@pages/top/Index.vue'), name: 'top'},
        ...Inquiry,
        ...Member,
        ...MyPage,
        {
          path: ':designate_page',
          component: () => import('@pages/artist/Index'),
          children: [
            ...Artist,
            ...Event,
            ...Category,
            ...Cart,
            ...Purchase,
            ...Repurchase,
            ...ArtistMyPage,
            ...ArtistInquiry,
            ...ArtistStatic,
          ]
        },
      ]
    },
    {
      path: '/:url_slug',
      name: "static.static_page",
      component: () => import("@pages/static/Static"),
    },
    {path: "*", redirect: {name: '404'}}
  ]
});

//setting middleware
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware) return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);

    subsequentMiddleware({...context, next: nextMiddleware});
  };
}

router.beforeEach((to, from, next) => {
  const {middleware = defaultMiddleware} = to.meta;

  const definiteMiddleware = middleware.length === 0 ? [() => next()] : middleware;

  const middlewares = Array.isArray(definiteMiddleware) ? definiteMiddleware : [definiteMiddleware];

  // check if there's no artist designate
  // if none, remove current artist from state
  if (!(to.params && to.params.designate_page)) {
    store.commit("REMOVE_ARTIST");
  }

  const context = {
    from,
    next,
    router,
    to,
  };

  const nextMiddleware = nextFactory(context, middlewares, 1);

  return middlewares[0]({...context, next: nextMiddleware});
});

export default router;
