<template>
  <div>
    <template>
      <MyPageHistoryDetailPC v-if="!is_mobile"/>
      <MyPageHistoryDetailSP v-else/>
    </template>
  </div>
</template>
<script>

import MasterDataService from '@/services/MasterDataService';

export default {
  data() {
    return {
      is_mobile: false
    }
  },
  created() {
    MasterDataService.index({is_mobile: true}).then(res => {
      this.is_mobile = res.data.is_mobile;
    });
  },
  components: {
    MyPageHistoryDetailPC: () => import('./MyPageHistoryDetailPC.vue'),
    MyPageHistoryDetailSP: () => import('./MyPageHistoryDetailSP.vue')
  }
}
</script>