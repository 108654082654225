<template>
  <slide-modal
    ref="popupPurchase"
    @onClose="handleOnClose"
    @onNext="handleOnNext"
    @onPrevious="handleOnPrevious"
  >
    <validation-observer id="popup-purchase" ref="observer" v-slot="{ handleSubmit }" tag="div">

      <div class="popup-purchase__image">
        <img
          v-if="contentLoaded"
          v-show="isPurchaseImageLoaded"
          ref="mainImage"
          :src="localData.main_image_url"
          @load="onLoadPopupImage"
          @error="onLoadPopupImage"
          @touchend="popupImageTouchEnd"
          @touchstart="popupImageTouchStart"
        >
      </div>

      <div
        v-show="isPurchaseImageLoaded"
        class="popup-purchase__caption category-product__content-wrapper mt-5 mb-5"
      >
        <p
          class="c-red caption-content"
          v-blank-to-br
          v-html="$sanitize(localData.name)"
        />
      </div>

      <div
        v-show="isPurchaseImageLoaded"
        class="popup-purchase__description"
      >

        <div class="popup-purchase__description__slide">
          <div class="popup-purchase__description__slide--id">{{ $t('category.id') }}{{ data.id }}</div>
          <img
            v-if="isExtraSmallDevice"
            :src="slideDescriptionImage"
            class="popup-purchase__description__slide--content"
          >
        </div>

        <validation-provider
          v-slot="{errors}"
          :rules="has_max_stock"
        >

          <div
            v-if="isExtraSmallDevice"
            class="purchase__cart"
          >
            <span class="purchase__cart-title number-sheet">{{numberOfSheetsLabel}}</span>

            <div class="purchase__number__select__wrapper">
              <select
                v-model="localData.qty"
                :name="$t('category.number_of_sheets')"
                class="purchase__number__select"
                @change="handleChangeQty"
              >
                <option
                  v-for="(value, i) in selectLength"
                  :key="i"
                  :value="value"
                >
                  {{ value }}
                </option>
              </select>
            </div>

            <input
              :class="{ active: isInCart  }"
              :value="isInCart ? 'カートから削除' : 'カートに入れる'"
              class="purchase__button-add-to-cart purchase__button-add-to-cart__x_sm box-shadow js-cart_button"
              type="button"
              @click="handleSubmit(addToCart())">
          </div>

          <div v-else class="purchase__cart">
            <span class="purchase__cart-title number-sheet">{{numberOfSheetsLabel}}</span>

            <input
              v-model="localData.qty"
              :max="localData.always_keep_in_stock ? '' : localData.number_of_stock"
              :name="$t('category.number_of_sheets')"
              :type="isPhone ? 'tel' : 'number'"
              class="purchase__number"
              min="0"
              @input="handleInput"
            >

            <img
              v-if="isPhone"
              :src="cartPopupImage"
              class="purchase__cart-image"
            >

            <div v-else class="purchase__cart-box">
              <div class="purchase__cart-box--flex">
                <div class="purchase__cart-icon purchase__cart-icon--nosp">
                  <span>アイコン説明：</span>
                </div>
                <div class="purchase__cart-icon">
                  <input class="purchase__cart-icon--button box-shadow js-cart_button"
                         type="button">
                  <span>選択が解除されています</span>
                </div>
                <div class="purchase__cart-icon active-icon">
                  <input class="purchase__cart-icon--button box-shadow js-cart_button active"
                         type="button">
                  <span>カートに入っています</span>
                </div>
              </div>
            </div>

            <!-- category-product__button--cart -->

            <input
              :class="{ active: isInCart  }"
              class="purchase__button-add-to-cart box-shadow js-cart_button"
              type="button"
              @click="handleSubmit(addToCart())">
          </div>

          <div v-if="errors[0]" class="purchase__cart--error c-red mt-2"> {{ errors[0] }}</div>
          <div class="purchase__cart--note">
            ※枚数を変更すると自動的に変更されます
          </div>
        </validation-provider>
      </div>
    </validation-observer>
  </slide-modal>
</template>
<script>
import cartPopupImage from '@assets/images/popup-cart-category-sp.png'
import slideDescriptionImage from '@assets/images/slide-description.png'

export default {
  components: {
    SlideModal: () => import('@components/base/SlideModal'),
  },
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    isInCart: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    },
    isExtraSmallDevice: {
      type: Boolean,
      default: false
    },
    isSet: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localData: { ...this.data },
      isPurchaseImageLoaded: false,
      contentLoaded: false,
      showCaptionArea: false,
    }
  },
  computed: {
    selectLength() {
      if (!this.localData.always_keep_in_stock && this.localData.number_of_stock === undefined) return 100
      return this.localData.always_keep_in_stock ? 100 : this.localData.number_of_stock
    },
    cartPopupImage() {
      return cartPopupImage
    },
    slideDescriptionImage() {
      return slideDescriptionImage
    },
    has_max_stock() {
      if (this.localData.always_keep_in_stock !== 0) {
        return 'required|min_value:0';
      } else {
        return 'required|min_value:0|max_value:' + this.localData.number_of_stock;
      }
    },
    numberOfSheetsLabel() {
      return this.isSet ? 'セット数' : this.$t('category.number_of_sheets')
    }
  },
  watch: {
    data(newData, prevData) {
      if (newData.id !== prevData.id) {
        this.localData = { ...newData }
        this.contentLoaded = false
        this.$nextTick(() => {
          this.contentLoaded = true
          this.isPurchaseImageLoaded = false
        })
      }
    }
  },
  methods: {
    addToCart() {
      this.$emit('add-to-cart', this.data)
    },
    handleInput() {
      this.$emit('input', this.localData)
    },
    handleOnClose() {
      this.$emit('close')
    },
    handleOnNext() {
      this.$emit('next', this.data)
    },
    handleOnPrevious() {
      this.$emit('prev', this.data)
    },
    handleChangeQty() {
      this.$emit('input', this.localData)
    },
    onLoadPopupImage() {
      this.isPurchaseImageLoaded = true
      this.$emit('image-loaded', this.data)
      // setTimeout(() => {
      // }, 1000)
      // // this.$emit('image-loaded', this.data)
    },
    popupImageTouchStart(e) {
      this.touch_start_x = e.changedTouches[0].screenX;
    },
    popupImageTouchEnd(e) {
      this.touch_end_x = e.changedTouches[0].screenX;
      let isSwipe = (this.touch_start_x - this.touch_end_x) > 50 || (this.touch_start_x - this.touch_end_x) < -50;
      // swiped left
      if ((this.touch_start_x > this.touch_end_x) && isSwipe) {
        this.handleOnNext();
      } else if ((this.touch_start_x < this.touch_end_x) && isSwipe) {
        // swiped right
        this.handleOnPrevious();
      }
    },
    show() {
      this.$refs.popupPurchase.show()
    },
    disablePrevious(bool) {
      this.$refs.popupPurchase.disablePrevious(bool)
    },
    disableNext(bool) {
      this.$refs.popupPurchase.disableNext(bool)
    },
    setCaptionArea(bool) {
      this.showCaptionArea = bool
    }
  }
}
</script>
<style scoped>
.caption-content {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 576.98px) {
  .caption-content {
    font-size: 10px;
  }
}

@media screen and (max-width: 320px) {
  .purchase__cart {
    padding: 0 !important;
  }
}
</style>
