export default [
  {
    path: "/",
    name: "artist.home",
    component: () => import("@pages/artist/Artist"),
  },
  {
    path: "lp",
    name: "artist.landing_page",
    component: () => import("@pages/landing/Landing"),
  },
];
