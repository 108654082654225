export default (store) => {
  let timer = null

  store.commit('SET_IS_MOBILE', window.innerWidth < 767)

  window.addEventListener('resize', () => {

    if (timer) {
      clearTimeout(timer)
    }

    timer = setTimeout(() => {
        store.commit('SET_IS_MOBILE', window.innerWidth < 767)
    }, 200)
  })

}