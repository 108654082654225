export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'マイページ利用解除完了',
    description: 'マイページ利用解除の手続きが完了しました。',
    thank_you: 'Bromide.jpサービス及びマイページのご利用ありがとうございました。 '
  },
  btn: {
    return_to_top: 'TOPに戻る'
  }
}