<template>
  <slide-modal
    ref="carouselModal"
    @onClose="handleOnClose"
    @onNext="handleOnNext"
    @onPrevious="handleOnPrevious"
  >
    <div class="popup-purchase__image">
      <img
        v-show="isPopupImageLoaded"
        ref="mainImage"
        :src="popupImage.image_url"
        @load="onLoadPopupImage"
        @error="onLoadPopupImage"
        @touchend="popupImageTouchEnd"
        @touchstart="popupImageTouchStart"
      >
    </div>
  </slide-modal>
</template>
<script>

export default {
  components: {
    SlideModal: () => import('@components/base/SlideModal'),
  },
  props: {
    popupImage: {
      type: Object,
      required: true
    },
  },
  data() {
    return {
      isPopupImageLoaded: false,
    }
  },
  computed: {
  },
  watch: {
    popupImage(newData, prevData) {
      if (newData.id !== prevData.id) {
        this.$store.dispatch('toggleLoading', true);

        this.$refs.mainImage.addEventListener('load', () => {
          this.onLoadPopupImage()
        })

        this.$refs.carouselModal.disablePrevious(newData.first)
        this.$refs.carouselModal.disableNext(newData.last)

        this.$nextTick(() => {
          this.isPopupImageLoaded = false
        })
      }
    }
  },
  methods: {
    handleOnClose() {
      this.$store.dispatch('toggleLoading', false);
      this.$emit('close')
    },
    handleOnNext() {
      this.$emit('next', this.popupImage)
    },
    handleOnPrevious() {
      this.$emit('prev', this.popupImage)
    },
    onLoadPopupImage() {
      this.$store.dispatch('toggleLoading', false);
      this.isPopupImageLoaded = true
    },
    popupImageTouchStart(e) {
      this.touch_start_x = e.changedTouches[0].screenX;
    },
    popupImageTouchEnd(e) {
      this.touch_end_x = e.changedTouches[0].screenX;
      let isSwipe = (this.touch_start_x - this.touch_end_x) > 50 || (this.touch_start_x - this.touch_end_x) < -50;
      // swiped left
      if ((this.touch_start_x > this.touch_end_x) && isSwipe) {
        if (!this.popupImage.last) {
          this.handleOnNext();
        }
        return
      } else if ((this.touch_start_x < this.touch_end_x) && isSwipe) {
        // swiped right
        if (!this.popupImage.first) {
          this.handleOnPrevious();
        }
      }
    },
    show() {
      this.$refs.carouselModal.show()
    },
    hide() {
      this.$refs.carouselModal.hide()
    },
  }
}
</script>
