export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'マイページ利用解除手続き',
    description: 'マイページ利用解除をするとお届け先、購入履歴、各情報はすべて削除されます。',
    do_you_want_to_unsubscribe: 'マイページ利用解除しますか?'
  },
  btn: {
    do_not_withdraw: '退会しない',
    withdraw: '退会する'
  }
}