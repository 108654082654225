export default {
  title: {
    main: 'Inquiry',
    main_jp: "お問い合わせ",
    create: 'Please fill in the inquiry',
    confirmation: "Please check the content of your inquiry",
    complete: "Your inquiry is complete"
  },
  sub_title: {
    create: "Please enter the required information below. You cannot use pictograms. <br><span class='c-red'>※ is required item.</span>",
    confirmation: "Please check the input contents.",
  },
  label: {
    name: "Name",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email Address",
    email_confirm: "Email Confirmation",
    category: "Inquiry Category",
    content: "Inquiry Content",

  },
  input: {
    name: "Name <span class='c-red'>※</span>",
    first_name: "First Name",
    last_name: "Last Name",
    email: "Email Address <span class='c-red'>※</span>",
    email_confirm: "Email Confirmation <span class='c-red'>※</span>",
    category: "Inquiry Category <span class='c-red'>※</span>",
    content: "Inquiry Content <span class='c-red'>※</span>",

  },
  placeholder: {
    email: "Please enter your e-mail address.",
    email_confirm: "Please enter your email address again.",
    content: "Please fill in the content of your inquiry."
  },
  btn: {
    submit: "Checking the content",
    send: "Send",
    confirm: "Return to Artist Page",
    back: "Back"
  },
}