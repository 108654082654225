import { mapMutations } from "vuex";


export default {


  mounted() {
    // 初期処理
    this.PAGE_INIT(false)
  },
  methods: {
    ...mapMutations([
      'PAGE_INIT',
    ]),
    init() {
      this.PAGE_INIT(true)
    }
  },
}
