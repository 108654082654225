export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'Confirmation of changes',
    description: 'If there are no mistakes in the following contents, click the "Register" button.'
  },
  basic_information: {
    title: 'Basic information',
    name: "Name",
    furigana: "Furigana",
    mail_address: "Mail address",
    sex: "Sex",
    profession: "Profession",
    date_of_birth: "Date of birth",
    password: "Password",
    send_email_newsletter: "Send e-mail newsletter"
  },
  product_delivery_address: {
    title: "Product delivery address",
    postal_code: "Postal code",
    prefecture: "Prefecture",
    street_address: "Street address",
    phone_number: "Phone number",
    unregistered: "Unregistered"
  },
  occupation: {
    employee: 'Employee',
    student: 'Student',
    self_employee: 'Self employed',
    other: 'Other',
  },
  gender: {
    male: 'Male',
    female: 'Female',
    anonymous: 'Do not answer'
  },
  btn: {
    update: 'Update',
    to_fix: 'To fix'
  }
}
