<template>
  <validation-observer ref="regist_observer" class="content__inner" tag="div">
    <PostalCodeSelectModal
      :is-show-modal="isShowPostalCodeSelectModal"
      :is-mobile="is_mobile"
      :addresses="addresses"
      @select="settingAddress"
      @close="isShowPostalCodeSelectModal = false"
    />

    <section class="mb-60">
      <Headline :title="$t('registration.title.register')"></Headline>

      <p v-html="$sanitize($t('registration.sub_title.register'))"></p>
    </section>

    <section class="mb-30">
      <h4 v-t="'registration.title.register_section_1'" class="main-title"></h4>

      <dl class="common-dl">
        <dt v-html="$sanitize($t('registration.input.name'))"></dt>

        <validation-provider v-slot="lastname" rules="max:15" tag="dd">
          <input
            :id="`last_name`"
            v-model="entity.last_name"
            :name="$t('registration.label.last_name')"
            :placeholder="$t('registration.placeholder.last_name')"
            class="form-text--50"
            maxlength="15"
            required
            @keydown.space.prevent
          />

          <validation-provider v-slot="firstname" rules="max:15">
            <input
              :id="`first_name`"
              v-model="entity.first_name"
              :name="$t('registration.label.first_name')"
              :placeholder="$t('registration.placeholder.first_name')"
              class="form-text--50"
              maxlength="15"
              required
              @keydown.space.prevent
            />

            <div v-if="lastname.errors[0] || firstname.errors[0]">
              <span v-if="lastname.errors" class="validate-error c-red text--50">{{ lastname.errors[0] }}</span>

              <span v-if="firstname.errors" class="validate-error c-red text--50">{{ firstname.errors[0] }}</span>
            </div>
          </validation-provider>
        </validation-provider>

        <dt v-html="$sanitize($t('registration.input.name_hiragana'))"></dt>

        <validation-provider v-slot="last_name_hiragana" rules="max:15" tag="dd">
          <input
            :id="`last_name_hiragana`"
            v-model="entity.last_name_hiragana"
            :name="$t('registration.label.last_name_hiragana')"
            :placeholder="$t('registration.placeholder.last_name_hiragana')"
            class="form-text--50"
            maxlength="15"
            required
            @keydown.space.prevent
          />

          <validation-provider v-slot="first_name_hiragana" rules="max:15">
            <input
              :id="`first_name_hiragana`"
              v-model="entity.first_name_hiragana"
              :name="$t('registration.label.first_name_hiragana')"
              :placeholder="$t('registration.placeholder.first_name_hiragana')"
              class="form-text--50"
              maxlength="15"
              required
              @keydown.space.prevent
            />

            <div v-if="last_name_hiragana.errors[0] || first_name_hiragana.errors[0]">
              <span
                v-if="last_name_hiragana.errors"
                class="validate-error c-red text--50"
              >
                {{ last_name_hiragana.errors[0] }}
              </span>

              <span
                v-if="first_name_hiragana.errors"
                class="validate-error c-red text--50"
              >
                {{ first_name_hiragana.errors[0] }}
              </span>
            </div>
          </validation-provider>
        </validation-provider>

        <dt v-if="is_mobile" v-html="$sanitize($t('registration.input.email'))"></dt>

        <dt v-else v-html="$sanitize($t('registration.input.email') + ' ' + $t('registration.email_info_pc') )"></dt>

        <dd>
          <validation-provider
            v-slot="{ errors }"
            :rules="`required|email`"
            :vid="$t('mypage_edit.basic_information.email_address')"
          >
            <input
              id="email"
              ref="email"
              v-model="entity.email"
              :name="$t('registration.label.email')"
              :placeholder="$t('registration.placeholder.email')"
              class='form-text--100'
              required
              type="text"
              @keydown.space.prevent
            />

            <p v-if="is_mobile" class="mb-10" v-html="$sanitize($t('registration.email_info_sp'))"></p>

            <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
          </validation-provider>
        </dd>

        <dt v-html="$sanitize($t('registration.input.email_confirm'))"></dt>

        <dd>
          <validation-provider
            v-slot="{ errors }"
            :rules="`required|email|confirmed:${$t('mypage_edit.basic_information.email_address')}`"
            :vid="$t('mypage_edit.basic_information.email_address_confirmation')"
          >
            <input
              id="email_confirm"
              v-model="entity.email_confirmation"
              :name="$t('registration.label.email')"
              :placeholder="$t('registration.placeholder.email_confirm')"
              class='form-text--100'
              required
              type="text"
              @keydown.space.prevent
            />

            <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
          </validation-provider>
        </dd>

        <dt v-html="$sanitize($t('registration.input.gender'))"></dt>

        <dd>
          <ul class="flex-list no-bullet">
            <li v-for="(gender, index) in gender_options" v-bind:key="index">
              <input
                :id="gender.value"
                v-model="entity.gender"
                :name="gender.value"
                type="radio"
                v-bind:value="gender.value"
              >

              <label :for="gender.value" class="radio--label">{{ gender.text }}</label>
            </li>
          </ul>
        </dd>

        <dt v-html="$sanitize($t('registration.input.occupation'))"></dt>

        <dd>
          <validation-provider v-slot="{ errors }" :rules="`required`">
            <div class="form-select">
              <select id="occupation" v-model="entity.occupation" :name="$t('registration.label.occupation')">
                <option hidden value="">{{ $t('mypage_edit.select.please_select') }}</option>

                <option
                  v-for="(option, index) in occupation_options"
                  :key="index"
                  :value="option.value"
                  selected
                >
                  {{ $t(`mypage_edit.occupation.${option.value}`) }}
                </option>
              </select>
            </div>

            <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
          </validation-provider>
        </dd>

        <dt v-html="$sanitize($t('registration.input.birthday'))"></dt>

        <dd>
          <ul class="flex-list registration--from__birthday-list">
            <li class="flex-list--item">
              <validation-provider v-slot="{ errors }" :rules="`required`">
                <div class="registration--from__birthday-input registration--from__year">
                  <div class="form-select">
                    <select
                      id="year"
                      v-model="selected_year"
                      :name="$t('registration.label.year')"
                      @change="onMonthChange()"
                    >
                      <option v-t="'registration.label.year'" hidden value=""></option>

                      <option v-for="(value, index) in year_list" v-bind:key="index" v-bind:value="value">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <p v-t="'registration.label.year'" class="pl-5"></p>
                </div>

                <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </li>

            <li class="flex-list--item">
              <validation-provider v-slot="{ errors }" :rules="`required`">
                <div class="registration--from__birthday-input registration--from__month">
                  <div class="form-select">
                    <select
                      id="month"
                      v-model="selected_month"
                      :name="$t('registration.label.month')"
                      @change="onMonthChange()"
                    >
                      <option hidden selected value="">{{ $t('registration.label.month') }}</option>

                      <option v-for="(value, index) in month_list" v-bind:key="index + 1" v-bind:value="index + 1">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <p v-t="'registration.label.month'" class="pl-5"></p>
                </div>

                <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </li>

            <li class="flex-list--item pr-0">
              <validation-provider v-slot="{ errors }" :rules="`required`">
                <div class="registration--from__birthday-input registration--from__day">
                  <div class="form-select">
                    <select id="day" v-model="selected_day" :name="$t('registration.label.day')">
                      <option v-t="'registration.label.day'" hidden value=""></option>

                      <option v-for="(value, index) in day_list" v-bind:key="index + 1" v-bind:value="index + 1">
                        {{ value }}
                      </option>
                    </select>
                  </div>

                  <p v-t="'registration.label.day'" class="pl-5"></p>
                </div>
                <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </li>
          </ul>
        </dd>

        <dt v-html="$sanitize($t('registration.input.password'))"></dt>

        <dd>
          <validation-provider
            v-slot="{ errors }"
            :rules="{ min:8,max:32,regex: /^([a-zA-Z0-9#?!@$%^&*_-]*)$/ }"
            :vid="$t('mypage_edit.basic_information.please_enter_your_password')"
          >
            <div class="form-password registration--from__password">
              <input
                id="password"
                v-model="entity.password"
                :name="$t('registration.label.password')"
                :placeholder="$t('registration.placeholder.password')"
                class="form-text--100 form-password__input registration--from__password__input"
                required
                :type="is_visible_password ? 'text' : 'password'"
              >

              <div class="form-password__append">
                <IconEye
                  v-if="is_visible_password"
                  class="form-password__append__icon"
                  @click.stop="() => is_visible_password = !is_visible_password"
                />

                <IconEyeSlash
                  v-else
                  class="form-password__append__icon"
                  @click.stop="() => is_visible_password = !is_visible_password"
                />
              </div>
            </div>

            <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
          </validation-provider>

          <p v-t="'registration.label.password_hint'" class="small"></p>

          <validation-provider
            v-slot="{ errors }"
            :rules="`min:8|max:32|confirmed:${$t('mypage_edit.basic_information.please_enter_your_password')}`"
            :vid="$t('mypage_edit.basic_information.repeat_password')"
          >
            <div class="form-password registration--from__password">
              <input
                id="password_confirmation"
                v-model="entity.password_confirmation"
                :name="$t('registration.label.password_confirm')"
                :placeholder="$t('registration.placeholder.password')"
                class="form-text--100 form-password__input registration--from__password__input"
                required
                :type="is_visible_password__confirmation ? 'text' : 'password'"
              >

              <div class="form-password__append">
                <IconEye
                  v-if="is_visible_password__confirmation"
                  class="form-password__append__icon"
                  @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                />

                <IconEyeSlash
                  v-else
                  class="form-password__append__icon"
                  @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                />
              </div>
            </div>

            <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
          </validation-provider>
        </dd>
      </dl>
    </section>

    <section class="mb-30">
      <h4 v-t="'registration.title.register_section_2'" class="main-title"></h4>

      <dl class="common-dl">
        <dt v-html="$sanitize($t('registration.input.postal_code'))"></dt>

        <dd>
          <PostalCodeInput
            v-model="entity.postal_code"
            input_class="form-text--100"
            :input_name="$t('mypage_edit.product_delivery_address.postal_code')"
            :placeholder="$t('registration.placeholder.postal_code')"
            autocomplete="off"
            @input="onSearchZipCode"
          />
        </dd>

        <dt v-html="$sanitize($t('registration.input.prefecture'))"></dt>

        <dd>
          <validation-provider v-slot="{ errors }" :rules="`required`">
            <div class="form-select">
              <select
                id="prefecture"
                v-model="entity.prefecture_id"
                :name="$t('registration.label.prefecture')"
                @change="onPrefectureChange"
              >
                <option v-t="'general.default_select'" hidden value=""></option>

                <option v-for="(option, index) in prefecture_options" v-bind:key="index" v-bind:value="option.value">
                  {{ option.text }}
                </option>
              </select>
            </div>

            <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
          </validation-provider>
        </dd>

        <dt>
          <span v-html="$sanitize($t('registration.input.address'))"/>

          <span v-if="!is_mobile" class='pl-1 c-red'>{{ $t('registration.text_address_pc') }}</span>
        </dt>

        <dd>
          <validation-provider v-slot="{ errors, failedRules }" :rules="`max:30`">
            <input
              ref="address1"
              type="text"
              id="address_city"
              v-model="entity.address"
              :name="$t('registration.label.address_1')"
              :placeholder="$t('registration.placeholder.address_1')"
              class="form-text--100"
              required
            >

            <p v-if="errors[0] && failedRules.max" class="validate-error c-red mb-0">
              {{$t('general.error_message_address_custom')}}
            </p>
          </validation-provider>

          <validation-provider v-slot="{ errors, failedRules }" :rules="`max:50`">
            <input
              id="address_home"
              v-model="entity.address_2"
              :name="$t('registration.label.address_2')"
              :placeholder="$t('registration.placeholder.address_2')"
              class="form-text--100" type="text"
            >

            <p v-if="errors[0]" class="validate-error c-red">
              {{ failedRules.max ? $t('general.error_message_address_2_custom') : errors[0] }}
            </p>
          </validation-provider>
        </dd>

        <dt v-html="$sanitize($t('registration.input.phone_number'))"></dt>

        <dd>
          <validation-provider
            v-slot="{ errors }"
            :rules="`phone_number|max:11|min:10`"
            :vid="`${$t('mypage_edit.product_delivery_address.phone_number')}`"
          >
            <input
              id="phone_number"
              v-model="entity.phone_number"
              :name="$t('registration.label.phone_number')"
              :placeholder="$t('registration.placeholder.phone_number')"
              class="form-text--100"
              maxlength="11"
              required
              type="tel"
            >

            <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
          </validation-provider>

          <validation-provider
            v-slot="{ errors }"
            :rules="`phone_number|confirmed:${$t('mypage_edit.product_delivery_address.phone_number')}`"
            :vid="`${$t('mypage_edit.product_delivery_address.phone_number_confirmation')}`"
          >
            <input
              id="phone_number_confirm"
              v-model="entity.phone_number_confirmation"
              :name="$t('registration.label.phone_number')"
              :placeholder="$t('registration.placeholder.phone_number_confirm')"
              class="form-text--100"
              maxlength="11"
              required
              type="tel"
            >

            <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
          </validation-provider>
        </dd>
      </dl>
    </section>

    <Button :title="$t('registration.btn.submit_form')" isPrimary @onClick="handleShowAddressConfirmModal"></Button>

    <DeliveryAddressConfirmModal
      :is-show-modal="isShowAddressModal"
      :user-info="entity"
      @address-confirm="handleAddressConfirm"
      @address-cancel="handleAddressCancel"
    />

    <TermOfIncompleteAddressConfirmModal
      :is-show-modal="isShowTermModal"
      :user-info="entity"
      @term-confirm="handleTermConfirm"
      @term-cancel="handleTermCancel"
    />
  </validation-observer>
</template>

<script>
import MasterDataService from '@services/MasterDataService';

export default {
  name: "RegisterForm",
  props: ['data'],
  data() {
    return {
      entity: {
        first_name: "",
        last_name: "",
        first_name_hiragana: "",
        last_name_hiragana: "",
        email: '',
        email_confirmation: "",
        gender: 'anonymous',
        occupation: '',
        password: '',
        password_confirmation: "",
        postal_code: "",
        prefecture_id: "",
        prefecture: "",
        address: "",
        address_2: "",
        phone_number: "",
        phone_number_confirmation: "",
        date_of_birth: ""
      },
      selected_year: '',
      selected_month: '',
      selected_day: '',
      gender_options: [],
      occupation_options: [],
      prefecture_options: [],
      year_list: [],
      month_list: [],
      day_list: [],
      is_mobile: '',
      isShowPostalCodeSelectModal: false,
      addresses: [],
      is_visible_password: false,
      is_visible_password__confirmation: false,

      isShowAddressModal: false,
      isShowTermModal: false,
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
    this.getMasterData();
    if (Object.keys(this.data).length !== 0) {
      this.entity = this.data;
      this.selected_year = this.entity.selected_year;
      this.selected_month = this.entity.selected_month;
      this.selected_day = this.entity.selected_day;
    }
  },
  methods: {
    async submit() {

      this.entity.selected_year = this.selected_year;
      this.entity.selected_month = this.selected_month;
      this.entity.selected_day = this.selected_day;
      this.entity.date_of_birth = this.selected_year + "-" + ('0' + this.selected_month).slice(-2) + "-" + ('0' + this.selected_day).slice(-2);
      this.$emit('clickNext', this.entity);
    },
    getMasterData() {
      MasterDataService.index({
        is_gender: true,
        is_occupation: true,
        is_prefecture: true,
        is_mobile: true
      }).then(res => {
        this.is_mobile = res.data.is_mobile

        this.gender_options = res.data.genders.map(item => ({
          value: item.value,
          text: this.$t('registration.options.' + item.value),
        }))

        this.occupation_options = res.data.occupations

        this.prefecture_options = res.data.prefectures.map(item => ({
          value: item.value,
          text: item.label,
        }))
      });
      this.year_list = this.generateArrayOfYears();
      this.month_list = this.generateArrayOfMonth();
      this.day_list = this.generateArrayOfDay(this.selected_month, this.selected_year);
    },
    getMonthList() {
      const maxMonth = 12;
      const minMonth = 1;
      let result = []
      for (let i = minMonth; i <= (maxMonth - minMonth) + 1; i++) {
        let number = `${i}`
        number = number.length > 1 ? number : '0' + number;
        result.push(number)
      }
      return result;
    },
    getDayList() {
      const minDay = 1;
      let maxDay = 31;
      let result = [];

      if (this.selected_month != '' && this.selected_year != '') {
        maxDay = new Date(this.selected_year, this.selected_month, 0).getDate();
      }

      for (let i = minDay; i <= maxDay; i++) {
        let number = `${i}`
        number = number.length > 1 ? number : '0' + number;
        result.push(number)
      }
      return result;
    },
    onMonthChange() {
      this.day_list = this.generateArrayOfDay(this.selected_month, this.selected_year);
    },
    onPrefectureChange() {
      if (this.entity.prefecture_id) {
        this.entity.prefecture = (this.prefecture_options.find(
            res => res.value === this.entity.prefecture_id).text
        );
      }
    },
    async onSearchZipCode() {
      const regexp = /[0-9]{7}/;

      if (regexp.test(this.entity.postal_code)) {
        const res = await MasterDataService.index({
          is_postal_code: true,
          postal_code: this.entity.postal_code
        })

        const { postal_codes } = res.data

        if (postal_codes.length === 1) {
          const definite_postal_code = postal_codes[0]

          const { city, street_address, state } = definite_postal_code
          const prefecture = this.prefecture_options.find(prefecture => prefecture.text == state);

          this.entity.address = city.concat(street_address);
          this.entity.prefecture_id = prefecture ? prefecture.value : '';
          this.entity.prefecture = prefecture ? prefecture.text : '';
        } else if (postal_codes.length !== 0) {
          this.addresses = postal_codes.map((postal_code, index) => {
            const address = postal_code.state.concat(postal_code.city.concat(postal_code.street_address));

            return { id: index, name: address, postal_code: postal_code };
          });

          this.isShowPostalCodeSelectModal = true;
        }
      }
    },
    settingAddress(address) {
      if (address) {
        const { postal_code } = address
        const { city, street_address, state } = postal_code
        const prefecture = this.prefecture_options.find(prefecture => prefecture.text == state);

        this.entity.address = city.concat(street_address);
        this.entity.prefecture_id = prefecture ? prefecture.value : '';
        this.entity.prefecture = prefecture ? prefecture.text : '';
      }

      this.addresses = []
      this.isShowPostalCodeSelectModal = false

      setTimeout(() => {
        this.$refs.address1.focus();
      }, 500);
    },

    async handleShowAddressConfirmModal() {
      let valid = await this.$refs.regist_observer.validate();
      if (!valid) {
        this.scrollToFirstError()
        return;
      }
      this.isShowAddressModal = true
    },

    handleAddressConfirm() {
      this.isShowAddressModal = false
      setTimeout(() => {
        this.$nextTick(() => {
          this.isShowTermModal = true
        })
      }, 500)
    },
    handleAddressCancel() {
      this.isShowAddressModal = false;
    },
    handleTermConfirm() {
      this.isShowTermModal = false
      this.submit()
    },
    handleTermCancel() {
      this.isShowTermModal = false
    }
  },
  components: {
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button'),
    PostalCodeInput: () => import('@components/base/PostalCodeInput'),
    PostalCodeSelectModal: () => import('@components/base/PostalCodeSelectModal'),
    DeliveryAddressConfirmModal: () => import('@pages/purchase/Modal/DeliveryAddressConfirmModal'),
    TermOfIncompleteAddressConfirmModal: () => import('@pages/purchase/Modal/TermOfIncompleteAddressConfirmModal'),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
  }
}
</script>

<style scoped>

.text--50 {
  display: inline-block;
  width: 45%;
  margin-right: 1%;
}
</style>
