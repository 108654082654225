<template>
  <article>
    <Title :subtitle="$t('mypage.title.main_jp')" :title="$t('mypage.title.main')"></Title>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                       class="d-block text-right mb-20"></router-link>
          <Headline :title="$t('mypage.title.create')"></Headline>
          <p>{{ $t('mypage.title.create_subtitle') }}</p>
        </section>
        <section class="mb-30">
          <h4 class="main-title">{{ $t('mypage.basic_information.title') }}</h4>
          <dl class="common-dl">
            <dt>{{ $t('mypage.basic_information.name') }}</dt>
            <dd>{{ get_name }}</dd>
            <dt>{{ $t('mypage.basic_information.furigana') }}</dt>
            <dd>{{ get_name_hiragana }}</dd>
            <dt>{{ $t('mypage.basic_information.mail_address') }}</dt>
            <dd>{{ data.email }}</dd>
            <dt>{{ $t('mypage.basic_information.sex') }}</dt>
            <dd>{{ $t(`mypage.gender.${data.gender}`) }}</dd>
            <dt>{{ $t('mypage.basic_information.profession') }}</dt>
            <dd>{{ $t(`mypage.occupation.${data.occupation}`) }}</dd>
            <dt>{{ $t('mypage.basic_information.date_of_birth') }}</dt>
            <dd>{{ data.date_of_birth.split('-')[0] + $t('general.year') }} {{ monthName }}
              {{ data.date_of_birth.split('-')[2] + $t('general.day') }}
            </dd>
            <dt>{{ $t('mypage.basic_information.password') }}</dt>
            <dd>
              <router-link :to="{ name: current_artist ? 'artist.mypage.change-password' : 'mypage.change-password' }">
                <a class="c-blue link-line">パスワード変更はこちら</a>
              </router-link>
            </dd>
          </dl>
        </section>
        <section v-for="index in 3" :key="index" class="mb-30">
          <h4 class="main-title">{{ $t('mypage.product_delivery_address.title') }}{{ NOIndex(index) }}</h4>
          <dl class="common-dl">
            <dt>{{ $t('mypage.product_delivery_address.postal_code') }}</dt>
            <dd>{{
                data.shipping_addresses[index - 1] != null ? data.shipping_addresses[index - 1].postal_code : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
            <dt>{{ $t('mypage.product_delivery_address.prefecture') }}</dt>
            <dd>{{
                data.shipping_addresses[index - 1] != null ? data.shipping_addresses[index - 1].prefecture : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
            <dt>{{ $t('mypage.product_delivery_address.street_address') }}</dt>
            <dd>
              <div>{{
                  data.shipping_addresses[index - 1] != null ? data.shipping_addresses[index - 1].address : $t('mypage_confirmation.product_delivery_address.unregistered')
                }}
              </div>
              <div>{{
                  data.shipping_addresses[index - 1] != null ? data.shipping_addresses[index - 1].address_2 : $t('mypage_confirmation.product_delivery_address.unregistered')
                }}
              </div>
            </dd>
            <dt>{{ $t('mypage.product_delivery_address.phone_number') }}</dt>
            <dd>{{
                data.shipping_addresses[index - 1] != null ? data.shipping_addresses[index - 1].phone_number : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
          </dl>
          <router-link :to="{ name: current_artist ? 'artist.mypage.edit' : 'mypage.edit' }">
            <input v-if="data.shipping_addresses[index-1] != null" :value="$t('mypage.product_delivery_address.edit')" class="btn-100"
                   type="button">
            <input v-else :value="$t('mypage.product_delivery_address.add')" class="btn-100" type="button">
          </router-link>
        </section>
      </div>
    </section>
  </article>
</template>
<script>
import MemberService from '@/services/MemberService';
import {mapState} from 'vuex';

export default {
  data() {
    return {
      data: {
        id: "",
        first_name: "",
        last_name: "",
        first_name_hiragana: "",
        last_name_hiragana: "",
        email: "",
        gender: "",
        occupation: "",
        date_of_birth: "",
        shipping_addresses: []
      }
    }
  },
  methods: {
    getCurrentMemberLogin() {
      MemberService.index().then(res => {
        this.data = res.data;
      });
    },
    NOIndex(index) {
      if (index == 1) {
        return '①';
      } else if (index == 2) {
        return '②';
      } else {
        return '③';
      }
    }
  },
  mounted() {
    this.getCurrentMemberLogin();
  },
  computed: {
    get_name() {
      return this.data.last_name + ' ' + this.data.first_name;
    },
    get_name_hiragana() {
      return this.data.last_name_hiragana + ' ' + this.data.first_name_hiragana;
    },
    monthName() {
      let date = new Date(this.data.date_of_birth)
      return this.$t(`general.months.${date.getMonth()}`)
    },
    ...mapState([
      'current_artist'
    ])
  },
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline')
  },
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>