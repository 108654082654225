<template>
  <div :is="root" ref="content" :class="[selector, 'content__inner--editor']" :style="style" v-on="$listeners">
    <div
      v-if="blankToBr"
      v-bind="$attrs"
      ref="contentInner"
      v-html="$sanitize(content)"
      v-blank-to-br
    />
    <div
      v-else
      v-bind="$attrs"
      ref="contentInner"
      v-html="$sanitize(content)"
    />
    <div
      v-if="$slots.default"
      v-bind="$attrs"
      ref="contentInner"
    >
      <slot />
    </div>
    <div is="style">
      {{keepProportionStyle}}
    </div>
  </div>
</template>
<script>
import uniqStr from "../../helpers/uniqStr";
import keepProportionStyle from "../../helpers/keepProportionStyle";

export default {
  inheritAttrs: false,
  props: {
    /**
     * ルートのタグの要素名を指定
     */
    root: {
      type: String,
      default: 'div'
    },
    /**
     * 中身のコンテンツ
     */
    content: {
      type: String,
      default: ''
    },
    pWidth: {
      type: Number,
      default: 1000
    },
    pSpWidth: {
      type: Number,
      default: 400
    },
    gap: {
      type: Number,
      default: 10
    },
    gapSp: {
      type: Number,
      default: 10
    },
    spMaxWidth: {
      type: Number,
      default: 767
    },

    transformOrigin: {
      type: String,
      default: 'left top'
    },
    proportionTarget: {
      type: [ String, HTMLElement, Function ],
      default: null
    },
    otherStyle: {
      type: String,
      default: ''
    },
    blankToBr: {
      type: Boolean,
      default: false
    },
    keepInbox: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      ratio: 1,
      width: `auto`,
      height: `auto`,
      proportionTargetElement: null,
      proportionTargetVar: null,
    }
  },
  watch: {
    content() {
      setTimeout(() => {
        this.calculation()
      }, 300)
    },
    pWidth() {
      this.calculation()
    },
    pSpWidth() {
      this.calculation()
    },
    proportionTarget() {
      this.calculation()
    }
  },
  computed: {
    selector() {
      return `kp-${uniqStr()}`
    },
    style() {
      return {
        height: this.height + 'px'
      }
    },
    keepProportionStyle() {
      let style = keepProportionStyle(
        this.selector,
        this.pWidth,
        this.pSpWidth,
        this.gap,
        this.gapSp,
        this.proportionTargetVar,
        this.spMaxWidth,
        this.ratio,
        this.transformOrigin,
        this.keepInbox
      )

      if (this.otherStyle) {
        style += `\n${this.otherStyle}\n`
      }

      return style
    }
  },
  mounted() {
    // this.getProportionTargetElement()
    this.calculation()
    window.addEventListener('resize', this.calculation.bind(this))
  },
  destroyed() {
    window.removeEventListener('resize', this.calculation.bind(this))
  },
  methods: {
    getProportionTargetElement() {
      if (this.proportionTarget && !this.proportionTargetVar) {
        let target
        // console.log(this.$parent, this.$parent.$parent.$refs)
        if (typeof this.proportionTarget === 'function') {
          target = this.proportionTarget()
          if (Array.isArray(target)) {
            target = target[0]
          }
          // console.log("target:::::::", target)
        } else {
          target = typeof this.proportionTarget === 'string' ? document.querySelector(this.proportionTarget) : this.proportionTarget
        }
        this.proportionTargetVar = `--${this.selector}-w`
        this.proportionTargetElement = target
      }
    },
    setProportionalTargetProperty() {
      if (this.proportionTargetElement) {
        const width = this.proportionTargetElement.getBoundingClientRect().width
        this.$refs.content?.style.setProperty(this.proportionTargetVar, width)
        this.$forceUpdate()
      }
    },
    calculation() {
      // console.log(this.$refs.contentInner.getBoundingClientRect())
      // console.log(this.$refs.contentInner.clientHeight)
      // console.log(this.$refs.contentInner)
      this.getProportionTargetElement()
      this.setProportionalTargetProperty()
      setTimeout(() => {

        if (this.keepInbox && this.proportionTargetElement) {
          const width_result = this.$refs.contentInner?.getBoundingClientRect().width
          this.width = width_result || this.width

          const target_width = this.proportionTargetElement.getBoundingClientRect().width
          // console.log("WIDHTGGGGG", width_result)
          // console.log("TARGET", target_width)

          if (width_result !== target_width && target_width < width_result) {
            this.ratio = this.proportionTargetElement.getBoundingClientRect().width / this.width
          }
          // console.log("RATIOOOOOOOOOO::::::::", this.ratio)

        }
        this.height = this.$refs.contentInner?.getBoundingClientRect().height
      }, 100)
    }
  }
}
</script>
