<template>
  <BaseCategoryGoods
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubtitle"
    :msg-title="msgTitle"

    :category="category"
    :artist_page_url="artist_page_url"
    :event_page_url="event_page_url"

    :purchase-item="purchaseItem"
    :product_current_qty_in_cart="product_current_qty_in_cart"
    :product_current_total_include_tax="product_current_total_include_tax"
    :except_category_id="$route.params.category_id"
    :filters="filters"
    :product-standard="productStandard"
    :sale-products="saleProducts"

    :carousel-setting="CarouselSetting"

    :current-artist="current_artist"
    :sub-commodities="subCommodities"
    :combined-sale-products="combinedSaleProducts"

  />
</template>

<script>
import EventService from "@/services/EventService";
import SaleProductService from "@/services/SaleProductService";
import CategoryTmpService from "@/services/CategoryTmpService";
import Constants from '@constants';
import BaseCategoryGoods from "@pages/category/base/CategoryGoods"
import { mapState } from "vuex";
import CarouselSetting from "../../components/category/CarouselSetting";

export default {
  data() {
    return {
      artist_header_logo_image_url: '',
      category: {},
      artist_notice_open: false,
      purchaseItem: {
        qty: null
      },
      isMsgHidden: false,
      msgTitle: '',
      msgSubtitle: '',
      msgContent: '',

      subCommodities: [],
      combinedSaleProducts: {
        data: [],
        meta: {
          pagination: {
            current: 1,
            last: '',
            per: '',
            from: '',
            to: '',
            count: '',
            total: ''
          },
          has_caption: false
        }
      },

      filters: {
        event_id: null,
        type: Constants.CATEGORY_TYPE_GOOD,
        printed_product_standard_id: null,
        normal_product_standard_id: null,
        commodity_id: null
      },
      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: [],

      CarouselSetting

    }
  },
  methods: {
    getCategoryGoodsPageData() {
      CategoryTmpService.categoryGoodsPage({
        token: this.$route.params.token
      }).then(res => {
        this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden);
        if (res.data.is_msg_when_hidden) {
          this.isMsgHidden = true;
          this.msgTitle = res.data.msg_when_hidden_title;
          this.msgSubtitle = res.data.msg_when_hidden_date;
          this.msgContent = res.data.msg_when_hidden;
          this.$store.commit('SHOW_LAYOUT');
        } else {
          this.category = res.data;
          this.filters = {
            ...this.filters,
            event_id: res.data.event_id,
          }
        }
        this.getProductStandardByEvent();
        const { is_combined_product, product_standard_id } = res.data
        if (is_combined_product) {
          this.getSaleProductSubCommodity(product_standard_id)
        }
      });

    },

    getSaleProductSubCommodity(product_standard_id) {
      return SaleProductService.saleProductSubCommodity(product_standard_id).then(res => {
        this.subCommodities = res.data
      })
    },

    getProductStandardByEvent() {
      EventService.getProductStandardByEvent(this.filters.event_id).then(res => {
        const newData = {...this.product_standard, ...res.data}
        this.productStandard = newData
      })
    },

    getEventCategoryOrProduct() {
      SaleProductService.saleProductRelationInCategory(this.filters.event_id).then(res => {
        this.saleProducts = res.data ? res.data.filter(el => el.id != this.$route.params.category_id) : res.data;
      })
    },

  },
  created() {
    this.artist_header_logo_image_url = localStorage.getItem('header_logo_image_url')
    this.getCategoryGoodsPageData();
  },
  watch: {
    cartProduct: {
      deep: true,
      handler(val) {
        if (val) {
          this.purchaseItem.qty = val.cart_product_qty
        }
      }
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'current_artist'
    ]),
    cartCategory() {
      return this.$store.state.cart.cartDatas.find(el => el.category_id == this.category.category_id)
    },
    cartProduct() {
      if (this.cartCategory) {
        return this.cartCategory.cart_products.find(el => el.sale_product_id == this.$route.params.category_id)
      }
      return null;
    },
    product_current_qty_in_cart() {
      if (this.cartProduct) {
        return this.cartProduct.cart_product_qty
      }
      return 0;
    },
    product_current_total_include_tax() {
      if (this.cartProduct) {
        return this.cartProduct.cart_product_subtotal_include_tax
      }
      return 0;
    },
    event_sale_period() {
      return this.category.event_sale_period_start_date + ' - ' + this.category.event_sale_period_end_date;
    },
    artist_notice_compute() {
      return this.artist_notice_open ? 'block' : 'none';
    },
    artist_page_url() {
      let router = this.$router.resolve({
        name: 'artist.home',
      });
      return router.href;
    },
    event_page_url() {
      let router = this.$router.resolve({
        name: 'event.page',
        params: {
          event_id: this.category.event_id
        }
      });
      return router.href;
    },
  },
  components: {
    BaseCategoryGoods,
  }
}
</script>
