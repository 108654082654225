<template>
  <header :class="['header', 'js-header', { 'under_space': showUnderSpace}]">
    <template v-if="!isMobile">

      <div class="header__content">

        <h1 class="header__artist-name">
          <router-link :to="logoLink" v-if="headerLogo">
            <img :src="headerLogo">
          </router-link>
        </h1>

        <!--<pre-cart
          :show="showPreCart"
          :on-publish-key="onPublishKey.bind(this)"
          :on-restore-cart="onRestoreCart.bind(this)"
        />-->

        <nav class="header__nav">
          <ul>
            <li class="header__nav-item" v-if="!isLoggedIn">
              <router-link :to="{ name: 'auth.login'}">
                <img src="@images/icon_login.svg" alt="">
                <span class="c-black header__nav-menu">
                                {{ $t('general.login') }}
                            </span>
              </router-link>
            </li>
            <li class="header__nav-item" v-if="!isLoggedIn && !isMessageWhenHidden">
              <router-link :to="{ name: 'member.index', query: registerQuery }">
                <img src="@images/icon_join.svg" alt="">
                <span class="c-black header__nav-menu">
                                 {{ $t('general.register') }}
                            </span>
              </router-link>
            </li>
            <li class="header__nav-item" v-if="isLoggedIn">
              <div class="js-accordion-title mypage-link" @click="sidebarToggle" style="cursor: pointer">
                <img src="@images/icon_mypage.svg" alt="">
                <span class="c-black header__nav-menu">
                                {{ $t('general.mypage') }}
                            </span>
              </div>
              <div class="user-menu">
                <ul class="user-menu__list">
                  <li class="user-menu__name">
                    {{ $t('general.mypage') }}
                  </li>
                  <li class="user-menu__item">
                    <router-link @click.native="sidebarToggle" class="c-black al-l"
                                 :to="{ name: current_artist ? 'artist.mypage.purchase_history' : 'mypage.purchase_history' }">
                      {{ $t('general.history') }}
                    </router-link>
                  </li>
                  <li class="user-menu__item">
                    <router-link @click.native="sidebarToggle" class="c-black al-l"
                                 :to="{ name: current_artist ? 'artist.mypage.index' : 'mypage.index' }">
                      {{ $t('general.profile') }}
                    </router-link>
                  </li>
                  <li class="user-menu__item">
                    <router-link
                      @click.native="sidebarToggle"
                      class="c-black al-l"
                      :to="{ name: current_artist ? 'artist.mypage.change-password' : 'mypage.change-password' }"
                    >
                      {{ $t('general.change_password') }}
                    </router-link>
                  </li>
                  <li class="user-menu__item" v-if="!!designate_page">
                    <router-link
                      @click.native="sidebarToggle"
                      class="c-black al-l"
                      :to="{ name: 'repurchase.reshipping'}"
                    >
                      再配送手続
                    </router-link>
                  </li>
                  <li class="user-menu__item" v-if="!!designate_page">
                    <router-link
                      @click.native="sidebarToggle"
                      class="c-black al-l"
                      :to="{ name: 'repurchase.reorder'}"
                    >
                      再注文手続
                    </router-link>
                  </li>
                  <li class="user-menu__item">
                    <a class="c-black al-l logout" @click="logout">{{ $t('general.logout') }}</a>
                  </li>
                  <li class="user-menu__item">
                    <router-link @click.native="sidebarToggle" class="c-black al-l"
                                 :to="{ name: current_artist ? 'artist.mypage.cancel' : 'mypage.cancel' }">
                      {{ $t('general.mypage_cancel') }}
                    </router-link>
                  </li>
                </ul>
              </div>
            </li>
            <li class="header__nav-item" v-if="isShowCart" :key="isMessageWhenHidden">
              <Cart
                :cart-category="cartCategory"
                :cart-sheet-amount="cartSheetAmount"
                @to-cart="$emit('to-cart')"
              />
            </li>
          </ul>
        </nav>

      </div>

    </template>

    <template v-else>
      <div class="header__content__wrapper">

        <div class="header__content">

          <h1 class="header__artist-name">
            <router-link :to="logoLink" v-if="headerLogo">
              <img :src="headerLogo">
            </router-link>
          </h1>

          <nav class="header__nav">
            <ul>
              <li class="header__nav-item" v-if="!isLoggedIn">
                <router-link :to="{ name: 'auth.login'}">
                  <img src="@images/icon_login.svg" alt="">
                  <span class="c-black header__nav-menu">
                                {{ $t('general.login') }}
                            </span>
                </router-link>
              </li>
              <li class="header__nav-item" v-if="!isLoggedIn && !isMessageWhenHidden">
                <router-link :to="{ name: 'member.index', query: registerQuery }">
                  <img src="@images/icon_join.svg" alt="">
                  <span class="c-black header__nav-menu">
                                 {{ $t('general.register') }}
                            </span>
                </router-link>
              </li>
              <li class="header__nav-item" v-if="isLoggedIn">
                <div class="js-accordion-title mypage-link" @click="sidebarToggle" style="cursor: pointer">
                  <img src="@images/icon_mypage.svg" alt="">
                  <span class="c-black header__nav-menu">
                                {{ $t('general.mypage') }}
                            </span>
                </div>
                <div class="user-menu">
                  <ul class="user-menu__list">
                    <li class="user-menu__name">
                      {{ $t('general.mypage') }}
                    </li>
                    <li class="user-menu__item">
                      <router-link
                        @click.native="sidebarToggle"
                        class="c-black al-l"
                        :to="{ name: current_artist ? 'artist.mypage.purchase_history' : 'mypage.purchase_history' }"
                      >
                        {{ $t('general.history') }}
                      </router-link>
                    </li>
                    <li class="user-menu__item">
                      <router-link
                        @click.native="sidebarToggle" class="c-black al-l"
                        :to="{ name: current_artist ? 'artist.mypage.index' : 'mypage.index' }"
                      >
                        {{ $t('general.profile') }}
                      </router-link>
                    </li>
                    <li class="user-menu__item">
                      <router-link
                        @click.native="sidebarToggle"
                        class="c-black al-l"
                        :to="{ name: current_artist ? 'artist.mypage.change-password' : 'mypage.change-password' }"
                      >
                        {{ $t('general.change_password') }}
                      </router-link>
                    </li>
                    <li class="user-menu__item">
                      <router-link
                        @click.native="sidebarToggle"
                        class="c-black al-l"
                        :to="{ name: 'repurchase.reshipping'}"
                      >
                        再配送手続
                      </router-link>
                    </li>
                    <li class="user-menu__item">
                      <router-link
                        @click.native="sidebarToggle"
                        class="c-black al-l"
                        :to="{ name: 'repurchase.reorder'}"
                      >
                        再注文手続
                      </router-link>
                    </li>
                    <li class="user-menu__item">
                      <a class="c-black al-l logout" @click="logout">{{ $t('general.logout') }}</a>
                    </li>
                    <li class="user-menu__item">
                      <router-link @click.native="sidebarToggle" class="c-black al-l"
                                   :to="{ name: current_artist ? 'artist.mypage.cancel' : 'mypage.cancel' }">
                        {{ $t('general.mypage_cancel') }}
                      </router-link>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="header__nav-item" v-if="isShowCart" :key="isMessageWhenHidden">
                <Cart
                  :cart-category="cartCategory"
                  :cart-sheet-amount="cartSheetAmount"
                  @to-cart="$emit('to-cart')"
                />
              </li>
            </ul>
          </nav>

        </div>

        <!--<pre-cart
          :show="showPreCart"
          :on-publish-key="onPublishKey.bind(this)"
          :on-restore-cart="onRestoreCart.bind(this)"
        />-->

      </div>
    </template>

  </header>
</template>

<script>
import $ from 'jquery'
//import PreCart from "@components/cart/PreCart/PreCart";

const SHOW_PRE_CART_PAGE_NAME = [
  'category.page',
  'category_goods.page',
  'cart.page',
]

const NO_UNDER_SPACE_PAGE = [
  'artist.home',
  'event.page',
  'category.page',
  'category_goods.page',
]

export default {
  name: 'HeaderLayout',
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  data() {
    return {
      bromide_logo: require('@assets/images/logo.png'),
      window: {
        width: 0,
        height: 0
      }
    }
  },
  props: {
    logo: {
      type: String,
      required: false,
    },
    logoLink: {
      type: [ String, Object ],
      required: false,
      default: '/',
    },
    current_artist: {
      type: Object,
      default: null,
    },
    designate_page: {
      type: [String, Array, Number, Object, Boolean],
      default: null,
    },
    isMessageWhenHidden: {
      type: Boolean,
      default: false,
    },
    isShowCart: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    cartCategory: {
      type: Object,
      default: null,
    },
    cartSheetAmount: {
      type: [String, Number],
      default: 0,
    },
    onPublishKey: {
      type: Function,
      default: async () => ''
    },
    onRestoreCart: {
      type: Function,
      default: async () => ''
    }
  },
  methods: {
    sidebarToggle() {
      $('.js-accordion-title').next().slideToggle(200)
      $('.user-menu').toggleClass('open', 200)
    },
    logout() {
      this.$emit('logout')
    },
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
  computed: {
    headerLogo() {
      return this.current_artist?.header_logo_image_url || this.bromide_logo
    },
    registerQuery() {
      if (this.current_artist) {
        return { current_artist: this.current_artist.designate_page_directory }
      }

      return undefined
    },
    currentPathName() {
      return this.$route.name
    },
    showUnderSpace() {
      return !NO_UNDER_SPACE_PAGE.includes(this.currentPathName)
    },
    showPreCart () {
      return SHOW_PRE_CART_PAGE_NAME.includes(this.currentPathName)
    },
    isMobile() {
      return this.window.width < 768
    }
  },
  components: {
    //PreCart,
    Cart: () => import('./Cart.vue'),
  },
}
</script>
<style scoped>
.logout {
  cursor: pointer;
}
</style>
