<template>
  <article>
    <Title subtitle="メールアドレス事前確認" title="Email Confirmation"/>
    <div class="content__inner">

      <Headline title="メールアドレス事前確認を受け付けました"></Headline>

      <h4 class="main-title">ご入力いただきましたメールアドレスに最終手続きのご案内メールを送信しました</h4>
      <p class="mb-20 c-red bold">【ご注意】まだ登録は完了していません。</p>

      <p class='mb-50'>送信されたメールに記載されている登録確認のURLリンクを３時間以内にアクセスしてメールアドレス事前確認を完了させてください。<br>この利用登録の手続きが完了しましたら、ログインの上お買い物をお楽しみください。 </p>

    </div>
  </article>
</template>

<script>
import Title from "@components/base/Title";
export default {
  components: {
    Title,
    Headline: () => import('@components/base/Headline'),
  }
}
</script>
