import ReshippingService from "@services/ReshippingService";
import ReorderService from "@services/ReorderService";


export default {
  state : {
    userCartData: null,
    userPurchaseInfo: null,
    userPaymentInfo: null,
    payments: null,
    type: '',
    token: '',
  },
  getters: {
    repurchaseType(state) {
      // console.log('state', state)
      return state.type
    },
    repurchaseData(state) {
      // console.log('state', state)
      return state.data
    },
    repurchasePayments(state) {
      // console.log('state', state)
      return state.payments
    },
    repurchaseUserCartData(state) {
      return state.userCartData
    },
  },

  mutations: {
    SET_REPURCHASE_DATA(state, { data, type, token }) {
      const userCartData = {
        tax_rate: data.tax_rate,
        subtotal_include_tax: data.subtotal_include_tax,
        total_purchase_amount_without_payment_fee: data.total_purchase_amount_without_payment_fee,
        cart_shippings: data.cart_shippings
      }
      state.userCartData = userCartData
      state.payments = data.payments
      state.type = type
      state.token = token
      // console.log('SET_REPURCHASE_DATA', state)
    },
    APPLY_REPURCHASE_SUCCESS() {
      // console.log('APPLY_REPURCHASE_SUCCESS')
    },
    SET_USER_REPURCHASE_INFO(state, data) {
      state.userPurchaseInfo = data
    },
    SET_USER_REPURCHASE_PAYMENT_INFO(state, data) {
      state.userPaymentInfo = data
    },
  },

  actions: {
    async getRepurchase({ commit }, {type, token}) {
      try {
        let response;

        if (type === 'reorder') {
          response = await ReorderService.getReorder(token)
        } else if (type === 'reshipping') {
          response = await ReshippingService.getOrderReshipping(token)
        }

        if (!response) {
          throw new Error('再注文・再発送情報の取得処理が実行されませんでした')
        }

        // console.log('in getRepurchase', response)

        commit('SET_REPURCHASE_DATA', { data: response.data, type, token })

        return response
      } catch (e) {
        console.log(e)

        throw e
      }
    },
    async applyRepurchase({commit,state}, data) {

      return new Promise( (resolve, reject) => {
        try {
          let response;
          if (state.type === 'reorder') {
            response = ReorderService.applyReorder(state.token, data)
          } else if (state.type === 'reshipping') {
            response = ReshippingService.applyOrderReshipping(state.token, data)
          }
          // console.log('applyRepurchase', response)
          commit('APPLY_REPURCHASE_SUCCESS')
          resolve(response)
        } catch (e) {
          console.log('promise errro!', e)
          reject(e)
        }
      })
      //
      // try {
      //   let response;
      //   if (state.type === 'reorder') {
      //     response = await ReorderService.applyReorder(state.token, state.data)
      //   } else if (state.type === 'reshipping') {
      //     response = await ReshippingService.applyOrderReshipping(state.token, state.data)
      //   }
      //   console.log('response', response)
      //   await commit('SAVE_REPURCHASE_DATA', { data: response.data })
      //   return response
      // } catch (e) {
      //   console.log(e)
      // }

    }
  },

}
