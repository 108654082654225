/**
 * フォントサイズを自動調節して特定のフォントサイズを画面幅が変わっても維持するスタイル文字列を生成する
 * @returns {string}
 */
export default (
  selector = '',
  pWidth = 800,
  pSpWidth = 400,
  gap = 10,
  gapSp = 10,
  proportionTargetVar = null,
  spMaxWidth = 767,
  proportionTargetContentRatio = 1,
  transformOrigin = 'left top',
  keepInbox = false
) => {
  let result = ''

  // const proportionTarget = proportionTargetVar ? `var(${proportionTargetVar}) * 1` : 'var(--vwv) * 100'
  // const proportionTarget = proportionTargetVar ? `var(${proportionTargetVar}) * 1` : 'var(--vwv) * 100'
  const proportionTarget = proportionTargetVar ? `var(${proportionTargetVar}) * ${keepInbox ? proportionTargetContentRatio : 1}` : 'var(--vwv) * 100'

  result += `
      .${selector} {
        width: ${pWidth}px;
        transform-origin: ${transformOrigin};
        transform: scale(calc((${proportionTarget} - ${gap})  / ${pWidth}));
        letter-spacing: 0.1rem;
        word-break: break-all;
        line-break: anywhere;
      }
      @media screen and (min-width: ${pWidth}px) {
        .${selector} {
          width: ${pWidth}px;
          transform: ${keepInbox ? `scale(calc((${proportionTarget} - ${gap})  / ${pWidth}));` :'scale(1);'}
        }
      }
      @media screen and (max-width: ${spMaxWidth}px) {
        .${selector} {
          width: ${pSpWidth}px;
          transform: scale(calc((${proportionTarget} - ${gapSp}) / ${pSpWidth}));
        }
      }
      `

  return result
}
