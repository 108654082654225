export default {
  before: 'The {field} field must before {target}',
  after: 'The {field} field must after {target}',
  decimal: 'The {field} field must contain only decimal values',
  phone_number: 'Please enter the correct phone number. Half-width numbers and no hyphens',
  email: 'The {field} field must be a valid email',
  trim_space: 'The {field} field must not have left or right space',
  trim_left_space: 'The {field} field must not have left space',
  zip_code: 'The {field} field must be a valid zip code',
  confirm: 'The {field} field does not match',
  checkbox: 'The {field} is required',
  date_format: 'The {field} do not macth the formate {format}'
}