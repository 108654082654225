export default {
  product_sales_and_product_delivery: "商品の販売・商品の納品はBromide.JP",
  bromide_co_ltd_will_do_its: "株式会社Bromideが行います。",
  notation_of_specified_commercial_transactions_law: "特定商取引法の表記",
  email_settings: "メール設定",
  if_the_customer_has_set_the_domain: "お客様が迷惑メール対策などで、ドメイン指定受信を設定されている場合に、メールが正しく届かないことがございます。\n購入に関する大事なメールが届かず、お問い合わせいただいてもBromide.JPからの返信も受け取れません。",
  please_set_so_that_you_can_receive_the_following_domains: "以下のドメインを受信できるように設定してください。",
  about_email_reception_settings_at_the_time_of_purchase: "購入時のメール受信設定について",
  when_paying_at_a_convenience_store: "コンビニ支払時に、決済会社より支払番号がメールで送られます。",
  please_cancel_the_following_domains: "以下のドメインを迷惑メール設定から解除、又は指定受信アドレスとして登録をお願いいたします。",
  about_the_contents_of_the_email_from_the_settlement_company: "決済会社からのメール内容について",
  you_will_receive_an_email_from_the_settlement: "決済会社からは以下のような件名でメールが届きます。",
  subject_notice_of_completion_of_convenience_store_payment_request: "【件名】コンビニ決済依頼完了のお知らせ",
  address_address_dedicated_to_sending_payment_services: "【アドレス】決済サービス送信専用アドレス",
  about_product_rights_after_purchase: "購入後の商品の権利について",
  ownership_of_the_purchased_product_will_be: "ご購入いただきました商品については、商品の引渡をもって、所有権が当社からお客様に移転します。",
  however_this_is_only_for_photographic_products: "ただし、写真商品（固形物）のみとなります、商品の著作権と被写体の肖像権・商標権、その他の利用権等の諸権利の取得および使用許諾に係わる料金は一切含まれておりません。",
  even_if_the_customer_purchases_the_product: "客様が商品を購入した場合でも、その商品の著作権と、被写体の肖像権・商標権等は、お客様に譲渡されていない事に留意し、それらの権利はお守りいただきますようお願いいたします。",
  sales_conditions: "販売条件",
  about_payment_and_delivery: "お支払・配送について",
  selling_price: "販売価格",
  as_described_on_the_detail_page_of_each_product_indicated_with_tax: "各商品の詳細ページに記載の通り（税込にて表記）",
  expenses_other_than_the_product_price: "商品代金以外の費用",
  consumption_tax_shipping_fee_packing_fee: "消費税、送料・梱包費用・コンビニ決済手数料(6000円以下)",
  method_of_payment: "支払い方法",
  web_money_payment_service: "WebMoney決済サービス",
  credit_card_payment: "クレジットカード決済",
  docomo_carrier_payment: "ドコモキャリア決済",
  softbank_carrier_settlement: "ソフトバンクキャリア決済",
  au_easy_payment: "auかんたん決済",
  product_shipping_time: "商品発送時期",
  period_for_common_delivery_please_check: "■共通する納品に関する期間　各商品ページに記載されている営業日をご確認ください。\nまた、土日祝際、ゴールデンウィーク、年末年始は製造がおやすみとなりますのでご注意ください。\n出荷後は地域によって宅配業者の配達が予定日より遅れることがあります。受注承認後、納品予定日のお知らせがメールで届きます。",
  when_the_shipment_is_confirmed_we_will: "出荷確定時は出荷メールを送付致しますのでご確認ください。",
  photo_sales_products_l_2_l_a_3_etc: " ●写真販売製品（L、2L～A3等)",
  after_confirming_your_order_we_plan_to_deliver_it_in_about_5_business_days: "ご注文確定後、約５営業日ほどでのお届けを予定しております。",
  the_delivery_method_is_basic: "発送方法は基本ゆうメール【ポスト投函】となります。 選択表示に宅配が表示された場合は宅配も選択可能です。費用は宅配費用が適応されます。",
  notes_when_yu_mail_post_mailing_is_selected: "※ゆうメール【ポスト投函】を選択された場合の注意事項",
  delivery_will_be_completed_by_post_mailing: "ポスト投函をもって配達完了となります。",
  we_cannot_confirm_receipt: "受け取りに関する確認はいたしかねます。",
  there_is_no_insurance: "保険等も一切ついておりません。",
  we_cannot_make_arrangements_for_reshipment: "再発送等の手配も行えません。",
  it_is_the_customer_s_own_responsibility: "受取完了、未完了にかかわらず全てお客様の自己責任になります。",
  please_choose_the_product_shipping: "以上のことに留意し商品発送方法をお選び下さい。",
  goods_products_photo_poster_t_shirt_with_photo: "●グッズ製品（写真ポスター//写真ポスターフレーム付Ｔシャツ/枕等）",
  we_plan_to_deliver_according_to_the_sales_period: "販売ページに記載されている販売期間及び受注締め切り、発送予定日の表記に従ったお届けを予定しております。発送方法は基本宅急便となります。",
  artist_goods_official_goods_c_ds_paper_pop_etc: "●アーティストグッズ(公式グッズ類、CD、紙POP等)",
  the_shipping_time_may_change_depending_on_the_manufacturing: "なお各商品の製造・在庫・天候状況により発送時期が変わる場合がございますが、 その際にはメール又はwebページ、マイページ等にて新しい発送時期をご連絡致します",
  returns_cancellations: "返品・キャンセル",
  we_do_not_accept_returns_or_cancellations: "ご注文後のお客様のご都合による返品・キャンセルは、一切受け付けておりませんが、以下の場合にのみ返品・お取替えを受け付けます。",
  however_if_any_of_the_following_items_apply_we: "ただし、以下の各号のいずれかに該当する場合、商品を受領した時から8日以内に当社に通知頂くことで無償で代替品を送付し、数量不足の場合は追加分を送付します。",
  if_you_receive_a_damaged_soiled_or_other: "1.破損、汚損その他毀損した商品を受領した場合（写真以外の商品に限ります）。",
  if_a_hidden_defect_is_found: "2.隠れた瑕疵が発見された場合（写真以外の商品に限ります）。",
  when_you_receive_a_product: "3.注文した商品・数量と異なる商品・数量を受領した場合。",
  we_will_bear_the_return_shipping_fee: "当社瑕疵による返品・お取替えにかかる返品送料は当社負担と致します",
  when_requesting_a_return_or_replacement: "返品・お取替えのお申出の際は、宅配便等の発送した証明が残る形態でご返送いただき、お手元に控えを保管してください。発送した証明のない形態でご返送された場合、発送中の紛失、破損等の事故に関して、当社では責任を負いかねます。",
  shipping_cost: "配送費用",
  shipping_costs_include_packing_costs_material_costs_consumption_tax_etc: "配送費用には梱包費用、資材費用、消費税等を含んでおります。",
  for_the_delivery_method_yu_mail_post_mailing: "配送手段(ゆうメール【ポスト投函】、宅配便)はカートに表示されている配送手段を選択頂きます。 配送手段が選択できない単一表示の場合は配送手段は選択できません。表示されている 配送手段となります。",
  the_delivery_company_will_be_japan_post: "配送会社は日本郵便・ヤマト運輸となります。配送会社の選択はできませんので あらかじめご了承願います。",
  if_the_shipping_cost_is_displayed_in_multiple_carts: "配送費用が複数カートに表示されるば場合は、同一梱包にできない場合に表示されます。 その際は、表示されている配送方法(ゆうメール【ポスト投函】、宅配便)を選択し配送費用をご負担頂きます。",
  examples_that_cannot_be_bundled: "同梱できない例",
  when_the_location_of_the_manufacturing_factory_is_different: "製造工場の立地が別の場合",
  combination_of_products_that_cannot_be_bundled_with_the_photo: "写真と同梱ができない商品の組み合わせ",
  combination_of_products_such_as_photographs_and_poster_products: "写真とポスター製品等の商品の組み合わせ",
  combination_of_products_with_different_shipping_timings: "出荷のタイミングが違う商品の組み合わせ",
  in_addition: "その他、商品詳細に同梱できませんのご案内がある場合等",
  individual_cost_of_delivery_method_and_delivery_method: "●配送手段と配送方法の個別費用",
  yu_mail_post_mailing_uniform_nationwide: "■ゆうメール【ポスト投函】　全国一律",
  japan_post_yu_mail_post_mailing_216: "日本郵便　ゆうメール【ポスト投函】　216円（税込）＜2019年10月より220円（税込）＞",
  yu_mail_post_mailing_will_be_delivered_2_to_5: "ゆうメール【ポスト投函】のお届けは、ご注文確定後から2日～5日後がお届けの目安です。",
  please_note_that_yu_mail_post_mailing: "※ゆうメール【ポスト投函】は郵便受けに投函されますのでご了承ください。",
  please_note_that_yu_mail_post_mailing_cannot_track: "※ゆうメール【ポスト投函】は配送状況の追跡を行うことができませんのでご了承ください",
  please_note_that_yu_mail_post_mailing_does_not_come_with_any_insurance: "※ゆうメール【ポスト投函】は保険等は一切ついておりませんのでご了承ください",
  the_estimated_delivery_date_of_the_product: "商品のお届け目安は、確実なお届け日をお約束するものではございません。",
  please_note_that_delivery_may_be_delayed_due_to_weather: "天候や道路交通状況、配送業者の都合により、配達が遅れる場合もございますのでご了承ください。",
  please_note_that_the_delivery_date_may_be_longer: "年末年始や大型連休時は納期が長くなる場合がございますのでご了承ください。",
  please_note_that_we_cannot_accept_requests: "納期短縮のご要望は承りかねますのでご了承ください。",
  please_note_that_we_do_not_accept_delivery_time_designation: "配達時間帯指定は承っておりませんのでご了承ください。",
  yu_mail_post_mailing_will_be_delivered_on_the_next_weekday: "ゆうメール【ポスト投函】は、お届け日が日曜日・祝日の場合は翌平日のお届けとなります。(日曜日・祝日は郵便局からのお届けはありません。)",
  cash_on_delivery_is_not_supported: "代金引換払いは対応しておりません。",
  courier_service: "■宅配便",
  shipping_center_tokyo: "発送センター東京",
  yamato_transport_takkyubin_nationwide: "ヤマト運輸　宅急便　　全国一律(離島・沖縄除く)160サイズ迄　832円（税込）＜2019年10月より848円（税込）＞",
  up_to_160_sizes_of_remote_islands_and_okinawa: "離島・沖縄160サイズ迄　2,736円（税込）＜2019年10月より2,790円（税込）＞",
  i_checked_the_email_settings: "メール設定について確認しました",
  i_confirmed_the_rights_of_the_product: "商品の権利について確認しました",
  i_confirmed_the_sales_conditions: "販売条件について確認しました",
  continue_shopping: "ショッピングを続ける",
  to_purchase_procedure: "購入手続きへ",
  i_have_not_received_email_regarding_my_purchase_title: `
        購入に関する大事なメールが届かず、せっかくの<br class="s-elem"/>お問い合わせの回答をお届けできません。<br>
        以下の内容をご確認いただき、速やかに注文確認、<br class="s-elem"/>決済、発送等のメールを受信できるようにお願い致します。
    `,
  common_to_all_customers: `お客様全員共通`,
  if_the_customer_is_set_to_receive_by_specifying_domain_description: `
        お客様が迷惑メール対策などで、ドメイン指定受信を<br class="s-elem"/>設定されている場合や、意図せずに設定がされている場合、<br>
        メールが正しく届かないことがございます。<br>
        Bromide.jpからの返信を受け取れない可能性が<br class="s-elem"/>ございます。<br><br>
        以下のドメインを受信できるように設定してください。
    `,
  customers_planning_to_use_combination_payment_title: `コンビニ決済をご利用予定のお客様`,
  after_selecting_convenience_store_payment_and_completing_the_purchase_procedure_description: `
        コンビニ決済を選択され、購入手続き完了後に、<br class="s-elem"/>決済会社より「コンビニ決済依頼完了のお知らせ」が<br class="s-elem"/>メールで届きます。<br>
        こちらの情報が得られないとお支払ができません。<br>
        迷惑メール設定を解除又は指定受信アドレスとして登録を<br class="s-elem"/>お願い致します。<br><br>

        以下のドメインを受信できるように設定してください。
    `,
  customers_using_free_mail: `フリーメールをお使いのお客様`,
  please_setup_so_that_you_can_receive_emails: `
        上記、当サービスの@bromide.jp及び、<br class="s-elem"/>決済会社system@p01.mul-pay.comのメール受信を<br class="s-elem"/>できるように設定してください。<br>
        お使いのフリーメールサービス、メールソフトなどの<br class="s-elem"/>設定により「迷惑メール」と認識され、<br class="s-elem"/>メールが届かない場合があります。<br>
        メールが届かない場合には、迷惑メールフォルダー及び<br class="s-elem"/>お使いのサービス、ソフトウェアの設定をご確認ください。
    `,
  the_customer_rights_to_the_photographic_product: `
        写真商品のお客様の権利は、購入された印画紙に<br class="s-elem"/>プリントされた商品の所有権のみとなります。<br>
        写真商品の著作権・被写体の肖像権・商標権、<br class="s-elem"/>その他の利用権等の諸権利の取得および使用許諾は<br class="s-elem"/>されておりません。<br>
        複写、複製は権利侵害として法廷な処罰、民事上の賠償請求<br class="s-elem"/>を受けますので、このようなことをしてはいけません。<br>
    `
}
