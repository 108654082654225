export default {
  login_title: "Login",
  login_subtitle: "ログイン",
  login: "ログイン",
  submit_login: "ログインする",
  message: "メールアドレスとパスワードを入力して<br class='s-elem'/>ログインしてください。",
  email: "メールアドレス",
  unauthorized: "パスワードが違います。",
  password: "パスワード",
  invalid:
    "あなたが入力したメールアドレスまたはパスワードが正しくありません。",
  remember: "一ヶ月間ログイン状態を保存する",
  forgot_password: "パスワードを忘れた場合はこちら",
  return_to_login_page: "ログインページへ戻る",
  unverified: "メールが確認されていません。ログインするには、まずメールアドレスを確認する必要があります。",
  resend_verificatioh: "メール認証を再送信",
  reset_pw: {
    title: "パスワード再設定申請",
    msg_1: "パスワードを再設定します",
    msg_2: "ご希望のパスワードを入力して「再設定」<br class='s-elem'/>ボタンをクリックしてください。",
    msg_3: "",
    date_of_bith: "生年月日",
    year: "年",
    month: "月",
    day: "日",
    send_email: "メールを送信する",
    email_placeholder: "登録メールアドレスを入力してください",
    invalid: "ご入力されたメールアドレスと生年月日が一致しません。"
  },
  reset_pw_mail: {
    title: "パスワード再設定申請・メール送信",
    msg: "ご指定のメールアドレスに、パスワード再設定用のメールを送信しました。",
    msg_expire: "24時間以内にメールに書かれたURLにアクセスして、パスワードを再設定してください。",
  },
  new_pw: {
    title: "パスワード再設定申請",
    msg_1: "パスワードを再設定します。",
    msg_2: 'ご希望のパスワードを入力して「再設定」ボタンをクリックしてください。',
    new_pw: "新しいパスワード",
    new_pw_hint: "半角英数字8文字以上32文字以内で大文字・小文字・数字・記号（#?!@$%^&*-_ のいずれか）を含んだ文字列",
    confirm_pw: "パスワード確認用",
    new_pw_placeholder: "パスワードを入力してください。",
    confirm_pw_placeholder: "パスワードを再度入力してください。",
    reset: "再設定する",
    invalid: "パスワード再設定用のURLが異なるまたは期限切れです。再度パスワード再設定申請をお願い致します。"
  },
  reset_pw_complete: {
    title: "パスワード再設定申請・完了",
    msg_1: "パスワードの再設定が完了しました。",
    msg_2: "早速ログインしてショッピングをお楽しみください。"
  }
};
