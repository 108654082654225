<template>
  <MainLayout
    :cart-category="cartCategory"
    :cart-sheet-amount="cartSheetAmount"
    :current_artist="current_artist"
    :designate_page="$route.params.designate_page"
    :error_message="$store.state.error_message"
    :footer-area-color="footerAreaColor"
    :footer-text="footerText"
    :footer-text-color="footerTextColor"
    :has_error="$store.state.has_error"
    :has_maintenance="$store.state.has_maintenance"
    :hide-layout="$store.state.hideLayout"
    :is-loading="isLoading"
    :is-logged-in="isLoggedIn"
    :is-msg-when-hidden="isMsgWhenHidden"
    :is-show-cart="isShowCart"
    :logo-link="logoLink"
    :mainClass="mainClass"
    :on-publish-key="handlePublishKey"
    :on-restore-cart="handleRestoreCart"
    :hide="!pageInit"
    @logout="logout"
    @to-cart="gotoCart"
    @error-confirmed="errorConfirmed"
    @maintenance-confirmed="maintenanceConfirmed"
  >
    <router-view/>
  </MainLayout>
</template>

<script>
import {mapActions, mapState, mapMutations} from 'vuex'
import ArtistService from "@/services/ArtistService";

const disableLinkPageName = [
  'artist.preview',
  'event.preview',
  'category.preview',
  'goods-category.preview'
]

export default {
  components: {
    MainLayout: () => import('./components/layouts/MainLayout'),
  },
  data() {
    return {
      artist_referrer: '',
      is_render: false,
    }
  },
  props: {
    logo: {
      type: String,
      required: false,
    },
    mainClass: {
      type: String,
      required: false,
      default: '',
    },
    footerText: {
      type: String,
      required: false,
      default: '(C) bromide.JP',
    },
    footerTextColor: {
      type: String,
      required: false,
      default: '#ffffff',
    },
    footerAreaColor: {
      type: String,
      required: false,
    },
  },
  computed: {
    ...mapState([
      'current_artist',
      'isLoading',
      'pageInit'
    ]),
    artist_url() {

      const {href} = this.$router.resolve({
        name: 'artist.home',
        params: {
          designate_page: this.designatePageDirectory
        }
      })

      return href
    },
    logoLink() {
      if (disableLinkPageName.includes(this.$route.name)) {
        return {}
      } else {
        return this.artist_url || '/'
      }
    },
    designatePageDirectory() {
      return this.current_artist?.designate_page_directory
    },
    isMsgWhenHidden() {
      return !!this.$store.getters.getIsMsgWhenHidden
    },
    isShowCart() {
      return !!this.$route.params.designate_page && !this.isMessageWhenHidden
    },
    isLoggedIn() {
      return this.$store.state.isLogged
    },
    cartCategory() {
      return this.$store.state.cart.cartDatas.find(el => el.category_id == this.$route.params.category_id)
    },
    cartSheetAmount() {
      return this.$store.state.cart.cartSheetAmount
    },
  },
  methods: {
    ...mapActions([
      'getCartData'
    ]),
    ...mapMutations([
      'RESET_GLOBAL_ERROR'
    ]),
    logout() {
      this.$store.dispatch('logout').then((res) => {
        let path_name = res ? 'artist.home' : 'top'

        if (this.$route.name != path_name) {
          this.$router.push({name: path_name})
        } else {
          this.$router.go()
        }
      })
    },
    gotoCart() {
      if (this.$route.name == 'cart.page') {
        return
      }

      if (
        this.$route.name === 'category.page' &&
        this.cartCategory &&
        this.cartCategory.cart_category_total_qty % this.cartCategory.purchasable_unit != 0
      ) {
        return this.$store.dispatch('setPrintedSetPopup', true)
      }

      return this.$router.push({name: 'cart.page'})
    },
    errorConfirmed() {
      // this.$store.state.has_error = false
      this.RESET_GLOBAL_ERROR()
    },
    maintenanceConfirmed() {
      window.location.reload()
    },
    /**
     * 商品休憩の処理メソッド
     * 一旦ヘッダーから削除されたが、一旦残しておく
     * @param data
     * @returns {Promise<*>}
     */
    async handlePublishKey(data) {
      const artistId = this.current_artist.id
      return ArtistService.savePreCart(artistId, data)
    },
    async handleRestoreCart(pubKey) {
      const artistId = this.current_artist.id
      await ArtistService.restorePreCart(artistId, pubKey)
      await this.getCartData()
    },
  },
}
</script>
