export default {
  title: {
    main: 'Regist',
    main_jp: "マイページ登録",
    pre: 'Membership registration (Terms of Service)',
    attention: 'Email Setting',
    register: "Member registration (information input)",
    register_section_1: "Basic information",
    register_section_2: "Product delivery address",
    confirmation: 'Register Confirmation',
    complete: "Member registration is complete",
    verify: "Member Registration",
  },
  sub_title: {
    register: "Please enter the basic information and shipping address according to the input form.<br><span class='c-red'>* Is a required item.</span>",
    confirmation: 'If there are no mistakes in the following contents, click the "Register" button.',
    complete: "We sent an email verification to the address you entered",
  },
  label: {
    verify_success: "Your email is verified. You can now login.",
    verify_existed: "Your email is already verified. You can now login.",
    term: "Agree to Terms",
    first_content: "<p class='bold mb-10'>If the customer has set the domain specified reception as a measure against junk mail, the mail may not be delivered correctly. I haven't received an important email regarding my purchase, and I haven't received a reply from bromide.jp for inquiries.</p><p class='mb-10'>Please set so that you can receive the following domains.</p><div class='form-text--100 mb-30'><span class='large bold'>@bromide.jp</span></div><p>Also, depending on the settings of your e-mail address and e-mail software, it may be recognized as 'junk e-mail' and you may not receive the e-mail.<br><span class='bold'>▼ If you are using free mail </span> <br> If you do not receive the mail, please check your junk mail folder, your service, and software settings.</p>",
    second_content: "<p class='bold mb-10'>About email reception settings at the time of purchase</p><p class='mb-10'>When paying at a convenience store, the payment company will send you a payment number by email.<br>Please cancel the following domains from the junk mail settings or register as the designated receiving address.</p><div class='form-text--100 mb-20'><span class='large bold'>@bromide.jp</span></div><p class='bold mb-10'>About the contents of the email from the settlement company</p><p>You will receive an email from the settlement company with the following subject.<br> [Subject] Notice of completion of convenience store payment request<br> [Address] Address dedicated to sending payment services</p>",
    attention_confirmation: "Have you completed the domain reception settings?<br>After completing the settings, proceed to new member registration.",
    attention_check: "I checked the email settings",
    name: "Name",
    name_hiragana: "Name (Hiragana)",
    first_name: "First Name",
    last_name: "Last Name",
    last_name_hiragana: 'Last Name Hiragana',
    first_name_hiragana: 'First Name Hiragana',
    email: "Email Address",
    email_confirm: "Email Confirmation",
    gender: "Gender",
    occupation: "Occupation",
    birthday: "Birthday",
    year: "Year",
    month: "Month",
    day: "Day",
    password: "Password",
    password_confirm: "Password Confirmation",
    password_hint: "8 to 32 single-byte alphanumeric characters",
    postal_code: "Postal Code",
    prefecture: "Prefectures",
    address: "Address",
    address_1: "City Address",
    address_2: "Building Name",
    phone_number: "Phone Number",
    phone_number_confirm: "Phone Number Confirmation",
    password_hidden: "* The password will not be sent by e-mail. Please make sure not to forget it.",
    complete_caution: "[Caution] Registration has not been completed yet.",
    complete_content: "<p> Please access the URL in the email within 24 hours to complete your membership registration. <br>After confirming the completion of membership registration, please log in and enjoy shopping.</ p>"
  },
  input: {
    name: "Name <span class='c-red'>*</span>",
    name_hiragana: "Name (Hiragana) <span class='c-red'>*</span>",
    email: "Email <span class='c-red'>*</span>",
    email_confirm: "Email Confirmation <span class='c-red'>*</span>",
    gender: "Gender <span class='c-red'>*</span>",
    content: "Inquiry Content <span class='c-red'>*</span>",
    occupation: "Occupation <span class='c-red'>*</span>",
    birthday: "Birthday <span class='c-red'>*</span>",
    password: "Password <span class='c-red'>*</span>",
    postal_code: "Postal Code <span class='c-red'>*</span>",
    prefecture: "Prefectures <span class='c-red'>*</span>",
    address: "Address",
    phone_number: "Phone Number <span class='c-red'>*</span>",
  },
  placeholder: {
    first_name: "First Name",
    last_name: "Last Name",
    email: "Please enter your e-mail address.",
    email_confirm: "Please enter your email address again.",
    password: "Please enter your password.",
    password_confirm: "Repeat password.",
    postal_code: "Half-width numbers and no hyphens",
    address_1: "City address",
    address_2: "Building name, room number",
    phone_number: "Half-width numbers and no hyphens",
    phone_number_confirm: "Please enter your phone number again",
  },
  options: {
    male: "Male",
    female: "Female",
    anonymous: "Do not answer"
  },
  btn: {
    submit: "To member registration",
    send: "Send",
    confirm: "Sign Up",
    back: "Go Back",
    checking_email_setting: "To Checking Email Setting",
    member_registration: "To Member Registration",
    not_agree: "I do not agree to the terms",
    submit_postal_code: "Enter address from zip code",
    submit_form: "Confirm Registration Details",
    complete: "Go to Login",
    back_artist: "Return to Top Artist",
    to_reset_password: "continue to reset password"
  },
  verification: {
    success: "Verification Successful",
    email_verified: "Your email is verified",
    success_msg: "Great, you have successfully registered a Bromide account.",
    you_can_login_with_email: "You can login your account with your email: ",
    pls_login: "Please log in and enjoy shopping.",
    invalid: "Invalid Verification Link",
    invalid_msg: "Your request to verify your email is invalid. This is probably because the link expired or has been used.",
    request_verification: "You can request email verification ",
    here: "here.",
    thank_you: "Thank you."
  },
  send_verification: {
    title: "Send Verification Email",
    subtitle: "確認メールを送信",
    headline: "Send Verification Email",
    email: "Email",
    btn: "Send Verification Email",
    message: "Enter the email address associated with your account and we will send you a link to verify your email.",
    email_not_arrive: "Email did not arrive?",
    resend_email: "Resend the verification email",
    error: "Your email address was not found. This is probably because the email is already verified or not yet registered."
  },
  occupation: {
    employee: 'Employee',
    student: 'Student',
    self_employee: 'Self employed',
    other: 'Other',
  },
  text_address_pc: "If the address is not entered correctly, or if the building name and room number are not entered, the luggage may not arrive.",
  address_validate: "City / ward / town / address is a required item",
  address2_validate: "If you do not enter the building name and room number, your luggage may not arrive.",
  email_info_pc: "<span class='c-red'>It will be used when logging in to My Page. Please do not forget to save it in a memo.</span",
  email_info_sp: "<span class='c-red'>It will be used when logging in to My Page. Please do not forget to save it in a memo.</span",
}
