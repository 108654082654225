<template>
  <BaseEvent
    :event="event"
    :other-events="otherEvents"
    :filters="filters"
    :product-standard="productStandard"
    :current-artist="current_artist"
    :sale-products="saleProducts"
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
    @filter-change="onFilterChange"
  />
</template>

<script>
import EventService from '@services/EventService'
import SaleProductService from '@/services/SaleProductService'
import pageDisplayControlMixin from "@/helpers/pageDisplayControlMixin";
import { mapState } from 'vuex'

export default {
  components: {
    BaseEvent: () => import('./base/Event'),
  },
  mixins: [ pageDisplayControlMixin ],
  metaInfo() {
    return {
      title: this.titleTag,
    }
  },
  data() {
    return {
      filters: {
        event_id: this.$route.params.event_id ?? this.$route.query.event_id,
        type: 'printed_product',
        printed_product_standard_id: null,
        normal_product_standard_id: null,
        commodity_id: null
      },
      artist_header_logo_image_url: '',
      event: {},
      eventId: this.$route.params.event_id,

      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: [],
      categories: [],

      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',
      otherEvents: [],
    }
  },
  async created() {
    try {
      this.artist_header_logo_image_url = localStorage.getItem('header_logo_image_url')
      await this.getEventPageData()
    } catch (e) {
      this.init()
      this.$router.push({ name: '404' })
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'current_artist'
    ]),
    titleTag() {
      return this.event ? this.event.event_name : null
    },
  },
  methods: {
    async getEventPageData() {
      const artist_designate_page_directory = this.$route.params.designate_page
      await EventService.show(this.filters.event_id, { artist_designate_page_directory })
        .then(res => {
        this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden)
        if (res.data.is_msg_when_hidden) {
          this.isMsgHidden = true
          this.msgTitle = res.data.event_msg_when_hidden_title
          this.msgSubTitle = res.data.event_msg_when_hidden_date
          this.msgContent = res.data.event_msg_when_hidden_content
          this.$store.commit('SHOW_LAYOUT')
          this.init()
        } else {
          this.event = res.data
          this.eventId = this.$route.params.event_id

          const promises = [
            this.getEventCategoryOrProduct(true),
            this.getOtherEventByArtistId(),
            this.getProductStandardByEvent(),
          ]

          Promise.all(promises)
            .then(() => {
              this.init()
            })
        }
      })
    },
    getOtherEventByArtistId() {
      const currentArtists = this.$store.state.current_artist
      return EventService.getEventByArtistId(currentArtists.id).then((res) => {
        if (res.data) {
          this.otherEvents = res.data.filter(el => el.id != this.eventId)
        }
      })
    },
    getEventCategoryOrProduct(isPageLoading = false) {
      return SaleProductService.saleProductRelationInCategory(this.filters, false).then(res => {
        this.saleProducts = res.data

        // ページ遷移時にプリントカテゴリがなかった場合、物販カテゴリを再取得する
        if (isPageLoading && !this.saleProducts && this.filters['type'] === 'printed_product') {
          this.filters['type'] = 'normal'
          SaleProductService.saleProductRelationInCategory(this.filters, false).then(res => {
            this.saleProducts = res.data
          })
        }
      })
    },
    onDetailClick(routeName, item) {
      this.$router.push({name: routeName, params: {id: item.id}})
    },

    onClickReturnToArtistTop() {
      this.$router.push({name: 'artist.home', params: {designate_page: this.event.artist_designate_page_directory}})
    },

    getProductStandardByEvent() {
      return EventService.getProductStandardByEvent(this.eventId).then(res => {
        const newData = {...this.productStandard, ...res.data}
        this.productStandard = newData
      })
    },

    onFilterChange({ key, value }) {
      if (key === 'type') {
        this.filters.commodity_id = null
      }
      this.filters[key] = value
      this.getEventCategoryOrProduct()
    }
  },
}
</script>
<style scoped>
.event-category-checked {
  background-color: #000;
  color: #FFF;
}
</style>
