import BaseService from "./BaseService";
import Constants from '@constants'
import Vue from 'vue';
import router from "../routes";

class PurchaseService {
  constructor(prefix) {
    this.prefix = prefix
  }

  getPurchaseProduct(data = {}) {
    let userPurchaseInfo = Vue.$cookies.get(Constants.USER_PURCHASE_INFO);

    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    data[Constants.DESIGNATE_PAGE_DIRECTORY] = router.currentRoute.params.designate_page
    data.prefecture_id = userPurchaseInfo.prefecture_id

    return this.performRequest(BaseService.METHOD_GET, 'shopping-cart-product', data);
  }

  getMemberInfo() {
    return this.performRequest(BaseService.METHOD_GET, 'member-info');
  }

  changeShippingMethod(cartShippingId, data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    data[Constants.DESIGNATE_PAGE_DIRECTORY] = router.currentRoute.params.designate_page

    return this.performRequest(BaseService.METHOD_PUT, `change-shipping-method/${cartShippingId}`, data);
  }

  confirmPurchase(userPurchaseInfo, userPaymentInfo) {
    var data = {
      ...userPaymentInfo,
      ...userPurchaseInfo,
    };
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    data[Constants.DESIGNATE_PAGE_DIRECTORY] = router.currentRoute.params.designate_page
    return this.performRequest(BaseService.METHOD_POST, 'confirm-purchase', data);
  }

  valationGuestUserInformation(data) {
    return this.performRequest(BaseService.METHOD_GET, 'validate-guest-user-information', data);
  }

  getArtistPaymentMethod() {
    var data = {}

    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    data[Constants.DESIGNATE_PAGE_DIRECTORY] = router.currentRoute.params.designate_page

    return this.performRequest(BaseService.METHOD_GET, 'artist-payment-method', data);
  }

  refreshShippingMethods(data = {}) {
    let userPurchaseInfo = Vue.$cookies.get(Constants.USER_PURCHASE_INFO);

    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    data[Constants.DESIGNATE_PAGE_DIRECTORY] = router.currentRoute.params.designate_page
    data.prefecture_id = userPurchaseInfo.prefecture_id

    return this.performRequest(BaseService.METHOD_GET, 'refresh-shipping-methods', data);
  }

  updateAddress(id, data) {
    return this.performRequest(BaseService.METHOD_PUT, `update-address/${id}`, data);
  }

  performRequest(method, url, data) {
    return (new BaseService(this.prefix)).performRequest(method, url, data)
  }
}

export default new PurchaseService('purchases')
