<template>
  <article>
    <h2 class="page-title">
      <span class="page-title--en">Purchase</span>
      <span class="page-title--jp">購入手続き</span>
    </h2>

    <section class="content pb-80">
      <div class="content__inner" ref="contentInner">
        <section class="mb-60">
          <h3 class="section-title">ご注文商品確認</h3>

          <keep-proportion-content
            class="mb-30"
            :content="'以下の購入内容、送付先およびお支払い方法の情報に<br />誤りがなければ、注文を確定してください。'"
            :proportion-target="() => $refs.contentInner"
            :gap-sp="0"
          />

          <div class="mb-60">
            <h4 class="main-title">ご注文商品</h4>

            <validation-observer ref="observer">
              <RepurchaseProduct
                :errors="errors"
                :selected_payment_id="userPaymentInfo.parent_payment_method_id"
                display-qualified-invoice-issuer-number
                @re-validate="checkValidation()"
              />
            </validation-observer>
          </div>

          <h4 class="main-title">お支払い方法</h4>

          <dl class="common-dl">
            <dt>お支払い方法</dt>

            <template v-if="userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CARRIER">
              <dd>{{ labels.paymentMethod[userPaymentInfo.payment_method_id] }}</dd>
            </template>

            <template v-else>
              <dd>{{ labels.paymentTypes[userPaymentInfo.parent_payment_method_id] }}</dd>

              <template v-if="userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT">
                <dt>カード番号</dt>
                <dd>{{ userPaymentInfo.cardBrandName }} *****{{ userPaymentInfo.card_number }}</dd>

                <dt>名義人</dt>
                <dd>{{ userPaymentInfo.holder_name }}</dd>

                <dt>有効期限</dt>
                <dd>{{ userPaymentInfo.expiration_date }}</dd>
              </template>

              <template v-else-if="userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CONVENIENCE_STORE">
                <dt>店舗名</dt>
                <dd>{{ labels.paymentMethod[userPaymentInfo.payment_method_id] }}</dd>
              </template>
            </template>
          </dl>
        </section>

        <section>
          <div class="mb-20">
            <div>
              <button :disabled="isDisableSubmitBtn" class="btn-100--red" @click="submit()">
                注文確定
              </button>
            </div>
          </div>

          <div>
            <button class="btn-100--none" @click="goBack()">
              修正する
            </button>
          </div>
        </section>
      </div>
    </section>

    <!-- redirect to authentication page of mobile payment -->
    <form :action="form.url" method="POST">
      <noscript>
        <input ref="mobile_carrie" type="submit">
      </noscript>

      <input :value="form.access_id" name="AccessID" type="hidden">
      <input :value="form.token" name="Token" type="hidden">
    </form>

    <Modal :modal="isPurchaseFail">
      <template slot="header">
        <span v-html="errorMessages" />
      </template>

      <template slot="body">
        <ol v-if="gmoErrors">
          <li v-for="(gmoError, i) in gmoErrors" :key="`gmo-error-${i + 1}`">
            <span>
              {{ $t('purchase_confirmation2.error_code') }} : {{ gmoError.info }}
            </span>

            <br>

            <span>
              {{ $t('purchase_confirmation2.error_message') }} : {{ gmoError.detail }}
            </span>
          </li>
        </ol>

        <ol v-else-if="validationErrors">
          <li v-for="(validationError, i) in validationErrors" :key="`validation-error-${i + 1}`">
            {{ validationError }}
          </li>
        </ol>
      </template>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="onCloseBagError()">
          {{ $t('cart.ok') }}
        </button>
      </div>
    </Modal>
  </article>
</template>

<script>
import Constants from '@constants'
import {getPaymentType, PAYMENT_KEYS, PAYMENT_TYPES,} from '../../constants/payment'
import {mapGetters, mapActions} from "vuex";
import i18n from '../../i18n'
import KeepProportionContent from "@components/base/KeepProportionContent";

const shippingErrorTypes = ['not_selected_shipping_method', 'too_many_shipping_products']

export default {
  components: {
    RepurchaseProduct: () => import('./RepurchaseProduct'),
    Modal: () => import('@components/base/Modal'),
    KeepProportionContent,
  },
  data() {
    return {
      PAYMENT_TYPES,
      labels: {
        paymentTypes: {
          [PAYMENT_TYPES.TYPE_CREDIT]: 'クレジット決済',
          [PAYMENT_TYPES.TYPE_CONVENIENCE_STORE]: 'コンビニ決済',
          [PAYMENT_TYPES.TYPE_CARRIER]: 'キャリア決済',
        },
        paymentMethod: {
          [PAYMENT_KEYS.KEY_CREDIT]: 'クレジット決済',
          [PAYMENT_KEYS.KEY_SEVEN_ELEVEN_CONVENIENCE_STORE]: 'セブンイレブン',
          [PAYMENT_KEYS.KEY_LAWSON_CONVENIENCE_STORE]: 'ローソン(スリーエフ含む)',
          [PAYMENT_KEYS.KEY_FAMILY_MART_CONVENIENCE_STORE]: 'ファミリーマート(サンクス、サークルK含む)',
          [PAYMENT_KEYS.KEY_MINI_STOP_CONVENIENCE_STORE]: 'ミニストップ',
          [PAYMENT_KEYS.KEY_SEIKO_MART_CONVENIENCE_STORE]: 'セイコーマート',
          [PAYMENT_KEYS.KEY_DOCOMO_CARRIER]: 'ドコモキャリア決済',
          [PAYMENT_KEYS.KEY_SOFTBANK_CARRIER]: 'ソフトバンクキャリア決済',
          [PAYMENT_KEYS.KEY_AU_EASY]: 'auかんたん決済',
        }
      },
      isDisableSubmitBtn: false,
      isPurchaseFail: false,
      errorMessages: '',
      userPurchaseInfo: {},
      userPaymentInfo: {},
      form: {
        access_id: null,
        token: null,
        url: null,
      },
      errors: {
        payment_fail: [],
        product_stock: [],
      },
      gmoErrors: null,
      validationErrors: null,
    }
  },
  created() {
    if (!this.repurchaseUserCartData) {
      this.$cookies.remove(Constants.USER_REPURCHASE_INFO)
      this.$cookies.remove(Constants.USER_REPURCHASE_PAYMENT_INFO)
      this.$cookies.remove(Constants.REPURCHASE_STEP)
      this.$router.push({name: `repurchase.${this.$route.meta?.type}`})
      return
    }

    this.userPurchaseInfo = this.$cookies.get(Constants.USER_REPURCHASE_INFO)
    this.userPaymentInfo = this.$cookies.get(Constants.USER_REPURCHASE_PAYMENT_INFO)

    let meta = this.$route.query

    if (meta && `${meta.code}` === '402') {
      meta.errors = JSON.parse(meta.errors)

      if (`${meta.status}` === '408') {
        meta.message = i18n.t('general.api_server_busy')
      }

      this.transformGMOError(meta)

      this.$router.replace({name: this.$route.name})
    }
  },
  computed: {
    ...mapGetters([
      'repurchaseType',
      'repurchaseUserCartData'
    ]),
  },
  methods: {
    ...mapActions([
      'applyRepurchase'
    ]),
    async checkValidation() {
      const valid = await this.$refs.observer.validate()

      if (valid) {
        return true
      }

      this.scrollToFirstError()

      return false
    },
    goBack() {
      // クレジット決済の場合
      if (this.userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT) {
        return this.$router.push({name: `repurchase.${this.repurchaseType}.credit`})
      }

      return this.$router.push({name: `repurchase.${this.repurchaseType}.confirmation`})
    },
    onCloseBagError() {
      this.isPurchaseFail = false
      if (this.gmoErrors) {
        this.gmoErrors = null
      }
      if (this.validationErrors) {
        this.validationErrors = null
      }
    },
    transformGMOError(meta) {
      console.warn(meta.errors)

      this.errorMessages = `決済エラー: ${decodeURIComponent(meta.message)}`
      this.gmoErrors = meta.errors
      this.isPurchaseFail = true
    },
    transformValidationError(data) {
      const { errors = {} } = data

      this.validationErrors = Object.keys(errors).reduce((reduced, propName) => [...reduced, ...errors[propName]], [])
      this.errorMessages = '入力エラー: ' + data.message
      this.isPurchaseFail = true
    },
    async submit() {
      this.isDisableSubmitBtn = true

      if (!(await this.checkValidation())) {
        this.isDisableSubmitBtn = false

        return
      }

      // if (await this.$store.dispatch('getCartData')) {
      //   this.isDisableSubmitBtn = false
      //
      //   return
      // }
      // const formData = {
      //   ...this.userPurchaseInfo,
      //   designate_page_directory: this.$route.params.designate_page,
      //   parent_payment_method_id: '',
      //   payment_method_id: '',
      //   shippings: this.repurchaseUserCartData.cart_shippings
      // }

      const _shipping = this.repurchaseUserCartData.cart_shippings.map( cart_shipping => {
        return {
          ...cart_shipping,
          delivery_time_frame_key: cart_shipping.specific_time_delivery
        }
      } )

      const formData = {
        designate_page_directory: this.$route.params.designate_page,
        ...this.userPurchaseInfo,
        ...this.userPaymentInfo,
        shippings: _shipping
      }

        await this.$store.dispatch('applyRepurchase', formData).then(res => {
          // クレジット or コンビニ決済の場合
          if (!getPaymentType(this.userPaymentInfo.parent_payment_method_id).is(PAYMENT_TYPES.TYPE_CARRIER)) {
            this.isDisableSubmitBtn = false

            this.$router.push({name: `repurchase.${this.repurchaseType}.fix`})
          } else {
            this.form = {
              token: res.data.Token,
              access_id: res.data.AccessID,
              url: res.data.StartURL,
            }

            return setTimeout(() => {
              this.isDisableSubmitBtn = false
              this.$refs.mobile_carrie.click()
            }, 1000)
          }
        }).catch(e => {
          console.log('catcheeee', e)

          this.isDisableSubmitBtn = false

          const status = (e.response && e.response.status) || 500

          const { data, meta } = e.data

          if (`${status}` === '422') {
            // バリデーションエラー
            this.transformValidationError(data)
          } else if (meta) {
              if (`${meta.code}` === '402') {
                // GMOエラー
                this.transformGMOError(meta)
              } else if (`${meta.code}` === '406') {
                if (shippingErrorTypes.includes(meta.error_type)) {
                  // 購入手続きでの配送に関するエラー
                  this.errorMessages = meta.message
                  this.isPurchaseFail = true
                } else {
                  // カート商品に関するエラー
                  this.$store.dispatch('setPurchaseError', meta)
                  this.$router.push({name: 'cart.page'})
                }
              } else if (`${meta.code}` === '408') {
                // GMOタイムアウトエラー
                this.$store.dispatch('setGlobalError', { error: i18n.t('general.api_server_busy') })
              } else if (`${meta.code}` === '422') {
                // バリデーションエラー
                this.transformValidationError(meta)
              } else {
                // 完全な例外となるエラー
                this.$store.dispatch('setGlobalError', { error: `処理中にエラーが発生しました。<br>ステータスコード: ${meta.code || (e.response && e.response.status) || 500}` })
              }
            } else {
              // 完全な例外となるエラー
              this.$store.dispatch('setGlobalError', { error: `処理中にエラーが発生しました。<br>ステータスコード: ${e.status || (e.response && e.response.status) || 500}` })
            }
          })
      //   if (!getPaymentType(this.userPaymentInfo.parent_payment_method_id).is(PAYMENT_TYPES.TYPE_CARRIER)) {
      //     this.isDisableSubmitBtn = false
      //
      //     this.$router.push({name: `repurchase.${this.repurchaseType}.fix`})
      //   } else {
      //     this.form = {
      //       token: response.data.Token,
      //       access_id: response.data.AccessID,
      //       url: response.data.StartURL,
      //     }
      //
      //     return setTimeout(() => {
      //       this.isDisableSubmitBtn = false
      //       this.$refs.mobile_carrie.click()
      //     }, 1000)
      //   }
      // } catch (e) {
      //   console.log('submit, error', e)
      //   this.isDisableSubmitBtn = false
      //
      //   let meta = e.data.meta
      //
      //   if (meta) {
      //     this.error_type = meta.error_type
      //
      //     //outof stock 200, 402 error payment, 408 timeout
      //     if (meta.code == '200') {
      //       this.$store.dispatch('setPurchaseError', meta)
      //       this.$router.push({name: 'cart.page'})
      //     } else if (meta.code == '402') {
      //       this.transformError(meta)
      //     } else if (meta.code == '408') {
      //       this.errorMessages = meta.message
      //       this.error_code = ''
      //       this.error_msg = ''
      //       this.isPurchaseFail = true
      //     }
      //   }
      // }

      // this.applyRepurchase().then(res => {
      //   // クレジット or コンビニ決済の場合
      //   if (!getPaymentType(this.userPaymentInfo.parent_payment_method_id).is(PAYMENT_TYPES.TYPE_CARRIER)) {
      //     this.isDisableSubmitBtn = false
      //
      //     this.$router.push({name: `repurchase.${this.repurchaseType}.fix`})
      //   } else {
      //     this.form = {
      //       token: res.data.Token,
      //       access_id: res.data.AccessID,
      //       url: res.data.StartURL,
      //     }
      //
      //     return setTimeout(() => {
      //       this.isDisableSubmitBtn = false
      //       this.$refs.mobile_carrie.click()
      //     }, 1000)
      //   }
      // }).catch(e => {
      //   this.isDisableSubmitBtn = false
      //
      //   let meta = e.data.meta
      //
      //   if (meta) {
      //     this.error_type = meta.error_type
      //
      //     //outof stock 200, 402 error payment, 408 timeout
      //     if (meta.code == '200') {
      //       this.$store.dispatch('setPurchaseError', meta)
      //       this.$router.push({name: 'cart.page'})
      //     } else if (meta.code == '402') {
      //       this.transformError(meta)
      //     } else if (meta.code == '408') {
      //       this.errorMessages = meta.message
      //       this.error_code = ''
      //       this.error_msg = ''
      //       this.isPurchaseFail = true
      //     }
      //   }
      // })
    },
    // backToArtist() {
    //   this.$router.push({name: 'artist.home'})
    // },
  },
}
</script>

<style scoped>
.js-cart_button {
  cursor: pointer;
}

.attention {
  display: flex;
  justify-content: center;
}

.attention__image {
  display: flex;
  justify-content: center;
  width: 10rem;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.footer__close {
  border: 2px solid black;
  cursor: pointer;
  padding: 1rem 3rem;
  background-color: transparent;
}

.confirm_footer {
  display: flex;
  justify-content: center;
}
</style>
