<template>
  <section class="content pt-50 bg-white">

    <slot name="accordion"/>
    <slot name="accordion_hidden"/>

    <h2 class="page-title">
      <span class="page-title--en">{{ title }}</span>
      <span class="page-title--jp">{{ subtitle }}</span>
    </h2>

    <div class="tab">

      <input v-if="printProduct.length"
             id="tab-1"
             :checked="filters.type == 'printed_product'"
             class="js-tab-switch"
             name="category"
             type="radio" value="print-service"
      >
      <input v-if="goodsProduct.length"
             id="tab-2" :checked="filters.type == 'normal'"
             class="js-tab-switch"
             name="print_product"
             type="radio" value="goods"
      >

      <label v-if="printProduct.length"
             class="tab__switch tab-1_label pointer"
             for="tab-1"
             @click="onFilterChange('type','printed_product')"
      >
        <span class="tab__title-en font-en">{{ $t('event.relation.print_service_title') }}</span>
        <span class="tab__title-jp">{{ $t('event.relation.print_service_subtitle') }}</span>
      </label>

      <label v-if="goodsProduct.length"
             class="tab__switch tab-2_label pointer"
             for="tab-2"
             @click="onFilterChange('type','normal')"
      >
        <span class="tab__title-en font-en">{{ $t('event.relation.goods_title') }}</span>
        <span class="tab__title-jp">{{ $t('event.relation.goods_subtitle') }}</span>
      </label>

    </div>

    <div class="bg-gray">

      <div
        v-show="printProduct.length"
        id="print-service"
        :class="{ active: filters.type == 'printed_product' }"
        class="content__inner event-category"
      >
        <div class="event-category__switch">
          <label
            :class="{ 'event-category-checked': filters.commodity_id === null }"
            class="event-category__switch-item pointer"
            @click="handleClickCommodityTab( null)"
          >
            {{ $t('event.relation.all') }}
          </label>
          <label
            v-for="(commodity, index) in printCommodityList"
            :key="index"
            :class="{ 'event-category-checked': filters.commodity_id === commodity.id }"
            class="event-category__switch-item pointer"
            @click="handleClickCommodityTab(commodity.id)"
          >
            {{ commodity.name }}
          </label>
        </div>

        <div class="event-category__list">
          <category-list-under-event
            v-for="(sale_product, index) in filteredSaleProducts"
            ref="underEvent"
            :key="index"
            :data="sale_product"
            :height="highest"
            @detail-click="handleCategoryDetailClick"
          />
        </div>

      </div>

      <div
        v-show="goodsProduct.length"
        id="goods"
        :class="{ active: filters.type === 'normal' }"
        class="content__inner event-category"
      >

        <div class="event-category__switch">
          <label
            :class="{ 'event-category-checked': filters.commodity_id === null }"
            class="event-category__switch-item pointer"
            @click="handleClickCommodityTab(null)"
          >
            {{ $t('event.relation.all') }}
          </label>
          <label
            v-for="(commodity, index) in goodsCommodityList"
            :key="index"
            :class="{ 'event-category-checked': filters.commodity_id === commodity.id }"
            class="event-category__switch-item pointer"
            @click="handleClickCommodityTab(commodity.id)"
          >
            {{ commodity.name }}
          </label>
        </div>

        <div class="event-category__list">
          <category-list-under-event
            v-for="(sale_product, index) in filteredSaleProducts"
            ref="underEvent"
            :key="index"
            :data="sale_product"
            :height="highest"
            show-amount
            @detail-click="handleGoodsCategoryDetailClick"
          />
        </div>

      </div>

    </div>
  </section>
</template>
<script>

import CategoryListUnderEvent from "./ChildUnderEvent/CategoryListUnderEvent";
export default {
  components: {
    CategoryListUnderEvent
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    saleProducts: {
      type: Array,
      default:() => []
    }
  },
  data() {
    return {
      highest: 0
    }
  },
  mounted() {
    this.$nextTick(function () {
      this.underEventResize()
    })
  },
  computed: {
    printProduct() {
      if (!this.productStandard.printed) return []
      return this.productStandard.printed
    },
    printCommodityList() {
      return this.printProduct.reduce((list, productStandard) => {
        const find_by_commodity = this.saleProducts.find(sp => {
          return sp.commodity_id === productStandard.commodity.id
        })
        if (find_by_commodity) {
          list[productStandard.commodity.id] = {
            id: productStandard.commodity.id,
            name: productStandard.commodity.name
          }
        }
        return list
      }, {})
    },
    goodsProduct() {
      if (!this.productStandard.normal) return []
      return this.productStandard.normal
    },
    goodsCommodityList() {
      return this.goodsProduct.reduce((list, productStandard) => {
        const find_by_commodity = this.saleProducts.find(sp => {
          return sp.commodity_id === productStandard.commodity.id
        })
        if (find_by_commodity) {
          list[productStandard.commodity.id] = {
            id: productStandard.commodity.id,
            name: productStandard.commodity.name
          }
        }
        return list
      }, {})
    },
    divideSaleProductByCommodity() {
      // SaleProductをcommodityId毎にまとめる、SaleProductはprint、goodsを切り替えるたびに内容が変わる
      const result = this.saleProducts.reduce((list, product) => {
        list[product.commodity_id] = product.commodity_id in list ? [
          ...list[product.commodity_id],
          product
        ] : [
          product
        ]
        return list
      }, {})
      return result
    },
    filteredSaleProducts() {
      if (this.filters.commodity_id === null) return this.saleProducts
      return this.divideSaleProductByCommodity[this.filters.commodity_id]
    },
  },
  watch:{
    filters: {
      deep: true,
      handler() {
        this.$nextTick(function () {
          this.underEventResize()
        })
      }
    }
  },
  methods: {
    onFilterChange(key, value) {
      this.$emit('filter-change', { key, value})
    },

    handleClickCommodityTab(id) {

      this.onFilterChange('commodity_id',id)
    },

    handleCategoryDetailClick(item) {
      this.$router.push({name: 'category.page', params: {category_id: item.id}});
    },
    handleGoodsCategoryDetailClick(item) {
      this.$router.push({name: 'category_goods.page', params: {category_id: item.id}});
    },
    underEventResize() {


      const resizeObserver = new ResizeObserver( entries => {

        const maxHeight = entries.reduce( (max_height, entry) => {
          max_height = max_height < entry.contentRect.height ? entry.contentRect.height : max_height
          return max_height
        }, 0)
        this.highest = this.highest < maxHeight ? maxHeight : this.highest
      })

      if (this.$refs.underEvent) {
        this.$refs.underEvent.forEach( item => {

          resizeObserver.observe(item.$el)

        })
      }
    }
  }
}
</script>
<style scoped>
.event-category-checked {
  background-color: #000;
  color: #FFF;
}
</style>
