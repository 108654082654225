export default {
  before: '{field} フィールドは前でなければなりません {target}',
  after: '{field} フィールドは後にする必要があります {target}',
  decimal: '{field} フィールドには10進値のみを含める必要があります',
  phone_number: '正しい電話番号をご入力ください。半角英数・ハイフンなし。',
  email: '正しい{field}をご入力ください',
  trim_space: '{field} フィールドには、左または右のスペースがあってはなりません',
  trim_left_space: '{field} フィールドにスペースを残してはなりません',
  zip_code: '{field}フィールドは有効な郵便番号である必要があります',
  confirm: '{field}フィールドが一致しません',
  checkbox: '{field}は必須項目です',
  date_format: '{field}は{format}の形式である必要があります'
}
