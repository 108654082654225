export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: '登録情報変更',
    description: '※ は入力必須項目です。'
  },
  basic_information: {
    title: '基本情報',
    name: 'お名前',
    first_name: '名',
    last_name: '姓',
    name_hiragana: 'ふりがな',
    first_name_hiragana: 'せい',
    last_name_hiragana: 'めい',
    email_address: 'メールアドレス',
    email_address_confirmation: 'メールアドレス確認用',
    gender: '性別',
    occupation: '職業',
    date_of_birth: '生年月日',
    year: '年',
    month: '月',
    day: '日',
    desired_password: '希望のパスワード',
    please_enter_your_password: 'パスワードを入力してください。',
    password_detail: '半角英数字8文字以上32文字以内で大文字・小文字・数字・記号（#?!@$%^&*-_ のいずれか）を含んだ文字列',
    current_password: '現在のパスワード',
    new_password: '新しいパスワード',
    password_confirmation: 'パスワード確認',
    repeat_password: 'パスワードを再度入力してください。',
    send_email_newsletter: 'メールマガジン送付',
    do_not_want: '希望しない',
    hope: '希望する'
  },
  product_delivery_address: {
    title: "商品お届け先",
    postal_code: "郵便番号",
    prefecture: "都道府県",
    address: "住所",
    phone_number: "電話番号",
    phone_number_confirmation: "電話番号の確認",
    enter_address_from_zip_code: "郵便番号から住所入力",
    edit: "編集",
    add: "追加",
    confirm_registration_details: "登録内容を確認する"
  },
  select: {
    please_select: '選択してください'
  },
  gender: {
    male: '男性',
    female: '女性',
    anonymous: '答えない'
  },
  occupation: {
    employee: '会社員',
    student: '学生',
    self_employee: '自営業',
    other: 'その他',
  },
  placeholder: {
    address: '市区町村・番地',
    half_width_numbers_and_no_hyphens: '半角数字・ハイフン無し',
    please_enter_your_phone_number_again: '電話番号を再度入力してください。'
  },
  text_address_pc: "住所が正しく入力されていなかったり、建物名と部屋番号が未入力の場合、荷物が届かない可能性がございます。",
  address_validate: "市区町村・番地は必須項目です",
  address2_validate: "建物名と部屋番号が未入力の場合、<br class='s-elem'/>荷物が届かない可能性がございます。"
}
