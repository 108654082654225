<template>
  <div>
    <MessageWhenHidden
      v-if="isMsgHidden"
      :content="msgContent"
      :is_mobile="isMobile"
      :subtitle="msgSubTitle"
      :title="msgTitle"
    />

    <EventTemplate
      v-else
      :event="event"
      :is-mobile="isMobile"
      :other-events="otherEvents"
      :filters="filters"
      :product-standard="productStandard"
      :sale-products="saleProducts"
      :categories="categories"
      :current-artist="currentArtist"
      @filter-change="onFilterChange"
    />
  </div>
</template>

<script>
export default {
  components: {
    EventTemplate: () => import('@components/event/EventTemplate'),
    MessageWhenHidden: () => import('@components/base/MessageWhenHidden'),
  },
  props: {
    isMsgHidden: {
      type: Boolean,
      required: true
    },
    msgContent: {
      type: String,
      default: ''
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    msgSubTitle: {
      type: String,
      default: ''
    },
    msgTitle: {
      type: String,
      default: ''
    },

    event: {
      type: Object,
      required: true
    },
    otherEvents: {
      type: Array,
      default: () => []
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    saleProducts: {
      type: Array
    },
    categories: {
      type: Array
    },
    currentArtist: {
      type: Object,
      required: true,
    }
  },
  methods: {
    onFilterChange(data) {
      this.$emit('filter-change', data)
    },
  }
}
</script>
