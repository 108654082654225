<template>
  <div>
    <div v-show="loading" class="spinner-wrap">
      <div class="spinner">
        <img :src="require('@assets/images/loading.gif')"/>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background: #000;
}

.spinner-wrap {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  top: 0;
  background-color: rgba(3, 3, 3, 0.3);
  z-index: 99999;
}

.spinner {
  height: 200px;
  width: 200px;
  display: flex;
  margin: 0 auto;
}
</style>
