import Vue from 'vue';
import Constants from '@constants';

Vue.use(require('vue-cookies'))
const state = {
  pageInit: true,
  isLoading: false,
  isMobile: false,
  numberOfProductInCart: 0,
  current_artist: null,
  has_error: false,
  has_maintenance: false,
  error_message: '',
  artistConfirmation: null,
  showEventListAttention: false,
  isLogged: Vue.$cookies.isKey(Constants.ACCESS_TOKEN),
  hideLayout: true
}

export default state
