<template>
  <article>
    <section class="content pb-80">
      <validation-observer ref="observer" class="content__inner" tag="div">
        <Headline title="パスワード変更"></Headline>

        <p class="small pb-10">※現在のパスワードと変更後のパスワードを入力してください。</p>

        <ErrorAlert v-if="hasError">パスワードが変更できませんでした</ErrorAlert>

        <dl class="common-dl">
          <dt>現在のパスワード<span class="c-red">*</span></dt>

          <dd>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="`required|min:8|max:32`"
              vid="current_password"
            >
              <div class="form-password change-password__password">
                <input
                  v-model="current_password"
                  :name="$t('mypage_edit.basic_information.current_password')"
                  :placeholder="$t('mypage_edit.basic_information.please_enter_your_password')"
                  class="form-text--100 form-password__input change-password__password__input"
                  :type="is_visible_current_password ? 'text' : 'password'"
                >

                <div class="form-password__append">
                  <IconEye
                    v-if="is_visible_current_password"
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_current_password = !is_visible_current_password"
                  />

                  <IconEyeSlash
                    v-else
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_current_password = !is_visible_current_password"
                  />
                </div>
              </div>

              <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>

          <dt>変更したいパスワード<span class="c-red">*</span></dt>

          <dd>
            <ValidationProvider
              v-slot="{ errors }"
              :rules="{ required: true, min: 8, max: 32, regex: /^([a-zA-Z0-9#?!@$%^&*_-]*)$/ }"
              vid="password"
            >
              <div class="form-password change-password__password">
                <input
                  v-model="password"
                  :name="$t('mypage_edit.basic_information.new_password')"
                  :placeholder="$t('mypage_edit.basic_information.please_enter_your_password')"
                  class="form-text--100 form-password__input change-password__password__input"
                  :type="is_visible_password ? 'text' : 'password'"
                >

                <div class="form-password__append">
                  <IconEye
                    v-if="is_visible_password"
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_password = !is_visible_password"
                  />

                  <IconEyeSlash
                    v-else
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_password = !is_visible_password"
                  />
                </div>
              </div>

              <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
            </ValidationProvider>

            <p class="small">{{ $t('mypage_edit.basic_information.password_detail') }}</p>

            <ValidationProvider
              v-slot="{ errors }"
              :rules="`required|min:8|max:32|confirmed:password`"
              vid="password_confirmation"
            >
              <div class="form-password change-password__password">
                <input
                  v-model="password_confirmation"
                  :name="$t('mypage_edit.basic_information.password_confirmation')"
                  :placeholder="$t('mypage_edit.basic_information.repeat_password')"
                  class="form-text--100 form-password__input change-password__password__input"
                  :type="is_visible_password__confirmation ? 'text' : 'password'"
                >

                <div class="form-password__append">
                  <IconEye
                    v-if="is_visible_password__confirmation"
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                  />

                  <IconEyeSlash
                    v-else
                    class="form-password__append__icon"
                    @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                  />
                </div>
              </div>

              <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
            </ValidationProvider>
          </dd>
        </dl>

        <Button title="パスワードを変更する" isPrimary @onClick="submit"></Button>
      </validation-observer>
    </section>
  </article>
</template>

<script>
import MemberService from '@services/MemberService';
import MasterDataService from '@services/MasterDataService';
import {mapState} from "vuex";
import ErrorAlert from "@components/base/ErrorAlert";

export default {
  name: "ChangePassword",
  components: {
    ErrorAlert,
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button'),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
  },
  data() {
    return {
      data: null,
      current_password: "",
      password: "",
      password_confirmation: "",
      is_mobile: false,
      hasError: false,
      is_visible_current_password: false,
      is_visible_password: false,
      is_visible_password__confirmation: false,
    }
  },
  methods: {
    getCurrentMemberLogin() {
      this.hasError = false
      MemberService.index().then(res => {
        this.data = res.data;
      })
        .catch(() => {
          this.hasError = true
        });
    },
    getMasterData() {
      MasterDataService.index({
        is_prefecture: true,
        is_gender: true,
        is_occupation: true,
        is_mobile: true
      }).then(res => {
        this.is_mobile = res.data.is_mobile;
      });
    },

    async submit() {
      this.hasError = false
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }

      MemberService.updateMypageUserPassword({
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then(() => {
        this.$router.push({name: this.current_artist ? 'artist.mypage.index' : 'mypage.index'});
      }).catch(() => {
        this.hasError = true
      });

    },

  },
  mounted() {
    this.getCurrentMemberLogin();
    this.getMasterData();
  },
  computed: {
    ...mapState([
      'current_artist'
    ])
  }
}
</script>

<style>

</style>
