<template>
  <article>

    <Title title="Email Confirmation" subtitle="メールアドレス事前確認" />

    <div v-if="success" class="content__inner">

      <div class="page-title">
        <span class="c-green verification-icon"><i class="fas fa-check-circle"></i></span>
        <span class="verification-title bold mb-20">確認が完了しました</span>
        <p>メールアドレスの事前確認が完了しました</p>
        <p style="display:inline">引き続きお買い物をお楽しみください</p>
      </div>

      <div class="mb-50">
        <Button :title="$t('registration.btn.back_artist')" @onClick="handleToArtist"></Button>
      </div>
    </div>

    <div v-else class="content__inner">

      <div class="page-title">

        <span class="c-red verification-icon"><i class="far fa-times-circle"></i></span>
        <span class="verification-title bold mb-20">メールアドレスの確認ができませんでした</span>
        <p>リンクの有効期限が切れているか、既にメールアドレスが登録されている可能性があります。再度ご確認ください。</p>
        <p>ありがとうございました。</p>
      </div>

      <div class="mb-50">
        <Button :title="$t('registration.btn.back_artist')" @onClick="handleToArtist"></Button>
      </div>
    </div>

  </article>
</template>
<script>
import Title from "@components/base/Title";
export default {
  components: {
    Title,
    Button: () => import('@components/base/Button')
  },
  props: {
    success: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    handleToArtist() {
      this.$emit('to-artist')
    }
  }
}
</script>
<style scoped>
.verification-title {
  font-size: 4rem;
  width: 100%;
  display: block;
  line-height: 110%;
}

.verification-icon {
  font-size: 8rem;
}

@media screen and (min-width: 768px) and (max-width: 834px) {
  .verification-title {
    font-size: 3rem;
  }

  .verification-icon {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) {
  .verification-title {
    font-size: 2rem;
  }

  .verification-icon {
    font-size: 6rem;
  }
}
</style>
