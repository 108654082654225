<template>
  <div :class="['category__list', 'category__list--gacha', {'mb-50': !isPhone}, {'mb-30': isPhone}]">

    <!--
    <div class="category-product__thumb box-shadow mb-40 gacha-thumb">
      <img :src="randomImage" alt="">
    </div>
    -->

    <div class="category__list--gacha--cart">
      <validation-observer id="gacha-item" ref="observer" v-slot="{ handleSubmit }" tag="div">

        <validation-provider
          v-slot="{errors}"
          :rules="has_max_stock"
          tag="div"
        >

          <div
            v-if="isExtraSmallDevice"
            class="purchase__cart"
          >
            <span class="purchase__cart-title number-sheet">セット数</span>

            <div class="purchase__number__select__wrapper">
              <select
                v-model="localData.qty"
                :name="$t('category.number_of_sheets')"
                class="purchase__number__select"
                @change="handleChangeQty"
              >
                <option
                  v-for="(value, i) in selectLength"
                  :key="i"
                  :value="value"
                >
                  {{ value }}
                </option>
              </select>
            </div>

            <input
              :class="{ active: isInCart  }"
              :value="isInCart ? 'カートから削除' : 'カートに入れる'"
              class="purchase__button-add-to-cart purchase__button-add-to-cart__x_sm box-shadow js-cart_button"
              type="button"
              @click="handleSubmit(addToCart())">
          </div>

          <div v-else class="purchase__cart">

            <span class="purchase__cart-title number-sheet">セット数</span>

            <input
              v-model="localData.qty"
              :max="localData.always_keep_in_stock ? '' : localData.number_of_stock"
              :name="$t('category.number_of_sheets')"
              :type="isPhone ? 'tel' : 'number'"
              class="purchase__number"
              min="0"
              @input="handleInput"
            >

            <!--
            <img
              v-if="isPhone"
              :src="cartPopupImage"
              class="purchase__cart-image"
            >
            -->


            <div class="purchase__cart-box">
              <div class="purchase__cart-box--flex">
                <div class="purchase__cart-icon purchase__cart-icon--nosp">
                  <span>アイコン説明：</span>
                </div>
                <div class="purchase__cart-icon">
                  <input class="purchase__cart-icon--button box-shadow js-cart_button"
                         type="button">
                  <span>選択が解除されています</span>
                </div>
                <div class="purchase__cart-icon active-icon">
                  <input class="purchase__cart-icon--button box-shadow js-cart_button active"
                         type="button">
                  <span>カートに入っています</span>
                </div>
              </div>
            </div>


            <!-- category-product__button--cart -->

            <input
              :class="{ active: isInCart  }"
              class="purchase__button-add-to-cart box-shadow js-cart_button"
              type="button"
              @click="handleSubmit(addToCart())"
            >

          </div>

          <div v-if="!isExtraSmallDevice && isInCart && errors[0]" class="purchase__cart--error c-red mt-2"> {{ errors[0] }}</div>
          <div v-if="isInCart" class="purchase__cart--note">
            ※枚数を変更すると自動的に変更されます
          </div>

        </validation-provider>

      </validation-observer>
    </div>


  </div>
</template>

<script>
import cartPopupImage from '../../assets/images/popup-cart-category-sp.png'
import randomImage from '../../assets/images/random.jpg'

export default {
  name: "CategoryGachaItem",
  model: {
    prop: 'data',
    event: 'input'
  },
  props: {
    data: {
      type: Object,
      required: true
    },
    sale_product: {
      type: Object,
      required: true
    },
    isInCart: {
      type: Boolean,
      default: false
    },
    isPhone: {
      type: Boolean,
      default: false
    },
    isExtraSmallDevice: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      localData: { ...this.data }
    }
  },
  computed: {
    selectLength() {
      if (!this.localData.always_keep_in_stock && this.localData.number_of_stock === undefined) return 100
      return this.localData.always_keep_in_stock ? 100 : this.localData.number_of_stock
    },
    randomImage() {
      return randomImage
    },
    cartPopupImage() {
      return cartPopupImage
    },
    has_max_stock() {
      if (this.localData.always_keep_in_stock !== 0) {
        return 'required|min_value:0';
      } else {
        return 'required|min_value:0|max_value:' + this.localData.number_of_stock;
      }
    },
  },
  methods: {
    addToCart() {
      if (!this.isInCart) this.localData.qty = this.localData.qty || 1
      this.$emit('add-to-cart', this.localData)
    },
    handleInput() {
      this.$emit('input', this.localData)
    },
    handleChangeQty() {
      this.$emit('input', this.localData)
    },
  }
}
</script>

<style scoped>
@media screen and (max-width: 320px) {
  .purchase__cart {
    padding: 0 !important;
  }
}
.purchase__cart-box {
  margin: 0 1rem;
}
</style>
