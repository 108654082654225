export default {
  title: {
    main: 'Regist',
    main_jp: "マイページ登録",
    pre: 'マイページ登録（利用規約）',
    attention: 'メール設定',
    register: "マイページ登録（情報入力）",
    register_section_1: "基本情報",
    register_section_2: "商品お届け先",
    confirmation: '登録内容確認',
    complete: "マイページ仮登録を受け付けました。",
    verify: "マイページ登録",
  },
  sub_title: {
    register: "入力フォームに沿って、基本情報とお届け先を<br class='s-elem'/>入力してください。<br><span class='c-red'>* は入力必須項目です。</span>",
    confirmation: '以下の内容に誤りがなければ、「登録」ボタンをクリックしてください。',
    complete: "ご入力いただきましたメールアドレスに<br class='s-elem'/>最終手続きのご案内メールを送信しました。",
  },
  label: {
    verify_success: "メールアドレスが確認されました。ログインできるようになりました。",
    verify_existed: "メールアドレスは既に確認済みです。ログインできるようになりました。",
    term: "規約に同意する",
    first_content_title: "<p class='bold mb-10'>ドメインの受信設定をお願いします</p>",
    first_content_1: `お客様が迷惑メール対策などで、<br class='s-elem'/>ドメイン指定受信を設定されている場合に、<br class='s-elem'/>メールが正しく届かないことがございます。<br/>購入に関する大事なメールが届かず、<br class='s-elem'/>お問い合わせいただいたbromide.jpからの<br class='s-elem'/>返信も受け取れません。`,
    first_content_2: "以下のドメインを受信できるように設定してください。",
    first_content_3: `またお使いのメールアドレス、<br class='s-elem'/>メールソフトなどの設定により「迷惑メール」と認識され、<br class='s-elem'/>メールが届かない場合があります。<br><br><span class='bold'>▼フリーメールをお使いの場合</span><br>メールが届かない場合には、迷惑メールフォルダ及び<br class='s-elem'/>お使いのサービス、ソフトウェアの設定をご確認ください。`,
    second_content_title: "<p class='bold mb-10'>購入時のメール受信設定について</p>",
    second_content_1: `コンビニ支払時に、決済会社より支払番号が<br class='s-elem'/>メールで送られます。<br>以下のドメインを迷惑メール設定から解除、<br class='s-elem'/>又は指定受信アドレスとして登録をお願いいたします。`,
    second_content_2_title: "決済会社からのメール内容について",
    second_content_2: `決済会社からは以下のような件名でメールが届きます。<br>【件名】コンビニ決済お支払い番号のお知らせ<br>【アドレス】決済サービス送信専用アドレス`,
    attention_confirmation: "ドメイン受信設定は終わりましたか？<br/>設定が完了しましたら､<br class='s-elem'/>新規マイページ登録へお進みください",
    attention_check: "ドメインの受信設定を確認しました",
    name: "お名前",
    name_hiragana: "ふりがな",
    first_name: "名",
    last_name: "姓",
    first_name_hiragana: 'めい',
    last_name_hiragana: 'せい',
    email: "メールアドレス",
    email_confirm: "メールアドレス確認用",
    gender: "性別",
    occupation: "職業",
    birthday: "生年月日",
    year: "年",
    month: "月",
    day: "日",
    password: "希望パスワード",
    password_confirm: "パスワードの確認",
    password_hint: "半角英数字8文字以上32文字以内で大文字・小文字・数字・記号（#?!@$%^&*-_ のいずれか）を含んだ文字列",
    postal_code: "郵便番号",
    prefecture: "都道府県",
    address: "住所",
    address_1: "市区町村・番地",
    address_2: "建物名・部屋番号",
    phone_number: "電話番号",
    phone_number_confirm: "電話番号の確認",
    password_hidden: "*パスワードはメール等で送信いたしません。お忘れにならないようご確認ください。",
    complete_caution: "【ご注意】まだ登録は完了していません。",
    complete_click_for_link: "送信されたメールのURLリンクを押して登録が完了します。",
    complete_content: "<p class = 'mb-50'>送信されたメールに記載されている登録確認の<br class='s-elem'/>URLリンクを３時間以内にアクセスして<br class='s-elem'/>マイページ利用登録を完了させてください。<br>この利用登録の手続きが完了しましたら、<br class='s-elem'/>ログインの上お買い物をお楽しみください。 </ p>"
  },
  input: {
    name: "お名前 <span class='c-red'>*</span>",
    name_hiragana: "ふりがな <span class='c-red'>*</span>",
    email: "メールアドレス <span class='c-red'>*</span>",
    email_confirm: "メールアドレス確認用 <span class='c-red'>*</span>",
    gender: "性別 <span class='c-red'>*</span>",
    occupation: "職業 <span class='c-red'>*</span>",
    birthday: "生年月日 <span class='c-red'>*</span>",
    password: "希望パスワード <span class='c-red'>*</span>",
    postal_code: "郵便番号 <span class='c-red'>*</span>",
    prefecture: "都道府県 <span class='c-red'>*</span>",
    address: "住所 <span class='c-red'>*</span>",
    phone_number: "電話番号 <span class='c-red'>*</span>",
  },
  options: {
    male: "男性",
    female: "女性",
    anonymous: "答えない"
  },
  placeholder: {
    first_name: "名",
    last_name: "姓",
    first_name_hiragana: "めい",
    last_name_hiragana: "せい",
    email: "メールアドレスを入力してください。",
    email_confirm: "メールアドレスを再度入力してください。",
    password: "パスワードを入力してください。",
    password_confirm: "パスワードを再度入力してください。",
    postal_code: "半角数字・ハイフン無し",
    address_1: "市区町村・番地",
    address_2: "建物名・部屋番号",
    phone_number: "半角数字・ハイフン無し",
    phone_number_confirm: "電話番号を再度入力してください。",
  },
  btn: {
    submit: "マイページ登録へ",
    send: "Send",
    confirm: "登録する",
    back: "戻る",
    checking_email_setting: "メール設定確認へ",
    member_registration: "マイページ登録へ",
    not_agree: "規約に同意しない",
    submit_postal_code: "郵便番号から住所入力",
    submit_form: "登録内容を確認する",
    complete: "ログインページへ",
    back_artist: "アーティストトップへ戻る",
    to_reset_password: "パスワードを再設定する"
  },
  verification: {
    success: "確認が完了しました",
    email_verified: "メールアドレスの確認が完了しました",
    success_msg: "マイページユーザの登録が完了しました。",
    you_can_login_with_email: "メールアドレスで<br class='s-elem'/>アカウントにログインできます<span class='s-elem'>: </span><br class='pc-elem'/><br class='s-elem'/>",
    pls_login: "ログインしてお買い物をお楽しみください。",
    invalid: "メールアドレスの確認が<br class='s-elem'/>できませんでした",
    invalid_msg: "リンクの有効期限が切れているか、<br class='s-elem'/>既にメールアドレスが登録されている<br class='s-elem'/>可能性があります。<br class='s-elem'/>再度ご確認ください。",
    request_verification: "メールアドレス確認リクエスト",
    here: "ここ",
    thank_you: "ありがとうございました。"
  },
  send_verification: {
    title: "Send Verification Email",
    subtitle: "確認メールを送信",
    headline: "確認メールを送信",
    email: "Eメール",
    btn: "確認メールを送信",
    message: "ご登録頂いたメールアドレスを入力すると、メールを確認するためのリンクが送信されます。",
    email_not_arrive: "メールが届かない？",
    resend_email: "確認メールを再送信する",
    error: "あなたのメールアドレスが見つかりませんでした。メールアドレスがすでに確認されているか、メールアドレスの登録がされておりません。"
  },
  occupation: {
    employee: '会社員',
    student: '学生',
    self_employee: '自営業',
    other: 'その他',
  },
  text_address_pc: "住所が正しく入力されていなかったり、建物名と部屋番号が未入力の場合、荷物が届かない可能性がございます。",
  address_validate: "市区町村・番地は必須項目です",
  address2_validate: "建物名と部屋番号が未入力の場合、荷物が届かない可能性がございます。",
  email_info_pc: "<span class='c-red'>マイページにログインする際に使用いたします。お忘れなきようメモなどに保存をお願いいたします。</span",
  email_info_sp: "<span class='c-red'>マイページにログインする際に使用いたします。お忘れなきようメモなどに保存をお願いいたします。</span",
}
