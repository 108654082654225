import BaseService from "./BaseService";

class AppSettingService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getBasicSetting() {
    return this.performRequest(BaseService.METHOD_GET, "basic");
  }

  updateBasicSetting(data) {
    return this.performRequest(BaseService.METHOD_PUT, "basic", data);
  }

  updateAppSetting(data) {
    return this.performRequest(BaseService.METHOD_PUT, "", data);
  }

  getPaymentFee(data) {
    return this.performRequest(BaseService.METHOD_GET, "payment-fee", data);
  }

  updatePaymentFee(data) {
    return this.performRequest(BaseService.METHOD_PUT, "payment-fee", data);
  }

  getEmail(data) {
    return this.performRequest(BaseService.METHOD_GET, "email", data);
  }

  updateEmail(data) {
    return this.performRequest(BaseService.METHOD_PUT, "email", data);
  }
}

export default new AppSettingService('settings');