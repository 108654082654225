<template>
  <Modal
    :modal="isShowModal"
    width="760px"
  >
    <template #body>
      <PostalCodeSelectModal
        :is-show-modal="is_show_modal"
        :is-mobile="is_mobile"
        :addresses="addresses"
        @select="settingAddress"
        @close="is_show_modal = false"
      />

      <validation-observer v-if="isShowModal" ref="observer">
        <section class="mb-30">
          <h4 class="main-title">{{ $t('purchase.product_delivery_address') }}</h4>
          <dl class="common-dl">
            <dt>
              {{ $t('purchase.postal_code') }}
              <span class="c-red">※</span>
            </dt>
            <dd>
              <PostalCodeInput
                v-model="userPurchaseInfo.zip_code"
                input_class="form-text--100"
                :input_name="$t('mypage_edit.product_delivery_address.postal_code')"
                :placeholder="$t('registration.placeholder.postal_code')"
                autocomplete="off"
                rule="required|numeric|digits:7"
                @input="onSearchZipCode"
              />
            </dd>
            <dt>
              {{ $t('purchase.prefectures') }}
              <span class="c-red">※</span>
            </dt>
            <dd>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <div class="form-select">
                  <select
                    v-model="userPurchaseInfo.prefecture_id"
                    :name="$t('purchase.prefectures')"
                    @change="onPrefectureChange"
                  >
                    <option :value="null" hidden>
                      {{ $t('purchase.please_select') }}
                    </option>
                    <option
                      v-for="(item, index) in prefectures"
                      :key="index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </option>
                  </select>
                </div>
                <span v-if="errors[0]" class="validate-error c-red">
                  {{ errors[0] }}
                </span>
              </ValidationProvider>
            </dd>
            <dt>
              {{ $t('purchase.street_address') }}
              <span class="c-red">※</span>
              <span v-if="!is_mobile" class="c-red edit-address-caution">
                {{ $t('purchase.text_address_pc') }}
              </span>
            </dt>
            <dd>
              <ValidationProvider v-slot="{ errors }" rules="required">
                <input
                  ref="address1"
                  type="text"
                  v-model="userPurchaseInfo.address"
                  :name="$t('purchase.city_address')"
                  :placeholder="$t('purchase.city_address')"
                  class="form-text--100"
                >
                <p v-if="errors[0] && !is_mobile" class="validate-error c-red">
                  {{ errors[0] }}
                </p>
                <p v-if="is_mobile" class="validate-error c-red">
                  {{ $t('purchase.address_validate') }}
                </p>
              </ValidationProvider>
              <ValidationProvider>
                <input
                  v-model="userPurchaseInfo.address_2"
                  :name="$t('purchase.building_name_room_number')"
                  :placeholder="$t('purchase.building_name_room_number')"
                  class="form-text--100"
                  type="text"
                >
                <span v-if="is_mobile" class="validate-error c-red">
                  {{ $t('purchase.address2_validate') }}
                </span>
              </ValidationProvider>
            </dd>
            <dt>
              {{ $t('purchase.phone_number') }}
              <span class="c-red">※</span>
            </dt>
            <dd>
              <ValidationProvider
                v-slot="{ errors }"
                :vid="$t('purchase.half_width_numbers_and_no_hyphens')"
                rules="required|phone_number|max:11|min:10"
              >
                <input
                  v-model="userPurchaseInfo.phone_number"
                  :name="$t('purchase.phone_number')"
                  :placeholder="$t('purchase.half_width_numbers_and_no_hyphens')"
                  class="form-text--80"
                  maxlength="11"
                  type="tel"
                >
                <p v-if="errors[0]" class="validate-error c-red">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|phone_number|confirmed:${$t('purchase.half_width_numbers_and_no_hyphens')}`"
              >
                <input
                  v-model="userPurchaseInfo.phone_number_confirmation"
                  :name="$t('purchase.phone_number')"
                  :placeholder="$t('purchase.please_enter_your_phone_number_again')"
                  class="form-text--80"
                  maxlength="11"
                  type="tel"
                >
                <p v-if="errors[0]" class="validate-error c-red">
                  {{ errors[0] }}
                </p>
              </ValidationProvider>
            </dd>
          </dl>
          <div class="mb-20">
            <div>
              <button class="btn-100--red" @click="onConfirm()">
                {{ $t('purchase_confirmation.update_edit_address') }}
              </button>
            </div>
            <div action="purchase.html">
              <button class="btn-100--none" @click="isShowModal = false">
                {{ $t('purchase_confirmation.cancel_edit_address') }}
              </button>
            </div>
          </div>
        </section>
      </validation-observer>
    </template>
  </Modal>
</template>

<script>
import MasterDataService from '@services/MasterDataService';
// import PurchaseService from '@services/PurchaseService';

export default {
  name: 'EditAddressModal',
  components: {
    Modal: () => import('@components/base/Modal'),
    PostalCodeInput: () => import('@components/base/PostalCodeInput'),
    PostalCodeSelectModal: () => import('@components/base/PostalCodeSelectModal'),
  },
  data() {
    return {
      is_mobile: '',
      isShowModal: false,
      prefectures: [],
      userPurchaseInfo: {},
      is_show_modal: false,
      addresses: [],
    }
  },
  methods: {
    init(val) {
      this.userPurchaseInfo = Object.assign({}, val)
      this.userPurchaseInfo.phone_number_confirmation = val.phone_number
      this.getPrefecture()
    },
    getPrefecture() {
      MasterDataService.index({
        is_prefecture: true,
      }).then(res => {
        this.isShowModal = true
        this.prefectures = res.data.prefectures
      })
    },
    onPrefectureChange() {
      this.userPurchaseInfo.prefecture = this.prefectures.find(el => el.value == this.userPurchaseInfo.prefecture_id).label
    },
    async onConfirm() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError();
        return;
      }
      // if (this.$store.state.isLogged) {
      //   PurchaseService.updateAddress(this.userPurchaseInfo.member_shipping_addresses_id, {
      //     postal_code: this.userPurchaseInfo.zip_code,
      //     prefecture_id: this.userPurchaseInfo.prefecture_id,
      //     address: this.userPurchaseInfo.address,
      //     address_2: this.userPurchaseInfo.address_2,
      //     phone_number: this.userPurchaseInfo.phone_number,
      //     phone_number_confirmation: this.userPurchaseInfo.phone_number_confirmation
      //   })
      // }
      this.$emit('on-save', this.userPurchaseInfo)
      this.isShowModal = false
    },
    async onSearchZipCode() {
      const regexp = /[0-9]{7}/;

      if (regexp.test(this.userPurchaseInfo.zip_code)) {
        const res = await MasterDataService.index({
          is_postal_code: true,
          postal_code: this.userPurchaseInfo.zip_code
        })

        const {postal_codes} = res.data

        if (postal_codes.length === 1) {
          const definite_postal_code = postal_codes[0]

          const {city, street_address, state} = definite_postal_code
          const prefecture = this.prefectures.find(prefecture => prefecture.label == state);

          this.userPurchaseInfo.address = city.concat(street_address);
          this.userPurchaseInfo.prefecture_id = prefecture ? prefecture.value : null;
          this.userPurchaseInfo.prefecture = prefecture ? prefecture.label : null;
        } else if (postal_codes.length !== 0) {
          this.addresses = postal_codes.map((postal_code, index) => {
            const address = postal_code.state.concat(postal_code.city.concat(postal_code.street_address));

            return {id: index, name: address, postal_code: postal_code};
          });

          this.is_show_modal = true;
        }
      }
    },
    settingAddress(address) {
      if (address) {
        const {postal_code} = address
        const {city, street_address, state} = postal_code
        const prefecture = this.prefectures.find(prefecture => prefecture.label == state);

        this.userPurchaseInfo.address = city.concat(street_address);
        this.userPurchaseInfo.prefecture_id = prefecture ? prefecture.value : null;
        this.userPurchaseInfo.prefecture = prefecture ? prefecture.label : null;
      }

      this.addresses = []
      this.is_show_modal = false

      setTimeout(() => {
        this.$refs.address1.focus();
      }, 500);
    },
  }
}
</script>
