<template>
  <article>
    <div v-if="verification_success" class="content__inner">
      <h2 class="page-title" style="padding: 0 10px 30px;">
                <span class="c-green verification-icon">
                    <i class="fas fa-check-circle"></i>
                </span>
        <span v-t="$t('registration.verification.success')" class="verification-title bold mb-20"></span>
        <p v-t="$t('registration.verification.success_msg')">
        <p v-html="$sanitize($t('registration.verification.you_can_login_with_email'))" style="display:inline)"></p>
        <span class="bold">{{ email }}</span>
        <br class='s-elem'/>
        <p v-t="$t('registration.verification.pls_login')"></p>
      </h2>
      <div class="mb-50">
        <Button :title="$t('registration.btn.complete')" isPrimary @onClick="loginPage()"></Button>
        <Button v-if="currentArtist !== undefined" :title="$t('registration.btn.back_artist')" @onClick="onBackArtist()"></Button>
        <Button v-if="isProvisional" :title="$t('registration.btn.to_reset_password')" @onClick="gotoResetPassword"></Button>
      </div>
    </div>
    <div v-if="invalid && !verification_success" class="content__inner">
      <h2 class="page-title">
                <span class="c-red verification-icon">
                    <i class="far fa-times-circle"></i>
                </span>
        <span v-html="$sanitize($t('registration.verification.invalid'))" class="verification-title bold mb-20)"></span>
        <p v-html="$sanitize($t('registration.verification.invalid_msg'))"/>
        <p>{{ $t('registration.verification.request_verification') }}
          <router-link :to="{ name: 'member.sendVerification', query: { redirect: this.currentArtist }}" class="c-blue bold">
            <u>{{ $t('registration.verification.here') }} </u>
          </router-link>
        </p>
        <p v-t="$t('registration.verification.thank_you')"></p>
      </h2>
      <div class="mb-50">
        <Button :title="$t('registration.btn.complete')" isPrimary @onClick="loginPage()"></Button>
        <Button v-if="currentArtist !== undefined" :title="$t('registration.btn.back_artist')" @onClick="onBackArtist()"></Button>
      </div>
    </div>
  </article>
</template>
<script>

import RegisterService from '@services/RegisterService';

export default {
  components: {
    Button: () => import('@components/base/Button')
  },
  data() {
    return {
      email: "",
      verification_success: false,
      invalid: false,
    }
  },
  computed: {
    currentArtist() {
      return this.$route.query.redirect
    },
    isProvisional() {
      const { provisional } = this.$route.query
      return provisional === "true"
    }
  },
  methods: {
    checkVerification() {
      RegisterService.verify(this.$route.params.token).then(res => {
        if (res.meta.code === 200) {
          this.verification_success = true;
          this.email = res.data.email;
        }
      })
        .catch(() => {
          this.invalid = true;
        })
    },
    loginPage() {
      const query = {}
      if (this.currentArtist) {
        query.redirect = this.$router.resolve({name: "artist.home", params:{designate_page: this.currentArtist} }).href
      }
      this.$router.push({name: "auth.login", query})
    },
    onBackArtist() {
      this.$router.push({name: "artist.home", params:{designate_page: this.currentArtist} })
    },
    gotoResetPassword() {
      const { reset_token } = this.$route.query
      if (this.isProvisional && reset_token !== undefined) {
        this.$router.push({name: "auth.new_pw", params: {token: reset_token}})
      } else {
        return
      }
    }
  },
  created() {
    this.checkVerification();
  }
}
</script>
<style scoped>
.verification-title {
  font-size: 4rem;
  width: 100%;
  display: block;
  line-height: 110%;
}

.verification-icon {
  font-size: 8rem;
}

@media screen and (min-width: 768px) and (max-width: 834px) {
  .verification-title {
    font-size: 3rem;
  }

  .verification-icon {
    font-size: 7rem;
  }
}

@media screen and (max-width: 767px) {
  .verification-title {
    font-size: 2rem;
  }

  .verification-icon {
    font-size: 6rem;
  }
}
</style>
