<template>
  <article>
    <keep-font-size-style-sheet
      selector=".artist-profile__name"
      :font-size="nameFontSize"
      :sp-font-size="nameFontSize"
    />
    <keep-font-size-style-sheet
      selector=".artist-profile__name .artist-profile__sub-name"
      :font-size="subNameFontSize"
      :sp-font-size="subNameFontSize"
    />
    <InformationListAccordion
      v-if="artist.whole_information_list && artist.whole_information_list.length"
      :informations="artist.whole_information_list"
      :content-class="'whole-information'"
      title="重要なお知らせ"
      noticeText="重要"
    />

    <section
      :style="{ backgroundColor: artist.page_sub_color, color: artist.background_label_color }"
      class="content bg-gray artist-profile_wrapper"
    >
      <span class="registered_trademark">Bromide.jp<span class="reg_mark">&reg;</span> Photo Produce</span>
      <a id="main_visual_artist_name_pc" />
      <a id="main_visual_artist_name_sp" />
      <div class="content__inner">

        <div ref="mainText" class="artist-profile">

          <div :class="`artist-profile__photo ${artist.main_visual_image_url ? '' : 'image_unloaded'} ${mainImageSizeSetting ? '' : ' artist-profile__photo--full'}`">
            <img
              v-if="artist.main_visual_image_url"
              :alt="artist.name_top_page"
              :src="artist.main_visual_image_url"
              height="auto"
              class="kv-img"
            />

            <div
              class="artist-profile__name_wrapper"
              :style="artistNamePositionStyle"
            >
              <div
                v-if="mainImageSizeSetting"
                :style="logoStyle"
                class="artist-profile__name"
              >
                {{ artist.name_top_page }}
                <p
                  v-if="artist.sub_name_top_page"
                  :style="subNameStyle"
                  class="artist-profile__sub-name"
                >
                  {{ artist.sub_name_top_page }}
                </p>
              </div>
            </div>

          </div>

          <keep-proportion-content
            :proportion-target="() => $refs.mainText"
            id="main_text"
            :style="{ color: artist.main_text_color }"
            class="artist-profile__bio content__inner--editor pb-50"
            :content="artist.main_text"
            :p-width="1000"
            :p-sp-width="360"
            :gap="0"
            :gap-sp="0"
          />
          <div is="style">
            #main_text a { color: {{ artist.main_text_color }} !important; }
            #main_text a:visited { color: {{ artist.main_text_color }} !important; }
            #main_text a:link { color: {{ artist.main_text_color }} !important; }
            #main_text a:active { color: {{ artist.main_text_color }} !important; }
          </div>

        </div>
      </div>
    </section>

    <EventList
      v-if="events.length"
      :event-link="{ name: 'event.page' }"
      :events="events"
      :is_mobile="isMobile"
      subtitle="イベント"
      title="EVENT LIST"
    >
      <template #header>
        <div v-if="artist.news && artist.news.length" class="mb-60">
          <InformationListAccordion
            :informations="artist.news"
            :content-class="'artist-profile__news'"
            title="お知らせ"
            noticeText="INFO"
          />
        </div>
      </template>
      <template #term_service>
        <slot name="term_service" />
      </template>
    </EventList>

    <section
      v-if="artistBanners.length > 0"
      :style="{ backgroundColor: artist.page_sub_color }"
      class="content bg-gray"
    >
      <div class="content__inner top-banner">
        <div
          v-for="(artistBanner, index) in artistBanners"
          :key="index"
          :class="`top-banner__item ${artistBanner.banner_image_url ? '' : 'image_unloaded'} box-shadow`"
        >
          <a
            :href="artistBanner.url"
            rel="noopener noreferrer"
          >
            <img :src="artistBanner.banner_image_url" alt=""/>
          </a>
        </div>
      </div>
    </section>
  </article>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
import KeepFontSizeStyleSheet from "@components/base/KeepFontSizeStyleSheet";

export default {
  name: 'NormaArtistTemplate',
  components: {
    KeepProportionContent,
    KeepFontSizeStyleSheet,
    InformationListAccordion: () => import('@components/base/InformationListAccordion'),
    EventList: () => import('@components/event/EventList'),
  },
  props: {
    artist: {
      type: Object,
      require: true,
    },
    events: {
      require: true,
    },
    artistBanners: {
      require: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    mainImageSizeSetting() {
      return this.artist.main_image_size_setting
        ? this.artist.main_image_size_setting === 'normal'
        : false
    },
    nameFontSize() {
      return parseInt(this.artist.name_font_size_top_page)
    },
    subNameFontSize() {
      return parseInt(this.artist.sub_name_font_size_top_page)
    },
    logoStyle() {
      return {
        color: this.artist.name_text_color,
      }
    },
    subNameStyle() {
      return {
        color: this.artist.sub_name_text_color,
        'padding-top': '1rem'
      }
    },

    artistNamePositionStyle() {
      let position = ''
      switch (this.artist.name_vertical_pc) {
        case 'top':
          position = 'flex-start'
          break;
        case 'center':
          position = 'center'
          break;
        case 'bottom':
          position = 'flex-end'
          break;
      }
      return {
        'align-items': position
      }
    }

  },
}
</script>

<style scoped>
.cursor {
  cursor: pointer;
}
</style>
