export default {
  title_login_en: "Login",
  title_login_jp: "ログイン",
  please_log_in_here_to_complete_the_purchase_procedure: "マイページ登録済みのお客様はログインしてください。",
  if_you_forget_your_password: "※パスワードをお忘れの場合は",
  here: "こちら",
  please_reset_your_new_password_from: "から新しいパスワードを再設定してください。",
  please_reset_your_new_password_from2: "マイページ登録をする場合は以下より登録してください。<br/>登録せずに購入のお客様は、<br class='s-elem'/>下段の登録せずに購入するを選択してください。",
  email_address: "メールアドレス",
  password: "パスワード",
  save_login_status_for_one_month: "一ヶ月間ログイン状態を保存する",
  register_for_membership: "マイページ登録する",
  here_you_will_find_information: `
        <span style="font-weight: bold">マイページ利用登録すると便利です。</span>
        <br>
        <br>
        ・送付先情報等購入に必要な情報を入力せずに進められます。
        <br>
        ※当サービスではクレジットカードの情報は保持・登録はできません。
        <br>
        <br>
        ・購入情報・履歴の閲覧確認ができます。
        <br>
        ※当社サービスの納品書には購入した写真のサムネール画像は付きませんので、購入した内容であるかの確認は取れません。
        <br>
        <br>
        ・マイページ登録を希望されないお客様は「マイページ登録せずに購入する」を選択してください。<br>
        購入手続きへ進めます。
    `,
  dummy_text: "ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト\nダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト",
  purchase_without_registering_as_a_member: "マイページ登録せずに購入する",
  continue_shopping: "ショッピングを続ける",
  login: "ログインする",

  please_read_register_for_membership_information: `
        ファンクラブ会員のログイン情報でログインすることは出来ません。<br>
        ページ下部にございます、マイページ登録についてのご案内をお読みくださいませ。
  `
}