<template>
  <article :style="listStyle" ref="item">
    <router-link
      :to="resolveEventLink"
      :style="event.is_active ? '' : { 'pointer-events' : 'none' }"
      class="article__link"
    >
      <img
        v-if="isRecommended"
        alt="おすすめ"
        class="article__logo--recommend"
        src="@assets/images/icon_recommend.svg"
      />

      <div class="article__thumbnail box-shadow">
        <img :src="event.name_image_url" alt="イベント"/>

        <span :style="`background-image:url(${event.name_image_url});`" class="article__thumbnail--blur"/>
      </div>

      <keep-proportion-content
        root="h3"
        :proportion-target="() => $refs.item"
        :style="{ color: event.name_text_color }"
        :class="`article__title_small bold${isAudition ? ' article__title_arrange' : ''}`"
        :content="event.name"
        :p-sp-width="173"
        :p-width="293"
        :gap="0"
        :gap-sp="0"
        blank-to-br
      />

      <template v-if="!isAudition">
        <EventSaleTerm
          v-if="event.sale_period_start_date || event.sale_period_end_date"
          :proportion-target="() => $refs.item"
          :end-date="event.sale_period_end_date"
          :start-date="event.sale_period_start_date"
          class="article__sale-term"
          :p-sp-width="173"
          :p-width="293"
          :gap="0"
          :gap-sp="0"
        />
      </template>

      <div class="article__label">
        <span v-if="event.is_sale_new" class="article__label--new">New</span>

        <span v-else-if="isAlmostClosing" class="article__label--3day">
          販売終了残り{{ event.remaining_sale_day }}日
        </span>

        <span v-if="isClosingToday" class="article__label--today">
          本日販売終了
        </span>
      </div>
    </router-link>
  </article>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  name: 'EventListItem',
  components: {
    KeepProportionContent,
    EventSaleTerm: () => import('@components/event/EventSaleTerm'),
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
    eventLink: {
      type: [String, Object, Function],
      default: '',
    },
    isAudition: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {}
  },
  computed: {
    listStyle() {
      let listStyle = {position: 'relative'}
      if (this.height) {
        listStyle = {
          ...listStyle,
          "min-height": `${this.height}px`
        }
      }
      return listStyle
    },
    resolveEventLink() {
      switch (typeof this.eventLink) {
        case 'function':
          return this.eventLink(this.event.id)
        case 'string':
          return `${this.eventLink}/${this.event.id}`
        case 'object':
          // eslint-disable-next-line no-case-declarations
          const obj = {...this.eventLink}

          if (obj.params) {
            obj.params = {...obj.params, event_id: this.event.id}
          } else {
            obj.params = {event_id: this.event.id}
          }

          return obj
        default:
          return undefined
      }
    },
    isRecommended() {
      return !!this.event.display_recommendations
    },
    isAlmostClosing() {
      return this.event.remaining_sale_day <= 3 && this.event.remaining_sale_day > 0
    },
    isClosingToday() {
      return !!this.event.is_sale_finish_today
    },
  },
}
</script>
