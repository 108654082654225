<template>
  <div>
    <StaticTemplate
      :static-page="staticPage"
    />
  </div>
</template>

<script>
export default {
  components: {
    StaticTemplate: () => import('@components/static/StaticTemplate')
  },
  props: {
    staticPage: {
      type: Object
    }
  }
}
</script>

<style>

</style>