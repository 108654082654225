import ArtistService from '@services/ArtistService';
import router from '@/routes';
import {debounce} from "lodash"
import {CONFIRMATION_NAMES, CONFIRMATION_STATUS} from "@constants/ArtistConfirmation";
import PriorEmailRegistrationService from "@services/PriorEmailRegistrationService";

const debounceHideLoading = debounce((commit) => commit('SET_GLOBAL_LOADING', false),1000)

const EXPIRATIONS = {
  [CONFIRMATION_NAMES['MAIL_REGISTRATION']]: 60 * 24 * 7,
  [CONFIRMATION_NAMES['TERMS_AGREEMENT']]: 60 * 24,
  [CONFIRMATION_NAMES['MY_PAGE_REGISTRATION']]: 60 * 24,
  [CONFIRMATION_NAMES['EVENT_LIST_ATTENTION']]: 60 * 24,
}

const actions = {
  // { state: true, interval: false }
  toggleLoading({ commit }, val) {
    const state = typeof val === 'boolean' ? val : val.state
    const interval = typeof val === 'boolean' ? true : val.interval
    if (state) {
      commit('SET_GLOBAL_LOADING', state)
    } else {
      if (interval) {
        debounceHideLoading(commit)
      } else {
        commit('SET_GLOBAL_LOADING', false)
      }
    }
  },
  /**
   * 現在のアーティスト情報を取得する
   * @param commit
   * @param dispatch
   * @param artistName
   */
  getCurrentArtistByDesignatePageProperty: ({commit, dispatch}, artistName) => {
    return ArtistService.getArtistByDesignatePage(
      artistName
    ).then(({data}) => {
      localStorage.setItem('header_logo_image_url', data.header_logo_image_url);
      commit('SET_CURRENT_ARTIST', data);
      commit('SUBSCRIBE_TO_GOOGLE_ANALYTIC', data.google_analytic);
      if (!data.is_msg_when_hidden) {
        dispatch('getArtistConfirmation')
      }
      commit('SHOW_LAYOUT');
      return data
    })
  },
  /**
   * 現在のアーティスト情報を取得する
   * @param commit
   * @param dispatch
   */
  getCurrentArtist: ({commit, dispatch}) => {

    const currentRoute = router.currentRoute;
    return ArtistService.getArtistByDesignatePage(
      currentRoute.params.designate_page,
    ).then(({data}) => {
      localStorage.setItem('header_logo_image_url', data.header_logo_image_url);
      commit('SET_CURRENT_ARTIST', data);
      commit('SUBSCRIBE_TO_GOOGLE_ANALYTIC', data.google_analytic);
      if (!data.is_msg_when_hidden) {
        dispatch('getArtistConfirmation')
      }
      commit('SHOW_LAYOUT');
      return data
    })
      .catch((e) => {
        if (e.status === 404) {
          router.push({name: '404'});
          return e;
        }
      });

  },

  verifyEmailRegistration: async ({ dispatch }, { token, designatePageId }) => {
    await PriorEmailRegistrationService.verifyEmailRegistration(token)
    await dispatch('setArtistConfirmation', { designatePageId, agreementName: CONFIRMATION_NAMES.MAIL_REGISTRATION })
    return true
  },

  myPageLoggedInConfirmed: async({ commit, state }) => {
    commit('SET_ARTIST_CONFIRMATION', {
      ...state.artistConfirmation,
      [CONFIRMATION_NAMES.MY_PAGE_REGISTRATION]: CONFIRMATION_STATUS.CONFIRMED
    })
  },

  /**
   * アーティスト確認事項状況を取得する
   * @param commit
   * @param state
   */
  getArtistConfirmation: ({ commit, state }) => {
    const result = ArtistService.getArtistConfirmationStatus(state.current_artist)
    commit('SET_ARTIST_CONFIRMATION', result)
  },

  /**
   * 指定したアーティスト確認事項を確認済にする
   * @param commit
   * @param state
   * @param dispatch
   * @param designatePageId
   * @param agreementName
   * @returns {Promise<void>}
   */
  setArtistConfirmation: async ({ commit, state, dispatch }, { designatePageId, agreementName }) => {
    await ArtistService.setArtistConfirmationStatus(designatePageId, agreementName, EXPIRATIONS[agreementName])
    if (!state.current_artist) {
      await dispatch('getCurrentArtistByDesignatePageProperty', designatePageId)
    }
    const result = ArtistService.getArtistConfirmationStatus(state.current_artist)
    commit('SET_ARTIST_CONFIRMATION', result)
  },

  setGlobalError: async ({ commit }, { error }) => {
    commit('SET_GLOBAL_ERROR', error)
  }
};

export default actions;
