export default {
  'notice': 'NOTICE',
  'sales_period': 'Sales period',
  'remaining_sale_day': '{remaining_days} days left',
  'new': 'NEW',
  'end_of_sale_today': 'End of sale today',
  'return_to_artist_top': 'Return to artist TOP',
  'return_to_event_top': 'Return to event TOP',
  'info': 'INFO',
  'selling_price': 'Selling price',
  'tax_included': 'Tax included',
  'sheet': 'Sheet',
  'spec': 'Spec',
  'comment': 'Comment',
  'in_the_current_cart': 'In the current cart',
  'set': 'Set',
  'see_the_cart': 'See the cart',
  'buy_all_photos': 'Buy all photos',
  'selected_set': 'I have selected {set} photos ・ Please proceed to the cart',
  'selected_under_set': 'Please select up to {set} and proceed to the cart',
  'selected_over_set': '{set} or more photos are selected',
  'expansion_purchase_multiple_sheets': 'Purchase multiple',
  'id': 'ID:',
  'add_to_cart': 'Add to cart',
  'number_of_sheets': 'Number of sheets',
  'relation': {
    'title': 'RELATION',
    'subtitle': '関連商品',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': 'Click here for details'
  },
  'printed_set_modal_warning': {
    'close': 'Close',
    'body': 'Please add printed product up to {sheet} sheets, price is calculate by set amount',
    'out_of_stock': "The stock of product is not enough"
  }
}