export const TYPE_CREDIT = 'credit';
export const TYPE_CONVENIENCE_STORE = 'convenience_store';
export const TYPE_CARRIER = 'carrier';

export const KEY_CREDIT = 'credit';
export const KEY_SEVEN_ELEVEN_CONVENIENCE_STORE = 'seven_eleven_convenience_store';
export const KEY_LAWSON_CONVENIENCE_STORE = 'lawson_convenience_store';
export const KEY_FAMILY_MART_CONVENIENCE_STORE = 'family_mart_convenience_store';
export const KEY_MINI_STOP_CONVENIENCE_STORE = 'mini_stop_convenience_store';
export const KEY_SEIKO_MART_CONVENIENCE_STORE = 'seiko_mart_convenience_store';
export const KEY_DOCOMO_CARRIER = 'docomo_carrier';
export const KEY_SOFTBANK_CARRIER = 'softbank_carrier';
export const KEY_AU_EASY = 'au_easy';

export const PAYMENT_TYPES = Object.freeze({
  TYPE_CREDIT,
  TYPE_CONVENIENCE_STORE,
  TYPE_CARRIER,
})

const PAYMENT_TYPE_FUNCTIONS = Object.freeze({
  [TYPE_CREDIT]: Object.freeze({
    type: () => TYPE_CREDIT,
    is: (type) => type === TYPE_CREDIT,
    needsConfirmation: () => false,
  }),
  [TYPE_CONVENIENCE_STORE]: Object.freeze({
    type: () => TYPE_CONVENIENCE_STORE,
    is: (type) => type === TYPE_CONVENIENCE_STORE,
    needsConfirmation: () => false,
  }),
  [TYPE_CARRIER]: Object.freeze({
    type: () => TYPE_CARRIER,
    is: (type) => type === TYPE_CARRIER,
    needsConfirmation: () => false,
  }),
})

export const getPaymentType = (type) => {
  if (!PAYMENT_TYPE_FUNCTIONS[type]) {
    throw new Error('invalid payment type')
  }

  return PAYMENT_TYPE_FUNCTIONS[type]
}

export const PAYMENT_KEYS = Object.freeze({
  KEY_CREDIT,
  KEY_SEVEN_ELEVEN_CONVENIENCE_STORE,
  KEY_LAWSON_CONVENIENCE_STORE,
  KEY_FAMILY_MART_CONVENIENCE_STORE,
  KEY_MINI_STOP_CONVENIENCE_STORE,
  KEY_SEIKO_MART_CONVENIENCE_STORE,
  KEY_DOCOMO_CARRIER,
  KEY_SOFTBANK_CARRIER,
  KEY_AU_EASY,
})

/**
 * 購入者に請求する決済手数料(税抜)の計算
 * クレジット決済, キャリア決済の場合購入者に請求する決済手数料は0円
 * コンビニ決済の場合、決済手数料以外の合計購入金額(=商品の購入金額+送料)の税込によって金額が変動する
 * ・5330円以上の場合、0円
 * ・2000以上5330円未満の場合、決済手数料設定で2000以上5330円未満に設定した金額
 * ・2000未満の場合、決済手数料設定で2000円未満に設定した金額
 *
 * @param paymentType
 * @param totalAmount
 * @param paymentFeeSetting
 * @returns {number}
 */
export const getPaymentFee = (paymentType, totalAmount, paymentFeeSetting) => {
  if (paymentType !== PAYMENT_TYPES.TYPE_CONVENIENCE_STORE) {
    return 0;
  }

  try {
    const communication_fee = +paymentFeeSetting.communication_fee_for_convenience_store_payment.from_value

    if (totalAmount > 0 && totalAmount < 2000) {
      return +paymentFeeSetting.fee_for_convenience_store_payment.from_value + communication_fee
    }

    if (totalAmount >= 2000 && totalAmount < 5330) {
      return +paymentFeeSetting.fee_for_convenience_store_payment_up.from_value + communication_fee
    }
  } catch (e) {
    return 0
  }

  return 0
}
