import BaseService from "./BaseService";

class ArtistService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getArtistByDesignatePage(designate_page, data) {
    let url = `get-by-designate-page/${designate_page}`
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }
}

export default new ArtistService('artist-tmps');
