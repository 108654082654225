<template>
  <div>
    <MessageWhenHidden
      v-if="isMsgHidden"
      :content="msgContent"
      :is_mobile="isMobile"
      :subtitle="msgSubTitle"
      :title="msgTitle"
    />

    <CategoryCombinedTemplate
      v-else-if="category.is_combined_product"
      :is-mobile="isMobile"
      :category="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"
      :purchase-item="purchaseItem"
      :product_current_qty_in_cart="product_current_qty_in_cart"
      :product_current_total_include_tax="product_current_total_include_tax"
      :except_category_id="except_category_id"
      :filters="filters"
      :product-standard="productStandard"
      :categories="categories"
      :sale-products="saleProducts"

      :carousel-setting="carouselSetting"
      :current-artist="currentArtist"

      :sub-commodities="subCommodities"
      :combined-sale-products="combinedSaleProducts"

      @add-combined-product-to-cart="onAddCombinedProductToCart"
      @to-cart="onToCart"
      @page="handleOnPage"
      @filter-change="onFilterChange"

    />

    <CategoryGoodsTemplate
      v-else

      :category="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"
      :purchase-item="purchaseItem"
      :product_current_qty_in_cart="product_current_qty_in_cart"
      :product_current_total_include_tax="product_current_total_include_tax"
      :except_category_id="except_category_id"
      :filters="filters"
      :product-standard="productStandard"
      :categories="categories"
      :sale-products="saleProducts"
      :current-artist="currentArtist"

      :carousel-setting="carouselSetting"

      @update-purchase-item="onUpdatePurchaseItem"
      @add-to-cart="onAddToCart"
      @filter-change="onFilterChange"
    />
  </div>
</template>

<script>
export default {
  name: "CategoryGoods",
  components: {
    CategoryGoodsTemplate: () => import('@components/category/CategoryGoodsTemplate'),
    CategoryCombinedTemplate: () => import('@components/category/CategoryCombinedTemplate'),
    MessageWhenHidden: () => import('@components/base/MessageWhenHidden'),
  },
  props: {
    isMsgHidden: {
      type: Boolean,
      required: true
    },
    msgContent: {
      type: String,
      default: ''
    },
    msgSubTitle: {
      type: String,
      default: ''
    },
    msgTitle: {
      type: String,
      default: ''
    },

    category: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },

    purchaseItem: {
      type: Object,
    },

    product_current_qty_in_cart: {
      type: [ Number, String ],
      default: 0
    },

    product_current_total_include_tax: {
      type: [ Number, String ],
      default: 0
    },

    except_category_id: {
      type: String,
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    categories: {
      type: Array
    },
    saleProducts: {
      type: Array
    },
    carouselSetting: {
      type: Object,
      default: () => {}
    },
    isMobile: {
      type: Boolean,
      default: false
    },
    currentArtist: {
      type: Object,
      required: true
    },
    subCommodities: {
      type: Array,
      default: () => []
    },
    combinedSaleProducts: {
      type: Object
    }
  },
  methods: {
    onToCart() {
      this.$emit('to-cart')
    },
    handleOnPage(page) {
      this.$emit('page', page)
    },
    onUpdatePurchaseItem(value) {
      this.$emit('update-purchase-item', value)
    },
    onAddToCart(data) {
      this.$emit('add-to-cart', data)
    },
    onAddCombinedProductToCart(data) {
      this.$emit('add-combined-product-to-cart', data)
    },
    onFilterChange(data) {
      this.$emit('filter-change', data)
    },
  }
}
</script>
