<template>
  <section class="content pb-50 pt-20">
    <div class="content__inner">
      <h2
        ref="eventName"
        :class="['event-main__name', 'content__inner--editor', 'bold']"
      >
        <keep-proportion-content
          :proportion-target="() => $refs.eventName"
          :style="{ color: data.event_name_text_color }"
          :content="data.event_name_top_page"
          :p-width="1000"
          :p-sp-width="360"
          :gap="0"
          :gap-sp="0"
          blank-to-br
        />
      </h2>

      <category-sale-term
        v-if="showSaleTerm"
        :data="data"
      />
      <div class="al-r" style="border-top: 1px solid #f0f2f4">
        <a :href="artist_page_url" class="link mt-10">{{ label.TO_TOP }}</a>
      </div>
      <div class="al-r">
        <a :href="event_page_url" class="link mt-10">{{ label.TO_EVENT }}</a>
      </div>
    </div>
  </section>
</template>
<script>
import KeepProportionContent from "../base/KeepProportionContent";
import CategorySaleTerm from "./CategorySaleTerm";
import auditionModeSwitcher from "../../helpers/auditionModeSwitcher.mixin";
export default {
  components: { KeepProportionContent, CategorySaleTerm},
  mixins: [ auditionModeSwitcher ],
  props: {
    data: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },
    showSaleTerm: {
      type: Boolean,
      default: true
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
}
</script>
