<template>
  <article>
    <h2 class="page-title">
      <span class="page-title--en">Purchase</span>
      <span class="page-title--jp">購入手続き</span>
    </h2>
    <section class="content pb-80">
      <validation-observer ref="observer">
        <div class="content__inner" ref="contentInner">

          <section v-if="isShowConsent">
            <div class="mb-60">
              <h3 class="section-title">未成年者購入に関する同意確認</h3>
              <keep-proportion-content
                class="mb-30"
                :proportion-target="() => $refs.contentInner"
                :gap-sp="0"
              >
                <span>未成年者様が商品を購入するには、<br class="s-elem"/>保護者の同意が必要です。</span>
                <br class="s-elem"/>
                <span class="bold">保護者から今回の商品購入の同意を得ていますか?</span>
              </keep-proportion-content>
              <div :class="{ 'validate-error': !agreement.iHaveChosen}" class="border-red al-c">
                <label class="check">
                  <input v-model="agreement.iHaveChosen" class="js-child-check" name="test" type="checkbox">
                  <span class="check--dummy"></span>
                  <span class="check--text">はい、私は同意を得ています。</span>
                </label>
              </div>
            </div>
          </section>

          <!-- render payment method -->
          <section>
            <div class="mb-60">
              <h3 class="section-title">お支払い方法選択</h3>
              <p class="mb-30">下記より、ご希望のお支払い方法を選択してください。</p>
              <PaymentSelectList
                :current-payment-key="userPaymentInfo.payment_method_id"
                :current-payment-type="userPaymentInfo.parent_payment_method_id"
                :payment-methods="payment_methods"
                @select-child-payment="selectChildPayment"
                @select-payment-type="selectPaymentType"
                @select-payment-key="selectPaymentKey"
              />
            </div>
          </section>

          <!-- render user purchase product -->
          <RepurchaseProduct ref="purchaseProduct" :selected_payment_id="userPaymentInfo.parent_payment_method_id"
                           @re-validate="checkValidation()"
                           @edit-address-click="$refs.editAddress.init(userPurchaseInfo)"/>

          <div class="mb-20">
            <div>
              <button :disabled="disabledSubmit" class="btn-100--red" @click="submit()">
                {{ selectedCreditPayment ? 'カード情報入力へ' : '注文確認へ' }}
              </button>
            </div>
            <div action="purchase.html">
              <button class="btn-100--none" @click="goBack()">
                {{ $t('purchase_credit.to_fix') }}
              </button>
            </div>
          </div>
        </div>
      </validation-observer>
    </section>

    <EditAddressModal ref="editAddress" @on-save="onEditAddressSave"/>
  </article>
</template>
<script>
import Constants from '@constants';
import {PAYMENT_TYPES} from '../../constants/payment'
import KeepProportionContent from "@components/base/KeepProportionContent";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'RepurchaseConfirmation',
  components: {
    PaymentSelectList: () => import('@components/payment/PaymentSelectList'),
    RepurchaseProduct: () => import('./RepurchaseProduct'),
    EditAddressModal: () => import('../purchase/Modal/EditAddressModal.vue'),
    KeepProportionContent
  },
  created() {
    if (!this.repurchaseUserCartData) {
      this.$cookies.remove(Constants.USER_REPURCHASE_INFO)
      this.$cookies.remove(Constants.USER_REPURCHASE_PAYMENT_INFO)
      this.$cookies.remove(Constants.REPURCHASE_STEP)
      this.$router.push({name: `repurchase.${this.$route.meta?.type}`})
      return
    }

    this.$cookies.set(Constants.REPURCHASE_STEP, `repurchase.${this.repurchaseType}.confirmation`, Constants.PURCHASE_INFO_DURATION_COOKIE)

    this.payment_methods = this.repurchasePayments

    // this.getArtistPaymentMethod()

    const userPaymentInfo = this.$cookies.get(Constants.USER_REPURCHASE_PAYMENT_INFO);
    // 入力情報を取得
    this.userPurchaseInfo = this.$cookies.get(Constants.USER_REPURCHASE_INFO);

    // 支払い方法情報があれば
    if (userPaymentInfo) {
      this.userPaymentInfo = userPaymentInfo
    }
  },
  computed: {
    ...mapGetters([
      'repurchaseType',
      'repurchasePayments',
      'repurchaseUserCartData',
    ]),
    isShowConsent() {
      let dob = this.getAge(this.userPurchaseInfo.date_of_birth)
      return dob < 20;
    },
    selectedCreditPayment() {
      return this.userPaymentInfo && this.userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT
    }
  },
  data() {
    return {
      PAYMENT_TYPES,
      agreement: {
        iHaveChosen: false,
      },
      userPurchaseInfo: {},
      userPaymentInfo: {
        payment_method_id: null,
        parent_payment_method_id: null,
        card_number: null,
        holder_name: null,
        security_code: null,
        expiration_date: null,
      },
      disabledSubmit: false,
      payment_methods: [],
      isShowWithRegisterModal: false,
      registeredMypage: false,
    }
  },
  methods: {
    ...mapMutations([
      'SET_USER_REPURCHASE_PAYMENT_INFO'
    ]),
    onEditAddressSave(val) {
      this.userPurchaseInfo = val
      this.$refs.purchaseProduct.updateAddress(val)
      this.$cookies.set(Constants.USER_REPURCHASE_INFO, JSON.stringify(val), Constants.PURCHASE_INFO_DURATION_COOKIE)
    },

    selectChildPayment(paymentKey) {
      this.userPaymentInfo.payment_method_id = paymentKey
    },
    selectPaymentType(paymentType) {
      this.userPaymentInfo.parent_payment_method_id = paymentType
      this.userPaymentInfo.payment_method_id = null
    },
    selectPaymentKey(paymentKey) {
      this.userPaymentInfo.payment_method_id = paymentKey

      const targetPaymentType = this.payment_methods.find((paymentMethodType) => {
        return paymentMethodType.children.find((paymentMethod) => paymentMethod.key === paymentKey)
      })

      if (targetPaymentType) {
        this.userPaymentInfo.parent_payment_method_id = targetPaymentType.type
      }
    },
    goBack() {
      return this.$router.push({name: `repurchase.${this.repurchaseType}.purchase`})
    },
    async checkValidation() {
      if (!(await this.$refs.observer.validate()) || (this.isShowConsent && !this.agreement.iHaveChosen)) {
        return false;
      }
      return true;
    },
    // getArtistPaymentMethod(designate_page) {
    //   // PurchaseService.getArtistPaymentMethod(designate_page).then(res => {
    //   //   this.payment_methods = res.data
    //   // })
    //   this.payment_methods = this.$store.state.repurchase.repurchase_data.payments
    // },
    async submit() {
      this.disabledSubmit = true
      if (!(await this.checkValidation())) {
        this.scrollToFirstError();
        this.disabledSubmit = false
        return;
      }
      // if (await this.$store.dispatch('getCartData')) {
      //   this.disabledSubmit = false
      //   return;
      // }
      this.$cookies.set(Constants.USER_REPURCHASE_PAYMENT_INFO, JSON.stringify(this.userPaymentInfo), Constants.PURCHASE_INFO_DURATION_COOKIE)
      this.$store.commit('SET_USER_REPURCHASE_PAYMENT_INFO',this.userPaymentInfo )
      //selected credit setlement
      if (this.userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT) {
        this.disabledSubmit = false
        return this.$router.push({name: `repurchase.${this.repurchaseType}.credit`})
      }
      this.disabledSubmit = false
      return this.$router.push({name: `repurchase.${this.repurchaseType}.confirmation2`})
    },
    backToArtist() {
      this.$router.push({name: 'artist.home'});
    },
    onRegisterConfirmModal() {
      this.isShowWithRegisterModal = false
      this.registeredMypage = true
    },
  }
}
</script>
<style scoped>
.confirm_footer {
  display: flex;
  justify-content: center;
}
</style>
