<template>
  <div class="category-product__item">

    <div class="category-product__thumb">
      <a>
        <img :src="sale_product.main_image_url">
        <span
          :style="{ 'background-image': sale_product.main_image_url }"
          alt=""
          class="sub-cate-link__blur" />
      </a>
    </div>

    <div class="category-product__content-wrapper mt-5 mb-5">
      <span :class="['caption-id', {'has_caption': hasCaption}]">{{ $t('category.id') }}{{ sale_product.id }}</span>
      <div ref="content" class="caption-content-wrapper">
        <keep-proportion-content
          ref="propotion"
          :proportion-target="() => $refs.content"
          :content="sale_product.name"
          class="c-red caption-content"
          :p-width="128"
          :p-sp-width="138"
          :sp-max-width="768"
          :gap="0"
          :gap-sp="0"
          transform-origin="center left"
          blank-to-br
          keep-inbox
        />
      </div>

    </div>

    <div class="category-product__button-wrapper">
      <a
        class="category-product__button"
        @click="handleChoseClick"
      >
        選択
      </a>
    </div>
  </div>
</template>
<script>
import KeepProportionContent from "../base/KeepProportionContent";

export default {
  components: {
    KeepProportionContent
  },
  props: {
    sale_product: {
      type: Object,
      required: true
    },
    isInCart: {
      type: Boolean,
      default: false
    },
    hasCaption: {
      type: Boolean,
      default: false
    }
  },
  computed: {
  },
  methods: {
    handleChoseClick() {
      this.$emit('choose-click', this.sale_product)
    },
  }
}
</script>
<style lang="scss" scoped>
.caption-id {
  font-size: 12px;
  line-height: 17.5px;
  width: 100%;
  max-width: 52px;
  margin-right: 4px;
}
.has_caption {
  line-height: 34px;
}

.category-product__thumb {
  a:hover {
    opacity: 1;
  }
  box-shadow: 0 0 10px -4px rgba(0,0,0,0.6);
}

.category-product__button {
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  &:hover {
    opacity: 1;
  }
  box-shadow: 0 0 10px -4px rgba(0,0,0,0.6);
}

.caption-content-wrapper {
  width: 128px;
  white-space: nowrap;
}
.caption-content {
  display: flex;
  align-items: center;
  font-size: 10px;
  height: 100%;
}

@media screen and (max-width: 767.98px){
  .caption-content-wrapper {
    width: calc(100% - 42px);
  }
  .caption-id {
    width: 100%;
    max-width: 42px;
    font-size: 10px;
    margin-right: 2px;
  }
}

</style>
