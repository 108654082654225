import BaseService from "./BaseService";
import Constants from '@constants'
import Vue from 'vue';

class ReorderService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getReorder(reorder_token) {
    return this.performRequest(BaseService.METHOD_POST, `${reorder_token}`, {}, {}, { is_ignore_interceptors_response_error: true });
  }

  applyReorder(reorder_token, data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    return this.performRequest(BaseService.METHOD_PUT, `${reorder_token}`, data)
  }

}

export default new ReorderService('reorders');
