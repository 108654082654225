export default {
  default_select: "選択してください",
  login: "ログイン",
  register: "マイページ登録",
  mypage: "マイページ",
  history: "購入履歴",
  profile: "登録情報（確認・編集）",
  change_password: "パスワード変更",
  logout: "ログアウト",
  mypage_cancel: "マイページ利用解除手続き",
  cart: "カート",
  guide: 'ご利用ガイド',
  delivery: '支払い・配送について',
  trading: '特定商取引法',
  privacy: 'プライバシーポリシー',
  terms: '利用規約',
  inquiry: 'お問い合わせ',
  notice: "NOTICE",
  info: "INFO",
  year: "年",
  day: "日",
  month: "月",
  months: {
    "0": "1月",
    "1": "2月",
    "2": "3月",
    "3": "4月",
    "4": "5月",
    "5": "6月",
    "6": "7月",
    "7": "8月",
    "8": "9月",
    "9": "10月",
    "10": "11月",
    "11": "12月",
  },
  day_name: {
    Monday: '月',
    Tuesday: '火',
    Wednesday: '水',
    Thursday: '木',
    Friday: '金',
    Saturday: '土',
    Sunday: '日'
  },
  technical: "技術的エラー、ブロマイド管理者に連絡してください",
  ok: 'OK',
  postal_code_error_msq: ' 郵便番号は7桁数字でご入力ください。',
  api_server_busy: "只今、大変込み合っています。<br />しばらく経ってから再度アクセスをお願い致します。",
  error_message_address_custom: '市区町村・番地は30文字以内でご入力ください',
  error_message_address_2_custom: '建物名・部屋番号は50文字以内でご入力ください'

}