/**
 * vhの高さ計算においてiosやandroidでバグあり
 * 参考
 * https://zenn.dev/tak_dcxi/articles/2ac77656aa94c2cd40bf
 * そのため、jsでvhの値を計算し、css変数化して処理を行うスクリプトを上の記事を参考に実装
 * css変数を仕様するため、いよいよIE11非対応に
 */
export default (window) => () => {

  let vh
  let vw

  const calcVh = () => {
    return window.innerHeight * 0.01
  }

  const calcVw = () => {
    return window.document.body.clientWidth * 0.01
  }

  const setFillHeight = () => {
    window.document.documentElement.style.setProperty('--vh', `${vh}px`)
    window.document.documentElement.style.setProperty('--vhv', `${vh}`)
  }

  const setFillWidth = () => {
    window.document.documentElement.style.setProperty('--vw', `${vw}px`)
    window.document.documentElement.style.setProperty('--vwv', `${vw}`)
  }

  const setFillWidthListener = () => {

    const newVw = calcVw()

    if (vw !== newVw) {
      vw = newVw
      setFillWidth()
    }

  }

  const setFillHeightListener = () => {

    const newVh = calcVh()

    if (vh !== newVh) {
      vh = newVh
      setFillHeight()
    }

  }

  vh = calcVh()
  vw = calcVw()

  window.addEventListener('resize', setFillHeightListener)
  window.addEventListener('resize', setFillWidthListener)
  setFillHeight()
  setFillWidth()

  /**
   * リスナーイベントの削除メソッドを返す
   */
  return () => {
    window.removeEventListener('resize', setFillHeightListener)
    window.removeEventListener('resize', setFillWidthListener)
  }

}
