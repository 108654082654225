<template>
  <validation-observer
    ref="observer"
    v-slot="{ handleSubmit }"
    class="content__inner"
    tag="div"
  >
    <template v-if="priorDescription">
      <Headline title="購入にはマイページのご登録が必要です" />
      <div ref="priorDescription" style="margin: 0 0 32px;" class="content__inner--editor registration--pre__description">
        <keep-proportion-content
          :proportion-target="() => $refs.priorDescription"
          :content="priorDescription"
          :p-width="1000"
          :p-sp-width="360"
          :gap="0"
          :gap-sp="0"
        />
      </div>
    </template>

    <template v-show="content">
      <div ref="contentWrap" style="margin: 0 0 32px;" class="content__inner--editor">
        <keep-proportion-content
          ref="keepContent"
          :proportion-target="() => $refs.contentWrap"
          :content="content"
          :p-width="1000"
          :p-sp-width="400"
          :gap="0"
          :gap-sp="0"
        />
      </div>
    </template>

    <div class="al-c mb-30">
      <validation-provider v-slot="{ errors }" rules="required|checkbox">
        <label class="check">
          <input
            v-model="isChecked"
            :name="$t('registration.label.term')"
            type="checkbox"
            :disabled="!isScrollBottom"
            @input="resetValidate"
          />
          <span :class="['check--dummy', {'check--dummy__disabled': !isScrollBottom}]"></span>
          <span v-t="checkText" :class="['check--text', { 'check--text__disabled': !isScrollBottom}]"></span>
        </label>
        <div v-if="errors[0]" class="c-red mt-10">必須確認項目です</div>
      </validation-provider>
    </div>
    <Button
      :title="$t('registration.btn.checking_email_setting')"
      isPrimary
      @onClick="handleSubmit(agree)"
    ></Button>
    <Button
      title="既にマイページ登録がお済みの方はこちら"
      isPrimary
      @onClick="toLogin"
    ></Button>
    <div class="al-c mt-10">
      <a
        v-t="'registration.btn.not_agree'"
        class="link-line"
        href="/"
      ></a>
    </div>
  </validation-observer>
</template>

<script>
import KeepProportionContent from "../../../components/base/KeepProportionContent";

export default {
  name: "BaseRegisterPre",
  components: {
    KeepProportionContent,
    Headline: () => import("@components/base/Headline"),
    Button: () => import("@components/base/Button"),
  },
  props: {
    content: {
      type: String,
      default: ''
    },
    priorDescription: {
      type: String,
      default: ''
    },
  },
  mounted() {
    this.$nextTick(function (){
      setTimeout(() => {
        if (this.$refs.keepContent.$el.querySelector('.div-scroll')) {
          this.$refs.keepContent.$el.querySelector('.div-scroll').addEventListener('scroll', (evt) => {
            if (!this.isScrollBottom) {
              this.isScrollBottom = ( evt.target.scrollHeight - ( evt.target.scrollTop + evt.target.clientHeight)) < 100
            }
          })
        } else {
          this.isScrollBottom = true
        }
      }, 2000)
    })
  },
  data() {
    return {
      isScrollBottom: false,
      checkText: "registration.label.term",
      isChecked: false,
    };
  },
  methods: {
    agree() {
      if (this.isChecked) {
        this.$emit("agree");
      }
    },
    resetValidate() {
      this.$refs.observer.reset();
    },
    toLogin() {
      this.$emit('to-login')
    }
  },
  async created() {
    if (this.scrollTerm) {
      this.scrollTerm()
    }
  },
};
</script>
