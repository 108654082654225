<template>
  <div>
    <h2 class="page-title">
      <span class="page-title--en">{{ $t('purchase_login.title_login_en') }}</span>

      <span class="page-title--jp">{{ $t('purchase_login.title_login_jp') }}</span>
    </h2>

    <section class="content pb-60">
      <div class="content__inner" ref="contentInner">
        <section class="mb-60">
          <keep-proportion-content
            class="mb-10"
            :proportion-target="() => $refs.contentInner"
            :gap-sp="0"
          >
            {{ $t('purchase_login.please_log_in_here_to_complete_the_purchase_procedure') }}<br>
            {{ $t('purchase_login.if_you_forget_your_password') }}<br/>
            <a class="link-line" href="/password-resetting" style="padding-left: 14px">{{ $t('purchase_login.here') }}</a>
            {{ $t('purchase_login.please_reset_your_new_password_from') }}<br/><br/>
            <span v-html="$sanitize($t('purchase_login.please_reset_your_new_password_from2'))"></span>
          </keep-proportion-content>

          <div
            v-if="isReferrerEnabled"
            class="attention attention-padding"
          >
            <div class="attention__image"><img alt="注意" src="@assets/images/icon_attention.svg"></div>

            <div class="attention__text c-red" ref="attention">
              <keep-proportion-content
                :content="$t('purchase_login.please_read_register_for_membership_information')"
                :proportion-target="() => $refs.attention"
                :p-sp-width="304"
                :gap-sp="0"
              />
            </div>
          </div>

          <ErrorAlert v-if="unauthorized" class="mb-30"> {{ $t('auth.unauthorized') }}</ErrorAlert>

          <validation-observer ref="observer">
            <div class="bg-gray pt-30 pb-30 mb-30">
              <dl class="login-dl">
                <dt class="bold large mb-10">
                  {{ $t('purchase_login.email_address') }}
                </dt>

                <dd class="mb-10">
                  <ValidationProvider v-slot="{ errors }" rules="required|email">
                    <input v-model="form.email" :name="$t('auth.email')" class="login-dl__input" type="text"/>

                    <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>

                <dt class="bold large">
                  {{ $t('purchase_login.password') }}
                </dt>

                <dd class="mb-10">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="form-password">
                      <input
                        v-model="form.password"
                        :name="$t('auth.password')"
                        class="login-dl__input form-password__input"
                        :type="is_visible_password ? 'text' : 'password'"
                      >

                      <div class="form-password__append">
                        <IconEye
                          v-if="is_visible_password"
                          class="form-password__append__icon"
                          @click.stop="() => is_visible_password = !is_visible_password"
                        />

                        <IconEyeSlash
                          v-else
                          class="form-password__append__icon"
                          @click.stop="() => is_visible_password = !is_visible_password"
                        />
                      </div>
                    </div>

                    <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>
              </dl>
            </div>

            <div class="mb-50">
              <button class="btn-100" @click="login">
                {{ $t('purchase_login.login') }}
              </button>
            </div>
          </validation-observer>

          <h4 class="main-title">
            {{ $t('purchase_login.register_for_membership') }}
          </h4>

          <keep-proportion-content
            class="mb-30"
            :content="$t('purchase_login.here_you_will_find_information')"
            :proportion-target="() => $refs.contentInner"
            :p-sp-width="420"
            :gap-sp="0"
          />

          <div class="al-c mb-30">
            <button
              class="btn-100"
              @click="$router.push({name: 'member.index', query: { current_artist: $route.params.designate_page }})"
            >
              {{ $t('purchase_login.register_for_membership') }}
            </button>
          </div>

          <div class="al-c mb-10">
            <div class="al-c">
              <span
                class="c-darkgray link-line large bold"
                href=""
                @click="$router.push({name: 'purchase.attention' })"
              >
                {{ $t('purchase_login.purchase_without_registering_as_a_member') }}
              </span>
            </div>
          </div>
        </section>

        <section>
          <div class="al-c">
            <a
              class="c-darkgray link-line large bold"
              href=""
              onclick="window.history.back();return false;"
            >
              {{ $t('purchase_login.continue_shopping') }}
            </a>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import Constants from '@constants';
import KeepProportionContent from "@components/base/KeepProportionContent";
import store from "../../store/store";

export default {
  beforeRouteEnter(to, from, next) {
    if (!store.state.cart.cartDatas.length) {
      next({name: 'cart.page', params: { designate_page: to.params.designate_page}})
    } else {
      next()
    }
  },
  created() {
    this.$cookies.set(Constants.PURCHASE_STEP, 'purchase.login', Constants.PURCHASE_INFO_DURATION_COOKIE)
  },
  data() {
    return {
      form: {
        email: null,
        password: null,
        remember: false,
      },
      window: {

      },
      unauthorized: false,
      is_visible_password: false,
    }
  },
  computed: {
    isReferrerEnabled() {
      const currentArtist = this.$store.state.current_artist
      return currentArtist ? currentArtist.referrer_type !== 'none' : false
    }
  },
  methods: {
    async login() {
      let valid = await this.$refs.observer.validate();

      if (!valid) {
        this.scrollToFirstError();
        return;
      }

      if (this.remember === true) {
        this.remember = 1
      } else {
        this.remember = 0
      }
      this.$store.dispatch("login", this.form)
        .then(() => {
          this.$router.push({name: "purchase.confirmation"});
        }).catch((e) => {
          console.error(e)
          return this.unauthorized = true;
        });
    }
  },
  components: {
    ErrorAlert: () => import("@components/base/ErrorAlert"),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
    KeepProportionContent
  }
}
</script>

<style scoped>
@media screen and (min-width: 1000px) {
  .attention-padding {
    padding: 0 60px 10px 60px;
  }
}
</style>
