<template>
  <article>
    <section class="content pb-80">
      <div class="content__inner">
        <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                     class="d-block text-right mb-20"></router-link>
        <Headline :title="$t('mypage_history.title.purchase_history')"></Headline>
        <p v-html="$sanitize($t('mypage_history.purchase_detail_expired_info'))" class="mb-30)"></p>
        <table class="history-page__table mb-10">
          <thead>
          <tr>
            <th v-t="'mypage_history.column.purchase_number'" class="al-c"></th>
            <th v-t="'mypage_history.column.order_date_and_time'" class="al-c"></th>
            <th v-t="'mypage_history.column.payment_method'" class="al-c"></th>
            <th v-t="'mypage_history.column.product_type'" class="al-c"></th>
            <th v-t="'mypage_history.column.shipping_date'" class="al-c"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="al-c">{{ history_data.order_id }}</td>
            <td class="al-c">{{ orderDataTimeFormat(history_data.order_date_time) }}</td>
            <td class="al-c"> {{ paymentMethodType(history_data.payment_method) }}</td>
            <td class="al-c">
              <template v-for="(productType, index) in productTypes">
                <span :key="productType">{{ productType }}</span>
                <br v-if="productTypes.length === index + 1" :key="`br-${productType}`">
              </template>
            </td>
            <td class="al-c">
              <template v-for="(eachShippingDate, shippingIndex) in history_data.shipping_dates">
                <span :key="`shipping-date-${eachShippingDate}-${shippingIndex}`">
                  {{ orderDataTimeFormat(eachShippingDate) }}
                </span>

                <br v-if="history_data.shipping_dates.length === shippingIndex + 1" :key="`shipping-date-br-${eachShippingDate}-${shippingIndex}`">
              </template>
            </td>
          </tr>
          <tr>
            <th v-t="'mypage_history.column.shipping_status'" class="al-c"></th>
            <th v-t="'mypage_history.column.artist_name'" class="al-c"></th>
            <th v-t="'mypage_history.column.event_name'" class="al-c"></th>
            <th v-t="'mypage_history.column.quantity'" class="al-c"></th>
            <th v-t="'mypage_history.column.total_amount_included_tax'" class="al-c"></th>
          </tr>
          <tr>
            <td class="al-c" v-html="$sanitize(history_data.shipping_status)"></td>
            <td class="al-c"> {{ history_data.artist_name }}</td>
            <td>
              <template v-for="(eachEventName, eventIndex) in history_data.event_category_names">
                <span :key="`${eachEventName.event_name}-${eventIndex}`">
                  {{ eachEventName.event_name }}
                </span>

                <br :key="`middle-br-${eachEventName.event_name}-${eventIndex}-${eachCategoryName}-${categoryIndex}`">

                <template v-for="(eachCategoryName, categoryIndex) in eachEventName.category_names">
                  <span :key="`${eachEventName.event_name}-${eventIndex}-${eachCategoryName}-${categoryIndex}`">
                    {{ eachCategoryName }}
                  </span>

                  <br v-if="eachEventName.category_names.length === categoryIndex + 1" :key="`br-${eachEventName.event_name}-${eventIndex}-${eachCategoryName}-${categoryIndex}`">
                </template>

                <br v-if="history_data.event_category_names.length === eventIndex + 1" :key="`last1-br-${eachEventName.event_name}-${eventIndex}`">
                <br v-if="history_data.event_category_names.length === eventIndex + 1" :key="`last2-br-${eachEventName.event_name}-${eventIndex}`">
              </template>
            </td>
            <td class="al-c"> {{ history_data.quantity }}{{ $t('mypage_history.sheet') }}</td>
            <td class="al-c">
              <dl class='align-dl'>
                <dt v-t="'mypage_history.column.subtotal'"></dt>
                <dd>¥{{ roundUp(history_data.subtotal) }}</dd>
              </dl>
              <dl class='align-dl'>
                <dt v-t="'mypage_history.column.shipping_fee'"></dt>
                <dd>¥{{ roundUp(history_data.shipping_fee) }}</dd>
              </dl>
              <dl v-if='is_payment_fee' class="align-dl">
                <dt v-t="'mypage_history.column.payment_fee'"></dt>
                <dd>¥{{ roundUp(history_data.payment_fee) }}</dd>
              </dl>
              <dl class="align-dl">
                <dt v-t="'mypage_history.column.total_amount'"></dt>
                <dd>¥{{ roundUp(history_data.total_payment_amount) }}</dd>
              </dl>
            </td>
          </tr>
          </tbody>
        </table>

        <p class="my_page_qualified_invoice_issuer_number_pc">
          株式会社Bromide 適格請求書発行事業者
          <br>
          登録番号:{{qualifiedInvoiceIssuerNumber}}
        </p>

        <div v-t="$t('mypage_history.purchase_detail')" class="main-title"></div>

        <p v-t="'mypage_history.purchase_detail_info_2'" class="mb-30"></p>

        <div v-if="event_category_data.length > 0" class="history-page__box">
          <label
            v-for="(item, index) in event_category_data"
            :key="index"
            :class="'history-page__box-switch--item pointer ' + (selected_event_category == index  ? 'active' : '')"
            @click="onEventCategoryChange(index)"
          >
            {{ item.event_name }}
            <br>
            {{ item.category_name }}
          </label>
        </div>

        <p
          v-if="event_category_view_limit"
          v-html="$sanitize($t(`mypage_history.purchase_detail_${event_category_view_limit}_info`))"
          style="padding: 30px; text-align: center"
        ></p>
        <OrderProductList v-show="!event_category_view_limit" :category_id="category_id" :event_id="event_id"/>

      </div>
    </section>
  </article>
</template>
<script>

import MemberService from '@/services/MemberService';

export default {
  name: "MyPageHistoryDetailPC",
  components: {
    Headline: () => import('@components/base/Headline'),
    OrderProductList: () => import('./OrderProductList.vue')
  },
  data() {
    return {
      history_data: {},
      event_category_data: [],
      selected_event_category: '',
    }
  },
  created() {
    this.getOrderHistoryByOrderId();
    this.getPurchaseEventCategoryByOrderID();
  },
  computed: {
    is_payment_fee() {
      return this.history_data.payment_fee ?? true;
    },
    productTypes() {
      const productTypes = Array.isArray(this.history_data.product_types) ?  this.history_data.product_types : []

      return productTypes.map((productType) => this.$t(`mypage_history.product_type.${productType}`));
    },
    event_id() {
      return this.event_category_data[this.selected_event_category] ? String(this.event_category_data[this.selected_event_category].event_id) : '';
    },
    category_id() {
      return this.event_category_data[this.selected_event_category] ? String(this.event_category_data[this.selected_event_category].category_id) : '';
    },
    event_category_sale_plan_type() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].sale_plan_type: ''
    },
    event_category_view_limit() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].view_limit : false
    },
    isRandomOrGacha() {
      return this.event_category_sale_plan_type === 'bundled_random' || this.event_category_sale_plan_type === 'gacha'
    },
    qualifiedInvoiceIssuerNumber() {
      return process.env.VUE_APP_QUALIFIED_INVOICE_ISSUER_NUMBER
    },
  },
  methods: {
    getOrderHistoryByOrderId() {
      MemberService.getOrderHistoryByOrderId(this.$route.params.history_id).then(res => {
        this.history_data = res.data;
        this.history_data.shipping_status = this.history_data.shipping_status.split('</br>').map((val) => {
          return val ? this.$t('mypage_history.shipping_status.' + val) : '</br></br>';
        }).join('');
      });
    },
    getPurchaseEventCategoryByOrderID() {
      MemberService.getPurchaseEventCategoryByOrderID(this.$route.params.history_id).then(res => {
        this.event_category_data = res.data;
        this.onEventCategoryChange(0);
      })
    },
    orderDataTimeFormat(order_date_time) {
      return order_date_time ? order_date_time.replaceAll('-', '/') : '';
    },
    paymentMethodType(payment_method) {
      return payment_method ? this.$t(`mypage_history.payment_method_type.${payment_method}`) : '';
    },
    roundUp(value) {
      return value ? Math.ceil(Number(value)) : 0;
    },
    onEventCategoryChange(id) {
      this.$set(this, 'selected_event_category', id)
    },
  }
}
</script>

<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}

.my_page_qualified_invoice_issuer_number_pc {
  text-align: right;
  margin-bottom: 30px;
}
</style>
