export default {
  jp_currency: "¥",
  confirmation_of_consent_for_purchase_by_minors: "Confirmation of consent for purchase by minors",
  minor_members_need_parental_consent_to_purchase_products: "Minor members need parental consent to purchase products.",
  do_you_have_the_consent_of_your_parents_to_purchase_this_product: "Do you have the consent of your parents to purchase this product?",
  yes_i_have_consent: "Yes, I have consent.",
  to_payment: "To payment",
  payment_method_selection: "Payment method selection",
  please_select_your_preferred_payment_method_below: "Please select your preferred payment method below.",
  web_money_payment_service: "WebMoney payment service",
  "1": "Credit settlement",
  "2": "Convenience store settlement",
  "3": "Seven-Eleven",
  "4": "Lawson",
  "5": "Family mart",
  "6": "Mobile Payment",
  "7": "DoCoMo carrier payment",
  "8": "au Easy Payment",
  "9": "Softbank carrier settlement",
  please_select: "Please select",
  customers_using_convenience_store_payment: "[Customers using convenience store payment]",
  payment_will_be_prepaid: "Payment will be prepaid. After payment at the designated convenience store, after confirming the payment notification from the settlement company, it will be manufactured and shipped. Please note that you will not receive the item just by ordering.",
  if_payment_is_completed: "If payment is completed by 13:00 on weekday orders, we will accept the order as the same day and manufacture the product.",
  please_note_that_the_order: "Please note that the order will be canceled if payment is not made within the deadline.",
  confirmation_of_ordered_products: "Confirmation of ordered products",
  please_proceed_to_the_payment: "Please proceed to the payment procedure after confirming that the purchase details and delivery address information are correct.",
  ordered_product: "Ordered product",
  tax_included: "(tax included)",
  quantity: "Quantity",
  subtotal: "Subtotal",
  set: "Sets",
  estimated_delivery: "Estimated delivery",
  shipping_method_selection_charge: "Shipping method selection / charge",
  after_payment_is_completed: "After payment is completed",
  business_day: "Business day",
  day: "Normal day",
  yu_mail: "Yu-mail",
  nekopos: 'Nekopost',
  express: 'Home Express Delivery',
  yamato_transport: "Yamato Transport",
  sagawa_express: 'Sagawa Express',
  shipping: "Shipping Fee",
  payment_fee: "Payment Fee",
  total_payment_amount: "Total payment amount",
  the_password_will_not_be_sent_by_e_mail_please_make_sure_not_to_forget_it: "* The password will not be sent by e-mail. Please make sure not to forget it.",
  send_e_mail_newsletter: "Send e-mail newsletter",
  please_confirm_your_consent: "Please confirm your consent for purchasing minors.",
  time: "Time",
  payment_method: "Payment Method",
  carrier_payment: "Carrier Payment",
  convenience_store_type: "Convenience Store Type",
  note: "Note",
  minimart_payment: "Minimart Payment",
  mobile_payment: "Mobile Payment",
  available_letter_package_and_nekopos: "Available Letter Package and Nekopost",
  available_nekopos_letter_package: "Available Nekopost Letter Package",
  available_yu_mail_letter_package: "Available Yu-Mail Letter Package",
  delivery_time_option: {
    "Morning (until 12:00)": "午前中(8時 - 12時)",
    "Morning (8:00 - 12:00)": "午前中(8時 - 12時)",
    "12:00 - 14:00": "12:00 - 14:00",
    "14:00 - 16:00": "14:00 - 16:00",
    "16:00 - 18:00": "16:00 - 18:00",
    "18:00 - 20:00": "18:00 - 20:00",
    "18:00 - 21:00": "18:00 - 21:00",
    "19:00 - 21:00": "19:00 - 21:00"
  },
  add_another_product: "Add Another Products",
  cancel_purchase: "Cancel the Purchase",
  cancel_purchase_msg: "Cancel the purchase, cancel and end. \nEmpty the cart and close your browser.",
  cancel_edit_address: "Cancel",
  update_edit_address: "Update"
}
