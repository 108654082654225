export const CONFIRMATION_NAMES = {
  MAIL_REGISTRATION: 'email_registration',
  TERMS_AGREEMENT: 'terms_agreement',
  MY_PAGE_REGISTRATION: 'my_page_registration',
  EVENT_LIST_ATTENTION: 'event_list_attention'
}

export const CONFIRMATION_STATUS = {
  NOT_CONFIRMED: 'not_confirmed', // まだ未確認
  CONFIRMED: 'confirmed', // 確認済
  NOT_DEMAND: 'not_demand', // 同意を求めていない場合はnot_demand
}

