import http from '@axios';
import store from '@/store/store'
import Vue from "vue";
import Constants from "@/constants";

export default class BaseService {

  constructor(prefix, method = BaseService.METHOD_GET) {
    this.prefix = prefix;
    this.method = method;
  }

  static get METHOD_GET() {
    return 'GET';
  }

  static get METHOD_POST() {
    return 'POST';
  }

  static get METHOD_PUT() {
    return 'PUT';
  }

  static get METHOD_DELETE() {
    return 'DELETE';
  }

  index(
    data,
    headers = {},
    is_ignore_interceptors_response_error = false,
    is_show_loading = true,
    showLoadingInterval = false,
  ) {
    var method = this.method || BaseService.METHOD_GET;
    return this.performRequest(method, '', data, headers, {
      is_ignore_interceptors_response_error,
      is_show_loading,
      showLoadingInterval
    });
  }

  show(id, data) {
    var method = this.method || BaseService.METHOD_GET;
    return this.performRequest(method, id, data);
  }

  store(data) {
    return this.performRequest(BaseService.METHOD_POST, '', data);
  }

  update(id, data) {
    return this.performRequest(BaseService.METHOD_PUT, id, data);
  }

  destroy(id, data) {
    return this.performRequest(BaseService.METHOD_DELETE, id, data);
  }

  importCSV(data, url = '') {
    return this.performRequest(BaseService.METHOD_POST, url, data, {'Content-Type': 'multipart/form-data'});
  }

  getAvailableDate(data, url = 'available-target-year-and-month') {
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }

  getExistDate(date) {
    let url = `exist-target-year-and-month/${date}`
    return this.performRequest(BaseService.METHOD_GET, url);
  }

  getBlacklist(data, url = '') {
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }

  getPrefectures(data, url = 'get-prefectures') {
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }

  getPurchaseHistory(data, url = 'get-purchase-history') {
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }

  getCurrentMember(data, url = 'get-current-member') {
    return this.performRequest(BaseService.METHOD_GET, url, data);
  }

  updateBlacklist(data, url = '') {
    return this.performRequest(BaseService.METHOD_PUT, url, data);
  }

  destroyBlacklist(id, url = '') {
    return this.performRequest(BaseService.METHOD_DELETE, url, id);
  }

  performRequest(method, url, data = {}, headers = {}, configOptions = {}) {
    let baseURL = process.env.VUE_APP_API_BASE_URL;

    let endPoint = this.prefix + (url ? "/" + url : '');
    let defaultConfigOptions = {
      is_show_loading: configOptions.is_show_loading ? configOptions.is_show_loading : true,
      showLoadingInterval: true,
      is_ignore_interceptors_response_error: configOptions.is_ignore_interceptors_response_error ? configOptions.is_ignore_interceptors_response_error : false,
      hideLayout: configOptions.hideLayout,
      ...configOptions
    }

    let options = {
      method,
      url: endPoint,
      baseURL: baseURL,
      data,
      headers,
      ...defaultConfigOptions
    };

    options[method.toUpperCase() === BaseService.METHOD_GET ? 'params' : 'data'] = data;
    return http(options)
      .catch((e) => {
        // 401の場合はログイン情報を破棄してログイン画面に遷移するように調整する
        if (e.status && e.status === 401 && e.config.url !== '/login') {
          // console.log('catch unauthorized error on BaseService;;;;;;;', e)
          Vue.$cookies.remove(Constants.ACCESS_TOKEN);
          Vue.$cookies.remove(Constants.MEMBER);
          Vue.$cookies.remove(Constants.USER_PURCHASE_INFO)
          Vue.$cookies.remove(Constants.USER_PAYMENT_INFO)
          Vue.$cookies.remove(Constants.PURCHASE_STEP)
          store.commit('SET_IS_LOGIN', false)
          store.commit('REMOVE_USER_DATA', false)
          store.dispatch('getCartData');
          location.href = '/login'

          return Promise.reject(e)

        } else {
          console.log('catch on BaseService;;;;;;;', e)
          return Promise.reject(e)
        }
      });
  }

}
