<template>
  <div>
    <div v-if="is_render">
      <ReferrerLayout />
      <Loading :loading="true" />
    </div>
    <div :class="[ 'brm-app', { hide, show: !hide } ]" v-else>
      <header-layout
          :class="[{['is_hide']: isLoading}]"
          :logo-link="logoLink"
          :is-show-cart="isShowCart"
          :is-logged-in="isLoggedIn"
          :is-message-when-hidden="isMsgWhenHidden"
          :cart-sheet-amount="cartSheetAmount"
          :cart-category="cartCategory"
          :current_artist="current_artist"
          :designate_page="designate_page"
          :on-publish-key="onPublishKey"
          :on-restore-cart="onRestoreCart"
          @logout="$emit('logout')"
          @to-cart="$emit('to-cart')"
      />
      <main :class="`wrapper ${mainClass}`">
        <slot />
      </main>
      <footer-layout
          v-if="!hideLayout && !isMsgWhenHidden"
          :current_artist="current_artist"
          :footerText="current_artist ? current_artist.footer_text : footerText"
          :footerTextColor="current_artist ? current_artist.footer_text_color : footerTextColor"
          :footer-area-color="current_artist ? current_artist.footer_area_color : footerAreaColor"
      />
    </div>
    <Loading :loading="isLoading" />
    <Modal :modal="has_error" ref="errorModal">
      <div slot="body" v-html="$sanitize(error_message)"></div>
      <div slot="footer" class="modal-footer">
        <button @click="$emit('error-confirmed')" class="footer__close">{{ $t('general.ok') }}</button>
      </div>
    </Modal>
    <Modal :modal="has_maintenance" ref="maintenanceModal">
      <div slot="body">
        現在メンテナンス中です。
        <br/>
        時間を置いてアクセスしてください。
      </div>
      <div slot="footer" class="modal-footer">
        <button @click="$emit('maintenance-confirmed')" class="footer__close">{{ $t('general.ok') }}</button>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {

  components: {
    Loading: () => import("../base/Loading"),
    HeaderLayout: () => import("./Header"),
    FooterLayout: () => import('./Footer'),
    ReferrerLayout: () => import('./ReferrerLayout'),
    Modal: () => import('../base/Modal')
  },

  data() {
    return {
      artist_referrer: '',
      is_render: false,
    }
  },
  props: {
    hideLayout: {
      type: Boolean,
      required: true
    },
    logoLink: {
      type: [ String, Object ],
      required: false,
      default: "/"
    },
    mainClass: {
      type: String,
      required: false,
      default: ""
    },
    footerText: {
      type: String,
      required: false,
      default: '(C) bromide.JP'
    },
    footerTextColor: {
      type: String,
      required: false,
      default: '#ffffff'
    },
    footerAreaColor: {
      type: String,
      required: false,
    },
    current_artist: {
      type: Object,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isMsgWhenHidden: {
      type: Boolean,
      required: true
    },
    has_error: {
      type: Boolean,
      default: false
    },
    has_maintenance: {
      type: Boolean,
      default: false
    },
    error_message: {
      type: String,
      default: ''
    },
    isShowCart: {
      type: Boolean,
      default: false
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    },
    designate_page: {
      type: [String, Array, Number, Object, Boolean],
      default: null
    },
    cartCategory: {
      type: Object,
      default: null
    },
    cartSheetAmount: {
      type: [String, Number],
      default: 0
    },
    onPublishKey: {
      type: Function,
      default: async () => ''
    },
    onRestoreCart: {
      type: Function,
      default: async () => ''
    },
    hide: {
      type: Boolean,
      default: false
    }
  },
  // methods: {
  //   handlePublishKey(data) {
  //     this.$emit('handle-publish-key', data)
  //   },
  //   handleRestoreCart(pubKey) {
  //     this.$emit('handle-restore-cart', pubKey)
  //   },
  // }
}
</script>

<style>
.is_hide {
  opacity: 0;
}
</style>
