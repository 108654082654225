/**
 * payment_method_id(決済方法のID)
 *
 * 1: クレジット
 * 2: コンビニ決済
 * 3: コンビニ決済(セブンイレブン) - 親決済: 2(コンビニ決済)
 * 4: コンビニ決済(ローソン) - 親決済: 2(コンビニ決済)
 * 5: コンビニ決済(ファミリーマート) - 親決済: 2(コンビニ決済)
 * 6: キャリア決済
 * 7: キャリア決済(ドコモ) - 親決済: 6(キャリア決済)
 * 8: キャリア決済(au Easy) - 親決済: 6(キャリア決済)
 * 9: キャリア決済(ソフトバンク) - 親決済: 6(キャリア決済)
 * 10: コンビニ決済(ミニストップ) - 親決済: 2(コンビニ決済)
 * 11: コンビニ決済(セイコーマート) - 親決済: 2(コンビニ決済)
 */


export default class Constants {

  static get ACCESS_TOKEN() {
    return 'access_token';
  }

  static get PURCHASE_STEP() {
    return 'purchase_step';
  }

  static get REPURCHASE_STEP() {
    return 'repurchase_step';
  }

  static get USER_PURCHASE_INFO() {
    return 'user_purchase_info'
  }

  static get USER_REPURCHASE_INFO() {
    return 'user_repurchase_info'
  }

  static get USER_PAYMENT_INFO() {
    return 'user_payment_info'
  }

  static get USER_REPURCHASE_PAYMENT_INFO() {
    return 'user_repurchase_payment_info'
  }


  static get CLIENT_UUID() {
    return 'client_uuid'
  }

  static get DESIGNATE_PAGE_DIRECTORY() {
    return 'designate_page_directory'
  }

  static get MEMBER() {
    return 'member'
  }

  static get NUMBER_PRODUCT_IN_CART() {
    return 'number_product_in_cart';
  }

  static get LOGIN_REQUIRED() {
    return 'login_required';
  }

  static get NOT_DISPLAY() {
    return 'not_display';
  }

  static get CATEGORY_TYPE_GOOD() {
    return 'normal';
  }

  static get CATEGORY_TYPE_PRINT_PRODUCT() {
    return 'printed_product';
  }

  static get PURCHASE_INFO_DURATION_COOKIE() {
    return '6000s'
  }

  static get PURCHASE_PAGE() {
    return 'purchase';
  }

  static get REDIRECT_URL() {
    return 'redirect_url';
  }
}
