import BaseService from "./BaseService";

class EventService extends BaseService {

  constructor(prefix, method) {
    super(prefix, method);
  }

  getEventByArtistId(artist_id, loadingInterval = true) {
    return this.performRequest(BaseService.METHOD_GET, `get-by-artist-id/${artist_id}`, {}, {}, { showLoadingInterval: loadingInterval });
  }

  getProductStandardByEvent(event_id) {
    return this.performRequest(BaseService.METHOD_GET, `${event_id}/product-standard`)
  }
}

export default new EventService('events', BaseService.METHOD_GET);
