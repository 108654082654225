import $ from 'jquery'
import MasterDataService from '@services/MasterDataService'

const helpers = {
  methods: {
    scrollTerm(top = 0) {
      MasterDataService.index({
        is_mobile: true,
      }).then((res) => {
        if (res.data.is_mobile) {
          $('.div-scroll').scroll(function () {
            if (
              this.scrollTop + this.offsetHeight >= this.scrollHeight ||
              this.scrollTop <= top
            ) {
              this.style.overflow = 'hidden'
            }
          })

          $(window).scroll(function () {
            $('.div-scroll')[0].style.overflowY = 'scroll'
          })
        }
      })
    },
    numberToAmount(number) {
      return '¥' + (number - 0).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      // return new Intl.NumberFormat('ja-JP', { style: 'currency', currency: 'JPY' }).format(number)
    },
    dialogConfirm(message, onConfirm = () => {
    }, onCancel = () => {
    }) {
      this.$swal
        .fire({
          title: message,
          showCancelButton: true,
          cancelButtonText: this.$t('general.cancel'),
          confirmButtonText: this.$t('general.delete'),
        })
        .then((result) => {
          result.isConfirmed ? onConfirm() : onCancel()
        })
    },
    dialogConfirmUpdate(message, onConfirm = () => {
    }, onCancel = () => {
    }) {
      this.$swal
        .fire({
          title: message,
          showCancelButton: true,
          cancelButtonText: this.$t('general.cancel'),
          confirmButtonText: this.$t('general.update'),
        })
        .then((result) => {
          result.isConfirmed ? onConfirm() : onCancel()
        })
    },
    dialogConfirmLogout(message, onConfirm = () => {
    }, onCancel = () => {
    }) {
      this.$swal
        .fire({
          title: message,
          showCancelButton: true,
          cancelButtonText: this.$t('general.cancel'),
          confirmButtonText: this.$t('general.yes'),
        })
        .then((result) => {
          result.isConfirmed ? onConfirm() : onCancel()
        })
    },
    toast(message, type, timer = 2500) {
      this.$swal.fire({
        position: 'top-right',
        text: message,
        icon: type,
        type,
        toast: true,
        timer,
        showConfirmButton: false,
      })
    },
    toastSuccess(message, timer = 2500) {
      this.toast(message, 'success', timer)
    },
    toastError(message, timer = 2500) {
      this.toast(message, 'error', timer)
    },
    handleImportCSVLoading() {
      this.$socket.subscribe('import-csv').bind('processing', (res) => {
        if (!res.data.processing) {
          this.$store.dispatch('toggleLoading', false)
          if (res.data.success) {
            this.toastSuccess(res.data.message)
          } else {
            this.toastSuccess(res.data.message)
          }
        }
      })
    },
    scrollToFirstError() {
      const errorFirst = this.$el.querySelector('.validate-error')
      if (errorFirst) {
        errorFirst.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    },
    generateArrayOfYears(subYear = 6, minYear = 1950) {
      const max = new Date().getFullYear()
      const maxYear = max - subYear
      const years = []

      for (let i = maxYear; i >= minYear; i--) {
        years.push(i)
      }
      return years
    },
    generateArrayOfMonth() {
      const maxMonth = 12
      const minMonth = 1
      const result = []
      for (let i = minMonth; i <= maxMonth - minMonth + 1; i++) {
        let number = `${i}`
        number = number.length > 1 ? number : '0' + number

        result.push(number)
      }
      return result
    },
    generateArrayOfDay(month = '', year = '') {
      const minDay = 1
      let maxDay = 31
      const result = []

      if (year != '' && month != '') {
        maxDay = new Date(year, month, 0).getDate()
      }
      for (let i = minDay; i <= maxDay; i++) {
        let number = `${i}`
        number = number.length > 1 ? number : '0' + number
        result.push(number)
      }
      return result
    },
    getAge(dateString) {
      const today = new Date()
      const birthDate = new Date(dateString)
      let age = today.getFullYear() - birthDate.getFullYear()
      const m = today.getMonth() - birthDate.getMonth()
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--
      }
      return age
    },
    screenSize() {
      const size = {}
      size.width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth
      size.height =
        window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight
      return size
    },
    calculateImageSize(defaultWidth, defaultHeight, defaultScreenWidth) {
      const size = {}
      const currentWidth = this.screenSize().width
      size.height = Math.round(
        (currentWidth * defaultHeight) / defaultScreenWidth
      )
      size.width = (currentWidth * defaultWidth) / defaultScreenWidth
      return size
    },
  },
}

export default helpers
