export default {
  'notice': 'NOTICE',
  'sales_period': 'Sales period',
  'new': 'NEW',
  'return_to_artist_top': 'Return to artist TOP',
  'return_to_event_top': 'Return to event TOP',
  'info': 'INFO',
  'limited_merchandise': 'Limited merchandise',
  'selling_price': 'Selling price',
  'tax_included': 'Tax included',
  'piece': 'piece',
  'about_the_product': 'About the product (Torr if title is not required)',
  'spec': 'Spec',
  'quantity': 'Quantity',
  'stock_quantity': 'Stock quantity',
  'set': 'Set',
  'add_product_to_cart': 'Add product to cart',
  'in_the_current_cart': 'In the current cart',
  'see_the_cart': 'See the cart',
  'relation': {
    'title': 'RELATION',
    'subtitle': '関連商品',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': 'Click here for details'
  }
}