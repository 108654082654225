<template>
  <article>
    <PostalCodeSelectModal
      :is-show-modal="is_show_modal"
      :is-mobile="is_mobile"
      :addresses="addresses"
      @select="address => settingAddress(edit_index, address)"
      @close="is_show_modal = false"
    />

    <Title :subtitle="$t('mypage_edit.title.main_jp')" :title="$t('mypage_edit.title.main')"></Title>

    <section class="content pb-80">
      <validation-observer ref="observer" class="content__inner" tag="div">
        <section class="mb-60">
          <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                       class="d-block text-right mb-20"></router-link>
          <Headline :title="$t('mypage_edit.headline.title')"></Headline>
          <p class="c-red">{{ $t('mypage_edit.headline.description') }}</p>
        </section>
        <section class="mb-30">
          <h4 class="main-title">{{ $t('mypage_edit.basic_information.title') }}</h4>
          <dl class="common-dl">
            <dt>{{ $t('mypage_edit.basic_information.name') }}<span class="c-red">*</span></dt>

            <ValidationProvider v-slot="lastname" rules="required|max:15" tag="dd">
              <input v-model="data.last_name" :name="$t('mypage_edit.basic_information.last_name')" class="form-text--50" maxlength="15"
                     type="text" @keydown.space.prevent>

              <ValidationProvider v-slot="firstname" rules="required|max:15">
                <input v-model="data.first_name" :name="$t('mypage_edit.basic_information.first_name')" class="form-text--50" maxlength="15"
                       type="text" @keydown.space.prevent>
                <div v-if="lastname.errors[0] || firstname.errors[0]">
                  <span class="validate-error c-red text--50">{{ lastname.errors[0] }}</span>
                  <span v-if="firstname.errors[0]" class="validate-error c-red text--50">{{
                      firstname.errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </ValidationProvider>
            <dt>{{ $t('mypage_edit.basic_information.name_hiragana') }}<span class="c-red">*</span></dt>
            <ValidationProvider v-slot="lastname" rules="required|max:15" tag="dd">
              <input v-model="data.last_name_hiragana" :name="$t('mypage_edit.basic_information.last_name_hiragana')" class="form-text--50"
                     maxlength="15" type="text">
              <ValidationProvider v-slot="firstname" rules="required|max:15">
                <input v-model="data.first_name_hiragana" :name="$t('mypage_edit.basic_information.first_name_hiragana')" class="form-text--50"
                       maxlength="15"
                       type="text">

                <div v-if="lastname.errors[0] || firstname.errors[0]">
                  <span class="validate-error c-red text--50">{{ lastname.errors[0] }}</span>
                  <span v-if="firstname.errors[0]" class="validate-error c-red text--50">{{
                      firstname.errors[0]
                    }}</span>
                </div>
              </ValidationProvider>
            </ValidationProvider>
            <dt>{{ $t('mypage_edit.basic_information.email_address') }}<span class="c-red">*</span></dt>
            <dd>
              <ValidationProvider v-slot="{ errors }" :rules="`required|email`"
                                  :vid="$t('mypage_edit.basic_information.email_address')">
                <input v-model="data.email" :name="$t('mypage_edit.basic_information.email_address')" class="form-text--100"
                       type="text">
                <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
            <dt>{{ $t('mypage_edit.basic_information.email_address_confirmation') }}<span class="c-red">*</span></dt>
            <dd>
              <ValidationProvider
                v-slot="{ errors }"
                :rules="`required|email|confirmed:${$t('mypage_edit.basic_information.email_address')}`" :vid="$t('mypage_edit.basic_information.email_address_confirmation')">
                <input v-model="data.email_confirmation" :name="$t('mypage_edit.basic_information.email_address')" class="form-text--100"
                       type="text">
                <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
              </ValidationProvider>
            </dd>
            <dt>{{ $t('mypage_edit.basic_information.gender') }}<span class="c-red">*</span></dt>
            <dd>
              <ul class="flex-list">
                <li v-for="(gender, index) in gender_options" :key="index">
                  <input :id="gender.value" v-model="data.gender" :value="gender.value" name="gender" type="radio">
                  <label :for="gender.value" class="radio--label">{{ $t(`mypage_edit.gender.${gender.value}`) }}</label>
                </li>
              </ul>
            </dd>
            <dt>{{ $t('mypage_edit.basic_information.occupation') }}<span class="c-red">*</span></dt>
            <dd>
              <div class="form-select">
                <select v-model="data.occupation">
                  <option hidden value="">{{ $t('mypage_edit.select.please_select') }}</option>
                  <option v-for="(occupation, index) in occupation_options" :key="index" :value="occupation.value"
                          selected>
                    {{ $t(`mypage_edit.occupation.${occupation.value}`) }}
                  </option>
                </select>
              </div>
            </dd>
            <dt>{{ $t('mypage_edit.basic_information.date_of_birth') }}<span class="c-red">*</span></dt>
            <dd>
              <ul class="flex-list mypage--edit__birthday-list">
                <li class="flex-list--item mypage--edit__year">
                  <div class="form-select">
                    <select id="year" v-model="dob.year" @change="onMonthChange()">
                      <option v-t="'mypage_edit.basic_information.year'" hidden value=""></option>
                      <option v-for="(value, index) in year_list" v-bind:key="index" v-bind:value="value">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <p class="pl-5">{{ $t('mypage_edit.basic_information.year') }}</p>
                </li>
                <li class="flex-list--item mypage--edit__month">
                  <div class="form-select">

                    <select id="month" v-model="dob.month" @change="onMonthChange()">
                      <option hidden selected value="">{{ $t('mypage_edit.basic_information.month') }}</option>
                      <option v-for="(value, index) in month_list" v-bind:key="index + 1" v-bind:value="index + 1">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <p class="pl-5">{{ $t('mypage_edit.basic_information.month') }}</p>
                </li>
                <li class="flex-list--item mypage--edit__day pr-0">
                  <div class="form-select">
                    <select id="day" v-model="dob.day">
                      <option hidden selected value="">{{ $t('mypage_edit.basic_information.day') }}</option>
                      <option v-for="(value, index) in day_list" v-bind:key="index + 1" v-bind:value="index + 1">
                        {{ value }}
                      </option>
                    </select>
                  </div>
                  <p class="pl-5">{{ $t('mypage_edit.basic_information.day') }}</p>
                </li>
              </ul>
            </dd>
          </dl>
        </section>

        <section v-for="index in 3" :key="index" class="mb-30">
          <h4 :id="`address_${index}`" class="pt-80 main-title">{{
              $t('mypage_edit.product_delivery_address.title')
            }}{{ NOIndex(index) }}</h4>
          <dl v-if="data.shipping_addresses[index-1]" class="common-dl">
            <dt>{{ $t('mypage_edit.product_delivery_address.postal_code') }}<span v-if="index == 1"
                                                                                  class="c-red">*</span></dt>
            <dd>
              <PostalCodeInput
                v-model="data.shipping_addresses[index-1].postal_code"
                input_class="form-text--100"
                :input_name="$t('mypage_edit.product_delivery_address.postal_code')"
                :placeholder="$t('mypage_edit.placeholder.half_width_numbers_and_no_hyphens')"
                autocomplete="off"
                @input="onSearchZipCode(index - 1)"
              />
            </dd>
            <dt>{{ $t('mypage_edit.product_delivery_address.prefecture') }}<span v-if="index == 1"
                                                                                 class="c-red">*</span></dt>
            <dd>
              <div class="form-select">
                <select v-model="data.shipping_addresses[index-1].prefecture_id">
                  <option hidden value="">{{ $t('mypage_edit.select.please_select') }}</option>
                  <option v-for="(prefecture, index) in prefecture_options" :key="index" :value="prefecture.value">
                    {{ prefecture.label }}
                  </option>
                </select>
              </div>
            </dd>
            <dt>
              {{ $t('mypage_edit.product_delivery_address.address') }}
              <span v-if="index == 1" class="c-red">*</span>
              <span v-if="!is_mobile" class="pl-1 c-red">{{ $t('mypage_edit.text_address_pc') }}</span></dt>
            <dd>
              <ValidationProvider v-slot="{ errors, failedRules }" :rules="`${deliveryAddressRule(index)}|max:30`">
                <input
                  ref="address1"
                  type="text"
                  v-model="data.shipping_addresses[index - 1].address"
                  :name="$t('mypage_edit.product_delivery_address.address')"
                  :placeholder="$t('mypage_edit.placeholder.address')"
                  class="form-text--100"
                >
                <p v-if="errors[0] && failedRules.max" class="c-red validate-error mb-0">
                  {{$t('general.error_message_address_custom')}}
                </p>
                <p v-if="is_mobile" class="c-red">{{ $t('mypage_edit.address_validate') }}</p>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors, failedRules }" :rules="`max:50`">
                <input id="address_home" v-model="data.shipping_addresses[index-1].address_2" :name="$t('registration.label.address_2')" :placeholder="$t('registration.placeholder.address_2')"
                       class="form-text--100"
                       type="text">
                <p v-if="errors[0]" class="c-red validate-error">
                  {{ failedRules.max ? $t('general.error_message_address_2_custom') : errors[0] }}
                </p>
                <p v-if="is_mobile" class="c-red" v-html="$sanitize($t('mypage_edit.address2_validate'))" />
              </ValidationProvider>
            </dd>

            <dt>{{ $t('mypage_edit.product_delivery_address.phone_number') }}<span v-if="index == 1"
                                                                                   class="c-red">*</span></dt>
            <dd>
              <ValidationProvider v-slot="{ errors }"
                                  :rules="`${deliveryAddressRule(index)}|phone_number|max:11|min:10`" :vid="`phone_number_${index}`">
                <input v-model="data.shipping_addresses[index-1].phone_number" :name="$t('mypage_edit.product_delivery_address.phone_number')" :placeholder="$t('mypage_edit.placeholder.half_width_numbers_and_no_hyphens')"
                       class="form-text--80"
                       maxlength="11"
                       type="tel">
                <p v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</p>
              </ValidationProvider>
              <ValidationProvider v-slot="{ errors }"
                                  :rules="`${deliveryAddressRule(index)}|phone_number|confirmed:phone_number_${index}`" :vid="`phone_number_confirmation_${index}`">
                <input v-model="data.shipping_addresses[index-1].phone_number_confirmation" :name="$t('mypage_edit.product_delivery_address.phone_number')" :placeholder="$t('mypage_edit.placeholder.please_enter_your_phone_number_again')"
                       class="form-text--80"
                       maxlength="11"
                       type="tel">
                <p v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</p>
              </ValidationProvider>
            </dd>
          </dl>
        </section>
        <form>
          <input :value="$t('mypage_edit.product_delivery_address.confirm_registration_details')" class="btn-100--red"
                 type="button"
                 @click="onConfirmRegistrationClick">
        </form>
      </validation-observer>
    </section>
  </article>
</template>
<script>
import MemberService from '@services/MemberService';
import MasterDataService from '@services/MasterDataService';
import {mapState} from 'vuex';

export default {
  data() {
    return {
      data: {
        id: "",
        first_name: "",
        last_name: "",
        first_name_hiragana: "",
        last_name_hiragana: "",
        email: "",
        email_confirmation: "",
        gender: "",
        occupation: "",
        date_of_birth: "",
        shipping_addresses: []
      },
      occupation_options: [],
      prefecture_options: [],
      gender_options: [],
      dob: {
        year: "",
        month: "",
        day: ""
      },
      year_list: [],
      month_list: [],
      day_list: [],
      is_mobile: '',
      is_show_modal: false,
      addresses: [],
      edit_index: 0,
    }
  },
  methods: {
    onMonthChange() {
      this.day_list = this.generateArrayOfDay(this.dob.month, this.dob.year);
    },
    getCurrentMemberLogin() {
      if (this.$session.exists("member")) {
        this.data = this.$session.get("member");

        this.dob.year = this.data.date_of_birth.split("-")[0];
        this.dob.month = parseInt(this.data.date_of_birth.split("-")[1]);
        this.dob.day = parseInt(this.data.date_of_birth.split("-")[2]);

        this.year_list = this.generateArrayOfYears();
        this.month_list = this.generateArrayOfMonth();
        this.day_list = this.generateArrayOfDay(this.dob.month, this.dob.year);

        return;
      }
      MemberService.index().then(res => {
        this.data = res.data;
        this.data.email_confirmation = res.data.email;
        for (let i = 0; i < this.data.shipping_addresses.length; i++) {
          this.data.shipping_addresses[i].phone_number_confirmation = this.data.shipping_addresses[i].phone_number;
        }
        for (let j = 0; j < 5 - this.data.shipping_addresses.length; j++) {
          this.data.shipping_addresses.push({
            postal_code: "",
            prefecture_id: "",
            address: "",
            phone_number: "",
            phone_number_confirmation: ""
          });
        }
        this.dob.year = parseInt(this.data.date_of_birth.split("-")[0]);
        this.dob.month = parseInt(parseInt(this.data.date_of_birth.split("-")[1]));
        this.dob.day = parseInt(this.data.date_of_birth.split("-")[2]);

        this.year_list = this.generateArrayOfYears();
        this.month_list = this.generateArrayOfMonth();
        this.day_list = this.generateArrayOfDay(this.dob.month, this.dob.year);
      });
    },
    getMasterData() {
      MasterDataService.index({
        is_prefecture: true,
        is_gender: true,
        is_occupation: true,
        is_mobile: true
      }).then(res => {
        this.prefecture_options = res.data.prefectures;
        this.gender_options = res.data.genders;
        this.occupation_options = res.data.occupations;
        this.is_mobile = res.data.is_mobile;
      });
    },
    NOIndex(index) {
      if (index == 1) {
        return '①';
      } else if (index == 2) {
        return '②';
      } else {
        return '③';
      }
    },
    deliveryAddressRule(index) {
      if (index == 1) {
        return 'required';
      }
      return '';
    },
    async onConfirmRegistrationClick() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }

      this.data.date_of_birth = this.dob.year.toString().concat('-', this.dob.month.toString().padStart(2, '0'), '-', this.dob.day.toString().padStart(2, '0'));
      this.$session.set("member", this.data);
      this.$router.push({name: this.current_artist ? 'artist.mypage.confirmation' : 'mypage.confirmation'});
    },
    async onSearchZipCode(index) {
      this.edit_index = index;

      const regexp = /[0-9]{7}/;

      if (regexp.test(this.data.shipping_addresses[index].postal_code)) {
        const res = await MasterDataService.index({
          is_postal_code: true,
          postal_code: this.data.shipping_addresses[index].postal_code,
        })

        const { postal_codes } = res.data

        if (postal_codes.length === 1) {
          const definite_postal_code = postal_codes[0]

          const { city, street_address, state } = definite_postal_code
          const prefecture = this.prefecture_options.find(prefecture => prefecture.label == state);

          this.data.shipping_addresses[index].address = city.concat(street_address)
          this.data.shipping_addresses[index].prefecture_id = prefecture ? prefecture.value : ''
        } else if (postal_codes.length !== 0) {
          this.addresses = postal_codes.map((postal_code, index) => {
            const address = postal_code.state.concat(postal_code.city.concat(postal_code.street_address));

            return { id: index, name: address, postal_code: postal_code };
          });

          this.is_show_modal = true;
        }
      }
    },
    settingAddress(index, address) {
      if (address) {
        const { postal_code } = address
        const { city, street_address, state } = postal_code
        const prefecture = this.prefecture_options.find(prefecture => prefecture.label == state);

        this.data.shipping_addresses[index].address = city.concat(street_address)
        this.data.shipping_addresses[index].prefecture_id = prefecture ? prefecture.value : ''
      }

      this.addresses = []
      this.is_show_modal = false

      setTimeout(() => {
        this.$refs.address1[index].focus();
      }, 500);
    },
  },
  mounted() {
    this.getCurrentMemberLogin();
    this.getMasterData();
  },
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline'),
    PostalCodeInput: () => import('@components/base/PostalCodeInput'),
    PostalCodeSelectModal: () => import('@components/base/PostalCodeSelectModal')
  },
  computed: {
    ...mapState([
      'current_artist'
    ])
  }
}
</script>

<style scoped>
.text--50 {
  display: inline-block;
  width: 45%;
  margin-right: 1%;
}

.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>
