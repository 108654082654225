<template>
  <article>
    <Title :subtitle="$t('inquiry.title.main_jp')" :title="$t('inquiry.title.main')"/>

    <section class="content pb-80 inquiry__form">
      <div v-if="currentStep === 1" class="content__inner">
        <Headline :title="$t('inquiry.title.create')"></Headline>

        <p class="mb-60" v-html="$sanitize($t('inquiry.sub_title.create'))"></p>

        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <dl class="common-dl mb-30">
            <dt v-html="$sanitize($t('inquiry.input.name'))"></dt>

            <validation-provider v-slot="lastname" tag="dd" rules="max:255">
              <input
                :id="`last_name`"
                v-model="entity.last_name"
                :name="$t('inquiry.label.last_name')"
                :placeholder="$t('inquiry.input.last_name')"
                class="form-text--50"
                required
                @keydown.space.prevent
              />

              <validation-provider v-slot="firstname" rules="max:255">
                <input
                  :id="`first_name`"
                  v-model="entity.first_name"
                  :name="$t('inquiry.label.first_name')"
                  :placeholder="$t('inquiry.input.first_name')"
                  class="form-text--50"
                  required
                  @keydown.space.prevent
                />

                <div v-if="lastname.errors[0] || firstname.errors[0]">
                  <span v-if="lastname.errors" class="c-red text--50">{{ lastname.errors[0] }}</span>
                  <span v-if="firstname.errors" class="c-red text--50">{{ firstname.errors[0] }}</span>
                </div>
              </validation-provider>
            </validation-provider>

            <dt v-html="$sanitize($t('inquiry.input.phone_number'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }" rules="phone_number|max:11|min:10|confirmed:phone_number_confirmation">
                <input
                  type="tel"
                  id="phone_number"
                  ref="phone_number"
                  v-model="entity.phone_number"
                  :name="$t('inquiry.label.phone_number')"
                  :placeholder="$t('inquiry.placeholder.phone_number')"
                  class='form-text--100'
                  maxlength="11"
                  required
                  @keydown.space.prevent
                />

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>

            <dt v-html="$sanitize($t('inquiry.input.phone_number_confirm'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }" rules="phone_number|max:11|min:10" vid="phone_number_confirmation">
                <input
                  type="tel"
                  id="phone_number_confirm"
                  ref="phone_number_confirm"
                  v-model="entity.phone_number_confirmation"
                  :name="$t('inquiry.label.phone_number_confirm')"
                  :placeholder="$t('inquiry.placeholder.phone_number_confirm')"
                  class='form-text--100'
                  maxlength="11"
                  required
                  @keydown.space.prevent
                />

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>

            <dt v-html="$sanitize($t('inquiry.input.email'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }" rules="email|confirmed:email_confirmation|max:255">
                <input
                  id="email"
                  ref="email"
                  v-model="entity.email"
                  :name="$t('inquiry.label.email')"
                  :placeholder="$t('inquiry.placeholder.email')"
                  class='form-text--100 inquiry__form--email'
                  required
                  @keydown.space.prevent
                />

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>

            <dt v-html="$sanitize($t('inquiry.input.email_confirm'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }" rules="email|max:255" vid="email_confirmation">
                <input
                  id="email_confirm"
                  v-model="entity.email_confirmation"
                  :name="$t('inquiry.label.email_confirm')"
                  :placeholder="$t('inquiry.placeholder.email_confirm')"
                  class='form-text--100 inquiry__form--email-confirm'
                  required
                  @keydown.space.prevent
                />

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>

            <dt v-html="$sanitize($t('inquiry.input.category'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }">
                <div class="form-select">
                  <select
                    id="inquiry_category"
                    v-model="entity.inquiry_category_id"
                    :name="$t('inquiry.label.category')"
                    required
                  >
                    <option v-t="'general.default_select'" hidden value=""></option>

                    <option
                      v-for="option in inquiry_category_options"
                      :key="option.value"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>

            <dt v-html="$sanitize($t('inquiry.input.content'))"></dt>

            <dd>
              <validation-provider v-slot="{ errors }">
                <textarea
                  id="content"
                  v-model="entity.content"
                  :name="$t('inquiry.label.content')"
                  :placeholder="$t('inquiry.placeholder.content')"
                  class='form-text--100'
                  required
                />

                <span v-if="errors[0]" class="c-red"> {{ errors[0] }}</span>
              </validation-provider>
            </dd>
          </dl>

          <Button :title="$t('inquiry.btn.submit')" isPrimary @onClick="handleSubmit(validateForm)"/>
        </validation-observer>
      </div>

      <div v-if="currentStep === 2" class="content__inner">
        <Headline :title="$t('inquiry.title.confirmation')"/>

        <p class="mb-60" v-html="$sanitize($t('inquiry.sub_title.confirmation'))"></p>

        <dl class="common-dl mb-30">
          <dt v-t="'inquiry.label.name'"></dt>
          <dd v-html="$sanitize(getFullName)"></dd>
          <dt v-t="'inquiry.label.phone_number'"></dt>
          <dd v-html="$sanitize(entity.phone_number)"></dd>
          <dt v-t="'inquiry.label.email'"></dt>
          <dd v-html="$sanitize(entity.email)"></dd>
          <dt v-t="'inquiry.label.category'"></dt>
          <dd v-html="$sanitize(getCategoryText)"></dd>
          <dt v-t="'inquiry.label.content'"></dt>
          <dd class="pre-line" v-html="$sanitize(entity.content)"></dd>
        </dl>

        <div class="mb-20">
          <Button :title="$t('inquiry.btn.send')" isPrimary @onClick="onConfirm()"></Button>
        </div>

        <Button :title="$t('inquiry.btn.back')" @onClick="onBack()"></Button>
      </div>

      <div v-if="currentStep === 3" class="content__inner">
        <Headline :title="$t('inquiry.title.complete')"></Headline>

        <p class="mb-60" v-html="$sanitize($t('inquiry.title.complete'))"></p>

        <Button :title="$t('inquiry.btn.confirm')" class="mt-3" @onClick="onComplete()"></Button>
      </div>
    </section>
  </article>
</template>

<script>
import InquiryService from '@services/InquiryService'
import { mapState } from 'vuex';

export default {
  name: 'inquiry',
  components: {
    Button: () => import('@components/base/Button'),
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline')
  },
  data() {
    return {
      currentStep: 1,
      entity: {
        first_name: '',
        last_name: '',
        phone_number: '',
        phone_number_confirmation: '',
        email: '',
        inquiry_category_id: '',
        email_confirmation: "",
        content: '',
        artist_id: null,
      },
      inquiry_category_options: []
    }
  },
  methods: {
    onSubmit() {
      this.currentStep++;
    },
    onBack() {
      this.currentStep--;
    },
    onConfirm() {
      if (this.current_artist) {
        this.entity.artist_id = this.current_artist.id
      }

      InquiryService.store(this.entity).then(() => {
        this.currentStep++;
      }).catch(e => {
        this.toastError(e.data.meta.message);
      })
    },
    onComplete() {
      if (this.$route.params.designate_page) {
        this.$router.push({name: 'artist.home'})

        return
      }

      this.$router.push({name: 'top'})
    },
    validateForm() {
      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.onSubmit();
        } else {
          this.toastError('Please fill your form correctly!');
        }
      })
    },
    getCategories() {
      InquiryService.getCategories().then(res => {
        this.inquiry_category_options = res.data.map(item => ({
          value: item.id,
          text: item.name
        }));
      });
    },
  },
  created() {
    this.getCategories();
    this.currentStep = 1;
  },
  mounted() {
    if (this.auth.member) {
      this.entity = {
        ...this.entity,
        first_name: this.auth.member.first_name || '',
        last_name: this.auth.member.last_name || '',
        email: this.auth.member.email || '',
      }
    }
  },
  computed: {
    ...mapState([
      'current_artist',
      'auth'
    ]),
    getCategoryText() {
      return this.inquiry_category_options.find(item => item.value === this.entity.inquiry_category_id).text;
    },
    getFullName() {
      return this.entity.last_name + " " + this.entity.first_name;
    },
  }
}
</script>

<style scoped>
.text--50 {
  display: inline-block;
  width: 45%;
  margin-right: 1%;
}
</style>
