<template>
  <div class="cart-info purchase__cart purchase__cart--center">
    <img v-if="isPhone" :src="cartImage" class="cart-info__image">
    <div v-else class="purchase__cart-box purchase__cart-box--bg-white">
      <div class="purchase__cart-box--flex">
        <div class="purchase__cart-icon">
          <span>アイコン説明：</span>
        </div>
        <div class="purchase__cart-icon">
          <input class="purchase__cart-icon--button box-shadow js-cart_button" type="button">
          <span>選択が解除されています</span>
        </div>
        <div class="purchase__cart-icon active-icon">
          <input class="purchase__cart-icon--button box-shadow js-cart_button active"
                 type="button">
          <span>カートに入っています</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import cartImage from '@assets/images/cart-category-sp.png'
export default {
  props: {
    isPhone: {
      type: Boolean,
      default: false
    },
  },
  computed: {
    cartImage() {
      return cartImage
    }
  }
}
</script>
<style></style>
