import loginRequired from '@middlewares/loginRequired';

export default [
  {
    path: '/mypage',
    name: 'mypage.index',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPage')
  },
  {
    path: '/change-password',
    name: 'mypage.change-password',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/ChangePassword')
  },
  {
    path: '/mypage-edit',
    name: 'mypage.edit',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageEdit')
  },
  {
    path: '/mypage-confirmation',
    name: 'mypage.confirmation',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageConfirmation')
  },
  {
    path: '/mypage-cancel',
    name: 'mypage.cancel',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageCancel')
  },
  {
    path: '/mypage-cancel-fix',
    name: 'mypage.cancel.fix',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageCancelFix')
  },
  {
    path: '/mypage-history',
    name: 'mypage.purchase_history',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageHistory')
  },
  {
    path: '/mypage-history/:history_id',
    name: 'mypage.history_detail',
    meta: {
      middleware: loginRequired
    },
    component: () => import('@pages/mypage/MyPageHistoryDetail')
  },
]
