export default {
  enter_delivery_address: "Enter delivery address",
  please_register_the_following: "Please register the following information required for shipping products.",
  is_a_required_item: "※ Is a required item.",
  basic_information: "Basic information",
  name: "Name",
  firstname: "First Name",
  lastname: "Last Name",
  furigana: "Furigana",
  lastname_furigana: "Last Name Furigana",
  firstname_furigana: "First Name Furigana",
  mail_address: "Mail address",
  please_enter_your_e_mail_address: "Please enter your e-mail address.",
  for_email_address_confirmation: "For email address confirmation",
  please_enter_your_email_address_again: "Please enter your email address again.",
  sex: "Sex",
  male: "Male",
  female: "Female",
  anonymous: "Do not answer",
  profession: "Profession",
  occupations: {
    employee: "Employee",
    student: "Student",
    self_employee: "Self employee",
    other: "Other"
  },
  please_select: "Please select",
  date_of_birth: "Birthday",
  year: "Year",
  month: "Month",
  day: "Day",
  desired_password: "Desired password",
  please_enter_your_password: "Please enter your password.",
  "8_to_16_single_byte_alphanumeric_characters": "8 to 32 single-byte alphanumeric characters",
  repeat_password: "Repeat password.",
  send_e_mail_newsletter: "Send e-mail newsletter",
  do_not_want: "Do not want",
  hope: "Hope",
  product_delivery_address: "Product delivery address",
  postal_code: "Postal code",
  half_width_numbers_and_no_hyphens: "Half-width numbers and no hyphens",
  enter_address_from_zip_code: "Enter address from zip code",
  prefectures: "Prefectures",
  street_address: "Street address",
  city_address: "City address",
  building_name_room_number: "Building name, room number",
  phone_number: "Phone number",
  please_enter_your_phone_number_again: "Please enter your phone number again.",
  confirm_registration_details: "Confirm registration details",
  text_address_pc: "If the address is not entered correctly, or if the building name and room number are not entered, the luggage may not arrive.",
  address_validate: "City / ward / town / address is a required item",
  address2_validate: "If you do not enter the building name and room number, your luggage may not arrive."

}
