<template>
  <div>
    <Modal :modal="isShowModal" width="80%">
      <div slot="header" class="delivery-modal-header">
        お届け先の確認
      </div>
      <div slot="body" class="delivery-modal-body" ref="modalBody">

        <keep-proportion-content
          :proportion-target="() => $refs.modalBody"
          :gap-sp="0"
          :p-sp-width="300"
        >
          <p class="c-red mb-10 delivery-modal-caution">お届け先や電話番号が間違っていると<br class="s-elem"/>商品をお届けできません。</p>
          <p style="font-size: 13px">改めて記載事項の確認をお願い致します。<br/>以下内容は、商品の送付、お問い合わせの回答、緊急時のご連絡をさせていただく大事な必要事項です。</p>

        </keep-proportion-content>

        <section>
          <dl class="common-dl delivery-modal-form">
            <dt>{{ $t('purchase.postal_code') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.postal_code')"
                :value="userInfo.zip_code || userInfo.postal_code"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.prefectures') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.prefectures')"
                :value="userInfo.prefecture"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.street_address') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.street_address')"
                :value="userInfo.address"
                class="form-text--100"
                readonly
              >
            </dd>
            <dt></dt>
            <dd>
              <input
                :name="$t('purchase.city_address')"
                :value="userInfo.address_2"
                class="form-text--100"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.phone_number') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.phone_number')"
                :value="userInfo.phone_number"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.mail_address') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.mail_address')"
                :value="userInfo.email"
                class="form-text--100"
                readonly
              >
            </dd>
          </dl>


        </section>

        <keep-proportion-content
          :proportion-target="() => $refs.modalBody"
          :gap-sp="0"
          :p-sp-width="300"
        >
          <p class="c-red mb-10 delivery-modal-caution">お届け先や電話番号が間違っていると<br class="s-elem"/>商品をお届けできません。</p>
        </keep-proportion-content>

      </div>

      <div slot="footer" class="delivery-modal-footer" ref="modalFooter">

        <label class="check">
          <input v-model="confirmed" name="confirmed"
                 type="checkbox">
          <span class="check--dummy"></span>
          <span class="check--text">上記内容で問題ないですか？</span>
        </label>

        <button
          :class="`btn-100--red`"
          :disabled="!confirmed"
          role="button"
          @click="handleConfirm"
        >
          お届け先を確認しました
        </button>

        <button class="btn-100" role="button" @click="handleCancel">
          修正する
        </button>

      </div>
    </Modal>
  </div>
</template>
<script>
import KeepProportionContent from "@components/base/KeepProportionContent";

export default {
  name: "DeliveryAddressConfirmModal",
  components: {
    Modal: () => import('@components/base/Modal'),
    KeepProportionContent
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    },
    userInfo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      confirmed: false
    }
  },
  methods: {
    handleConfirm() {
      if (this.confirmed === true) {
        this.$set(this, 'confirmed', false)
        this.$emit('address-confirm')
      }
    },
    handleCancel() {
      this.$set(this, 'confirmed', false)
      this.$emit('address-cancel')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/common/variables';
@import '../../../assets/scss/common/mixins';

.delivery-modal-header {
  font-weight: bold;
  margin: 0 80px;
  border-bottom: 1px solid black;
  @include mq-s {
    margin: 0;
  }
}

.delivery-modal-body {
  margin: 0 80px;
  padding-top: 12px;
  @include mq-s {
    margin: 0;
  }
}

.delivery-modal-form {
  padding: 0 50px;
  @include mq-s {
    padding: 0;
  }
}

.delivery-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn--disabled {
  background-color: #ACABAB !important;
}
.delivery-modal-caution {
  font-size: 16px;
}

</style>
