export default {
  title: {
    main: 'Reship',
    main_jp: '再発送手続き',
  },
  about_reshipping_procedure: 'About resending procedure',
  content: "Thank you very much for your continued use of Bromide.jp.<br>We are sorry to inform you that your order has been returned to us due to an unknown delivery address.<br>Please enter the 「Key for reshipping」 provided in the email.",
  key: 'Key for reshipping',

  btn: {
    reshipping_procedure: 'To reshipping procedure'
  }
}