<template>
  <BaseLanding
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
    :landing-page="landingPage"
  />
</template>

<script>
import {mapState} from 'vuex'
import ArtistService from "@services/ArtistService";
import pageDisplayControlMixin from '@/helpers/pageDisplayControlMixin'

export default {
  components: {
    BaseLanding: () => import('@pages/landing/base/Landing'),
  },
  mixins: [ pageDisplayControlMixin ],
  data() {
    return {
      landingPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      },
      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: ''
    }
  },
  async created() {
    await this.renderPage()
  },
  computed: {
    ...mapState([
      'isMobile',
    ]),
  },
  methods: {
    async renderPage() {
      const currentArtist = this.$store.state.current_artist

      ArtistService.getArtistLandingPage(currentArtist.id)
        .then(res => {
          const landingPage = res.data

          this.$store.dispatch('setIsMsgWhenHidden', landingPage.is_msg_when_hidden)

          if (landingPage.is_msg_when_hidden) {
            this.isMsgHidden = true
            this.msgTitle = landingPage.msg_when_hidden_title
            this.msgSubTitle = landingPage.msg_when_hidden_date
            this.msgContent = landingPage.msg_when_hidden

            this.$store.commit('SHOW_LAYOUT')

            this.init()
          } else {
            this.landingPage = res.data

            this.$store.commit('SHOW_LAYOUT')

            this.init()
          }
        }).catch(()=> {
          this.$router.replace({name: '404'})
        })
    },
  }
}

</script>
