<template>
  <BaseCategory
    ref="top_category"
    :category="category"
    :artist_page_url="artistPageUrl"
    :event_page_url="eventPageUrl"

    :total_qty="totalQty"
    :real-total-qty="realTotalQty"
    :sub_total="subTotal"
    :sets_amount="setsAmount"
    :just-qty-as-set-amount="justQtyAsSetAmount"
    :unit-name="unitName"

    :sale-products="saleProducts"
    :related-products="relatedProducts"
    :filters="filters"

    :suggest_qty_amount="suggestQtyAmount"

    :is-phone="isPhone"

    :cart-category="cartCategory"

    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"

    :except_category_id="$route.params.category_id"
    :product-standard="productStandard"

    :other_events="other_events"
    :sale_product_purchase_popup="sale_product_purchase_popup"
    :is-extra-small-device="isExtraSmallDevice"
    :selected-sale-product="selectedSaleProduct"

    :carousel-setting="CarouselSetting"

    :to-cart="{ name: 'cart.page' }"

    :display-restore-cart="displayRestoreCart"
    :on-publish-key="handlePublishKey.bind(this)"
    :on-restore-cart="handleRestoreCart.bind(this)"

    :current-artist="current_artist"

    @page="(page) => getSaleProductByCategoryId(category.category_id, page, true, true)"
    @multi-purchase-click="onMultiPurchaseClick"
    @add-to-cart="addToCart"
    @to-cart="seeTheCart"
    @filter-change="onFilterChange"
    @close="handleOnClose"
    @next="handleOnNext"
    @prev="handleOnPrevious"
    @image-loaded="onLoadPopupImage"
    @input="updateCart"
    @update-popup="updatePopup"
  />
</template>
<script>
import { mapState } from "vuex";
import EventService from '@services/EventService';
import CategoryService from '@/services/CategoryService';
import SaleProductService from '@/services/SaleProductService';
import '@assets/scss/plugins/lity.css';
import pageDisplayControlMixin from "@/helpers/pageDisplayControlMixin";
import CarouselSetting from "@/components/category/CarouselSetting";
import restoreCartMixin from "@/helpers/restoreCart.mixin";
import Constants from "@/constants";

export default {
  metaInfo() {
    return {
      title: this.titleTag
    }
  },
  mixins: [ pageDisplayControlMixin, restoreCartMixin ],
  components: {
    BaseCategory: () => import('@pages/category/base/Category'),
  },
  data() {
    return {
      artist_header_logo_image_url: '',
      category: {
        event_id: ''
      },

      sale_product_purchase_popup: {},
      artist_notice_open: false,
      category_id: '',
      selectedSaleProduct: null,
      imageStyle: '',
      window: {
        width: 0,
        height: 0
      },

      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',

      filters: {
        event_id: '',
        type: 'printed_product',
        printed_product_standard_id: null,
        normal_product_standard_id: null,
        commodity_id: null
      },
      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: {
        data: [],
        meta: {
          pagination: {
            current: 1,
            last: '',
            per: '',
            from: '',
            to: '',
            count: '',
            total: ''
          },
          has_caption: false
        }
      },
      relatedProducts: [],
      other_events: [],

      CarouselSetting

    }
  },
  methods: {

    getCategoryPageData() {
      const artist_designate_page_directory = this.$route.params.designate_page
      return CategoryService.categoryPage(this.$route.params.category_id, { artist_designate_page_directory }).then(res => {
        this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden);
        if (res.data.is_msg_when_hidden) {
          this.isMsgHidden = true;
          this.msgTitle = res.data.category_msg_when_hidden_title;
          this.msgSubTitle = res.data.category_msg_when_hidden_date;
          this.msgContent = res.data.category_msg_when_hidden_content;
          this.$store.commit('SHOW_LAYOUT');
        } else {
          this.category = res.data;
          this.filters.event_id = res.data.event_id

          this.getOtherEventByArtistId(res.data.artist_id);
          this.getSaleProductByCategoryId(this.$route.params.category_id, this.$route.query.page);
          this.getProductStandardByEvent()
          this.getEventCategoryOrProduct()

          // ガチャ販売でカート内の値で初期値が必要
          const categoryCartProduct = this.cartCategory?.cart_products.find((cartProduct) => {
            return cartProduct.purchasable_type === 'category' && cartProduct.sale_product_id === this.cartCategory.category_id
          })
          if (categoryCartProduct) {
            this.sale_product_purchase_popup.qty = categoryCartProduct.cart_product_qty
          }
        }
      });
    },

    getSaleProductByCategoryId(category_id, page = 1, scrollIntoView = false, is_fetch = false) {

      if (is_fetch) {
        this.$router.push({query: { page }})
      }
      this.saleProducts.meta.pagination.current = page;
      SaleProductService.saleProductByCategoryId(category_id, {
        page: page
      }).then(res => {
        this.saleProducts = res;
        if (scrollIntoView) {
          document.getElementById('first-pagination').scrollIntoView({behavior: 'smooth'});
        }
      });
    },

    getEventCategoryOrProduct() {
      SaleProductService.saleProductRelationInCategory(this.filters, false).then(res => {
        this.relatedProducts = res.data ? res.data.filter(el => el.id != this.$route.params.category_id) : res.data;
      })
    },

    getProductStandardByEvent() {
      EventService.getProductStandardByEvent(this.category.event_id).then(res => {
        const newData = {...this.product_standard, ...res.data}
        this.productStandard = newData
      })
    },

    getOtherEventByArtistId(current_artist_id) {
      EventService.getEventByArtistId(current_artist_id).then((res) => {
        if (res.data) {
          this.other_events = res.data.filter(el => el.id != this.category.event_id);
        }
      });
    },

    addToCart({sale_product, qty = 1}) {
      qty = parseInt(qty);
      if (qty && !this.productInCartExists(sale_product.id)) {
        this.$store.dispatch('storeCartData', {
          purchasable_type: sale_product.purchasable_type,
          sale_product_id: sale_product.id,
          qty: qty,
          designate_page_directory: this.designatePageDirectory,
          client_uuid: this.clientUuId,
        })
      } else if (this.productInCartExists(sale_product.id)) {
        this.removeItemFromCart(sale_product);
      }
    },

    updateCart({sale_product, qty = 1}) {
      qty = parseInt(qty);
      if (qty && this.productInCartExists(sale_product.id)) {
        this.$store.dispatch('storeCartData', {
          purchasable_type: sale_product.purchasable_type,
          sale_product_id: sale_product.id,
          qty: qty,
          designate_page_directory: this.designatePageDirectory,
          client_uuid: this.clientUuId
        })
      } else if (qty === 0 && this.productInCartExists(sale_product.id)) {
        this.removeItemFromCart(sale_product)
      }
    },

    addToCartAll() {
      this.saleProducts.data.forEach(sale_product => {
        this.addToCart({sale_product})
      });
    },

    removeItemFromCart(sale_product) {
      this.$store.dispatch('removeProductFromCart', {
        id: this.cartCategory.cart_products.find(item => item.sale_product_id == sale_product.id).cart_product_id,
        designate_page_directory: this.designatePageDirectory,
        client_uuid: this.clientUuId
      })
    },

    onCartClick(sale_product) {
      if (this.productInCartExists(sale_product.id)) {
        this.removeItemFromCart(sale_product);
      } else {
        this.addToCart({sale_product});
      }
    },

    onMultiPurchaseClick(saleProduct) {
      this.setSaleProduct(saleProduct);
      this.$refs.top_category.$refs.base_category.$refs.popupPurchase.show();
    },

    onLoadPopupImage() {
      this.$store.dispatch('toggleLoading', { state: false, interval: false });
      this.isPurchaseImageLoaded = true;
    },

    async handleOnNext() {
      let currentIndex = this.saleProducts.data.indexOf(this.selectedSaleProduct);
      let saleProduct;

      // check for next page
      let {current, last} = this.saleProducts.meta.pagination;

      if (currentIndex + 1 >= this.saleProducts.data.length) {

        if (current >= last) {
          return;
        }

        this.$store.dispatch('toggleLoading', true);
        // fetch next page
        this.saleProducts = await SaleProductService.saleProductByCategoryId(this.$route.params.category_id, {
          page: current + 1
        });

        this.$store.dispatch('toggleLoading', { state: false, interval: false });

        saleProduct = this.saleProducts.data[0];
      } else {
        saleProduct = this.saleProducts.data[currentIndex + 1]
      }

      if (!saleProduct) {
        return;
      }

      this.setSaleProduct(saleProduct);
    },

    async handleOnPrevious() {
      let currentIndex = this.saleProducts.data.indexOf(this.selectedSaleProduct);
      let saleProduct;

      let {current} = this.saleProducts.meta.pagination;

      if (currentIndex - 1 < 0) {
        // check for previous page
        if (current <= 1) {
          return;
        }
        // fetch previous page
        this.$store.dispatch('toggleLoading', true);
        this.saleProducts = await SaleProductService.saleProductByCategoryId(this.$route.params.category_id, {
          page: current - 1
        });
        this.$store.dispatch('toggleLoading', false);
        saleProduct = this.saleProducts.data[this.saleProducts.data.length - 1];
      } else {
        saleProduct = this.saleProducts.data[currentIndex - 1]
      }

      this.setSaleProduct(saleProduct);
    },

    async handleOnClose() {
      this.isPurchaseImageLoaded = false;
      this.sale_product_purchase_popup = {};
    },

    updatePopup(value) {
      this.sale_product_purchase_popup = value
    },

    setSaleProduct(saleProduct) {
      this.sale_product_purchase_popup = {
        id: saleProduct.id,
        qty: 1,
        name: saleProduct.name,
        number_of_stock: saleProduct.number_of_stock,
        always_keep_in_stock: saleProduct.always_keep_in_stock == 1 ? true : false,
        main_image_url: saleProduct.full_main_image_url,
      }

      //load image
      this.$store.dispatch('toggleLoading', true);

      if (this.cartCategory) {
        let tmp = this.cartCategory.cart_products.find(item => item.sale_product_id == saleProduct.id);
        if (tmp) {
          this.sale_product_purchase_popup.qty = tmp.cart_product_qty;
        }
      }
      this.selectedSaleProduct = saleProduct;

      let {current, last} = this.saleProducts.meta.pagination;

      this.$refs.top_category.$refs.base_category.$refs.popupPurchase.setCaptionArea(!!this.saleProducts.meta.has_caption)

      this.$refs.top_category.$refs.base_category.$refs.popupPurchase.disablePrevious(
        this.saleProducts.data.indexOf(saleProduct) <= 0 && current <= 1
      );

      this.$refs.top_category.$refs.base_category.$refs.popupPurchase.disableNext(
        this.saleProducts.data.indexOf(saleProduct) >= this.saleProducts.data.length - 1 && current >= last
      );
    },

    productInCartExists(id) {
      return this.cartCategory && this.cartCategory.cart_products.some(el => el.sale_product_id == id)
    },
    /**
     * @TODO 使用されていないメソッド?
     *
     * @param amount_in_cart
     * @returns {number}
     */
    getPrintedSetAmount(amount_in_cart) {
      return Math.ceil(amount_in_cart / this.category.purchasable_unit);
    },

    seeTheCart() {
      if (this.justQtyAsSetAmount) {
        this.$router.push({name: 'cart.page'});
      } else {
        this.$store.dispatch('setPrintedSetPopup', true)
      }
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    onFilterChange({ key, value }) {
      if (key === 'type') {
        this.filters.commodity_id = null
      }
      this.filters[key] = value
      this.getEventCategoryOrProduct()
    }
  },

  async mounted() {

    window.addEventListener('resize', this.handleResize);
    this.handleResize();

    this.artist_header_logo_image_url = localStorage.getItem('header_logo_image_url')

    try {
      await this.getCategoryPageData();
      let newWidth = window.innerWidth - 40;

      if (newWidth <= 767) {
        let defaultWidth = 170;
        let defaultHeight = 121.28;
        newWidth = newWidth / 2;
        let newHeight = newWidth * defaultHeight / defaultWidth;
        this.imageStyle = 'padding-top:0;width:' + newWidth + 'px;height:' + newHeight + 'px;';
      }
      this.init()
    } catch (e) {
      this.init()
      await this.$router.push({ name: '404' })
      // this.$nextTick(async () => {
      // })
    }



  },

  computed: {
    ...mapState([
      'isMobile',
      'current_artist'
    ]),
    designatePageDirectory() {
      return this.current_artist.designate_page_directory || ''
    },
    clientUuId() {
      return this.$cookies.get(Constants.CLIENT_UUID) || ''
    },
    isPhone() {
      return !(this.window.width > 767);
    },
    isExtraSmallDevice() {
      return !(this.window.width > 576);
    },
    //find cart category in current page
    cartCategory() {
      return this.$store.state.cart.cartDatas.find(el => el.category_id == this.$route.params.category_id)
    },
    totalQty() {
      return this.cartCategory ? this.cartCategory.cart_category_total_qty : 0;
    },
    /**
     * 現在選択中のセット数
     * 5枚/セットのカテゴリで7枚選択中であれば2セット選択中となる
     *
     * @returns {*|number}
     */
    setsAmount() {
      return this.cartCategory ? this.cartCategory.cart_category_set_amount : 0;
    },
    realTotalQty() {
      if (this.category.print_sale_plan_type === 'bundled_random' || this.category.print_sale_plan_type === 'gacha') {
        return this.totalQty * this.category.bundled_unit
      }

      return this.totalQty
    },
    subTotal() {
      if (!this.cartCategory) return 0

      if (this.cartCategory.print_sale_plan_type === 'bundled') {
        const unit_price = this.cartCategory.cart_products ? this.cartCategory.cart_products[0].unit_price : 0
        const bundled_unit = parseInt(this.cartCategory.bundled_unit)
        if (unit_price === 0 || Number.isNaN(bundled_unit)) return 0
        const bundled_price = unit_price * bundled_unit
        const result = bundled_price * this.setsAmount
        return Math.ceil(result)

      } else {
        return this.cartCategory.cart_category_subtotal_include_tax
      }
    },
    /**
     * 選択枚数の合計がセット枚数の倍数となっており、購入可能かどうか
     * 5枚/セットのカテゴリで、今選択している枚数が5の倍数枚なら購入可能
     *
     * @returns {boolean}
     */
    justQtyAsSetAmount() {
      return (this.totalQty % this.category.purchasable_unit) === 0
    },

    unitName() {
      if (this.category.print_sale_plan_type === 'bundled_random' || this.category.print_sale_plan_type === 'gacha') {
        return 'セット'
      }

      return '枚'
    },
    artistPageUrl() {
      let router = this.$router.resolve({
        name: 'artist.home',
      });
      return router.href;
    },
    eventPageUrl() {
      let router = this.$router.resolve({
        name: 'event.page',
        params: {
          event_id: this.category.event_id
        }
      });
      return router.href;
    },
    suggestQtyAmount() {
      const result = Math.ceil(this.setsAmount) * this.category.purchasable_unit;

      this.$store.dispatch('setSuggestQtyAmount', result)

      return result;
    },
    titleTag() {
      return this.category ? this.category.category_name : null;
    },
    displayRestoreCart() {
      if (this.current_artist) {
        return this.current_artist.display_restore_cart
      }
      return false
    },
  }
}
</script>
