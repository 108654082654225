<template>
  <base-register-pre
    :content="content"
    :prior-description="priorDescription"
    @agree="agree"
    @to-login="toLogin"
  />
</template>

<script>
import StaticService from "@services/StaticService";
import BaseRegisterPre from "@pages/member/base/RegisterPre";
import ArtistService from "@services/ArtistService";
import {CONFIRMATION_NAMES} from "@constants/ArtistConfirmation";

export default {
  name: "RegisterPre",
  components: {BaseRegisterPre},
  data() {
    return {
      content: "",
      priorDescription: '',
    };
  },
  methods: {
    agree() {
      this.$emit("clickNext");
    },
    toLogin() {
      this.$router.push({
        name: 'auth.login'
      })
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  async created() {
    const from = this.$route.query.from

    await StaticService.getRegisterTerm().then((res) => {
      this.content = res.data.main_text;
    });

    if (from) {
      const response = await ArtistService.getArtistByDesignatePage(from)
      if (response.data.prior_display.type === CONFIRMATION_NAMES.MY_PAGE_REGISTRATION) {
        this.priorDescription = response.data.prior_display.description
      }
    }

    this.scrollTerm()
  },
};
</script>
