<template>
  <validation-observer ref="observer">
    <article>
      <Title :subtitle="$t('auth.login_subtitle')" :title="$t('auth.login_title')"/>

      <section class="content pb-60">
        <div class="content__inner">
          <section>
            <HeadLine :title="$t('auth.login')"/>

            <p class="mb-30" v-html="$sanitize($t('auth.message'))"></p>

            <ErrorAlert v-if="unauthorized" class="mb-30"> {{ $t('auth.unauthorized') }}</ErrorAlert>

            <ErrorAlert v-if="unverified" class="mb-30" fontAwsomeIconClass="fas fa-exclamation">
              {{ $t('auth.unverified') }}

              <router-link :to="{name:'member.sendVerification', query:{email: email}}" class="link">
                {{ $t('auth.resend_verificatioh') }}
              </router-link>
            </ErrorAlert>

            <div class="bg-gray pt-30 pb-30 mb-30">
              <dl class="login-dl">
                <dt class="bold large mb-10"> {{ $t('auth.email') }}</dt>

                <dd class="mb-10">
                  <ValidationProvider v-slot="{ errors }" rules="required|email">
                    <input
                      id="email"
                      v-model="email"
                      :name="$t('auth.email')"
                      class="login-dl__input"
                      type="email"
                      @input="clearError"
                    >

                    <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>

                <dt class="bold large mb-10"> {{ $t('auth.password') }}</dt>

                <dd class="mb-10">
                  <ValidationProvider v-slot="{ errors }" rules="required">
                    <div class="form-password">
                      <input
                        v-model="password"
                        :name="$t('auth.password')"
                        class="login-dl__input form-password__input"
                        :type="is_visible_password ? 'text' : 'password'"
                        @input="clearError"
                      >

                      <div class="form-password__append">
                        <IconEye
                          v-if="is_visible_password"
                          class="form-password__append__icon"
                          @click.stop="() => is_visible_password = !is_visible_password"
                        />

                        <IconEyeSlash
                          v-else
                          class="form-password__append__icon"
                          @click.stop="() => is_visible_password = !is_visible_password"
                        />
                      </div>
                    </div>

                    <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>

                <dt class="blank"></dt>

                <dd class="flex wrap">
                  <label class="check">
                    <input v-model="remember" type="checkbox"/>

                    <span class="check--dummy"></span>

                    <span class="check--text">
                      {{ $t('auth.remember') }}
                    </span>
                  </label>
                </dd>
              </dl>
            </div>

            <Button :title="$t('auth.submit_login')" @onClick="login"/>

            <div class="al-c">
              <router-link :to="{ name: 'auth.password_resetting'}" class="link-line">
                {{ $t('auth.forgot_password') }}
              </router-link>
            </div>
          </section>
        </div>
      </section>
    </article>
  </validation-observer>
</template>

<script>
import Constants from '@constants'

export default {
  data() {
    return {
      email: "",
      password: "",
      unauthorized: false,
      remember: false,
      unverified: false,
      is_visible_password: false,
    };
  },
  methods: {
    async login() {
      let valid = await this.$refs.observer.validate();

      if (!valid) {
        this.scrollToFirstError();
        return;
      }

      if (this.remember === true) {
        this.remember = 1
      } else {
        this.remember = 0
      }
      this.$store.dispatch("login", {
        email: this.email,
        password: this.password,
        remember: this.remember
      })
        .then(() => {
          const redirectUrl = this.$cookies.get(Constants.REDIRECT_URL);
          const purchasePage = this.$cookies.get(Constants.PURCHASE_PAGE);

          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect);
          } else if (redirectUrl && !purchasePage) {
            this.$cookies.remove(Constants.REDIRECT_URL);
            return this.$router.push(redirectUrl);
          } else if (purchasePage && !redirectUrl) {
            this.$cookies.remove(Constants.PURCHASE_PAGE);
            return this.$router.push({name: 'purchase'});
          } else {
            return this.$router.push("/");
          }
        })
        .catch((e) => {
          if (e.status === 403) {
            return this.unverified = true;
          }
          return this.unauthorized = true;
        });
    },
    clearError() {
      this.unauthorized = false;
      this.unverified = false;
    }
  },
  beforeRouteEnter(to, from, next) {
    const fromRouteName = from.name;
    if (fromRouteName && fromRouteName.slice(0, 5) !== "auth." && fromRouteName.slice(0, 7) !== "member.") {
      next((vm) => {
        vm.$cookies.set(Constants.REDIRECT_URL, from.fullPath);
      })
    }
    next()
  },
  components: {
    HeadLine: () => import("@components/base/Headline"),
    Title: () => import("@components/base/Title"),
    Button: () => import("@components/base/Button"),
    ErrorAlert: () => import("@components/base/ErrorAlert"),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
  }
}
</script>
