export default {
  if_the_purchase_details: "以下の購入内容、送付先およびお支払い方法の情報に誤りがなければ、注文を確定してください。",
  time_designation: "時間指定",
  unspecified: "指定なし",
  in_the_morning: "午前中",
  "14_00_〜16_00": "14:00〜16:00",
  payment_method: "お支払い方法",
  card_number: "カード番号",
  order_confirmed: "注文確定",
  store_name: "店舗名",
  message: "メッセージ",
  month: {
    "0": "1月",
    "1": "2月",
    "2": "3月",
    "3": "4月",
    "4": "5月",
    "5": "6月",
    "6": "7月",
    "7": "8月",
    "8": "9月",
    "9": "10月",
    "10": "11月",
    "11": "12月",
  },
  error_title: 'タイトル',
  error_code: 'エラーコード',
  error_message: 'エラーメッセージ',
  error_type: {
    Payment_fail: '支払いの失敗'
  }
}