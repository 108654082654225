import BaseService from "./BaseService";

class InquiryService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getCategories(data) {
    return this.performRequest(BaseService.METHOD_GET, 'categories', data);
  }
}

export default new InquiryService('inquiries');