export default [
  {
    path: "/register",
    name: "member.index",
    component: () => import("@pages/member/Index"),
  },
  {
    path: "/email-verification/:token",
    name: "member.verification",
    component: () => import("@pages/member/Verification"),
  },
  {
    path: "/send-verification",
    name: "member.sendVerification",
    component: () => import("@pages/member/SendVerificationEmail"),
  }
];
