export default {
  title: {
    main: 'Reorder',
    main_jp: '再注文手続き',
  },
  about_reorder_procedure: '再注文手続きについて',
  content: "日頃より、Bromide.jpをご利用いただきまして誠にありがとうございます。<br>メールに記載されております「再注文用のキー」をご入力くださいませ。",
  key: '再注文用キー',

  btn: {
    reorder_procedure: '再注文手続きへ'
  }
}
