<template>
  <footer :style="{ backgroundColor: footerAreaColor, color: footerTextColor, borderColor: footerTextColor }"
          class="footer">
    <div class="footer__sns">
      <div class="footer__sns">
        <p class="font-en mb-5">SHARE</p>
        <ul class="footer__sns--list">
          <li class="footer__sns--item">
            <a :href="`https://twitter.com/share?url=${currentUrl}/&amp;text=${currentTitle}`" target="_blank">
              <i class="fab fa-twitter"></i>
            </a>
          </li>
          <li class="footer__sns--item">
            <a :href="`https://www.facebook.com/sharer/sharer.php?u=${currentUrl}`" target="_blank">
              <i class="fab fa-facebook-f"></i>
            </a>
          </li>
          <li class="footer__sns--item">
            <a :href="`http://line.me/R/msg/text/?${currentUrl}`" target="_blank">
              <i class="fab fa-line"></i>
            </a>
          </li>
        </ul>
      </div>
      <div class="footer__nav">
        <ul class="footer__nav-list">
          <li class="footer__nav-item">
            <router-link :to="{ name: current_artist ? 'artist.static.delivery' : 'static.delivery' }" class="small">
              {{ $t('general.delivery') }}
            </router-link>
          </li>
          <li class="footer__nav-item">
            <router-link :to="{ name: current_artist ? 'artist.static.trading' : 'static.trading'}" class="small">
              {{ $t('general.trading') }}
            </router-link>
          </li>
          <li class="footer__nav-item">
            <router-link :to="{ name: current_artist ? 'artist.static.privacy' : 'static.privacy'}" class="small">
              {{ $t('general.privacy') }}
            </router-link>
          </li>
          <li class="footer__nav-item">
            <router-link :to="{ name: current_artist ? 'artist.static.terms' : 'static.terms'}" class="small">
              {{ $t('general.terms') }}
            </router-link>
          </li>
          <li class="footer__nav-item pc-elem">
            <router-link :to="{ name: current_artist ? 'inquiry.artist.create' : 'inquiry.create'}" class="small">
              {{ $t('general.inquiry') }}
            </router-link>
          </li>
        </ul>
        <ul class="footer__nav-list--large mb-elem">
          <li class="footer__nav-item footer__nav-item--inquiry">
            <router-link :to="{ name: current_artist ? 'inquiry.artist.create' : 'inquiry.create'}" class="small inquiry">
              【{{ $t('general.inquiry') }}】
            </router-link>
          </li>
        </ul>
      </div>
      <div class="footer__annotation al-c">
        <p class="small">
          当サイトに掲載されている全てのコンテンツは<br class="s-elem">著作権法により保護されています。<br>
          SNS等へのスクリーンショットの転載を含む、<br class="s-elem">私的使用の範囲を超える無断複製・引用・転載は<br class="s-elem">固くお断りします。
        </p>
      </div>
      <div class="copyright">
        <small class="small font-en copyright__text">
          {{ footerText }} All Rights Reserved.<br/>
          Powered by Bromide,inc.
        </small>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterLayout",
  props: {
    footerText: {
      type: String,
      required: false,
      default: '(C) bromide.JP'
    },
    footerTextColor: {
      type: String,
      required: false,
      default: '#ffffff'
    },
    footerAreaColor: {
      type: String,
      required: false,
    },
    partner_id: {
      required: false,
    },
    current_artist: {
      type: Object,
      default: null
    }
  },
  computed: {
    currentUrl() {
      return window.location.href;
    },
    currentTitle() {
      return document.title;
    },
  }
};
</script>
