<template>
  <div v-if="order_products.data.length > 0">
    <div class="category__list mb-50">
      <div
        v-for="(sale_product, index) in order_products.data"
        :key="index"
        class="category-product__item"
      >
        <div class="category-product__thumb box-shadow">
          <a @click="onProductImageClick(sale_product)">
            <img :src="sale_product.main_image_url">
            <span :style="{ 'background-image': sale_product.main_image_url }"
                  alt=""
                  class="sub-cate-link__blur"></span>
          </a>
        </div>
        <div class="category-product__button-wrapper mt-5 text-sm">
          <div>
            {{ $t('mypage_history.id') }} {{ sale_product.sale_product_id }}
          </div>
        </div>
        <div class="category-product__button-wrapper mb-5 text-sm">
          <div>
            {{ $t('mypage_history.qty') }} {{ sale_product.product_qty }}
          </div>
        </div>
      </div>
    </div>
    <p class="mb-10 al-c font-en">
      {{ order_products.meta.pagination.current }} / {{ order_products.meta.pagination.last }}
      page [total {{ order_products.meta.pagination.total }}]
    </p>
    <app-pagination
      v-if="order_products.data.length > 0"
      :current_page="order_products.meta.pagination.current"
      :interval="5"
      :total_pages="order_products.meta.pagination.last"
      @onPageChange="(page) => getPurchaseProductByOrderID(page)"
    />
    <slide-modal
      ref="popupPurchase"
      @onClose="handleOnClose"
      @onNext="handleOnNext"
      @onPrevious="handleOnPrevious"
    >
      <div id="popup-purchase">
        <div class="popup-purchase__image">
          <img v-show="isPurchaseImageLoaded"
               ref="mainImage"
               :src="sale_product_purchase_popup.main_image_url"
               class="purchase__photo"
               @load="onLoadPopupImage"
               @error="onLoadPopupImage"
          >
        </div>
        <div v-show="isPurchaseImageLoaded" class="popup-purchase__description">
          <div class="category-product__button-wrapper mt-5 text-sm">
            <div>
              {{ $t('mypage_history.id') }} {{ sale_product_purchase_popup.sale_product_id }}
            </div>
          </div>
          <div class="category-product__button-wrapper mb-5 text-sm">
            <div>
              {{ $t('mypage_history.qty') }} {{ sale_product_purchase_popup.product_qty }}
            </div>
          </div>
        </div>
      </div>
    </slide-modal>
  </div>
</template>
<script>

import MemberService from '@/services/MemberService';

export default {
  name: "OrderProductList",
  data() {
    return {
      order_products: {
        data: [],
        meta: {
          pagination: {
            current: '',
            total: '',
            last: '',
            first: ''
          }
        }
      },
      selected_sale_product: null,
      loadedImage: [],
      sale_product_purchase_popup: {},
      isPurchaseImageLoaded: false,
    }
  },
  props: {
    event_id: {
      type: String,
      required: true,
      default: ''
    },
    category_id: {
      type: String,
      required: true,
      default: ''
    }
  },
  methods: {
    getPurchaseProductByOrderID(page = 1) {
      if (this.order_id && this.has_event_category) {
        let params = {
          event_id: this.event_id,
          category_id: this.category_id,
          page: page
        }

        this.order_products.meta.pagination.current = page;
        MemberService.getPurchaseProductByOrderID(this.order_id, params).then(res => {
          this.order_products = res;
        })
      }
    },
    async handleOnNext() {
      let currentIndex = this.order_products.data.indexOf(this.selected_sale_product);
      let saleProduct;

      // check for next page
      let {current, last} = this.order_products.meta.pagination;

      if (currentIndex + 1 >= this.order_products.data.length) {

        if (current >= last) {
          return;
        }

        this.$store.dispatch('toggleLoading', true);
        this.loadedImage = [];
        // fetch next page
        await this.getPurchaseProductByOrderID(current + 1);

        this.$store.dispatch('toggleLoading', false);

        saleProduct = this.order_products.data[0];
      } else {
        saleProduct = this.order_products.data[currentIndex + 1]
      }

      if (!saleProduct) {
        return;
      }

      this.setSaleProduct(saleProduct);
    },
    async handleOnPrevious() {
      let currentIndex = this.order_products.data.indexOf(this.selected_sale_product);
      let saleProduct;

      let {current} = this.order_products.meta.pagination;

      if (currentIndex - 1 < 0) {
        // check for previous page
        if (current <= 1) {
          return;
        }
        // fetch previous page
        this.$store.dispatch('toggleLoading', true);
        this.loadedImage = [];
        await this.getPurchaseProductByOrderID(current - 1);
        this.$store.dispatch('toggleLoading', false);
        saleProduct = this.order_products.data[this.order_products.data.length - 1];
      } else {
        saleProduct = this.order_products.data[currentIndex - 1]
      }

      this.setSaleProduct(saleProduct);
    },
    async handleOnClose() {
      this.isPurchaseImageLoaded = false;
      this.sale_product_purchase_popup = {};
    },
    setSaleProduct(saleProduct) {
      this.sale_product_purchase_popup = {
        sale_product_id: saleProduct.sale_product_id,
        product_qty: saleProduct.product_qty,
        main_image_url: saleProduct.main_image_url
      }

      //load image
      this.$store.dispatch('toggleLoading', true);
      if (!this.loadedImage.includes(saleProduct.sale_product_id)) {
        this.loadedImage.push(saleProduct.sale_product_id);
      } else {
        this.$refs.mainImage.addEventListener('load', () => {
          this.onLoadPopupImage()
        });
      }

      this.selected_sale_product = saleProduct;

      let {current, last} = this.order_products.meta.pagination;

      this.$refs.popupPurchase.disablePrevious(
        this.order_products.data.indexOf(saleProduct) <= 0 && current <= 1
      );

      this.$refs.popupPurchase.disableNext(
        this.order_products.data.indexOf(saleProduct) >= this.order_products.data.length - 1 && current >= last
      );
    },
    onLoadPopupImage() {
      this.$store.dispatch('toggleLoading', false);
      this.isPurchaseImageLoaded = true;
    },
    onProductImageClick(saleProduct) {
      this.setSaleProduct(saleProduct);
      this.$refs.popupPurchase.show();
    },
  },
  watch: {
    category_id: function () {
      this.getPurchaseProductByOrderID();
    }
  },
  computed: {
    order_id() {
      return this.$route.params.history_id;
    },
    has_event_category() {
      return this.event_id && this.category_id;
    }
  },
  components: {
    AppPagination: () => import('@components/base/Pagination'),
    SlideModal: () => import('@components/base/SlideModal'),
  }
}
</script>
