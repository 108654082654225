<template>
  <svg
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <path d="M24.949 8.48342C22.5953 3.89097 17.9356 0.783768 12.6001 0.783768C7.26457 0.783768 2.60355 3.89314 0.251116 8.48385C0.151828 8.68026 0.100098 8.89725 0.100098 9.11732C0.100098 9.33739 0.151828 9.55438 0.251116 9.75078C2.60485 14.3432 7.26457 17.4504 12.6001 17.4504C17.9356 17.4504 22.5966 14.3411 24.949 9.75035C25.0483 9.55395 25.1 9.33696 25.1 9.11688C25.1 8.89681 25.0483 8.67982 24.949 8.48342ZM12.6001 15.3671C11.3639 15.3671 10.1556 15.0005 9.12776 14.3138C8.09995 13.627 7.29887 12.6509 6.82583 11.5089C6.35278 10.3668 6.22901 9.11017 6.47017 7.89779C6.71132 6.68541 7.30658 5.57176 8.18066 4.69768C9.05473 3.82361 10.1684 3.22835 11.3808 2.98719C12.5931 2.74604 13.8498 2.86981 14.9918 3.34285C16.1339 3.8159 17.11 4.61698 17.7968 5.64479C18.4835 6.67259 18.8501 7.88097 18.8501 9.1171C18.8505 9.93797 18.6891 10.7509 18.3751 11.5093C18.0612 12.2678 17.6008 12.957 17.0204 13.5374C16.4399 14.1179 15.7508 14.5782 14.9923 14.8922C14.2339 15.2061 13.4209 15.3675 12.6001 15.3671ZM12.6001 4.95043C12.2282 4.95563 11.8587 5.01096 11.5015 5.11493C11.7959 5.51496 11.9372 6.00724 11.8997 6.50249C11.8622 6.99774 11.6485 7.46317 11.2973 7.81437C10.9461 8.16556 10.4807 8.37927 9.98547 8.41674C9.49022 8.4542 8.99794 8.31294 8.5979 8.01858C8.37011 8.85781 8.41123 9.74734 8.71547 10.562C9.01972 11.3766 9.57176 12.0753 10.2939 12.5598C11.0161 13.0442 11.872 13.29 12.7411 13.2626C13.6103 13.2351 14.4489 12.9358 15.1391 12.4067C15.8292 11.8777 16.3361 11.1455 16.5883 10.3133C16.8405 9.48106 16.8254 8.5907 16.5451 7.76752C16.2648 6.94434 15.7334 6.22979 15.0257 5.72444C14.318 5.21909 13.4697 4.94839 12.6001 4.95043Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconEye',
}
</script>
