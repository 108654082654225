<template>
  <BaseLanding
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
    :landing-page="landingPage"
  />
</template>

<script>
import LandingPageTmpService from "@services/LandingPageTmpService";
import ArtistTmpService from "@services/ArtistTmpService";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    BaseLanding: () => import('@pages/landing/base/Landing'),
  },
  data() {
    return {
      landingPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      },
      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: ''
    }
  },
  async created() {
    await this.getLandingPagePreview()
  },
  computed: {
    ...mapState([
      'current_artist',
      'isMobile',
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_CURRENT_ARTIST'
    ]),
    async getArtist (designatePageId) {
      try {
        const artist = await ArtistTmpService.getArtistByDesignatePage(designatePageId)
        this.SET_CURRENT_ARTIST(artist.data)
      } catch (e) {
        console.error(e)
      }
    },
    async getLandingPagePreview() {
      try {
        const res = await LandingPageTmpService.getLandingPagePreview(this.$route.params.token)

        const { artist_designate_page_directory } = res.data

        if (artist_designate_page_directory) {
          await this.getArtist(artist_designate_page_directory)
        }

        this.landingPage = res.data
      } catch (e) {
        this.$router.replace({name: '404'})
      }
    },
  }
}

</script>
