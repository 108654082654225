import BaseService from "./BaseService";
import Constants from '@constants'
import Vue from 'vue';

class ReshippingService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getOrderReshipping(reshipping_token) {
    return this.performRequest(BaseService.METHOD_POST, `${reshipping_token}`, {}, {}, { is_ignore_interceptors_response_error: true });
  }

  applyOrderReshipping(reshipping_token, data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    return this.performRequest(BaseService.METHOD_PUT, `${reshipping_token}`, data)
  }

}

export default new ReshippingService('reshippings');
