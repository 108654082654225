<template>
  <section ref="item" :class="['content', contentClass]">
    <div class="content__inner">
      <div class="information">
        <div
          :class="
            isActive
              ? 'js-accordion-title information__heading open'
              : 'js-accordion-title information__heading'
          "
          @click="isActive = !isActive"
        >
          <span class="information__type">
            <span>{{ noticeText }}</span>
          </span>
          <div class="information__title">
            {{ title }}
          </div>
        </div>
        <div
          v-if="isActive"
          class="information__detail content__inner--editor"
        >
          <InformationListAccordionItem
            v-for="(information, i) in informations"
            :key="i"
            class="information__detail__inner"
            :information="information"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
// import InformationListAccordionItem from "@components/base/InformationListAccordionItem";
export default {
  components: {
    InformationListAccordionItem: () => import('@components/base/InformationListAccordionItem'),
  },
  props: {
    contentClass: {
      type: String,
      require: false,
      default: '',
    },
    active: {
      type: Boolean,
      require: false,
      default: false,
    },
    noticeText: {
      type: String,
      require: false,
      default: '',
    },
    title: {
      type: String,
      require: false,
      default: '',
    },
    informations: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isActive: this.active,
    }
  },
}
</script>
