export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'Change registration information',
    description: '* Is a required item.'
  },
  basic_information: {
    title: 'Basic information',
    name: 'Name',
    first_name: 'First name',
    last_name: 'Last name',
    name_hiragana: 'Name Hiragana',
    first_name_hiragana: 'First name Hiragana',
    last_name_hiragana: 'Last name Hiragana',
    email_address: 'Email address',
    email_address_confirmation: 'Email address confirmation',
    gender: 'Gender',
    occupation: 'Occupation',
    date_of_birth: 'Date of birth',
    year: 'Year',
    month: 'Month',
    day: 'Day',
    desired_password: 'Desired password',
    please_enter_your_password: 'Please enter your password.',
    password_detail: '8 to 32 single-byte alphanumeric characters',
    current_password: 'Current password',
    new_password: 'New password',
    password_confirmation: 'Password Confirmation',
    repeat_password: 'Repeat password.',
    send_email_newsletter: 'Send e-mail newsletter',
    do_not_want: 'Do not want',
    hope: 'Hope'
  },
  product_delivery_address: {
    title: "Product delivery address",
    postal_code: "Postal code",
    prefecture: "Prefecture",
    address: "Address",
    phone_number: "Phone number",
    phone_number_confirmation: "Phone number confirmation",
    enter_address_from_zip_code: "Enter address from zip code",
    edit: "Edit",
    add: "Add",
    confirm_registration_details: "Confirm registration details"
  },
  select: {
    please_select: 'Please select'
  },
  gender: {
    male: 'Male',
    female: 'Female',
    anonymous: 'Rather not to say'
  },
  occupation: {
    employee: 'Employee',
    student: 'Student',
    self_employee: 'Self employed',
    other: 'Other',
  },
  placeholder: {
    address: 'Address',
    half_width_numbers_and_no_hyphens: 'Half-width numbers and no hyphens',
    please_enter_your_phone_number_again: 'Please enter your phone number again.'
  },
  text_address_pc: "If the address is not entered correctly, or if the building name and room number are not entered, the luggage may not arrive.",
  address_validate: "City / ward / town / address is a required item",
  address2_validate: "If you do not enter the building name and room number, your luggage may not arrive."
}
