export default {
  'notice': 'NOTICE',
  'sales_period': 'Sales period',
  'remaining_sale_day': '{remaining_days} days left',
  'new': 'NEW',
  'end_of_sale_today': 'End of sale today',
  'return_to_artist_top': 'Return to artist TOP',
  'info': 'INFO',
  'set': '{amount} set',
  'tax_included': 'Tax included',
  'relation': {
    'title': 'CATEGORY',
    'subtitle': 'カテゴリー',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': 'Click here for details'
  },
  'up_to': "",
  'sale_term': "Sale Period:",
  'other_event_title': 'OTHER EVENT',
  'other_event_subtitle': '関連イベント'
}