<template>
  <Modal :modal="isShowModal" width="600px">
    <div slot="body" class="body">
      <label class="mr-5">以下の住所からお選びください</label>

      <ul class="no-bullet postal-code-list">
        <li v-for="address in addresses" v-bind:key="address.id">
          <input
            type="radio"
            v-model="selected_item"
            v-bind:value="address.id"
            :id="address.name"
            :name="address.name"
          >
          <label class="radio--label" :for="address.name">{{ address.name }}</label>
        </li>
      </ul>
    </div>

    <div slot="footer" class="modal-footer">
      <button
        :class="{ mobilebackbutton: isMobile }"
        class="footer__close mr-5"
        @click="onClose"
      >
        戻る
      </button>

      <button class="form-btn" @click="onSelectAddress">設定する</button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'PostalCodeSelectModal',
  components: {
    Modal: () => import('@components/base/Modal'),
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: true,
    },
    isMobile: {
      type: Boolean,
      default: false,
    },
    addresses: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selected_item: 0,
    }
  },
  methods: {
    onSelectAddress() {
      const address = this.addresses[this.selected_item]
      this.selected_item = 0
      this.$emit('select', address)
    },
    onClose() {
      this.selected_item = 0
      this.$emit('close')
    }
  },
}
</script>

<style scoped>
.postal-code-list {
  max-height: 200px;
  overflow-y: auto;
}
</style>
