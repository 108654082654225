<template>
  <div class="event-main__detail">
    <div class="event-main__label right event-main__label">
      <span class="event-main__label--period">{{ $t('category.sales_period') }}</span>
      <span class="event-main__label--date" v-html="$sanitize(data.category_sale_period)"/>
    </div>
    <div class="event-main__label left event-main__label">
          <span
            v-if="data.category_sale_new"
            class="event-main__label--new"
          >{{ $t('category.new') }}</span>
      <span
        v-else-if="data.category_remaining_sale_day <= 3 && data.category_remaining_sale_day > 0"
        class="event-main__label--3day"
      >{{ $t('category.remaining_sale_day', {remaining_days: data.category_remaining_sale_day}) }}</span>

      <span
        v-else-if="data.category_sale_finish_today"
        class="event-main__label--today"
      >{{ $t('category.end_of_sale_today') }}</span>

    </div>
  </div>
</template>

<script>
export default {
  name: "CategorySaleTerm",
  props: {
    data: {
      type: Object,
      required: true
    }
  }
}
</script>
