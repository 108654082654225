<template>
  <div class="pt-30">

    <mail-registration-form
      v-if="showEmailRegistration && !sendEmail && !emailConfirmed"
      :content="priorDescription"
      @email-registration-confirm="handleEmailRegistrationConfirm"
    />

    <mail-registration-mail-send
      v-if="showEmailRegistration && sendEmail"
    />

    <mail-registration-verification
      v-if="showEmailRegistration && emailConfirmed"
      success
      @to-artist="handleToArtist"
    />

    <term-agreement
      v-if="showTermService"
      :content="termAgreementContent"
      :is-event-list-attention="showEventListAttention"
      :current-artist="current_artist"
      @not-agree-click="handleNotAgree"
      @agree-click="handleAgree"
    />

  </div>
</template>
<script>

import TermAgreement from "@components/artist/ArtistConfirmation/TermAgreement";
import {CONFIRMATION_NAMES, CONFIRMATION_STATUS} from "@constants/ArtistConfirmation";
import {mapState, mapActions} from "vuex"
import MailRegistrationMailSend from "@components/artist/ArtistConfirmation/MailRegistration/MailRegistrationMailSend";
import MailRegistrationForm from "@components/artist/ArtistConfirmation/MailRegistration/MailRegistrationForm";
import ArtistService from "@services/ArtistService";
import MailRegistrationVerification
  from "@components/artist/ArtistConfirmation/MailRegistration/MailRegistrationVerification";

export default {
  components: {
    MailRegistrationVerification,
    MailRegistrationForm,
    MailRegistrationMailSend,
    TermAgreement
  },
  data() {
    return {
      sendEmail: false,
      emailConfirmed: false,
    }
  },
  computed: {
    ...mapState([
      'current_artist',
      'artistConfirmation',
      'showEventListAttention',
      'auth'
    ]),
    showTermService() {
      // ページ表示の設定を優先
      return (this.current_artist.prior_display?.type === CONFIRMATION_NAMES.TERMS_AGREEMENT && !this.termAgreementVerified) || this.showEventListAttention
    },
    termAgreementContent() {
      if (!this.showEventListAttention) {
        return this.current_artist.prior_display
      } else {
        return this.current_artist.event_list_attention_display
      }
    },
    priorDescription() {
      return this.current_artist.prior_display?.description || ''
    },
    termAgreementVerified() {
      return this.artistConfirmation &&
        this.artistConfirmation[CONFIRMATION_NAMES.TERMS_AGREEMENT] === CONFIRMATION_STATUS.CONFIRMED
    },
    emailVerified() {
      return this.artistConfirmation &&
        this.artistConfirmation[CONFIRMATION_NAMES.MAIL_REGISTRATION] === CONFIRMATION_STATUS.CONFIRMED
    },
    showMyPageRegistration() {
      return this.current_artist.prior_display?.type === CONFIRMATION_NAMES.MY_PAGE_REGISTRATION
    },
    showEmailRegistration(){
      return this.current_artist.prior_display?.type === CONFIRMATION_NAMES.MAIL_REGISTRATION && !this.emailVerified
    },
  },
  mounted() {
    if (this.auth.member === null && this.showMyPageRegistration) {
      this.$router.replace({
        name: 'member.index',
        query: {
          from: this.current_artist.designate_page_directory
        }
      })
    }
    if (this.auth.member && this.showMyPageRegistration) {
      // 過去に別アーティストでの規約の確認が済んでいれば無条件でconfirmed
      this.setArtistConfirmation({
        designatePageId: this.current_artist.designate_page_directory,
        agreementName: CONFIRMATION_NAMES.MY_PAGE_REGISTRATION
      })
      this.myPageLoggedInConfirmed()
    }
  },
  watch: {
    auth: {
      deep: true,
      handler(newValue) {
        if (newValue.member) {
          this.myPageLoggedInConfirmed()
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'getArtistConfirmation',
      'setArtistConfirmation',
      'myPageLoggedInConfirmed'
    ]),
    handleAgree() {
      this.setArtistConfirmation({
        designatePageId: this.current_artist.designate_page_directory,
        agreementName: this.showEventListAttention ? CONFIRMATION_NAMES.EVENT_LIST_ATTENTION : CONFIRMATION_NAMES.TERMS_AGREEMENT
      })
      if (this.showEventListAttention) {
        this.$store.commit('CLOSE_EVENT_LIST_ATTENTION')
      }
    },
    handleNotAgree() {
      this.$store.commit('CLOSE_EVENT_LIST_ATTENTION')
    },

    async handleEmailRegistrationConfirm(data) {
      const response = await ArtistService.checkEmailRegistration(this.current_artist.id, data)
      const isConfirmed = response.data.status === 'confirmed'
      if (isConfirmed) {
        await this.setArtistConfirmation({
          designatePageId: this.current_artist.designate_page_directory,
          agreementName: CONFIRMATION_NAMES.MAIL_REGISTRATION
        })
        this.sendEmail = false

        this.emailConfirmed = true
      } else {
        this.sendEmail = true
      }
    },

    handleToArtist() {

      this.$router.push({
        name: 'artist.home',
        params: {
          designate_page: this.current_artist.designate_page_directory
        }
      })

    }
  }
}
</script>
