<template>
  <BaseCategoryGoods
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubtitle"
    :msg-title="msgTitle"

    :category="category"
    :artist_page_url="artist_page_url"
    :event_page_url="event_page_url"
    :current-artist="current_artist"

    :purchase-item="purchaseItem"
    :product_current_qty_in_cart="product_current_qty_in_cart"
    :product_current_total_include_tax="product_current_total_include_tax"
    :except_category_id="$route.params.category_id"
    :filters="filters"
    :product-standard="productStandard"
    :sale-products="saleProducts"

    :carousel-setting="CarouselSetting"

    :sub-commodities="subCommodities"
    :combined-sale-products="combinedSaleProducts"

    @page="(page) => getSaleProductByCategoryId(category.category_id, page, true, true)"
    @to-cart="seeTheCart"
    @update-purchase-item="UpdatePurchaseItem"
    @add-to-cart="addToCart"
    @add-combined-product-to-cart="addCombinedProductToCart"
    @filter-change="onFilterChange"
  />
</template>

<script>
import { mapState } from "vuex";
import CategoryService from '@/services/CategoryService';
import EventService from "@/services/EventService";
import SaleProductService from "@/services/SaleProductService";
import Constants from '@constants';
import pageDisplayControlMixin from "@/helpers/pageDisplayControlMixin";
import CarouselSetting from "@/components/category/CarouselSetting";

export default {
  metaInfo() {
    return {
      title: this.titleTag
    }
  },
  mixins: [ pageDisplayControlMixin ],
  components: {
    BaseCategoryGoods: () => import('@pages/category/base/CategoryGoods'),
  },
  data() {
    return {
      artist_header_logo_image_url: '',
      category: {},
      artist_notice_open: false,
      purchaseItem: {
        qty: null
      },
      isMsgHidden: false,
      msgTitle: '',
      msgSubtitle: '',
      msgContent: '',

      subCommodities: [],
      combinedSaleProducts: {
        data: [],
        meta: {
          pagination: {
            current: 1,
            last: '',
            per: '',
            from: '',
            to: '',
            count: '',
            total: ''
          },
          has_caption: false
        }
      },

      filters: {
        event_id: null,
        type: Constants.CATEGORY_TYPE_GOOD,
        printed_product_standard_id: null,
        normal_product_standard_id: null,
        commodity_id: null
      },
      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: [],

      CarouselSetting

    }
  },
  methods: {
    async getCategoryGoodsPageData() {
      const artist_designate_page_directory = this.$route.params.designate_page
      await CategoryService.categoryGoodsPage(this.$route.params.category_id, { artist_designate_page_directory }).then(res => {
        this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden);
        if (res.data.is_msg_when_hidden) {
          this.isMsgHidden = true;
          this.msgTitle = res.data.category_msg_when_hidden_title;
          this.msgSubtitle = res.data.category_msg_when_hidden_date;
          this.msgContent = res.data.category_msg_when_hidden_content;
          this.$store.commit('SHOW_LAYOUT');
          this.init()
        } else {

          this.category = res.data;
          this.filters = {
            ...this.filters,
            event_id: res.data.event_id,
          }

          this.$nextTick(() => {
            const promises = [
              this.getProductStandardByEvent(),
              this.getEventCategoryOrProduct(),
            ]
            const { is_combined_product, product_standard_id } = res.data
            if (is_combined_product) {
              promises.push(this.getSaleProductSubCommodity(product_standard_id))
              promises.push(this.getSaleProductByCategoryId(this.$route.params.category_id, this.$route.query.page))
            }
            Promise.all(promises)
              .then(() => {
                this.init()
              })
          })
        }
      });

    },
    addToCart() {
      this.$store.dispatch('storeCartData', {
        purchasable_type: this.category.purchasable_type,
        sale_product_id: this.category.sale_product_id,
        qty: this.purchaseItem.qty
      })
    },

    addCombinedProductToCart({ commodity_id, sale_product }) {
      console.log("addCombinedProductToCart", sale_product)
      this.$store.dispatch('storeCartData', {
        purchasable_type: sale_product.purchasable_type,
        sale_product_id: sale_product.id,
        qty: 1,
        designate_page_directory: this.$route.params.designate_page,
        commodity_id
      })
    },

    getSaleProductByCategoryId(category_id, page = 1, scrollIntoView = false, is_fetch = false) {

      if (is_fetch) {
        this.$router.push({query: { page }})
      }
      this.combinedSaleProducts.meta.pagination.current = page;
      return SaleProductService.saleProductByCategoryId(category_id, {
        page: page
      }).then(res => {
        this.combinedSaleProducts = res;
        if (scrollIntoView) {
          document.getElementById('first-pagination').scrollIntoView({behavior: 'smooth'});
        }
      });
    },

    getSaleProductSubCommodity(product_standard_id) {
      return SaleProductService.saleProductSubCommodity(product_standard_id).then(res => {
        this.subCommodities = res.data
      })
    },

    getProductStandardByEvent() {
      return EventService.getProductStandardByEvent(this.filters.event_id).then(res => {
        const newData = {...this.product_standard, ...res.data}
        this.productStandard = newData
      })
    },
    getEventCategoryOrProduct() {
      return SaleProductService.saleProductRelationInCategory(this.filters, false).then(res => {
        this.saleProducts = res.data ? res.data.filter(el => el.id != this.$route.params.category_id) : res.data;
      })
    },
    seeTheCart() {
      this.$router.push({name: 'cart.page'});
    },

    UpdatePurchaseItem(value) {
      this.purchaseItem.qty = value
    },
    onFilterChange({ key, value }) {
      if (key === 'type') {
        this.filters.commodity_id = null
      }
      this.filters[key] = value
      this.getEventCategoryOrProduct()
    },
  },
  async created() {
    try {
      this.artist_header_logo_image_url = localStorage.getItem('header_logo_image_url')
      await this.getCategoryGoodsPageData();
    } catch {
      this.$router.push({ name: '404' })
    }
  },
  watch: {
    cartCategory: {
      deep: true,
      handler(val) {
        if (val) {
          this.purchaseItem.qty = val.cart_category_total_qty
        }
      }
    }
  },
  computed: {
    ...mapState([
      'isMobile',
      'current_artist'
    ]),
    cartCategory() {
      return this.$store.state.cart.cartDatas.find(el => el.category_id == this.category.category_id)
    },
    cartProduct() {
      if (this.cartCategory) {
        return this.cartCategory.cart_products.find(el => el.sale_product_id == this.$route.params.category_id)
      }
      return null;
    },
    product_current_qty_in_cart() {
      if (this.cartCategory) {
        return this.cartCategory.cart_category_set_amount
      }
      return 0;
    },
    product_current_total_include_tax() {
      if (this.cartCategory) {
        return this.cartCategory.cart_category_subtotal_include_tax
      }
      return 0;
    },
    event_sale_period() {
      return this.category.event_sale_period_start_date + ' - ' + this.category.event_sale_period_end_date;
    },
    artist_notice_compute() {
      return this.artist_notice_open ? 'block' : 'none';
    },
    artist_page_url() {
      let router = this.$router.resolve({
        name: 'artist.home',
      });
      return router.href;
    },
    event_page_url() {
      let router = this.$router.resolve({
        name: 'event.page',
        params: {
          event_id: this.category.event_id
        }
      });
      return router.href;
    },
    titleTag() {
      return this.category ? this.category.category_name : null;
    },
  },
}
</script>
