<template>
  <BaseEvent
    :event="event"
    :event-id="eventId"
    :other-events="otherEvents"
    :filters="filters"
    :product-standard="productStandard"
    :sale-products="saleProducts"
    :categories="categories"
    :current-artist="current_artist"
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
    @filter-change="onFilterChange"
  />
</template>

<script>
import EventService from '@services/EventService'
import SaleProductService from '@/services/SaleProductService'
import EventTmpService from '@services/EventTmpService'
import MasterDataService from '@services/MasterDataService'
import { mapState, mapMutations } from "vuex";
import ArtistTmpService from "../../services/ArtistTmpService";

export default {
  components: {
    BaseEvent: () => import('@pages/event/base/Event'),
  },
  metaInfo() {
    return {
      title: this.titleTag,
    }
  },
  data() {
    return {
      filters: {
        event_id: null,
        type: 'printed_product',
        printed_product_standard_id: null,
        normal_product_standard_id: null,
      },
      event: {},
      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: [],
      categories: [],

      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',
      isMobile: false,
      otherEvents: [],
    }
  },
  async created() {
    await this.getEventPreview()
  },
  mounted() {

    MasterDataService.index({
      is_mobile: true,
    }).then((res) => {
      this.isMobile = res.data.is_mobile
    })

  },

  computed: {
    ...mapState([
      'current_artist'
    ]),
    titleTag() {
      return this.event ? this.event.event_name : null
    },
  },

  methods: {

    ...mapMutations([
      'SET_CURRENT_ARTIST'
    ]),

    getOtherEventByArtistId() {
      const currentArtist = this.$store.state.current_artist
      EventService.getEventByArtistId(currentArtist.id).then((res) => {
        if (res.data) {
          this.otherEvents = res.data.filter(el => el.id != this.eventId)
        }
      })
      .catch(e => console.error(e, "do Nothing"))
    },

    getEventCategoryOrProduct() {
      SaleProductService.saleProductRelationInCategory(this.filters).then(res => {
        switch (this.filters.type) {
          case 'normal':
            this.saleProducts = res.data
            break

          default:
            this.categories = res.data
            break
        }
      })
    },

    onDetailClick(routeName, item) {
      this.$router.push({name: routeName, params: {id: item.id}})
    },

    async getArtist (designatePageId) {
      try {
        const artist = await ArtistTmpService.getArtistByDesignatePage(designatePageId)
        this.SET_CURRENT_ARTIST(artist.data)
      } catch (e) {
        console.error(e)
      }
    },

    async getEventPreview() {

      const res = await EventTmpService.index({
        token: this.$route.params.token
      })

      const { artist_designate_page_directory } = res.data

      if (artist_designate_page_directory) {
        await this.getArtist(artist_designate_page_directory)
      }

      await this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden)

      if (res.data.is_msg_when_hidden) {

        this.isMsgHidden = true
        this.msgTitle = res.data.event_msg_when_hidden_title
        this.msgSubTitle = res.data.event_msg_when_hidden_date
        this.msgContent = res.data.event_msg_when_hidden

        this.$store.commit('SHOW_LAYOUT')

      } else {

        this.event = res.data
        this.eventId = res.data.event_id || null
        this.filters.event_id = this.eventId

        // this.$nextTick(() => {
        //   if (this.eventId) {
            // this.getEventCategoryOrProduct()
            // this.getOtherEventByArtistId()
            // this.getProductStandardByEvent()
        //   }
        // })
      }
    },

    onClickReturnToArtistTop() {
      this.$router.push({name: 'artist.home', params: {designate_page: this.event.artist_designate_page_directory}})
    },

    getProductStandardByEvent() {
      if (this.eventId) {
        EventService.getProductStandardByEvent(this.eventId).then(res => {
          const newData = {...this.productStandard, ...res.data}
          this.productStandard = newData
        })
      }
    },

    onFilterChange({ key, value }) {
      this.filters[key] = value
      this.getEventCategoryOrProduct()
    }
  },
}
</script>
<style scoped>
.event-category-checked {
  background-color: #000;
  color: #FFF;
}
</style>
