import Vue from 'vue';
import Constants from '@constants';
import store from '@/store/store'

export default function loginRequired({next, to}) {
  if (!Vue.$cookies.isKey(Constants.ACCESS_TOKEN)) {

    Vue.$cookies.remove(Constants.ACCESS_TOKEN);
    Vue.$cookies.remove(Constants.MEMBER);
    Vue.$cookies.remove(Constants.USER_PURCHASE_INFO)
    Vue.$cookies.remove(Constants.USER_PAYMENT_INFO)
    Vue.$cookies.remove(Constants.PURCHASE_STEP)
    store.commit('SET_IS_LOGIN', false)
    store.commit('REMOVE_USER_DATA')
    store.dispatch('getCartData');

    return next({
      name: 'auth.login',
      query: {redirect: to.fullPath}
    });
  }

  return next();
}
