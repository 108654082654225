<template>
  <div class="content content__inner referrer-page">
    <h1 class="logo">
      <router-link :to="logoLink">
        <img :src="logo">
      </router-link>
    </h1>
    <div class="description">
      <img :src="referrerText">
    </div>
  </div>
</template>

<script>
export default {
  name: "ReferrerLayout",
  props: {
    referrerText: {
      type: String,
      required: false,
      default: require('@assets/images/bromide_referrer_text.png')
    },
    logo: {
      type: String,
      required: false,
      default: require('@assets/images/bromide_logo_referrer.png')
    },
    logoLink: {
      type: String,
      required: false,
      default: '/'
    }
  }
};
</script>
