<template>
  <mail-registration-verification
    v-if="checked"
    :success="success"
    @to-artist="handleToArtist"
  />
</template>
<script>
import {mapActions} from 'vuex'
import MailRegistrationVerification
  from "@components/artist/ArtistConfirmation/MailRegistration/MailRegistrationVerification";
export default {
  components: {MailRegistrationVerification},
  data() {
    return {
      checked: false,
      success: false,
      designatePageId: null,
    }
  },
  computed: {},
  methods: {
    ...mapActions([
      'verifyEmailRegistration'
    ]),
    handleToArtist() {
      this.$router.push({
        name: 'artist.home',
        params: {
          designate_page: this.designatePageId
        }
      })
    }
  },
  async mounted() {
    const token = this.$route.params.token
    const designatePageId = this.$route.query.designate_page_directory
    this.designatePageId = designatePageId
    if (!designatePageId) {
      this.checked = true
      this.success = false
    } else {
      // トークン処理を実装
      try {
        await this.verifyEmailRegistration({ token, designatePageId })
        this.checked = true
        this.success = true
      } catch (e) {
        this.checked = true
        this.success = false
      }
    }
  },

}
</script>
<style lang="scss">

</style>
