export default [
  {
    path: "guide",
    name: "artist.static.guide",
    component: () => import("@pages/static/Guide"),
  },
  {
    path: "qa",
    name: "artist.static.qa",
    component: () => import("@pages/static/Qa"),
  },
  {
    path: "delivery",
    name: "artist.static.delivery",
    component: () => import("@pages/static/Delivery"),
  },
  {
    path: "privacy",
    name: "artist.static.privacy",
    component: () => import("@pages/static/Privacy"),
  },
  {
    path: "trading",
    name: "artist.static.trading",
    component: () => import("@pages/static/Trading"),
  },
  {
    path: "terms",
    name: "artist.static.terms",
    component: () => import("@pages/static/Terms"),
  },
  {
    path: ":url_slug",
    name: "artist.static_page",
    component: () => import("@pages/static/Static"),
  },
];
