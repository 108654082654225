export default [

  {
    path: "/guide",
    name: "static.guide",
    component: () => import("@pages/static/Guide"),
  },
  {
    path: "/qa",
    name: "static.qa",
    component: () => import("@pages/static/Qa"),
  },
  {
    path: "/delivery",
    name: "static.delivery",
    component: () => import("@pages/static/Delivery"),
  },
  {
    path: "/privacy",
    name: "static.privacy",
    component: () => import("@pages/static/Privacy"),
  },
  {
    path: "/trading",
    name: "static.trading",
    component: () => import("@pages/static/Trading"),
  },
  {
    path: "/terms",
    name: "static.terms",
    component: () => import("@pages/static/Terms"),
  },
];
