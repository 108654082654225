<template>
  <div class="bundled-category-footer-menu">

    <div class="bundled-category-footer-menu-status">
      <div class="bundled-category-footer-menu-set">
        <span>{{choosingSetNum}}</span>{{ ableToBy ? '選択完了' : 'セット目' }}
      </div>
      <div v-if="!ableToBy">
        <span>{{currentSetSelected}}/{{bundledUnit}}</span>選択中
        合計{{totalQuantity}}枚選択中
      </div>
      <div v-else>セットを追加する場合はさらに画像を選択してください</div>
    </div>

    <div>
      <div v-if="!ableToBy" class="bundled-category-footer-menu-remains">残り {{currentSetRemains}}枚</div>
      <router-link v-else class="bundled-category-footer-menu-btn" :to="toCart">購入</router-link>
    </div>

  </div>
</template>
<script>
export default {
  props: {
    cartData: {
      type: Object,
      required: true
    },
    toCart: {
      type: [ Object, String ],
      default: ''
    }
  },
  computed: {
    bundledUnit() {
      return parseInt(this.cartData.bundled_unit)
    },
    totalQuantity() {
      return parseInt(this.cartData.cart_category_total_qty)
    },
    currentSetNum() {
      return Math.floor(this.totalQuantity / this.bundledUnit)
    },
    choosingSetNum() {
      const counted = Math.ceil(this.totalQuantity / this.bundledUnit)
      return counted || 1  // 最低は1セット目
    },
    // 現在のセットが後何枚選択が必要かの数値
    currentSetSelected() {
      return this.totalQuantity - (this.currentSetNum * this.bundledUnit)
    },
    currentSetRemains() {
      return this.bundledUnit - this.currentSetSelected
    },
    // 購入可能かどうか
    ableToBy () {
      return this.currentSetRemains === this.bundledUnit && this.choosingSetNum >= 1
    }
  }
}
</script>
<style lang="scss">
@import './style';
</style>
