<template>
  <BaseStatic
    :static-page="staticPage"
  />
</template>

<script>
import StaticService from '@services/StaticService';

export default {
  components: {
    BaseStatic: () => import('@pages/static/base/Static'),
  },
  data() {
    return {
      staticPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      }
    }
  },
  created() {
    StaticService.getPrivacy().then(res => {
      this.staticPage = res.data
    }).catch(()=> {
      this.$router.replace({name: '404'})
    })
  },
}
</script>
