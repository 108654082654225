<template>
  <div>
    <h2 class="page-title"><span class="page-title--en">Purchase</span><span class="page-title--jp">購入手続き</span></h2>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <h3 class="section-title">{{ $t('purchase_fix.your_order_is_complete') }}</h3>
          <p class="mb-50">
            <span>ご注文の手続きが完了いたしました。<br class="mb-elem" />ありがとうございます。</span> <br>
            <span>注文受付メール、発送完了メールなどを<br class="mb-elem" />ご確認いただき、商品到着までお待ちください。</span>
          </p>
          <button class="btn-100" @click="gotoArtistTop()">
            {{ $t('purchase_fix.return_to_artist_top') }}
          </button>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import Constants from '@constants';

export default {
  created() {
    this.$cookies.remove(Constants.USER_PURCHASE_INFO)
    this.$cookies.remove(Constants.USER_PAYMENT_INFO)
    this.$cookies.remove(Constants.PURCHASE_STEP)
    this.$store.commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)
    this.$store.dispatch('getCartData')
  },
  methods: {
    gotoArtistTop() {
      this.$router.push({name: 'artist.home'})
    }
  }
}
</script>
