export default {
  'sales_period': '販売期間',
  'remaining_sale_day': '販売終了残り{remaining_days}日',
  'new': 'NEW',
  'end_of_sale_today': '本日販売終了',
  'return_to_artist_top': 'アーティストTOPに戻る',
  'return_to_event_top': 'イベントTOPに戻る',
  'selling_price': '販売価格',
  'tax_included': '税込',
  'sheet': '枚',
  'spec': 'スペック',
  'comment': 'コメント',
  'in_the_current_cart': '現在のカートの中',
  'set': 'セット',
  'see_the_cart': 'カートを見る',
  'buy_all_photos': '写真を全て購入する',
  'selected_set': '{set}枚の写真を選択しました',
  'proceed_to_card': 'カートに進んでください。',
  'selected_under_set': '{set}枚まで選択して、カートに進んでください',
  'selected_over_set': '{set}枚以上の写真が選択されています',
  'expansion_purchase_multiple_sheets': '拡大・複数枚購入',
  'id': 'ID:',
  'add_to_cart': 'カートに入れる',
  'number_of_sheets': '枚数',
  'relation': {
    'title': 'RELATION',
    'subtitle': '関連商品',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': '詳細はこちら'
  },
  'printed_set_modal_warning': {
    'close': '閉じる',
    'body': '印刷物は{sheet}枚まで追加してください。価格は設定金額で計算されます。',
    'out_of_stock': "商品の在庫が足りません"
  }
}