import BaseService from "./BaseService";

class ArtistBannerTmpService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }
}

export default new ArtistBannerTmpService('artist-banner-tmps');
