export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ',
    purchase_history: "購入履歴"
  },
  purchase_detail: "■購入明細",
  purchase_info: "※行をクリックすると詳細が閲覧できます。",
  purchase_detail_expired_info: "※商品画像は、<br class='s-elem' />「販売期間終了後、2週間程度」で<br class='s-elem' />削除されます。",
  purchase_detail_random_prepared_info: "※ランダム商品の内容は、<br class='s-elem' />商品到着から数日後に表示されます。",
  purchase_detail_info_2: "下記イベント名をクリックすると、ご購入いただいた商品の詳細がご確認いただけます。",
  sheet: '点',
  set: 'セット',
  id: "ID:",
  qty: '数量:',
  include_tax: "(税込)",
  event_being_display: "表示中のイベント",
  column: {
    purchase_number: "注文ID",
    order_date_and_time: "注文日時",
    payment_method: "お支払い方法",
    product_type: "商品カテゴリ",
    shipping_date: "発送日時",
    shipping_status: "ステータス",
    artist_name: "アーティスト名",
    event_name: "イベント名",
    quantity: "商品数量",
    total_amount_included_tax: "合計金額(税込)",
    subtotal: "小計",
    shipping_fee: "送料",
    payment_fee: "決済手数料",
    total_amount: "合計金額",
    total_qty: "数量",
    total: "金額",
    settlement_amount: '決済金額'
  },
  product_type: {
    goods: '物販',
    print: 'プリント商品',
    combined_product: '額装商品'
  },
  shipping_status: {
    not_send_order: '未発注',
    not_shipped: '未発送<br>(発注済みで未発送)',
    completed: '完了',
    // order_not_placed_to_supplier: '未発注',
    // not_shipped: '未発送(発注済みで未発送)',
    // payed_cancellation: '有料キャンセル',
    // free_cancellation: '無料キャンセル',
    // complete: '完了',
    // return: '返品',
    // error: 'エラー',
    // capture_actual_paid: '実売上',
    // capture_actual_paid_failed: '実売上失敗',
    // cancel: 'キャンセル',
    // not_delivered: '未納品 (未発注で発注期限(3日)を過ぎたもの)'
  },
  order_status: {
    unapproved: "未承認",
    approved: "承認済み",
    on_hold: "保留",
    waiting_for_payment: "入金待ち",
    completed: "完了",
    cancelled: "キャンセル",
    blacklist: "ブラックリスト"
  },
  payment_method_type: {
    credit: "クレジット決済",
    carrier: "モバイル決済",
    convenience_store: "コンビニ決済",
    seven_eleven_convenience_store: "セブンイレブン",
    lawson_convenience_store: "ローソン(スリーエフ含む)",
    family_mart_convenience_store: "ファミリーマート(サンクス、サークルK含む)",
    docomo_carrier: "ドコモキャリア決済",
    softbank_carrier: "ソフトバンクキャリア決済",
    au_easy: "Au簡単支払い",
    pay_later: "後払い",
    mini_stop_convenience_store: "ミニストップ",
    seiko_mart_convenience_store: "セイコーマート"
    // credit_settlement: "クレジット決済",
    // seven_eleven: "セブンイレブン",
    // lawson: "ローソン(スリーエフ含む)",
    // family_mart: "ファミリーマート(サンクス、サークルK含む)",
    // mobile_payment: "モバイル決済",
    // convenience_store_settlement: "コンビニ決済",
    // docomo_carrier_payment: "ドコモキャリア決済",
    // softbank_carrier_settlement: "ソフトバンクキャリア決済",
    // au_easy_payment: "Au簡単支払い",
    // pay_later: "後払い",
    // ministop: "ミニストップ",
    // seiko_mart: "セイコーマート"
  }
}
