export default {
  state: {
    is_msg_when_hidden: false
  },
  mutations: {
    SET_MSG_WHEN_HIDDEN_DATA(state, payload) {
      state.is_msg_when_hidden = payload
    }
  },
  getters: {
    getIsMsgWhenHidden(state) {
      return state.is_msg_when_hidden;
    },
  },
  actions: {
    setIsMsgWhenHidden({commit}, payload) {
      commit('SET_MSG_WHEN_HIDDEN_DATA', payload);
    },
  }
}