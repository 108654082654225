<template>
  <article class="hidden-content pt-30">
    <div class="hidden-content__header content">
      <div class="content__inner">

        <h2 ref="hiddenTitle" class="page-title">
          <keep-proportion-content
            :proportion-target="() => $refs.hiddenTitle"
            class="page-title--msg-title"
            :content="title"
            :p-width="960"
            :p-sp-width="360"
            :gap="40"
            :gap-sp="40"
            blank-to-br
          />

          <div class="page-title--msg-date" v-html="$sanitize(subtitle)" />
        </h2>
      </div>
    </div>

    <section class="hidden-content__description content">
      <div ref="hiddenContent" class="content__inner">
        <keep-proportion-content
          :proportion-target="() => $refs.hiddenContent"
          :content="content"
          :p-width="1000"
          :p-sp-width="380"
          :gap="0"
          :gap-sp="0"
        />

        <!--        <section v-if="!isLoggedIn" class="hidden-content__description&#45;&#45;info">-->
        <!--          <div-->
        <!--            v-if="!is_mobile"-->
        <!--            v-html="$sanitize($t('message_when_hidden.label.info_pc'))"-->
        <!--          ></div>-->
        <!--          <div v-else v-html="$sanitize($t('message_when_hidden.label.info_sp'))"></div>-->
        <!--        </section>-->
      </div>
    </section>
  </article>
</template>

<script>
import KeepProportionContent from './KeepProportionContent'

export default {
  name: 'MessageWhenHidden',
  components: {
    KeepProportionContent
  },
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
    content: {
      type: String,
      required: true,
      default: '',
    },
    is_mobile: {
      type: Boolean,
      required: true
    },
    isLoggedIn: {
      type: Boolean,
      default: false
    }
  }
}
</script>
