export default {
  'sales_period': '販売期間',
  'new': 'NEW',
  'return_to_artist_top': 'アーティストTOPに戻る',
  'return_to_event_top': 'イベントTOPに戻る',
  'info': 'INFO',
  'limited_merchandise': '限定商品',
  'selling_price': '販売価格',
  'tax_included': '税込',
  'piece': '個',
  'about_the_product': '商品について',
  'spec': 'スペック',
  'quantity': '数量',
  'stock_quantity': '在庫数',
  'set': 'セット',
  'add_product_to_cart': '商品をカートに追加',
  'in_the_current_cart': '現在のカートの中',
  'see_the_cart': 'カートを見る',
  'relation': {
    'title': 'RELATION',
    'subtitle': '関連商品',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': 'Click here for details'
  }
}
