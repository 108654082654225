const getters = {
  currentArtist: (state) => state.currentArtist,
  artistDesignatePageDirectory: (state) => {
    if (state.current_artist && state.current_artist.designate_page_directory) {
      return state.current_artist.designate_page_directory
    }
    return ''
  },
  artistId: (state) => {
    if (state.current_artist && state.current_artist.id) {
      return state.current_artist.id
    }
    return ''
  }
}

export default getters
