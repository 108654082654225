import $ from "jquery";

$(function () {
  document.ondragstart = function () {
    return false;
  };
  document.onselectstart = function () {
    return false;
  };
  document.body.ondragstart = "return false;";
  document.body.onselectstart = "return false;";
  document.oncontextmenu = function () {
    return false;
  };
  document.body.oncontextmenu = "return false;";
});