<template>
  <validation-observer ref="observer" class="content__inner" tag="div">
    <Headline :title="$t('registration.title.attention')"></Headline>
    <div class="attention mb-20">
      <div class="attention__title mb-10">
        <div class="attention__image"><img alt="注意" src="@assets/images/icon_attention.svg"></div>
        <div class="attention__title__text" v-html="$sanitize($t('registration.label.first_content_title'))"></div>
      </div>
      <div class="attention__text" ref="domain">
        <keep-proportion-content
          :proportion-target="() => $refs.domain"
          :p-sp-width="380"
          :gap-sp="0"
          :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
        >
          <p class="bold mb-2" v-html="$sanitize($t('registration.label.first_content_1'))"></p>
          <br>
          <p class="mb-10" v-html="$sanitize($t('registration.label.first_content_2'))"></p>
          <div class='form-text--100 mb-30'><span class='large bold'>@bromide.jp</span></div>
          <p v-html="$sanitize($t('registration.label.first_content_3'))"></p>
        </keep-proportion-content>
      </div>
    </div>
    <validation-provider ref="e1" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
      <label class="check">
        <input v-model="email_1_checked" :name="$t('registration.label.attention_check')" type="checkbox"
               @input="resetValidate(0)">
        <span class="check--dummy"></span>
        <span v-t="'registration.label.attention_check'" class="check--text"></span>
      </label>
      <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
    </validation-provider>

    <div class="attention mb-20">
      <div class="attention__title mb-10">
        <div class="attention__image"><img alt="注意" src="@assets/images/icon_attention.svg"></div>
        <div class="attention__title__text" v-html="$sanitize($t('registration.label.second_content_title'))"></div>
      </div>
      <div class="attention__text" ref="convenienceStore">
        <keep-proportion-content
          :proportion-target="() => $refs.convenienceStore"
          :p-sp-width="380"
          :gap-sp="0"
          :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
        >
          <p class="mb-10" v-html="$sanitize($t('registration.label.second_content_1'))"></p>
          <div class='form-text--100 mb-20'><span class='large bold'>system@p01.mul-pay.com</span></div>
          <p class="bold mb-10" v-html="$sanitize($t('registration.label.second_content_2_title'))"></p>
          <p v-html="$sanitize($t('registration.label.second_content_2'))"></p>
        </keep-proportion-content>
      </div>
    </div>
    <validation-provider ref="e2" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
      <label class="check">
        <input v-model="email_2_checked" :name="$t('registration.label.attention_check')" type="checkbox"
               @input="resetValidate(1)">
        <span class="check--dummy"></span>
        <span v-t="'registration.label.attention_check'" class="check--text"></span>
      </label>
      <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
    </validation-provider>

    <p class="al-c mb-10" v-html="$sanitize($t('registration.label.attention_confirmation'))"></p>
    <Button :title="$t('registration.btn.member_registration')" isPrimary @onClick="agree"></Button>
  </validation-observer>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";

export default {
  name: "RegisterAttention",
  data() {
    return {
      term_of_service: "",
      checkText: 'registration.label.term',
      email_1_checked: false,
      email_2_checked: false
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    async agree() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }
      this.$emit('clickNext');
    },
    resetValidate(iCheckBox) {
      switch (iCheckBox) {
        case 0:
          this.$refs.e1.validate();
          break;
        case 1:
          this.$refs.e2.validate();
          break;
      }
    },
  },
  components: {
    KeepProportionContent,
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button'),
  }
}
</script>
