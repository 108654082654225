<template>
  <div v-if="show" class="pre-cart-wrapper">

    <div class="pre-cart-action-fixed">
      <div class="pre-cart-action-wrapper">
        <pre-cart-actions
          class="pre-cart-action-wrapper-content"
          @rest-shopping="handleShowRestShopping"
          @restore-cart="handleShowRestoreCart"
        />
      </div>
    </div>

    <rest-shopping-modal
      :pub-key="pubKey"
      :is-show-modal="showRestShopping"
      @publish-key="handlePublishKey"
      @close="handleCloseRestShoppingModal"
    />

    <restore-cart-modal
      :is-show-modal="showRestoreCart"
      :error="error"
      @close="showRestoreCart = false"
      @restore="handleRestoreCart"
    />

  </div>
</template>
<script>

export default {
  components: {
    RestoreCartModal: () => import('@components/cart/PreCart/RestoreCartModal'),
    RestShoppingModal: () => import('@components/cart/PreCart/RestShoppingModal'),
    PreCartActions: () => import('@components/cart/PreCart/PreCartActions'),
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    onPublishKey: {
      type: Function,
      default: async () => ''
    },
    onRestoreCart: {
      type: Function,
      default: async () => ''
    }
  },
  data() {
    return {
      showRestShopping: false,
      showRestoreCart: false,
      error: null,
      pubKey: '',
    }
  },
  computed: {
  },
  methods: {
    handleShowRestShopping() {
      this.showRestShopping = true
    },
    handleShowRestoreCart() {
      this.showRestoreCart = true
    },
    handleCloseRestShoppingModal() {
      this.showRestShopping = false
      if (this.pubKey) {
        this.pubKey = ''
      }
    },
    async handlePublishKey(data) {
      try {
        this.error = null
        const response = await this.onPublishKey(data)
        this.pubKey = response.data.token
      } catch (e) {
        console.error(e)
        if (e.response && e.response.error.status === 422) {
          this.error = e.response.data.data
        }
      }
    },
    async handleRestoreCart(pubKey) {
      try {

        this.error = null
        await this.onRestoreCart(pubKey)
        this.showRestoreCart = false

      } catch (e) {
        console.error(e)
        this.error = 'カートの選定途中データの取得ができませんでした。<br />キーが存在しないか、データの有効期限が過ぎている可能性があります。'
      }
    },
  }
}
</script>
<style lang="scss">

.pre-cart-wrapper {
  width: 100%;
  max-width: 400px;

  @media screen and (max-width: 767px) {
    max-width: 767px;
  }
}


.pre-cart-action-fixed {
  //position: fixed;
  //bottom: 16px;
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;

}

.pre-cart-action-wrapper {
  width: 1000px;
  box-sizing: border-box;
  flex: 1;
}
</style>
