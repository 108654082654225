<template>
  <section ref="item" :class="['content', contentClass]">
    <div class="content__inner">
      <div class="information">
        <div
          :class="
            isActive
              ? 'js-accordion-title information__heading open'
              : 'js-accordion-title information__heading'
          "
          @click="isActive = !isActive"
        >
          <span class="information__type font-en">
            <span>{{ noticeText }}</span>
          </span>
          <div class="information__title">
            <keep-proportion-content
              v-if="title"
              class="information__title--date font-en"
              :gap-sp="105"
              :content="title"
            />
            <keep-proportion-content
              class="content__inner--editor information__subtitle"
              :content="subTitle"
              :p-sp-width="500"
              :proportion-target="() => $refs.item"
            />
          </div>
        </div>
        <div
          v-if="isActive && description != ''"
          class="information__detail content__inner--editor"
        >
          <keep-proportion-content
            :p-width="950"
            :gap-sp="40"
            :gap="70"
            :content="description"
          />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  name: 'Accordion',
  components: {KeepProportionContent},
  props: {
    contentClass: {
      type: String,
      require: false,
      default: '',
    },
    active: {
      type: Boolean,
      require: false,
      default: false,
    },
    noticeText: {
      type: String,
      require: false,
      default: '',
    },
    title: {
      type: String,
      require: false,
      default: '',
    },
    subTitle: {
      type: String,
      require: false,
      default: '',
    },
    description: {
      type: String,
      require: false,
      default: '',
    },
  },
  data() {
    return {
      isActive: this.active,
    }
  },
}
</script>
