<template>
  <div class="with-register-modal">
    <Modal :modal="isShowModal" width="700px" show-close @close="handleCancel">
      <template #body>
        <validation-observer ref="observer">

          <div ref="scrollContent" class="with-register-modal-content content__inner--editor" v-html="$sanitize(content)"></div>

          <div class="with-register-modal-check al-c mb-20">
            <validation-provider v-slot="{ errors }" rules="required|checkbox">
              <label class="check">
                <input
                  v-model="entity.with_registration"
                  :name="$t('registration.label.term')"
                  type="checkbox"
                  :disabled="!isScrollBottom"
                  @input="resetValidate"
                />

                <span :class="['check--dummy', {'check--dummy__disabled': !isScrollBottom}]"></span>

                <span v-t="checkText" :class="['check--text', { 'check--text__disabled': !isScrollBottom}]"></span>
              </label>

              <div v-if="errors[0]" class="c-red mt-10">必須確認項目です</div>
            </validation-provider>
          </div>

          <div v-if="isError">
            <ErrorAlert
              v-for="message in errorMessages"
              :key="message"
              class="mb-20"
            >
              {{ message }}
            </ErrorAlert>
          </div>

          <dl class="common-dl" v-if="entity.with_registration">
            <dt v-html="$sanitize($t('registration.input.password'))"></dt>

            <dd>
              <validation-provider
                v-slot="{ errors }"
                :rules="{ min:8,max:32,regex: /^([a-zA-Z0-9#?!@$%^&*_-]*)$/ }"
                :vid="$t('mypage_edit.basic_information.please_enter_your_password')"
              >
                <div class="form-password registration--from__password">
                  <input
                    id="password"
                    v-model="entity.password"
                    :name="$t('registration.label.password')"
                    :placeholder="$t('registration.placeholder.password')"
                    class="form-text--100 form-password__input registration--from__password__input"
                    required
                    :type="is_visible_password ? 'text' : 'password'"
                  >

                  <div class="form-password__append">
                    <IconEye
                      v-if="is_visible_password"
                      class="form-password__append__icon"
                      @click.stop="() => is_visible_password = !is_visible_password"
                    />

                    <IconEyeSlash
                      v-else
                      class="form-password__append__icon"
                      @click.stop="() => is_visible_password = !is_visible_password"
                    />
                  </div>
                </div>

                <span v-if="errors[0]" class="validate-error c-red">
                  {{ errors[0] }}
                </span>
              </validation-provider>

              <p v-t="'registration.label.password_hint'" class="small"></p>

              <validation-provider
                v-slot="{ errors }"
                :rules="`min:8|max:32|confirmed:${$t('mypage_edit.basic_information.please_enter_your_password')}`"
                :vid="$t('mypage_edit.basic_information.repeat_password')"
              >
                <div class="form-password registration--from__password">
                  <input
                    id="password_confirmation"
                    v-model="entity.password_confirmation"
                    :name="$t('registration.label.password_confirm')"
                    :placeholder="$t('registration.placeholder.password')"
                    class="form-text--100 form-password__input registration--from__password__input"
                    required
                    :type="is_visible_password__confirmation ? 'text' : 'password'"
                  >

                  <div class="form-password__append">
                    <IconEye
                      v-if="is_visible_password__confirmation"
                      class="form-password__append__icon"
                      @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                    />

                    <IconEyeSlash
                      v-else
                      class="form-password__append__icon"
                      @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                    />
                  </div>
                </div>

                <span v-if="errors[0]" class="validate-error c-red">
                  {{ errors[0] }}
                </span>
              </validation-provider>
            </dd>
          </dl>

          <Button
            :title="$t('registration.btn.confirm')"
            isPrimary
            @onClick="onConfirm"
          />

          <div class="al-c mt-10">
            <span
              v-t="'registration.btn.not_agree'"
              class="link-line"
              @click="handleCancel"
            ></span>
          </div>
        </validation-observer>
      </template>
    </Modal>
  </div>
</template>

<script>
import StaticService from "@services/StaticService";

export default {
  name: "WithRegisterModal",
  components: {
    Modal: () => import('@components/base/Modal'),
    Button: () => import('@components/base/Button'),
    ErrorAlert: () => import("@components/base/ErrorAlert"),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
  },
  props: {
    entity: {
      required: true
    },
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      isError: false,
      errorMessages: "",
      content: "",
      checkText: "registration.label.term",
      sub_title: "",
      isScrollBottom: false,
      is_visible_password: false,
      is_visible_password__confirmation: false,
    }
  },
  mounted() {
    this.$nextTick(function (){
      setTimeout(() => {
        if (this.$refs.scrollContent.querySelector('.div-scroll')) {
          this.$refs.scrollContent.querySelector('.div-scroll').addEventListener('scroll', (evt) => {
            if (!this.isScrollBottom) {
              this.isScrollBottom = ( evt.target.scrollHeight - ( evt.target.scrollTop + evt.target.clientHeight)) < 100
            }
          })
        } else {
          this.isScrollBottom = true
        }
      }, 2000)
    })
  },
  computed: {
    translatedEntity() {
      const {zip_code, ..._entity} = this.entity
      // const dates = date_of_birth.split('-')
      // const [birthday_year, birthday_month, birthday_day] = dates
      return {
        ..._entity,
        postal_code: zip_code
      }
    },
  },
  methods: {
    async onConfirm() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }
      if (this.entity.with_registration) {
        this.$emit('register-confirm');
      }
    },
    handleCancel() {
      this.entity.with_registration = false
      this.$emit('register-cancel')
    },
    resetValidate() {
      this.$refs.observer.reset();
    },
  },
  async created() {
    await StaticService.getRegisterTerm().then((res) => {
      this.content = res.data.main_text;
    });
    window.document.body.style.overflow = 'hidden'
  },
  destroyed() {
    window.document.body.style.overflow = 'auto'
  }
}
</script>

<style lang="scss">
@import '../../../assets/scss/common/variables';
.with-register-modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
  }
  .modal-body {
    margin: 0;
  }
}

.with-register-modal-content {
  min-height: 50vh;
  & [class^='div-scroll'] {
    height: 50vh;
  }
}
.with-register-modal-check {
  margin-top: -30px;
}
</style>
