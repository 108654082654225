import BaseService from "./BaseService";

class CategoryTmpService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  categoryPage(data) {
    // console.log("categoryPage::::", data)
    return this.performRequest(BaseService.METHOD_GET, '', data);
  }

  categoryGoodsPage(data) {
    return this.performRequest(BaseService.METHOD_GET, `goods-pages`, data);
  }
}

export default new CategoryTmpService('category-tmps');
