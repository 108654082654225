export default {
  credit_card_information_input: "Credit card information input",
  please_enter_your_card_information: "Please enter your card information according to the following items.",
  is_a_required_item: "Is a required item.",
  accepted_credit_cards: "Accepted credit cards",
  credit_card_information: "Credit card information",
  card_number: "Card number",
  nominee: "Nominee",
  security_code: "Security code",
  what_is_a_security_code_1: "What is a security code?",
  expiration_date: "expiration date",
  confirmation_of_your_order: "Confirmation of your order",
  to_fix: "To fix",
  what_is_a_security_code: "What is a security code?",
  refers_to_the_last_3_or_4_digits: "Refers to the last 3 or 4 digits of the number printed on the back of your credit card, at the top of your signature line.",
  some_credit_cards_are_listed_on_the_surface: "(Some credit cards are listed on the surface)",
  if_you_do_not_know_the_security_code_or_it_is_not_printed: "If you do not know the security code or it is not printed, please contact the card issuer (contact information on the back of the card).",
  month: "Month/",
  credit_card_is_invalid: "Credit card is invalid"
}