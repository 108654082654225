<template>
  <BaseCategory
    ref="top_category"
    :category="category"

    :total_qty="totalQty"
    :real-total-qty="realTotalQty"
    :sub_total="subTotal"
    :sets_amount="setsAmount"
    :just-qty-as-set-amount="justQtyAsSetAmount"
    :unit-name="unitName"

    :sale-products="saleProducts"

    :cart-category="cartCategory"

    :current-artist="current_artist"

    :is-mobile="isMobile"
    :is-phone="isPhone"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"

    :except_category_id="$route.params.category_id"
    :product-standard="productStandard"

    :other_events="other_events"
    :sale_product_purchase_popup="sale_product_purchase_popup"
    :is-extra-small-device="isExtraSmallDevice"
    :selected-sale-product="selectedSaleProduct"

    :carousel-setting="CarouselSetting"
  />
</template>
<script>
import EventService from '@services/EventService';
import MasterDataService from '@services/MasterDataService';
import SaleProductService from '@/services/SaleProductService';
import CategoryTmpService from '@/services/CategoryTmpService';
import '@assets/scss/plugins/lity.css';
import BaseCategory from "@pages/category/base/Category"
import { mapState, mapMutations } from "vuex";
import ArtistService from "@/services/ArtistService";
import CarouselSetting from "../../components/category/CarouselSetting";

export default {
  metaInfo() {
    return {
      title: this.titleTag
    }
  },
  data() {
    return {
      artist_header_logo_image_url: '',
      category: {
        event_id: ''
      },

      sale_product_purchase_popup: {},
      artist_notice_open: false,
      category_id: '',
      selectedSaleProduct: null,
      imageStyle: '',
      window: {
        width: 0,
        height: 0
      },

      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',
      isMobile: false,

      filters: {
        event_id: '',
        type: 'printed_product',
        printed_product_standard_id: null,
        normal_product_standard_id: null,
      },
      productStandard: {
        normal: [],
        printed: []
      },
      saleProducts: {
        data: [],
        meta: {
          pagination: {
            current: 1,
            last: '',
            per: '',
            from: '',
            to: '',
            count: '',
            total: ''
          }
        }
      },
      other_events: [],

      CarouselSetting

    }
  },

  methods: {
    ...mapMutations([
      'SET_CURRENT_ARTIST'
    ]),
    getSaleProductByCategoryId(category_id, page = 1, scrollIntoView = false) {
      //set page in meta
      this.saleProducts.meta.pagination.current = page;
      SaleProductService.saleProductByCategoryId(category_id, {
        page: page
      }).then(res => {
        this.saleProducts = res;
        if (scrollIntoView) {
          document.getElementById('first-pagination').scrollIntoView({behavior: 'smooth'});
        }
      });
    },

    getOtherEventByArtistId() {
      const currentArtists = this.current_artist;
      EventService.getEventByArtistId(currentArtists.id).then((res) => {
        if (res.data) {
          this.other_events = res.data.filter(el => el.id != this.category.event_id);
        }
      });
    },

    getCategoryData() {
      CategoryTmpService.categoryPage({
        token: this.$route.params.token
      })
        .then(res => {

          this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden);
          if (res.data.is_msg_when_hidden) {

            this.isMsgHidden = true;
            this.msgTitle = res.data.category_msg_when_hidden_title;
            this.msgSubTitle = res.data.category_msg_when_hidden_date;
            this.msgContent = res.data.category_msg_when_hidden_content;
            this.$store.commit('SHOW_LAYOUT');

          } else {

            this.category = res.data;
            this.category_id = res.data.id || ''
            if (this.category_id) {
              this.getSaleProductByCategoryId(this.category_id);
            }

            ArtistService.getArtistByDesignatePage(this.category.artist_designate_page_directory)
              .then((res) => {
                this.SET_CURRENT_ARTIST(res.data)
              })

          }

          this.getOtherEventByArtistId();
        });
    },

    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },

    getMasterData() {
      MasterDataService.index({
        is_mobile: true
      }).then(res => {
        this.isMobile = res.data.is_mobile
      });
    },

    toTop() {
      this.$router.push({name: 'top'}).catch(() => {
        this.$router.go()
      })
    },
  },

  created() {

    window.addEventListener('resize', this.handleResize);

    this.handleResize();
    this.getCategoryData();
    this.getMasterData();

    let newWidth = window.innerWidth - 40;

    if (newWidth <= 767) {
      let defaultWidth = 170;
      let defaultHeight = 121.28;
      newWidth = newWidth / 2;
      let newHeight = newWidth * defaultHeight / defaultWidth;
      this.imageStyle = 'padding-top:0;width:' + newWidth + 'px;height:' + newHeight + 'px;';
    }
  },

  computed: {

    ...mapState([
      'current_artist'
    ]),
    isPhone() {
      return !(this.window.width > 767);
    },
    isExtraSmallDevice() {
      return !(this.window.width > 576);
    },
    //find cart category in current page
    cartCategory() {
      return this.$store.state.cart.cartDatas.find(el => el.category_id == this.$route.params.category_id)
    },
    totalQty() {
      return this.cartCategory ? this.cartCategory.cart_category_total_qty : 0;
    },
    /**
     * 現在選択中のセット数
     * 5枚/セットのカテゴリで7枚選択中であれば2セット選択中となる
     *
     * @returns {*|number}
     */
    setsAmount() {
      return this.cartCategory ? this.cartCategory.cart_category_set_amount : 0;
    },

    realTotalQty() {
      if (this.category.print_sale_plan_type === 'bundled_random' || this.category.print_sale_plan_type === 'gacha') {
        return this.totalQty * this.category.bundled_unit
      }

      return this.totalQty
    },
    subTotal() {
      if (!this.cartCategory) return 0

      if (this.cartCategory.print_sale_plan_type === 'bundled') {
        const unit_price = this.cartCategory.cart_products ? this.cartCategory.cart_products[0].unit_price : 0
        const bundled_unit = parseInt(this.cartCategory.bundled_unit)
        if (unit_price === 0 || Number.isNaN(bundled_unit)) return 0
        const bundled_price = unit_price * bundled_unit
        const result = bundled_price * this.setsAmount
        return Math.ceil(result)

      } else {
        return this.cartCategory.cart_category_subtotal_include_tax
      }
    },
    /**
     * 選択枚数の合計がセット枚数の倍数となっており、購入可能かどうか
     * 5枚/セットのカテゴリで、今選択している枚数が5の倍数枚なら購入可能
     *
     * @returns {boolean}
     */
    justQtyAsSetAmount() {
      return (this.totalQty % this.category.purchasable_unit) === 0
    },

    unitName() {
      if (this.category.print_sale_plan_type === 'bundled_random' || this.category.print_sale_plan_type === 'gacha') {
        return 'セット'
      }

      return '枚'
    },

    titleTag() {
      return this.category ? this.category.category_name : null;
    },

  },
  components: {
    BaseCategory,
  }
}
</script>
