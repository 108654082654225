<template>
  <validation-observer ref="observer">
    <article>
      <Title
        :subtitle="isEventListAttention ? label.ATTENTION_TITLE_JP : '利用規約'"
        :title="isEventListAttention ? label.ATTENTION_TITLE_EN : 'Term of service'"
      />

      <section class="content pb-120">
        <div class="content__inner">
          <section class="mb-50">
            <h3 class="section-title" v-html="$sanitize(`必ずお読みいただき、確認後、<br class='s-elem'/>商品閲覧、購入へお進みください。`)"/>

            <div class="mb-50">
              <div
                v-if="content.type === 'display_image' && content.image"
                class="event-main__photo"
              >
                <img
                  :src="content.image.url"
                  :alt="content.image.filename"
                >
              </div>

              <div v-else ref="content" class="content__inner--editor term-agreement">
                <img width="1200" class="event-info-image" v-if="eventInfo" :src="eventInfo" alt="" />
                <keep-proportion-content
                  :proportion-target="() => $refs.content"
                  :content="content.description"
                  :p-width="1000"
                  :p-sp-width="360"
                  :gap="0"
                  :gap-sp="0"
                />
              </div>
            </div>

            <validation-provider v-slot="{errors}" class="al-c mb-30" rules="required|checkbox" tag="div">
              <label class="check">
                <input
                  v-model="term_service"
                  name="上記の内容を確認しました"
                  type="checkbox"
                  @input="resetValidate"
                />
                <span class="check--dummy"></span>
                <span class="check--text">上記の内容を確認しました</span>
              </label>

              <div v-if="errors[0]" class="validate-error c-red">必須確認項目です</div>
            </validation-provider>

            <Button
              :isPrimary="true"
              :title="isEventListAttention ? '注意事項を確認しました' : `${label.ARTIST_LABEL}ページへ`"
              @onClick="agreeTermService"
            />

            <div v-if="isEventListAttention" class="al-c mt-10">
              <a class="link-line cursor" @click="notAgree">{{label.ARTIST_LABEL}}ページに戻る</a>
            </div>
          </section>
        </div>
      </section>
    </article>
  </validation-observer>
</template>

<script>
import KeepProportionContent from "../../base/KeepProportionContent";
import AuditionModeSwitcherMixin from "../../../helpers/auditionModeSwitcher.mixin";
import forBotMixins from "../../event/forBot.mixins";

export default {
  mixins: [ AuditionModeSwitcherMixin, forBotMixins ],
  props: {
    content: {
      type: Object,
      default: () => ({ type: 'not_display', description: '', image: null }),
    },
    isEventListAttention: {
      type: Boolean,
      default: false
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
  components: {
    KeepProportionContent,
    Title: () => import('@components/base/Title'),
    Button: () => import('@components/base/Button'),
  },
  mounted() {
    this.$nextTick(function (){
      window.scrollTo(0,0)
    })
  },
  data() {
    return {
      term_service: null,
    }
  },
  methods: {
    resetValidate() {
      this.$refs.observer.reset()
    },
    async agreeTermService() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return
      }

      if (this.term_service) {
        this.$emit('agree-click')
        window.scrollTo(0,0)
      }
    },
    notAgree() {
      this.$emit('not-agree-click')
    },
  },
}
</script>
