export default {
  title: {
    main: 'Reship',
    main_jp: '再発送手続き',
  },
  about_reshipping_procedure: '再発送手続きについて',
  content: "日頃より、Bromide.jpをご利用いただきまして誠にありがとうございます。<br>この度はお届け先が不明のため商品が返送されてまいりました。<br>メールに記載されております「再発送用のキー」をご入力くださいませ。",
  key: '再発送用キー',

  btn: {
    reshipping_procedure: '再発送手続きへ'
  }
}
