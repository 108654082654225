import Vue from 'vue';
import Constants from '@constants';
import AuthService from '@services/AuthService';

const auth = {
  state: {
    member: null,
  },
  mutations: {
    SAVE_USER_DATA(state, data) {
      state.member = data.member;
      const expiresIn = data.expires_in;
      Vue.$cookies.set(Constants.MEMBER, JSON.stringify(data.member), `${expiresIn}min`);
      Vue.$cookies.set(Constants.ACCESS_TOKEN, data.access_token, `${expiresIn}min`);
    },
    SET_USER_DATA(state) {
      state.member = Vue.$cookies.get(Constants.MEMBER);
    },
    REMOVE_USER_DATA(state) {
      state.member = null
    }
  },
  actions: {
    login({commit}, credentials) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)
      return AuthService.login(credentials).then(({data}) => {
        commit('SAVE_USER_DATA', data)
        commit('SET_IS_LOGIN', true);
      });
    },
    logout({commit, dispatch}) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)
      return AuthService.logout().then(() => {
        Vue.$cookies.remove(Constants.ACCESS_TOKEN);
        Vue.$cookies.remove(Constants.MEMBER);
        Vue.$cookies.remove(Constants.USER_PURCHASE_INFO)
        Vue.$cookies.remove(Constants.USER_PAYMENT_INFO)
        Vue.$cookies.remove(Constants.PURCHASE_STEP)
        commit('SET_IS_LOGIN', false)
        commit('REMOVE_USER_DATA', false)
        dispatch('getCartData');

        return this.state.current_artist ? true : false;
      });
    }
  },
};

export default auth;
