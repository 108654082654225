<template>
  <div class="choose_frame_modal">
    <Modal :modal="isShowModal" width="700px">
      <template #body>
        <div class="choose_frame_container">

          <div class="choose_frame_top">

            <div class="choose_frame_top_header">
              <div class="choose_frame_top_header_title">選択した写真</div>
              <a
                class="choose_frame_top_header_button"
                @click="handleClose"
              >閉じる</a>
            </div>

            <div class="choose_frame_sale_product_image">
              <img
                v-if="sale_product"
                :src="sale_product.main_image_url"
              />
            </div>


          </div>

          <div class="choose_frame_main">

            <div class="choose_frame_main_title">以下のフレームが選択されています</div>

            <div class="frame_slider_container" v-show="currentCommodity">

              <span
              class="frame_slider_arrow_left_wrap"
              @click="handlePrev"
            >
              <img
                v-if="enablePrev"
                :src="require('@assets/images/arw-left.svg')"
                class="frame_slider_arrow_left"
                alt="<"
              />
            </span>

              <div class="choose_frame_sale_product_image">
                <img
                  v-if="currentCommodity.sample_images.length"
                  :src="currentCommodity.sample_images[0].url"
                  draggable="false"
                />
              </div>

              <span
                class="frame_slider_arrow_right_wrap"
                @click="handleNext"
              >
              <img
                v-if="enableNext"
                :src="require('@assets/images/arw-right.svg')"
                class="frame_slider_arrow_right"
                alt=">"
              />
              </span>

            </div>

            <div class="choose_frame_main_commodity_name">{{ currentCommodity.commodity_name }}</div>

            <div
              class="choose_frame_main_button"
              @click="handleChoseClick"
            >
              画像のフレームを選択する
            </div>

          </div>



        </div>
      </template>
    </Modal>
  </div>
</template>

<script>

export default {
  name: "CategoryCombinedChooseFrameModal",
  components: {
    Modal: () => import('@components/base/Modal'),
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    },
    sale_product: {
      type: Object,
    },
    subCommodities: {
      type: Array,
      default: () => []
    },

  },
  data() {
    return {
      currentCommodityIndex: 0,
    }
  },
  computed: {
    currentCommodity() {
      if (this.subCommodities.length < 0) return null
      return this.subCommodities[this.currentCommodityIndex]
    },
    enablePrev() {
      return this.currentCommodityIndex > 0
    },
    enableNext() {
      return this.currentCommodityIndex + 1 < this.subCommodities.length
    },
  },
  methods: {
    handlePrev() {
      if (this.enablePrev) this.currentCommodityIndex = this.currentCommodityIndex - 1
    },
    handleNext() {
      if (this.enableNext) this.currentCommodityIndex = this.currentCommodityIndex + 1
    },
    handleClose() {
      this.currentCommodityIndex = 0
      this.$emit('close')
    },
    handleChoseClick() {
      const targetSubCommodity = this.subCommodities[this.currentCommodityIndex]
      this.currentCommodityIndex = 0
      if (targetSubCommodity) {
        this.$emit('choose-frame', {
          sale_product: this.sale_product,
          commodity_id: targetSubCommodity.commodity_id
        })
      } else {
        return false
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/scss/common/variables';
.choose_frame_modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
    height: calc(100% - 8px);
  }
  .modal-container {
    padding: 0;
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
  .modal-body {
    margin: 0;
  }
}

.choose_frame_sale_product {

  &_image {
    width: 100%;
    height: calc(310px * 0.713412);
    overflow: hidden;

    @media screen and (max-width: 767.98px){
      height: calc((100vw - 220px) * 0.713412) !important;
    }

    @media screen and (max-width: 576.98px) {
      height: calc((100vw - 100px) * 0.713412) !important;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      vertical-align: top;
    }
  }
}

.choose_frame_top {
  background-color: #000000;
  padding: 26px 30px 12px 30px;
  position: relative;
  &_header {
    text-align: center;
    &_title {
      color: #FFFFFF;
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    &_button {
      position: absolute;
      top: 18px;
      right: 12px;
      border: 1px solid #fff;
      font-size: 12px;
      color: white;
      width: 78px;
      height: 28px;
      line-height: 28px;
    }
  }
}

.choose_frame_main {
  padding: 12px 8px;

  &_title {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background-color: #b90000;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      background-color: #fff;
      color: #000;
      margin-bottom: 8px;
    }
  }

  &_commodity_name {
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #fff;
    background-color: #000;
    margin-top: 16px;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      background-color: #fff;
      color: #000;
    }
  }

  &_button_wrapper {
    text-align: center;
  }
  &_button {
    margin: 16px auto 4px;
    border: 2px solid #000000;
    box-sizing: border-box;
    font-size: 14px;
    font-weight: bold;
    width: 200px;
    height: 40px;
    text-align: center;
    line-height: 40px;
  }

}

.frame_slider_container {

  height: calc(310px * 0.713412);

  @media screen and (max-width: 767.98px){
    height: calc((100vw - 220px) * 0.713412) !important;
  }

  @media screen and (max-width: 576.98px) {
    height: calc((100vw - 100px) * 0.713412) !important;
  }
  display: grid;
  gap: 0 16px;
  grid-template-columns: 20px 1fr 20px;

  .frame_slider_arrow_left, .frame_slider_arrow_right{
    display: flex;
    align-items: center;
    height: 100%;
  }

  //.frame_slider_image_wrapper {
  //  text-align: center;
  //}
  //.frame_slider_image {
  //  height: 174px;
  //  object-fit: contain;
  //}
}

</style>
