<template>
  <div>
    <MessageWhenHidden
      v-if="isMsgHidden"
      :content="msgContent"
      :is_mobile="isMobile"
      :subtitle="msgSubTitle"
      :title="msgTitle"
    />

    <CategoryTemplate
      v-else
      ref="base_category"
      :is-mobile="isMobile"

      :category="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"

      :total_qty="total_qty"
      :real-total-qty="realTotalQty"
      :sub_total="sub_total"
      :sets_amount="sets_amount"
      :just-qty-as-set-amount="justQtyAsSetAmount"
      :unit-name="unitName"

      :sale-products="saleProducts"
      :related-products="relatedProducts"
      :filters="filters"

      :suggest_qty_amount="suggest_qty_amount"

      :is-phone="isPhone"

      :cart-category="cartCategory"

      :except_category_id="except_category_id"
      :product-standard="productStandard"

      :other_events="other_events"

      :sale_product_purchase_popup="sale_product_purchase_popup"
      :is-extra-small-device="isExtraSmallDevice"
      :selected-sale-product="selectedSaleProduct"

      :carousel-setting="carouselSetting"

      :to-cart="toCart"

      :display-restore-cart="displayRestoreCart"
      :on-publish-key="onPublishKey"
      :on-restore-cart="onRestoreCart"

      :current-artist="currentArtist"

      @page="handleOnPage"
      @multi-purchase-click="onMultiPurchaseClick"
      @add-to-cart="onAddCart"
      @to-cart="onToCart"
      @filter-change="onFilterChange"
      @image-loaded="onLoadPopupImage"
      @update-popup="onUpdatePopup"
      @close="handleOnClose"
      @next="handleOnNext"
      @prev="handleOnPrevious"
      @input="onUpdateCart"
    />
  </div>
</template>

<script>
export default {
  name: "Category",
  components: {
    CategoryTemplate: () => import('@components/category/CategoryTemplate'),
    MessageWhenHidden: () => import('@components/base/MessageWhenHidden'),
  },
  props: {
    isMsgHidden: {
      type: Boolean,
      required: true
    },
    msgContent: {
      type: String,
      default: ''
    },
    msgSubTitle: {
      type: String,
      default: ''
    },
    msgTitle: {
      type: String,
      default: ''
    },

    isMobile: {
      type: Boolean,
      required: true
    },
    category: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },

    total_qty: {},
    realTotalQty: {
      type: [String, Number],
      default: 0
    },
    sub_total: {},
    sets_amount: {},

    justQtyAsSetAmount: {
      type: Boolean,
      required: true,
    },

    unitName: {
      type: String,
      required: true
    },

    saleProducts: {
      type: Object
    },

    relatedProducts: {
      type: Array,
      default: () => ([])
    },

    suggest_qty_amount: {},

    isPhone: {
      type: Boolean,
      default: false
    },

    cartCategory: {
      type: [String, Number, Object],
    },

    except_category_id: {},
    productStandard: {},

    other_events: {
      type: Array,
      default: () => []
    },
    sale_product_purchase_popup: {},
    isExtraSmallDevice: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Object,
      default: () => ({})
    },
    selectedSaleProduct: {},
    carouselSetting: {
      type: Object,
      default: () => {}
    },
    toCart: {
      type: [ String, Object ],
      default: ''
    },

    onPublishKey: {
      type: Function,
      default: () => {}
    },
    onRestoreCart: {
      type: Function,
      default: () => {}
    },
    displayRestoreCart: {
      type: Boolean,
      default: false
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleOnPage(page) {
      this.$emit('page', page)
    },
    onMultiPurchaseClick(sale_product) {
      this.$emit('multi-purchase-click', sale_product)
    },
    onAddCart(data) {
      this.$emit('add-to-cart', data)
    },
    onToCart() {
      this.$emit('to-cart')
    },
    onUpdateCart(data) {
      this.$emit('input', data)
    },
    onFilterChange(data) {
      this.$emit('filter-change', data)
    },
    handleOnClose() {
      this.$emit('close')
    },
    handleOnNext() {
      this.$emit('next')
    },
    handleOnPrevious() {
      this.$emit('prev')
    },
    onLoadPopupImage() {
      this.$emit('image-loaded')
    },

    onUpdatePopup(value) {
      this.$emit('update-popup', value)
    }

  }
}
</script>
