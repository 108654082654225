export default {
  cart_title: "Shopping cart",
  cart_subtitle: "ショッピングカート",
  msg_1: "The following items are currently in the cart.",
  msg_2: "If there are no mistakes in the purchase details, proceed to the purchase procedure as it is.",
  cart_empty_msg_1: 'There are currently no items in the cart.',
  cart_empty_msg_2: 'Please continue shopping.',
  product_image: "Product image",
  product_name: "Product name",
  price: "Price",
  quantity: "Quantity",
  delete: "Delete",
  tax_included: "Tax included",
  number_of_products: "Number of products",
  subtotal_amount: "Subtotal amount",
  sheet: "Sheet",
  to_purchase_procedure: "To purchase procedure",
  continue_shopping: "Continue shopping",
  total_sets_amount: "Total sets amount",
  invalide_qty_in_printed_sets: "You need to purchase this product every {qty_per_set} sheet.",
  some_products_have_not_reached_the_set_number: "Some products have not reached the set number. \n Please check the items in the cart again.",
  ok: "OK",
  cancel: "Cancel",
  remove_all_product_alert: "Delete all items in the cart. Are you sure you want to delete it?",
  event_sale_term_expired: 'You cannot purchase because the sales period has expired.',
  cart_data_change: "The items in your cart have been updated.\n Please check the items in the cart again."
}