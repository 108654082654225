export default {
  cart_title: "Shopping cart",
  cart_subtitle: "ショッピングカート",
  msg_1: "現在カート内には以下の商品が入っています。",
  msg_2: "購入内容に間違いがなければ、このまま購入手続きに<br class='s-elem'/>お進みください。",
  cart_empty_msg_1: '現在カートに商品が入っておりません。',
  cart_empty_msg_2: 'ショッピングを続けてください。',
  product_image: "商品画像",
  product_name: "商品名",
  price: "価格",
  quantity: "数量",
  delete: "削除",
  tax_included: "税込",
  number_of_products: "商品点数",
  subtotal_amount: "小計金額",
  sheet: "点",
  to_purchase_procedure: "購入手続きへ",
  continue_shopping: "ショッピングを続ける",
  total_sets_amount: "セット合計金額",
  invalide_qty_in_printed_sets: "この商品は{qty_per_set}枚単位で購入する必要があります",
  some_products_have_not_reached_the_set_number: "セット枚数に達していない商品があります。​\n カート内の商品を再度ご確認ください。",
  ok: 'OK',
  cancel: "キャンセル",
  remove_all_product_alert: "カート内の全商品を削除します。<br>本当に削除してよろしいですか？",
  event_sale_term_expired: '販売期間外の商品があるため、購入ができません。',
  cart_data_change: "カート内の商品が更新されています。\n 再度、カート内の商品をご確認ください。"
}
