<template>
  <transition v-if="modal" name="modal">
    <div class="modal-mask">

      <div class="modal-wrapper">

        <div :style="{ width: width, 'max-width': maxWidth }" class="modal-container">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
        <div v-if="showClose" :style="{ width, 'max-width': maxWidth }" class="modal-close">
          <span @click="handleClose">閉じる ×</span>
        </div>

      </div>


    </div>
  </transition>
</template>
<script>
export default {
  props: {
    modal: {
      required: true,
      default: false,
    },
    width: {
      required: false,
      type: String,
      default: '400px',
    },
    maxWidth: {
      type: String,
      default: '1000px'
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    modal: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal && newVal === true) {
          window.document.body.style.overflow = 'hidden'
        } else if (newVal !== oldVal && newVal === false) {
          window.document.body.style.overflow = 'auto'
        }
      }
    }
  },
  destroyed() {
    window.document.body.style.overflow = 'auto'
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
