import BaseService from "./BaseService";

class MemberService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  checkMyPageUserEmailDuplicate(data) {
    return this.performRequest(BaseService.METHOD_GET, 'email-duplicate', data);
  }

  getPurchaseHistory(data) {
    return this.performRequest(BaseService.METHOD_GET, 'history', data);
  }

  getOrderHistoryByOrderId(id, data = {}) {
    return this.performRequest(BaseService.METHOD_GET, `history/${id}`, data);
  }

  getPurchaseEventCategoryByOrderID(id, data = {}) {
    return this.performRequest(BaseService.METHOD_GET, `history/get-event-category-by-order/${id}`, data);
  }

  getPurchaseProductByOrderID(id, data = {}) {
    return this.performRequest(BaseService.METHOD_GET, `history/order-product-by-order/${id}`, data);
  }

  updateCurrentMember(data) {
    return this.performRequest(BaseService.METHOD_PUT, '', data);
  }

  updateMypageUserPassword(data) {
    // console.log('send', data)
    return this.performRequest(BaseService.METHOD_PUT, 'password', data)
  }

  withdrawMembership() {
    return this.performRequest(BaseService.METHOD_PUT, 'withdraw', {});
  }

}

export default new MemberService('members');