<template>
  <header class="header js-header">
    <div class="header__content">
      <h1 class="header__artist-name">
        <router-link v-if="headerLogo" :to="logoLink">
          <img :src="headerLogo">
        </router-link>
      </h1>

      <nav class="header__nav">
        <ul>
          <li v-if="!isLoggedIn" class="header__nav-item">
            <router-link :to="{ name: 'auth.login'}">
              <img alt="" src="@images/icon_login.svg">
              <span class="c-black header__nav-menu">{{ $t('general.login') }}</span>
            </router-link>
          </li>

          <li v-if="!isLoggedIn && !isMessageWhenHidden" class="header__nav-item">
            <router-link :to="{ name: 'member.index'}">
              <img alt="" src="@images/icon_join.svg">
              <span class="c-black header__nav-menu">{{ $t('general.register') }}</span>
            </router-link>
          </li>

          <li v-if="isLoggedIn" class="header__nav-item">
            <div
              class="js-accordion-title mypage-link"
              style="cursor: pointer"
              @click="sidebarToggle"
            >
              <img alt="" src="@images/icon_mypage.svg">
              <span class="c-black header__nav-menu">{{ $t('general.mypage') }}</span>
            </div>

            <div class="user-menu">
              <ul class="user-menu__list">
                <li class="user-menu__name">
                  {{ $t('general.mypage') }}
                </li>

                <li class="user-menu__item">
                  <router-link
                    :to="{ name: current_artist ? 'artist.mypage.purchase_history' : 'mypage.purchase_history' }"
                    class="c-black al-l"
                    @click.native="sidebarToggle"
                  >
                    {{ $t('general.history') }}
                  </router-link>
                </li>

                <li class="user-menu__item">
                  <router-link
                    :to="{ name: current_artist ? 'artist.mypage.index' : 'mypage.index' }"
                    class="c-black al-l"
                    @click.native="sidebarToggle"
                  >
                    {{ $t('general.profile') }}
                  </router-link>
                </li>
                <li class="user-menu__item">
                  <router-link
                    @click.native="sidebarToggle"
                    class="c-black al-l"
                    :to="{ name: current_artist ? 'artist.mypage.change-password' : 'mypage.change-password' }"
                  >
                    {{ $t('general.change_password') }}
                  </router-link>
                </li>

                <li class="user-menu__item">
                  <a class="c-black al-l logout" @click="logout">{{ $t('general.logout') }}</a>
                </li>

                <li class="user-menu__item">
                  <router-link
                    :to="{ name: current_artist ? 'artist.mypage.cancel' : 'mypage.cancel' }"
                    class="c-black al-l"
                    @click.native="sidebarToggle"
                  >
                    {{ $t('general.mypage_cancel') }}
                  </router-link>
                </li>
              </ul>
            </div>
          </li>

          <li v-if="isShowCart" :key="isMessageWhenHidden" class="header__nav-item">
            <div>
              <a href="" @click.prevent="$emit('to-cart')">
                <img :src="require('@images/icon_shopping.svg')" alt="">
                <span :key="cartSheetAmount" class="purchase_count">{{ cartSheetAmount }}</span>
                <span class="c-black header__nav-menu">{{ $t('general.cart') }}</span>
              </a>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
import $ from 'jquery'

export default {
  name: 'Header',
  props: {
    logo: {
      type: String,
      required: false,
    },
    logoLink: {
      type: String,
      required: false,
      default: '/',
    },
    current_artist: {
      type: Object,
      default: null,
    },
    designate_page: {
      type: [String, Array, Number, Object],
      default: null,
    },
    isMessageWhenHidden: {
      type: Boolean,
      default: false,
    },
    isShowCart: {
      type: Boolean,
      default: false,
    },
    isLoggedIn: {
      type: Boolean,
      default: false,
    },
    cartCategory: {
      type: [String, Number, Object],
      default: null,
    },
    cartSheetAmount: {
      type: [String, Number],
      default: 0,
    },
  },
  data() {
    return {
      bromide_logo: require('@assets/images/logo.png'),
    }
  },
  computed: {
    headerLogo() {
      if (!this.designate_page) {
        return this.bromide_logo
      }

      return this.current_artist ? this.logo : null
    },
  },
  methods: {
    sidebarToggle() {
      $('.js-accordion-title').next().slideToggle(200)
      $('.user-menu').toggleClass('open', 200)
    },
    logout() {
      this.$emit('logout')
    },
  },
}
</script>

<style scoped>
.logout {
  cursor: pointer;
}
</style>
