<template>
  <div>
    <article>
      <h2 class="page-title">
        <span class="page-title--en">Purchase</span>
        <span class="page-title--jp">購入手続き</span>
      </h2>
      <section class="content pb-80">
        <div class="content__inner">
          <section class="mb-60">
            <h3 class="section-title">{{ $t('purchase_credit.credit_card_information_input') }}</h3>
            <p class="mb-30">
              {{ $t('purchase_credit.please_enter_your_card_information') }}<br><span
              class="c-red">※ {{ $t('purchase_credit.is_a_required_item') }}</span></p>
            <div class="mb-30">
              <h5 class="sub-title">{{ $t('purchase_credit.accepted_credit_cards') }}</h5><img
              :src="require('@assets/images/icon_credit.png')" alt="">
            </div>
            <validation-observer ref="observer">
              <div class="mb-60">
                <h4 class="main-title">{{ $t('purchase_credit.credit_card_information') }}</h4>
                <dl class="common-dl">
                  <dt>{{ $t('purchase_credit.card_number') }}<span class="c-red">※</span></dt>
                  <dd>
                    <ValidationProvider v-slot="{ errors }" rules="required|min:13|max:19|numeric">
                      <input v-model="userPaymentInfo.card_number" :name="$t('purchase_credit.card_number')" class="form-text--100"
                             placeholder="0001020121212021020" type="number">
                      <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                  <dt>{{ $t('purchase_credit.nominee') }}<span class="c-red">※</span></dt>
                  <dd>
                    <ValidationProvider v-slot="{ errors }" rules="required|max:50">
                      <input v-model="userPaymentInfo.holder_name" :name="$t('purchase_credit.nominee')" class="form-text--100"
                             placeholder="TARO YAMADA" type="text">
                      <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                  <dt>{{ $t('purchase_credit.security_code') }}<span class="c-red">※</span></dt>
                  <dd>
                    <ValidationProvider v-slot="{ errors }" rules="required|max:4|min:3|numeric">
                      <input v-model="userPaymentInfo.security_code" :name="$t('purchase_credit.security_code')" class="form-text--50" maxlength="4"
                             placeholder="1111" size="4" type="number">
                      <br>
                      <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <p class="small">
                      <a class="link-line" data-lity="" href="#popup-security">
                        {{ $t('purchase_credit.what_is_a_security_code_1') }}
                      </a>
                    </p>
                  </dd>
                  <dt>{{ $t('purchase_credit.expiration_date') }}<span class="c-red">※</span></dt>
                  <dd>
                    <ValidationProvider v-slot="{ errors }" rules="required|date_format:mm/yy">
                      <input v-model="userPaymentInfo.expiration_date" :name="$t('purchase_credit.expiration_date')" class="form-text--100"
                             type="hidden">
                      <ul class="flex-list">
                        <li class="flex-list--item">
                          <div class="form-select">
                            <select v-model="expirationDate.month" :name="$t('purchase.month')">
                              <option hidden value="">{{ $t('purchase.month') }}</option>
                              <option v-for="item in avialableMonths" :key="item" :value="item">
                                {{ item }}
                              </option>
                            </select>
                          </div>
                          <p class="pl-5">{{ $t('purchase.month') }}</p>
                        </li>
                        <li class="flex-list--item pr-0">
                          <div class="form-select">
                            <select v-model="expirationDate.year" :name="$t('purchase.year')">
                              <option hidden value="">{{ $t('purchase.year') }}</option>
                              <option v-for="item in 11" :key="(item-1)" :value="currentYearShortFormat + (item - 1)">
                                {{ current_year + (item - 1) }}
                              </option>
                            </select>
                          </div>
                          <p class="pl-5">{{ $t('purchase.year') }}</p>
                        </li>
                      </ul>
                      <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </dl>
              </div>
              <div class="mb-20">
                <div>
                  <button :disabled="isDisableSubmitBtn" class="btn-100--red" @click="submit">
                    {{ $t('purchase_credit.confirmation_of_your_order') }}
                  </button>
                </div>
              </div>
              <div>
                <button class="btn-100--none" @click="$router.push({name: `repurchase.${repurchaseType}.confirmation`})">
                  {{ $t('purchase_credit.to_fix') }}
                </button>
              </div>
            </validation-observer>
          </section>
        </div>
      </section>
    </article>
    <div id="popup-security" class="lity-hide">
      <p class="bold large mb-10">
        {{ $t('purchase_credit.what_is_a_security_code') }}
      </p>
      <p class="mb-20">
        {{ $t('purchase_credit.refers_to_the_last_3_or_4_digits') }}<br>
        {{ $t('purchase_credit.some_credit_cards_are_listed_on_the_surface') }}<br>
        {{ $t('purchase_credit.if_you_do_not_know_the_security_code_or_it_is_not_printed') }}
      </p>
      <div class="security-img">
        <img :src="require('@assets/images/security_code.png')" alt="">
      </div>
    </div>
    <Modal :modal="isError">
      <div slot="body">{{ errorMsg }}</div>
      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="isError = false">{{ $t('cart.ok') }}</button>
      </div>
    </Modal>
  </div>
</template>
<style scoped>

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

</style>
<script>
import Constants from '@constants';
import {mapGetters} from "vuex";

export default {
  components: {
    Modal: () => import('@components/base/Modal')
  },
  created() {
    if (!this.repurchaseUserCartData) {
      this.$cookies.remove(Constants.USER_REPURCHASE_INFO)
      this.$cookies.remove(Constants.USER_REPURCHASE_PAYMENT_INFO)
      this.$cookies.remove(Constants.REPURCHASE_STEP)
      this.$router.push({name: `repurchase.${this.$route.meta?.type}`})
      return
    }

    const userPaymentInfo = this.$cookies.get(Constants.USER_REPURCHASE_PAYMENT_INFO);
    if (userPaymentInfo) {
      this.userPaymentInfo.payment_method_id = userPaymentInfo.payment_method_id
      this.userPaymentInfo.parent_payment_method_id = userPaymentInfo.parent_payment_method_id
    }
  },
  watch: {
    expirationDate: {
      handler(val) {
        this.userPaymentInfo.expiration_date = `${val.month}/${val.year}`
        this.userPaymentInfo.token_expiration_date = `${val.year}${val.month}`
        this.avialableMonths = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
        if (val.year == this.currentYearShortFormat) {

          let index = this.avialableMonths.indexOf(this.currentMonth);
          this.avialableMonths = this.avialableMonths.slice(index);

          if (!this.avialableMonths.includes(val.month)) {
            this.expirationDate.month = null
          }
        }
      },
      deep: true
    },
    userPaymentInfo: {
      handler(val) {
        this.checkCardBrandName(val.card_number)

        if (val.security_code && val.security_code.length > 4) {
          this.userPaymentInfo.security_code = val.security_code.slice(0, 4)
        }

        if (val.card_number && val.card_number.length > 19) {
          this.userPaymentInfo.card_number = val.card_number.slice(0, 19)
        }

      },
      deep: true
    }
  },
  data() {
    return {
      isDisableSubmitBtn: false,
      currentYearShortFormat: (new Date()).toJSON().slice(2, 4) - 0,
      current_year: new Date().getFullYear(),
      currentMonth: new Date().toJSON().split('-')[1],

      isError: false,
      errorMsg: null,
      avialableMonths: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
      expirationDate: {
        year: null,
        month: null,
      },
      userPaymentInfo: {
        expiration_date: null,
        card_number: null,
        token_expiration_date: null,
        security_code: null,
        holder_name: null
      }
    }
  },
  computed: {
    ...mapGetters([
      'repurchaseType',
      'repurchaseUserCartData',
    ]),
  },
  methods: {
    async submit() {
      this.isDisableSubmitBtn = true

      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError();
        this.isDisableSubmitBtn = false
        return;
      }

      try {
        window.Multipayment.init(process.env.VUE_APP_GMO_SHOP_ID)
      } catch {
        this.errorMsg = this.$t('general.technical')
        this.isError = true
        this.isDisableSubmitBtn = false
        return;
      }

      this.$store.dispatch('toggleLoading', true);
      window.Multipayment.getToken({
        cardno: this.userPaymentInfo.card_number,
        expire: this.userPaymentInfo.token_expiration_date,
        securitycode: this.userPaymentInfo.security_code,
        holdername: this.userPaymentInfo.holder_name,
      }, res => {
        this.$store.dispatch('toggleLoading', false);
        if (res.resultCode === '000') {

          this.userPaymentInfo.token = res.tokenObject.token
          this.userPaymentInfo.card_number = this.userPaymentInfo.card_number.slice(-4)
          this.userPaymentInfo.security_code = null
          this.userPaymentInfo.token_expiration_date = null

          this.$cookies.set(Constants.USER_REPURCHASE_PAYMENT_INFO, JSON.stringify(this.userPaymentInfo), Constants.PURCHASE_INFO_DURATION_COOKIE)
          this.$router.push({name: `repurchase.${this.repurchaseType}.confirmation2`});

        } else {
          this.errorMsg = this.$t('purchase_credit.credit_card_is_invalid')
          this.isError = true
        }
        this.isDisableSubmitBtn = false
      });
    },
    checkCardBrandName(card_number) {
      let patterns = [
        {
          name: 'Visa',
          req: new RegExp('^4[0-9]{12}(?:[0-9]{3})?$')
        },
        {
          name: 'American Express',
          req: new RegExp('^3[47][0-9]{13}$')
        },
        {
          name: 'JCB',
          req: new RegExp('^35[0-9]{14}[0-9]*$')
        },
        {
          name: 'Master Card',
          req: new RegExp('^5[1-5][0-9]{14}$')
        },
        {
          name: 'Diner Club',
          req: new RegExp('^3[0689][0-9]{12}[0-9]*$')
        }
      ];

      for (let i = 0; i < patterns.length; i++) {
        if (patterns[i].req.test(card_number)) {
          return this.userPaymentInfo.cardBrandName = patterns[i].name
        }
      }
      return this.userPaymentInfo.cardBrandName = ''
    }
  }
}
</script>
<style scoped>
.modal-footer {
  display: flex;
  justify-content: center;
}

.footer__close {
  border: 2px solid black;
  cursor: pointer;
  padding: 1rem 3rem;
  background-color: transparent;
}
</style>
