<template>
  <div class="event-category__item" :style="listStyle">

    <div
      v-if="data.limited_product_flag"
      class="event-category__recommend"
    >
      <img
        alt="限定商品"
        src="@assets/images/icon-limited.svg"
      />
    </div>

    <a
      :title="data.name"
      :style="data.is_active ? '' : { 'pointer-events' : 'none' }"
      class="event-category__link"
      @click="onDetailClick"
    >
      <div class="event-category__item--main">

        <div class="cate-link__thumb box-shadow">
          <img :alt="data.name" :src="data.main_image_url">
          <span
            :style="{ 'background-image': data.main_image_url }"
            alt=""
            class="cate-link__blur"
          />

        </div>
        <div ref="categoryName" class="cate-link__detail">

          <keep-proportion-content
            root="h3"
            class="cate-link__title"
            :proportion-target="() => $refs.categoryName"
            :style="{color: data.name_text_color}"
            :content="data.name"
            :p-width="293.33"
            :p-sp-width="173"
            :gap="0"
            :gap-sp="0"
            blank-to-br
          />

        </div>
      </div>

      <div class="event-category__item--price">

        <div
          v-if="showAmount"
          class="font-en price-no"
        >{{ $t('event.set', { amount: 1 }) }}</div>

        <div class="font-en price-item">
          ¥{{ data.price_included_tax }}
          <span class="tax">（{{ $t('event.tax_included') }}）</span>
        </div>

      </div>

      <span
        class="event-category__link--button event-category__item-button box-shadow pointer"
      >{{ $t('event.relation.click_here_for_details') }}</span>
    </a>
  </div>

</template>
<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  components: {KeepProportionContent},
  props: {
    data: {
      type: Object,
      required: true
    },
    showAmount: {
      type: Boolean,
      default: false,
    },
    forceSp: {
      type: Boolean,
      default: false,
    },
    height: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      imageStyle: ''
    }
  },
  created() {
    let size = this.screenSize();
    const newWidth = size.width;
    if (newWidth <= 767 || this.forceSp) {
      let imageSize = this.calculateImageSize(170, 121.27, 414);
      this.imageStyle = 'padding:0;width:' + imageSize.width + 'px;height:' + imageSize.height + 'px;';
    }
  },
  computed: {
    listStyle() {
      if (this.height === 0) {
        return {}
      }
      return {
        "height": `${this.height}px`
      }
    }
  },
  methods: {
    onDetailClick() {
      this.$emit('detail-click', this.data)
    }
  },
}
</script>
