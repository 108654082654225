<template>
  <ValidationProvider
    ref="validation"
    v-slot="{ errors }"
    :rules="rule"
  >
    <div class="postal-code">
      <input
        v-model="content"
        :class="input_class"
        :name="input_name"
        :placeholder="placeholder"
        autocomplete="off"
        type="tel"
        @compositionstart="handleCompositionStart"
        @compositionend="handleCompositionEnd"
        @input="onInputChange"
      />
      <p v-if="errors[0]" class="c-red">
        {{ $t('general.postal_code_error_msq') }}
      </p>
    </div>
  </ValidationProvider>
</template>

<script>
import numOnly from "@/helpers/numOnly";

export default {
  name: 'PostalCodeInput',
  props: {
    placeholder: {
      type: String,
      required: false,
    },
    input_name: {
      type: String,
      required: true,
    },
    rule: {
      type: String,
      required: false,
      default: 'numeric|digits:7',
    },
    input_class: {
      type: String,
      required: false,
      default: 'form-text',
    },
    value: {
      required: true,
    },
  },
  data() {
    return {
      content: this.value,
      isEditing: false,
    }
  },
  watch: {
    value(value) {
      this.content = value
    },
  },
  methods: {
    emitEvent(val) {
      const text = numOnly(val)
      this.content = text
      this.$emit('input', text)
    },
    onInputChange() {
      if (!this.isEditing) {
        this.emitEvent(this.content)
      }
    },
    handleCompositionStart() {
      this.isEditing = true
    },
    handleCompositionEnd(evt) {
      this.isEditing = false
      this.emitEvent(evt.target.value)
    }
  },
}
</script>
<style scoped>
.postal-code {
  float: left;
}

.select-list {
  z-index: 999999;
  width: 45rem;
  max-height: 50rem;
  position: absolute;
  background-color: #ffffff;
  border: 0.5px solid #000000;
  margin-top: 4.4rem;
  overflow-y: scroll;
}

.list {
  padding: 0.3rem 1rem;
  cursor: pointer;
}

.list:hover {
  background-color: #f0f2f4;
}
</style>
