<template>
  <article>
    <Title :subtitle="$t('auth.login_subtitle')" :title="$t('auth.login_title')"/>
    <section class="content pb-60">
      <div class="content__inner">
        <template v-if="!mail_was_sent">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <section>
              <HeadLine :title="$t('auth.reset_pw.title')"/>
              <p class="mb-30">
                {{ $t('auth.reset_pw.msg_1') }} <br>
                <span v-html="$sanitize($t('auth.reset_pw.msg_2'))"/>
                {{ $t('auth.reset_pw.msg_3') }}
              </p>
              <ErrorAlert v-if="invalid" class="mb-30"> {{ $t('auth.reset_pw.invalid') }}</ErrorAlert>
              <div class="bg-gray pt-30 pb-30 mb-30">
                <dl class="login-dl">
                  <dt class="bold large">{{ $t('auth.email') }}</dt>
                  <dd class="mb-10">
                    <ValidationProvider v-slot="{ errors }" rules="required">
                      <input v-model="email" :name="$t('auth.email')" :placeholder="$t('auth.reset_pw.email_placeholder')" class="login-dl__input login-dl__input--reset-pw-email" type="email"
                             value="">
                      <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                  <dt class="bold large">{{ $t('auth.reset_pw.date_of_bith') }}</dt>
                  <dd>
                    <ul class="flex-list reset-pw-birthday">
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <li class="flex-list--item">
                          <div class="login-dl__select login-dl__select--reset-pw-year">
                            <select id="year" v-model="year" :for="$t('auth.reset_pw.year')"
                                    :name="$t('auth.reset_pw.year')" @change="onMonthChange()">
                              <option v-t="'auth.reset_pw.year'" hidden value=""></option>
                              <option v-for="(value, index) in year_list" v-bind:key="index" v-bind:value="value">
                                {{ value }}
                              </option>
                            </select>
                          </div>
                          <p class="pl-5">{{ $t('auth.reset_pw.year') }}</p>
                        </li>
                        <span v-if="errors[0]" class="c-red pr-10">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <li class="flex-list--item">
                          <div class="login-dl__select login-dl__select--reset-pw-month">
                            <select id="month" v-model="month" :for="$t('auth.reset_pw.month')"
                                    :name="$t('auth.reset_pw.month')" @change="onMonthChange()">
                              <option hidden selected value="">{{ $t('auth.reset_pw.month') }}</option>
                              <option v-for="(value, index) in month_list" v-bind:key="index" v-bind:value="value">
                                {{ value }}
                              </option>
                            </select>
                          </div>
                          <p class="pl-5">{{ $t('auth.reset_pw.month') }}</p>
                        </li>
                        <span v-if="errors[0]" class="c-red pr-10">{{ errors[0] }}</span>
                      </ValidationProvider>
                      <ValidationProvider v-slot="{ errors }" rules="required">
                        <li class="flex-list--item pr-0">
                          <div class="login-dl__select login-dl__select--reset-pw-day">
                            <select id="day" v-model="day" :for="$t('auth.reset_pw.day')"
                                    :name="$t('auth.reset_pw.day')">
                              <option v-t="'auth.reset_pw.day'" hidden value=""></option>
                              <option v-for="(value, index) in day_list" v-bind:key="index" v-bind:value="value">
                                {{ value }}
                              </option>
                            </select>
                          </div>
                          <p class="pl-5">{{ $t('auth.reset_pw.day') }}</p>
                        </li>
                        <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                      </ValidationProvider>
                    </ul>
                  </dd>
                </dl>
              </div>
              <Button :title="$t('auth.reset_pw.send_email')" @onClick="handleSubmit(requestResetPW)"/>
            </section>
          </validation-observer>
        </template>
        <template v-if="mail_was_sent">
          <section>
            <HeadLine :title="$t('auth.reset_pw_mail.title')"/>
            <p class="mb-60">
              {{ $t('auth.reset_pw_mail.msg') }} <br>
              {{ $t('auth.reset_pw_mail.msg_expire') }}
            </p>
            <Button :title="$t('auth.return_to_login_page')" @onClick="loginPage"/>
          </section>
        </template>
      </div>
    </section>
  </article>
</template>

<script>
import AuthService from '@services/AuthService';

export default {
  data() {
    return {
      mail_was_sent: false,
      email: '',
      year: '',
      month: '',
      day: '',
      year_list: [],
      month_list: [],
      day_list: [],
      invalid: false
    };
  },
  methods: {
    onMonthChange() {
      this.day_list = this.generateArrayOfDay(this.month, this.year);
    },
    requestResetPW() {
      const date_of_bith = this.year + '-' + (this.month) + '-' + (this.day);
      AuthService.requestResetPassword({
        email: this.email,
        date_of_birth: date_of_bith
      })
        .then(() => {
          this.mail_was_sent = true;
          this.invalid = false;
        })
        .catch(() => {
          this.invalid = true;
        });
    },
    loginPage() {
      this.$router.push({name: "auth.login"});
    }
  },
  created() {
    this.year_list = this.generateArrayOfYears();
    this.month_list = this.generateArrayOfMonth();
    this.day_list = this.generateArrayOfDay(this.month, this.year);
  },
  components: {
    HeadLine: () => import("@components/base/Headline"),
    Title: () => import("@components/base/Title"),
    Button: () => import("@components/base/Button"),
    ErrorAlert: () => import("@components/base/ErrorAlert")
  },
}
</script>
