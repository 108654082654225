<template>
  <article>
    <Title :subtitle="$t('mypage_cancel_fix.title.main_jp')" :title="$t('mypage_cancel_fix.title.main')"></Title>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                       class="d-block text-right mb-20"></router-link>
          <Headline :title="$t('mypage_cancel_fix.headline.title')"></Headline>
          <p class="mb-50">
            {{ $t('mypage_cancel_fix.headline.description') }}<br>
            {{ $t('mypage_cancel_fix.headline.thank_you') }}
          </p>
          <div class="al-c">
            <router-link :to="{ name: current_artist ? 'artist.home' : 'top' }" class="btn-50">
              {{ $t('mypage_cancel_fix.btn.return_to_top') }}
            </router-link>
          </div>
        </section>
      </div>
    </section>
  </article>
</template>
<script>
import {mapState} from 'vuex';

export default {
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline')
  },
  computed: {
    ...mapState([
      'current_artist'
    ])
  }
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>