<template>
  <div>
    <MessageWhenHidden
      v-if="isMsgHidden"
      :content="msgContent"
      :is_mobile="isMobile"
      :subtitle="msgSubTitle"
      :title="msgTitle"
    />

    <LandingTemplate
      :landing-page="landingPage"
    />
  </div>
</template>

<script>
export default {
  components: {
    MessageWhenHidden: () => import('@components/base/MessageWhenHidden'),
    LandingTemplate: () => import('@components/landing/LandingTemplate')
  },
  props: {
    isMsgHidden: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    msgTitle: {
      type: String,
      default: ''
    },
    msgSubTitle: {
      type: String,
      default: ''
    },
    msgContent: {
      type: String,
      default: ''
    },
    landingPage: {
      type: Object
    }
  }
}
</script>
