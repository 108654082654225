<template>
  <article>
    <h2 class="page-title"><span class="page-title--en">Attention</span><span class="page-title--jp">注意事項</span></h2>
    <section class="content pb-60">
      <validation-observer ref="observer" class="content__inner" tag="div">
        <div class="al-c border-red mb-30" ref="al">
          <keep-proportion-content
            :proportion-target="() => $refs.al"
            :p-width="900"
            :p-sp-width="340"
            :gap-sp="60"
            :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
          >
            <p class="bold large">{{ $t('purchase_attention.product_sales_and_product_delivery') }}&reg;
              {{ $t('purchase_attention.bromide_co_ltd_will_do_its') }}</p>
            <a class="link-line c-black bold" href="../trading" target="_blank">
              {{ $t('purchase_attention.notation_of_specified_commercial_transactions_law') }}
            </a>
          </keep-proportion-content>
        </div>
        <h3 class="section-title">{{ $t('purchase_attention.email_settings') }}</h3>

        <div class="attention mb-20">
          <div class="attention__title mb-10">
            <div class="attention__image"><img alt="注意" :src="require('@assets/images/icon_attention.svg')"></div>
            <div class="attention__title__text"><p class="bold mb-10">ドメインの受信設定をお願いします</p></div>
          </div>
          <div class="attention__text" ref="domain">
            <keep-proportion-content
              :proportion-target="() => $refs.domain"
              :p-sp-width="380"
              :gap-sp="0"
            >
              <p class="bold mb-10" v-html="$sanitize($t('purchase_attention.i_have_not_received_email_regarding_my_purchase_title'))"></p>
              <br>
              <p class="bold mb-2" v-html="$sanitize($t('purchase_attention.common_to_all_customers'))"></p>
              <p class="mb-10" v-html="$sanitize($t('purchase_attention.if_the_customer_is_set_to_receive_by_specifying_domain_description'))"></p>
              <div class="form-text--100"><span class="large bold">@bromide.jp</span></div>
            </keep-proportion-content>
          </div>
        </div>
        <validation-provider ref="e1" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
          <label class="check">
            <input v-model="email_1_checked" :name="$t('registration.label.attention_check')" type="checkbox"
                   @input="resetValidate(0)">
            <span class="check--dummy"></span>
            <span v-t="'registration.label.attention_check'" class="check--text"></span>
          </label>
          <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
        </validation-provider>

        <div class="attention mb-20">
          <div class="attention__title mb-10">
            <div class="attention__image"><img alt="注意" :src="require('@assets/images/icon_attention.svg')"></div>
            <div class="attention__title__text"><p class="bold mb-10">ドメインの受信設定をお願いします</p></div>
          </div>
          <div class="attention__text" ref="convenienceStore">
            <keep-proportion-content
              :proportion-target="() => $refs.convenienceStore"
              :p-sp-width="380"
              :gap-sp="0"
            >
              <p class="bold mb-10" v-html="$sanitize($t('purchase_attention.customers_planning_to_use_combination_payment_title'))"></p>
              <p class="mb-10" v-html="$sanitize($t('purchase_attention.after_selecting_convenience_store_payment_and_completing_the_purchase_procedure_description'))"></p>
              <div class="form-text--100 mb-20"><span class="large bold">system@p01.mul-pay.com</span></div>
            </keep-proportion-content>
          </div>
        </div>
        <validation-provider ref="e2" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
          <label class="check">
            <input v-model="email_2_checked" :name="$t('registration.label.attention_check')" type="checkbox"
                   @input="resetValidate(1)">
            <span class="check--dummy"></span>
            <span v-t="'registration.label.attention_check'" class="check--text"></span>
          </label>
          <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
        </validation-provider>

        <div class="attention mb-20">
          <div class="attention__title mb-10">
            <div class="attention__image"><img alt="注意" :src="require('@assets/images/icon_attention.svg')"></div>
            <div class="attention__title__text"><p class="bold mb-10">ドメインの受信設定をお願いします</p></div>
          </div>
          <div class="attention__text" ref="freeMail">
            <keep-proportion-content
              :proportion-target="() => $refs.freeMail"
              :p-sp-width="380"
              :gap-sp="0"
            >
              <p class="bold mb-10" v-html="$sanitize($t('purchase_attention.customers_using_free_mail'))"></p>
              <p class="mb-10" v-html="$sanitize($t('purchase_attention.please_setup_so_that_you_can_receive_emails'))">
              </p>
            </keep-proportion-content>
          </div>
        </div>
        <validation-provider ref="e3" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
          <label class="check">
            <input v-model="email_3_checked" :name="$t('registration.label.attention_check')" type="checkbox"
                   @input="resetValidate(2)">
            <span class="check--dummy"></span>
            <span v-t="'registration.label.attention_check'" class="check--text"></span>
          </label>
          <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
        </validation-provider>

        <h3 class="section-title">{{ $t('purchase_attention.about_product_rights_after_purchase') }}</h3>
        <div ref="aboutProductRightsAfterPurchase" class="mb-20">
          <keep-proportion-content
            :proportion-target="() => $refs.aboutProductRightsAfterPurchase"
            :p-sp-width="380"
            :gap-sp="0"
          >
            <p class="mb-10" v-html="$sanitize($t('purchase_attention.the_customer_rights_to_the_photographic_product'))"></p>
          </keep-proportion-content>
        </div>
        <validation-provider ref="p2" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
          <label class="check">
            <input v-model="agreements.product" :name="$t('purchase_attention.i_confirmed_the_rights_of_the_product')"
                   type="checkbox" @input="resetValidate(3)">
            <span class="check--dummy"></span>
            <span class="check--text">{{ $t('purchase_attention.i_confirmed_the_rights_of_the_product') }}</span>
          </label>
          <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
        </validation-provider>

        <h3 class="section-title mt-10">{{ $t('purchase_attention.sales_conditions') }}</h3>
        <div class="mb-20">
          <div ref="keepContent" class="div-scroll mb-50 content__inner--editor">
            <keep-proportion-content
              :proportion-target="() => $refs.keepContent"
              :p-width="1000"
              :p-sp-width="400"
              :gap="20"
              :gap-sp="20"
              :content="sale_condition_text"
              :other-style="'.content__inner--editor .large{font-size :14px; line-height: 1.6;}'"
            />
          </div>
        </div>

        <validation-provider ref="p3" v-slot="{errors}" class="al-c mb-30" rules="required|checkbox" tag="div">
          <label class="check">
            <input
              v-model="agreements.condition"
              :name="$t('purchase_attention.i_confirmed_the_sales_conditions')"
              type="checkbox"
              :disabled="!isScrollBottom"
              @input="resetValidate(4)"
            />
            <span :class="['check--dummy', {'check--dummy__disabled': !isScrollBottom}]"></span>
            <span :class="['check--text', {'check--text__disabled': !isScrollBottom}]">{{ $t('purchase_attention.i_confirmed_the_sales_conditions') }}</span>
          </label>
          <div v-if="errors[0]" class="c-red mt-10">必須確認事項です</div>
        </validation-provider>
        <div class="mb-20">
          <button class="btn-100--red" @click="agree" @input="resetValidate">
            {{ $t('purchase_attention.to_purchase_procedure') }}
          </button>
        </div>
      </validation-observer>
    </section>
  </article>
</template>

<script>
import StaticService from '@services/StaticService';
import Constants from '@constants';
import KeepProportionContent from "@/components/base/KeepProportionContent";
import { mapGetters } from 'vuex'

export default {
  components: {
    KeepProportionContent
  },
  computed: {
    ...mapGetters([
      'repurchaseType',
      'repurchaseUserCartData',
    ]),
    isDisable: function () {
      return this.agreements.setting && this.agreements.product && this.agreements.condition
    }
  },
  data() {
    return {
      email_1_checked: false,
      email_2_checked: false,
      email_3_checked: false,
      agreements: {
        product: false,
        condition: false
      },
      sale_condition_text: "",
      isScrollBottom: false
    }
  },
  mounted() {
    this.$nextTick(function (){
      setTimeout(() => {
        this.$refs.keepContent.addEventListener('scroll', (evt) => {
          if (!this.isScrollBottom) {
            this.isScrollBottom = ( evt.target.scrollHeight - ( evt.target.scrollTop + evt.target.clientHeight)) < 40
          }
        })
      }, 2000)
    })
  },
  methods: {
    async agree() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }
      this.$router.push({name: `repurchase.${this.repurchaseType}.purchase`});
    },
    resetValidate(iCheckBox) {
      switch (iCheckBox) {
        case 0:
          this.$refs.e1.validate();
          break;
        case 1:
          this.$refs.e2.validate();
          break;
        case 2:
          this.$refs.e3.validate();
          break;
        case 3:
          this.$refs.p2.validate();
          break;
        case 4:
          this.$refs.p3.validate()
          break;
      }
    },
  },
  async created() {
    if (!this.repurchaseUserCartData) {
      this.$cookies.remove(Constants.USER_REPURCHASE_INFO)
      this.$cookies.remove(Constants.USER_REPURCHASE_PAYMENT_INFO)
      this.$cookies.remove(Constants.REPURCHASE_STEP)
      this.$router.push({name: `repurchase.${this.$route.meta?.type}`})
      return
    }

    this.$cookies.set(Constants.REPURCHASE_STEP, `repurchase.${this.repurchaseType}.attention`, Constants.PURCHASE_INFO_DURATION_COOKIE)

    await StaticService.getPurchaseAttention().then(res => {
      this.sale_condition_text = res.data.main_text;
    });

    this.scrollTerm()
  },
}
</script>
