<template>
  <div>
    <button
      :class="[
        isPrimary
          ? 'btn-red-white btn-100--red'
          : 'btn-red-white btn-100--white',
      ]"
      type="button"
      @click="() => $emit('onClick')"
    >
      {{ title }}
    </button>
  </div>
</template>
<script>
export default {
  name: 'Button',
  props: {
    title: {
      type: String,
      required: true,
    },
    isPrimary: {
      type: Boolean,
      require: false,
    },
  },
}
</script>
<style scoped>
.btn-red-white {
  border: 2px solid #000 !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  padding: 16px 5px;
  text-align: center;
  cursor: pointer;
  transition: 0.3s ease;

  display: block;
  width: 95%;
  max-width: 420px;
  margin: 10px auto;

  line-height: 1.25;
  font-size: 1.3rem;
  font-weight: 700;
}

.btn-red-white:hover {
  opacity: 0.7;
}

@media screen and (min-width: 768px) and (max-width: 834px) {
  .btn-red-white {
    font-size: 1.6rem;
    line-height: 1.25;
  }
}

@media screen and (max-width: 767px) {
  .btn-red-white {
    font-size: 1.4rem;
    line-height: 1.25;
  }
}

.btn-100--red {
  background-color: #b90000;
  color: #fff;
}

.btn-100--white {
  background-color: #fff;
  color: #000;
}
</style>
