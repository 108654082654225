<template>
  <BaseStatic
    :static-page="staticPage"
  />
</template>
<script>

import StaticServie from '@services/StaticService';
// import Constants from '@constants';

export default {
  components: {
    BaseStatic: () => import('@pages/static/base/Static'),
  },
  data() {
    return {
      staticPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      }
    }
  },
  created() {
    StaticServie.getTopPage().then(res => {
      this.staticPage = res.data
      // this.checkVisible(res.data.visible_status);
      // this.checkAuthorization(res.data.authorization);
    }).catch(() => {
      this.$router.push({name: 404});
    });
  },
  // methods: {
  //   checkAuthorization(authorization) {
  //     if (authorization == Constants.LOGIN_REQUIRED) {
  //       if (!this.$cookies.isKey(Constants.ACCESS_TOKEN)) {
  //         this.$router.push({name: "auth.login"});
  //       }
  //     }
  //   },
  //   checkVisible(visible) {
  //     if (visible == Constants.NOT_DISPLAY) {
  //       this.$router.replace({name: "404"})
  //     }
  //   }
  // }
}
</script>
