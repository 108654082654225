<template>
  <div>
    <tag :is="tag" :class="[ 'section-title', { 'no-bg-color': noBgColor } ]" :style="headStyle">{{ title }}</tag>
  </div>
</template>
<script>
export default {
  name: 'Headline',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'h3',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    noBgColor: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    headStyle() {
      const result = {}
      if (this.color) {
        result.color = this.color
      }
      return result
    }
  }
}
</script>
