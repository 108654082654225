export default {
  state: {
    is_error: false,
    error_type: null,
    errors: [],
  },
  mutations: {
    SET_ERROR(state, val) {
      if (val) {
        state.is_error = true
        state.error_type = val.error_type
        state.errors = val.errors
      } else {
        state.is_error = false
        state.error_type = null
        state.errors = []
      }
    }
  },
  actions: {
    setPurchaseError({commit}, data) {
      commit('SET_ERROR', data)

      const commitDictionary = {
        event_sale_period_ended: 'SET_IS_EVENT_SALE_TERM_EXPIRED',
        product_stock: 'SET_IS_PRODUCT_OUT_OF_STOCK',
      }

      commit(commitDictionary[data.error_type], true)
    }
  }
}
