import Vue from 'vue'
import BaseService from "./BaseService";
import Constants from '@constants';

class AuthService extends BaseService {

  constructor(prefix) {
    super(prefix);
  }

  login(data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    return this.performRequest(BaseService.METHOD_POST, 'login', data, {}, {
      scroll_to_input_on_error: true
    });
  }

  logout() {
    return this.performRequest(BaseService.METHOD_POST, 'logout')
      .then(() => {
        Vue.$cookies.remove('access_token')
        Vue.$cookies.remove('member')
      });
  }

  requestResetPassword(data) {
    return this.performRequest(BaseService.METHOD_POST, 'reset-password', data, {}, {
      is_ignore_interceptors_response_error: true
    });
  }

  changePassword(data) {
    return this.performRequest(BaseService.METHOD_POST, 'change-password', data);
  }

  checkResetToken(data) {
    return this.performRequest(BaseService.METHOD_POST, 'check-reset-token', data, {}, {
      is_ignore_interceptors_response_error: true
    });
  }

}

export default new AuthService('');
