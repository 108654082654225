<template>
  <div>
    <dl class="category-dl mt-10">
      <dt class="category-dl__cart">{{ $t('category.in_the_current_cart') }}</dt>
      <dd v-if="!isCombinedProduct" class="category-dl__count category-dl__count--total first">
        <p>{{ total }}<span class="small">{{ $t('category.sheet') }}</span></p>
      </dd>
      <dd :class="['category-dl__count', 'category-dl__count--set-total', {'is_combined_product': isCombinedProduct}]">
        <p>{{ setTotal }}<span class="small">{{ $t('category.set') }}</span></p>
      </dd>
      <dd :class="['category-dl__count', 'category-dl__count--price', {'is_combined_product': isCombinedProduct}]">
        <p>¥{{ amount }}<span class="small">({{ $t('category.tax_included') }})</span></p>
      </dd>
      <dd v-if="!is_mobile" class="category-dl__link">
        <a style="cursor: pointer" @click="seeTheCart">{{ $t('category.see_the_cart') }}</a>
      </dd>
    </dl>
    <dd v-if="is_mobile" class="category-dl__link">
      <a style="cursor: pointer" @click="seeTheCart">{{ $t('category.see_the_cart') }}</a>
    </dd>
  </div>
</template>
<script>
export default {
  props: {
    is_mobile: {
      type: Boolean,
      default: false
    },
    total: {
      type: [String, Number],
      default: 0
    },
    setTotal: {
      type: [String, Number],
      default: 0
    },
    amount: {
      type: [String, Number],
      default: 0
    },
    isCombinedProduct: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    seeTheCart() {
      this.$emit('to-cart')
    }
  }
}
</script>
<style lang="scss" scoped>

@media screen and (max-width: 767px){
  .category-dl__count {
    padding: 0;
    &.is_combined_product {
      height: 56px;
    }
  }
  .category-dl__count--total {
    width: 28% !important;
  }
  .category-dl__count--set-total {
    width: 28% !important;
    &.is_combined_product {
      width: 50% !important;
      border-left: none;
    }
  }
  .category-dl__count--price {
    width: 44% !important;
    &.is_combined_product {
      width: 50% !important;
    }
  }
}

</style>
