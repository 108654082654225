export default {
  title: {
    main: 'Inquiry',
    main_jp: "お問い合わせ",
    create: 'お問い合わせ内容をご記入ください',
    confirmation: "お問い合わせ内容をご確認ください",
    complete: "お問い合わせが完了しました。"
  },
  sub_title: {
    create: "下記の必要事項をご入力ください。絵文字の使用はできません。<br>氏名などはお問い合わせ時の検索等で必要となりますので正しくご入力ください。<br><span class='c-red'>※ は入力必須項目です。</span>",
    confirmation: "入力内容をご確認ください。",
  },
  label: {
    name: "お名前",
    first_name: "名",
    last_name: "姓",
    phone_number: "電話番号",
    phone_number_confirm: "電話番号確認用",
    email: "メールアドレス",
    email_confirm: "メールアドレス確認用",
    category: "お問い合わせ項目",
    content: "お問い合わせ内容​",
  },
  input: {
    name: "お名前 <span class='c-red'>※</span>",
    first_name: "名",
    last_name: "姓",
    phone_number: "電話番号 <span class='c-red'>※</span>",
    phone_number_confirm: "電話番号確認用 <span class='c-red'>※</span>",
    email: "メールアドレス <span class='c-red'>※</span>",
    email_confirm: "メールアドレス確認用 <span class='c-red'>※</span>",
    category: "お問い合わせ項目 <span class='c-red'>※</span>",
    content: "お問い合わせ内容​ <span class='c-red'>※</span>",
  },
  placeholder: {
    phone_number: "半角数字・ハイフン無し",
    phone_number_confirm: "電話番号を再度入力してください。",
    email: "メールアドレスを入力してください。",
    email_confirm: "メールアドレスを再度入力してください。",
    content: "お問い合わせ内容について記載してください。お急ぎの場合は連絡可能時間を必ず明記してください。"
  },
  btn: {
    submit: "内容を確認する",
    send: "送信する",
    confirm: "アーティストトップへ戻る",
    back: "修正する",

  },
}