<template>
  <div>
    <Modal :modal="isShowModal" width="90%">
      <div slot="header" class="delivery-modal-header">お届け先不備に関する注意事項</div>
      <div slot="body" class="delivery-modal-body" ref="modalBody">

        <keep-proportion-content
          :proportion-target="() => $refs.modalBody"
          :p-sp-width="300"
          :gap-sp="0"
        >
          <p class="c-red mb-10 delivery-modal-caution">お届け先や電話番号が間違っていると商品をお届けできません。</p>

          <p style="font-weight: bold">
            以下の内容でお間違えないですか？<br class="mb-elem" />最終確認をお願い致します。<br/>
            再送付は宅配850円 ネコポス便330円 <br class="mb-elem" />
            ゆうメール220円<br/>
            各税込み費用を実費負担となります。<br/>
            ここで最終確認し、無駄な費用をかけないようご確認ください。
          </p>

        </keep-proportion-content>

        <section>
          <dl class="common-dl delivery-modal-form">
            <dt>{{ $t('purchase.postal_code') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.postal_code')"
                :value="userInfo.zip_code || userInfo.postal_code"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.prefectures') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.prefectures')"
                :value="userInfo.prefecture"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.street_address') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.street_address')"
                :value="userInfo.address"
                class="form-text--100"
                readonly
              >
            </dd>
            <dt></dt>
            <dd>
              <input
                :name="$t('purchase.city_address')"
                :value="userInfo.address_2"
                class="form-text--100"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.phone_number') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.phone_number')"
                :value="userInfo.phone_number"
                class="form-text--50"
                readonly
              >
            </dd>
            <dt>{{ $t('purchase.mail_address') }}<span class="c-red">※</span></dt>
            <dd>
              <input
                :name="$t('purchase.mail_address')"
                :value="userInfo.email"
                class="form-text--100"
                readonly
              >
            </dd>
          </dl>


        </section>

        <keep-proportion-content
          :proportion-target="() => $refs.modalBody"
          :p-sp-width="300"
          :gap-sp="0"
        >
          <p class="c-red mb-10 delivery-modal-caution">お届け先や電話番号が間違っていると商品をお届けできません。</p>
        </keep-proportion-content>

      </div>

      <div slot="footer" class="delivery-modal-footer">

        <label class="check">
          <input v-model="confirmed" name="confirmed"
                 type="checkbox">
          <span class="check--dummy"></span>
          <span class="check--text">上記内容で問題ないですか？</span>
        </label>

        <button
          :class="`btn-100--red`"
          :disabled="!confirmed"
          role="button"
          @click="handleConfirm"
        >
          お届け先不備に関する<br />注意事項を確認しました
        </button>

        <button class="btn-100" role="button" @click="handleCancel">
          修正する
        </button>

      </div>
    </Modal>
  </div>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  name: "TermOfIncompleteAddressConfirmModal",
  components: {
    Modal: () => import('@components/base/Modal'),
    KeepProportionContent
  },
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    },
    userInfo: {
      type: Object,
      required: true,
    }
  },
  data() {
    return {
      confirmed: false
    }
  },
  methods: {
    handleConfirm() {
      if (this.confirmed === true) {
        this.$set(this, 'confirmed', false)
        this.$emit('term-confirm')
      }
    },
    handleCancel() {
      this.$set(this, 'confirmed', false)
      this.$emit('term-cancel')
    }
  }

}
</script>

<style lang="scss" scoped>
@import '../../../assets/scss/common/variables';
@import '../../../assets/scss/common/mixins';

.delivery-modal-header {
  font-weight: bold;
  margin: 0 80px;
  @include mq-s {
    margin: 0;
  }
  border-bottom: 1px solid black;
}

.delivery-modal-body {
  margin: 0 80px;
  padding-top: 12px;
  @include mq-s {
    margin: 0;
  }
}

.delivery-modal-form {
  padding: 0 50px;
  @include mq-s {
    padding: 0;
  }
}


.delivery-modal-footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.btn--disabled {
  background-color: #ACABAB !important;
}

.delivery-modal-caution {
  font-size: 16px;
}

</style>
