<template>
  <article>
    <Title :subtitle="$t('auth.login_subtitle')" :title="$t('auth.login_title')"/>

    <section class="content pb-60">
      <div class="content__inner">
        <template v-if="!is_rest_complete">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <section>
              <HeadLine :title="$t('auth.new_pw.title')"/>

              <p class="mb-30">
                {{ $t('auth.new_pw.msg_1') }}<br>
                {{ $t('auth.new_pw.msg_2') }}
              </p>

              <ErrorAlert v-if="invalid" class="mb-20">{{ $t('auth.new_pw.invalid') }}</ErrorAlert>

              <div class="bg-gray pt-30 pb-30 mb-30">
                <dl class="login-dl">
                  <dt class="bold large">{{ $t('auth.new_pw.new_pw') }}</dt>

                  <dd class="mb-10">
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="{ min:8,max:32,regex: /^([a-zA-Z0-9#?!@$%^&*_-]*)$/ }"
                      :vid="$t('auth.new_pw.new_pw')"
                    >
                      <div class="form-password">
                        <input
                          v-model="password"
                          :name="$t('auth.new_pw.new_pw')"
                          :placeholder="$t('auth.new_pw.new_pw_placeholder')"
                          class="login-dl__input form-password__input"
                          :type="is_visible_password ? 'text' : 'password'"
                        >

                        <div class="form-password__append">
                          <IconEye
                            v-if="is_visible_password"
                            class="form-password__append__icon"
                            @click.stop="() => is_visible_password = !is_visible_password"
                          />

                          <IconEyeSlash
                            v-else
                            class="form-password__append__icon"
                            @click.stop="() => is_visible_password = !is_visible_password"
                          />
                        </div>
                      </div>

                      <p v-t="'auth.new_pw.new_pw_hint'" class="small"></p>

                      <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>

                  <dt class="bold large">{{ $t('auth.new_pw.confirm_pw') }}</dt>

                  <dd>
                    <ValidationProvider
                      v-slot="{ errors }"
                      :rules="`required|min:8|max:32|confirmed:${$t('auth.new_pw.new_pw')}`"
                    >
                      <div class="form-password">
                        <input
                          v-model="password_confirmation"
                          :name="$t('auth.new_pw.confirm_pw')"
                          :placeholder="$t('auth.new_pw.confirm_pw_placeholder')"
                          class="login-dl__input form-password__input"
                          :type="is_visible_password__confirmation ? 'text' : 'password'"
                        >

                        <div class="form-password__append">
                          <IconEye
                            v-if="is_visible_password__confirmation"
                            class="form-password__append__icon"
                            @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                          />

                          <IconEyeSlash
                            v-else
                            class="form-password__append__icon"
                            @click.stop="() => is_visible_password__confirmation = !is_visible_password__confirmation"
                          />
                        </div>
                      </div>

                      <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </dd>
                </dl>
              </div>

              <Button :title="$t('auth.new_pw.reset')" @onClick="handleSubmit(reset)"/>
            </section>
          </validation-observer>
        </template>

        <template v-if="is_rest_complete">
          <section>
            <HeadLine :title="$t('auth.reset_pw_complete.title')"/>

            <p class="mb-60">
              {{ $t('auth.reset_pw_complete.msg_1') }} <br>
              {{ $t('auth.reset_pw_complete.msg_2') }}
            </p>

            <Button :title="$t('auth.return_to_login_page')" @onClick="loginPage"/>
          </section>
        </template>
      </div>
    </section>

    <Modal :modal="invalidToken" width="300px">
      <div slot="body" class="body">
        URLの有効期限が切れています。
        <br>
        ログイン画面へと移動します。
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__ok" @click="loginPage">OK</button>
      </div>
    </Modal>
  </article>
</template>

<script>
import AuthService from '@services/AuthService';

export default {
  data() {
    return {
      is_rest_complete: false,
      password: "",
      password_confirmation: "",
      invalid: false,
      invalidToken: false,
      is_visible_password: false,
      is_visible_password__confirmation: false,
    };
  },
  methods: {
    reset() {
      const token = this.$route.params ?? '';
      AuthService.changePassword({
        password: this.password,
        password_confirmation: this.password_confirmation,
        reset_token: token.token
      })
        .then(() => {
          this.is_rest_complete = true;
          this.invalid = false;
        })
        .catch(() => {
          this.invalid = true;
        });
    },
    loginPage() {
      this.$router.push({name: "auth.login"});
    }
  },
  mounted() {
    //check if reset token exist
    if (this.$route.params) {
      AuthService.checkResetToken({
        reset_token: this.$route.params.token
      })
        .catch((e) => {
          console.error(e)
          this.invalidToken = true
        });
    }
  },
  components: {
    HeadLine: () => import("@components/base/Headline"),
    Title: () => import("@components/base/Title"),
    Button: () => import("@components/base/Button"),
    ErrorAlert: () => import("@components/base/ErrorAlert"),
    Modal: () => import('@components/base/Modal'),
    IconEye: () => import("@components/base/IconEye"),
    IconEyeSlash: () => import("@components/base/IconEyeSlash"),
  },
}
</script>
