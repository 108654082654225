<template>
  <ul class="pagination">
    <li class="page-item">
      <a
        :class="{ disabled: current_active_page == 1 }"
        class="page-link"
        href="javascript:void(0)"
        @click="set_current_active_page(--current_active_page)"
      >
        <i class="fas fa-chevron-left"></i>
      </a>
    </li>
    <li
      v-for="page in total_pages"
      :key="page"
      :class="{ active: page === current_active_page }"
      class="page-item"
    >
      <a
        v-if="Math.abs(page - current_active_page) < interval || page == 0"
        :class="{ active: page === current_active_page }"
        class="page-link"
        href="javascript:void(0)"
        @click="set_current_active_page(page)"
      >
        {{ page }}
      </a>
    </li>
    <li class="page-item">
      <a
        :class="{ disabled: current_active_page == total_pages }"
        class="page-link"
        href="javascript:void(0)"
        @click="set_current_active_page(++current_active_page)"
      >
        <i class="fas fa-chevron-right"></i>
      </a>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    // interval for lower and upper value
    interval: {
      type: Number,
      default: 2,
    },
    total_pages: {
      type: Number,
      required: true,
    },
    current_page: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      current_active_page: 1,
    }
  },
  watch: {
    current_page(page) {
      this.current_active_page = page
    },
  },
  mounted() {
    this.current_active_page = parseInt(this.current_page)
  },
  methods: {
    set_current_active_page(page) {
      this.current_active_page = page
      this.$emit('onPageChange', page)
    },
  },
}
</script>

<style scoped>
.disabled {
  pointer-events: none;
}
</style>
