export default {
  jp_currency: "¥",
  confirmation_of_consent_for_purchase_by_minors: "未成年者購入に関する同意確認",
  minor_members_need_parental_consent_to_purchase_products: "未成年者様が商品を購入するには、保護者の同意が必要です。",
  do_you_have_the_consent_of_your_parents_to_purchase_this_product: "保護者から今回の商品購入の同意を得ていますか?",
  yes_i_have_consent: "はい、私は同意を得ています。",
  to_payment: "お支払いへ",
  payment_method_selection: "お支払い方法選択",
  please_select_your_preferred_payment_method_below: "下記より、ご希望のお支払い方法を選択してください。",
  web_money_payment_service: "WebMoney決済サービス",
  credit_settlement: "クレジット決済",
  convenience_store_settlement: "コンビニ決済",
  please_select: "選択してください",
  seven_eleven: "セブンイレブン",
  lawson: "ローソン",
  family_mart: "ファミリーマート",
  customers_using_convenience_store_payment: "【コンビニ決済ご利用のお客様】",
  payment_will_be_prepaid: "お支払は前払いとなります。ご指定のコンビニでのお支払い後、決済会社からの入金通知確認後、製造・発送となります。ご注文だけでは、商品は届きませんのでご注意ください。",
  if_payment_is_completed: "平日ご注文の13時までにお支払いが完了すると当日注文としてお受けし商品の製造をいたします。",
  please_note_that_the_order: "期限内に支払ができませんと注文はキャンセルとさせていただきますので予めご了承ください。",
  docomo_carrier_payment: "ドコモキャリア決済",
  softbank_carrier_settlement: "ソフトバンクキャリア決済",
  au_easy_payment: "auかんたん決済",
  confirmation_of_ordered_products: "ご注文商品確認",
  please_proceed_to_the_payment: "ご購入内容・お届け先の情報にお間違えのないことをご確認の上、お支払い手続きへとお進みください。",
  ordered_product: "ご注文商品",
  tax_included: "(税込)",
  quantity: "数量",
  subtotal: "小計",
  set: "セット",
  estimated_delivery: "製造目安",
  shipping_method_selection_charge: "配送方法選択/料金/お届け目安",
  selectable_shipping_method_is_nothing: "選択できる配送方法がありません",
  after_payment_is_completed: "決済完了後",
  business_day: "営業日",
  day: "日",
  yu_mail: "ゆうメール(日本郵便)",
  yamato_transport: "宅配便(ヤマト運輸)",
  sagawa_express: "宅配便(佐川急便)",
  express: "宅配便",
  nekopos: 'ネコポス便',
  shipping: "送料",
  payment_fee: "決済手数料",
  total_payment_amount: "お支払い金額合計",
  the_password_will_not_be_sent_by_e_mail_please_make_sure_not_to_forget_it: "※パスワードはメール等で送信いたしません。お忘れにならないようご確認ください。",
  send_e_mail_newsletter: "メールマガジン送付",
  please_confirm_your_consent: "未成年者購入に関する同意確認を行ってください。",
  time: "時間",
  payment_method: "支払方法",
  carrier_payment: "運送業者の支払い",
  convenience_store_type: "コンビニエンスストアタイプ",
  note: "注意",
  minimart_payment: "ミニマート決済",
  mobile_payment: "モバイル決済",
  "1": "クレジット決済",
  "2": "コンビニ決済",
  "3": "セブンイレブン",
  "4": "ローソン(スリーエフ含む)​",
  "5": "ファミリーマート(サンクス、サークルK含む)​",
  "6": "モバイル決済",
  "7": "ドコモキャリア決済",
  "8": "auかんたん決済",
  "9": "ソフトバンクキャリア決済",
  "10": "ミニストップ",
  "11": "セイコーマート",
  delivery_time_option: {
    "Morning (until 12:00)": "午前中(8時 - 12時)",
    "Morning (8:00 - 12:00)": "午前中(8時 - 12時)",
    "12:00 - 14:00": "12:00 - 14:00",
    "14:00 - 16:00": "14:00 - 16:00",
    "16:00 - 18:00": "16:00 - 18:00",
    "18:00 - 20:00": "18:00 - 20:00",
    "18:00 - 21:00": "18:00 - 21:00",
    "19:00 - 21:00": "19:00 - 21:00"
  },
  add_another_product: "他の商品を追加する​",
  cancel_purchase: "購入を中止しキャンセルして終了する​",
  cancel_purchase_msg: "購入を中止しキャンセルして終了します。<br>カートの中身を空にして、<br>アーティストトップへ戻ります。",
  cancel_edit_address: "キャンセル​",
  update_edit_address: "更新​"
}
