<template>
  <div>
    <MessageWhenHidden
      v-if="isMsgHidden"
      :content="msgContent"
      :is_mobile="isMobile"
      :subtitle="msgSubTitle"
      :title="msgTitle"
    />

    <template v-else>
      <NormalArtistTemplate
        v-if="isNormalTemplate"
        :artist="artist"
        :artistBanners="artistBannersProp"
        :events="events"
        :is-mobile="isMobile"
      >
        <template #term_service>
          <term-service
            :is-show="!eventListAttentionVerified && events.length > 0 "
            @attention-term-service-click="onTermServiceClick"
          />
        </template>
      </NormalArtistTemplate>

      <ArrangedArtistTemplate
        v-else
        :artist="artist"
        :artistBanners="artistBannersProp"
        :events="events"
        :is-mobile="isMobile"
      >
        <template #term_service>
          <term-service
            :is-show="!eventListAttentionVerified && events.length > 0 "
            @attention-term-service-click="onTermServiceClick"
          />
        </template>
      </ArrangedArtistTemplate>
    </template>
  </div>
</template>

<script>

export default {
  components: {
    NormalArtistTemplate: () => import('@components/artist/NormalArtistTemplate'),
    ArrangedArtistTemplate: () => import('@components/artist/ArrangedArtistTemplate'),
    MessageWhenHidden: () => import('@components/base/MessageWhenHidden'),
    TermService: () => import('@components/artist/TermService')
  },
  props: {
    isMsgHidden: {
      type: Boolean,
      required: true
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    msgTitle: {
      type: String,
      default: ''
    },
    msgSubTitle: {
      type: String,
      default: ''
    },
    msgContent: {
      type: String,
      default: ''
    },
    artist: {
      type: Object,
      default: () => ({})
    },
    events: {
      type: Array,
      default: () => []
    },
    artistBanners: {
      type: Array,
      default: () => []
    },
    eventListAttentionVerified: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isNormalTemplate() {
      return this.artist.arrange_template === 'normal'
    },
    artistBannersProp() {
      return this.artistBanners.filter((banner) => banner.banner_image_url !== null && banner.banner_image_url !== '');
    },
  },
  methods: {
    onTermServiceClick() {
      this.$emit('term-service-click')
    },
  },
};
</script>
