<template>
  <article>
    <Title :subtitle="$t('cart.cart_subtitle')" :title="$t('cart.cart_title')"/>
    <section class="content pb-60">
      <div class="content__inner">
        <section class="mb-60">
          <p v-if="isHaveProduct" ref="haveProduct">
            <keep-proportion-content
              :content="$t('cart.msg_1')"
              :proportion-target="() => $refs.haveProduct"
              :gap-sp="0"
            />
            <keep-proportion-content
              :content="$t('cart.msg_2')"
              :proportion-target="() => $refs.haveProduct"
              :gap-sp="0"
            />
          </p>
          <p v-else ref="haveNotProduct">
            <keep-proportion-content
              :content="$t('cart.cart_empty_msg_1')"
              :proportion-target="() => $refs.haveNotProduct"
              :gap-sp="0"
            />
            <keep-proportion-content
              :content="$t('cart.cart_empty_msg_2')"
              :proportion-target="() => $refs.haveNotProduct"
              :gap-sp="0"
            />
          </p>
        </section>

        <section class="mb-60">
          <validation-observer ref="cart_observe" class="content__inner" tag="div">
            <div class="scroll-x">
              <table class="cart-table mb-30">
                <thead>
                <tr class="pc-elem">
                  <th>{{ $t('cart.product_image') }}</th>
                  <th>{{ $t('cart.product_name') }}</th>
                  <th>{{ $t('cart.price') }}</th>
                  <th>
                    {{ $t('cart.quantity') }}
                    <span class="cart-table__note pc">※数量を変更すると自動的に変更されます</span>
                  </th>
                  <th>{{ $t('cart.delete') }}</th>
                </tr>
                </thead>

                <template v-if="localCart">
                  <tbody
                    v-for="(cart_category, index) in localCart.cartDatas"
                    :key="index"
                    class="warning-when-purchase-procedure-fiale"
                  >
                  <tr
                    v-for="(cart_product, cart_product_index) in cart_category.cart_products"
                    :key="cart_product_index"
                  >
                    <td class="al-c">
                      <div class="cart-table__image__wrapper">
                        <img :src="cart_product.sale_product_main_image_url" alt="" class="cart-table__image">
                        <img v-if="cart_product.sub_commodity" :src="cart_product.sub_commodity.commodity_image_url" alt="" class="cart-table__image">
                      </div>
                    </td>

                    <td :class="isProductError(cart_product) ? 'c-red' : ''">
                      {{ cart_product.sale_product_name }}

                      <br>

                      <span :class="`${isProductError(cart_product) ? 'c-red' : 'c-darkgray'} small`">
                        ID:{{cart_product.sale_product_id}}
                      </span>

                      <br>
                    </td>

                    <template v-if="cart_category.is_combined_product || cart_category.category_type == 'printed_product'">
                      <td v-if="!cart_product_index" class="cart-table__price pc" :rowspan="cart_category.cart_products.length">
                        <span class="s-elem mr-10">{{ $t('cart.price') }}：</span>
                        <span class="large">¥{{ subTotal(cart_category) }}</span>
                        <span class="c-darkgray">({{ $t('cart.tax_included') }})</span>
                        <br>
                        <span
                          v-if="cart_category.cart_category_total_qty % cart_category.purchasable_unit != 0"
                          class="c-red"
                        >
                          {{ $t('cart.invalide_qty_in_printed_sets', { qty_per_set: cart_category.bundled_unit }) }}
                        </span>
                      </td>

                      <td class="cart-table__price sp">
                        <span class="s-elem mr-10">{{ $t('cart.price') }}：</span>
                        <span class="large">¥{{ cart_product.cart_product_subtotal_include_tax }}</span>
                        <span class="c-darkgray">({{ $t('cart.tax_included') }})</span>
                      </td>
                    </template>

                    <template v-else>
                      <td>
                        <span class="s-elem mr-10">{{ $t('cart.price') }}：</span>
                        <span class="large">¥{{ cart_product.cart_product_subtotal_include_tax }}</span>
                        <span class="c-darkgray">({{ $t('cart.tax_included') }})</span>
                      </td>
                    </template>

                    <td>
                      <validation-provider
                        v-slot="{ errors }"
                        :name="$t('category_goods.quantity')"
                        :rules="`${cart_product.sale_product_always_keep_in_stock ? '' : `max_value:${cart_product.sale_product_number_of_stock}`}|min_value:1|required`"
                        :vid="cart_product.cart_product_id"
                      >
                        <ol>
                          <li>
                            <input
                              v-model="cart_product.cart_product_qty"
                              :max="cart_product.sale_product_always_keep_in_stock ? '' : cart_product.sale_product_number_of_stock"
                              :type="isPhone ? 'tel' : 'number'"
                              class="cart-table__input"
                              min="1"
                              name="auth"
                              @blur="onUpdateProductClick(cart_product)"
                              @input="onUpdateProductQuantity(index, cart_product_index, cart_product)"
                            >
                          </li>

                          <li v-if="errors[0]">
                            <p class="small c-red validate-error">
                              <template
                                v-if="!cart_product.sale_product_always_keep_in_stock && (cart_product.sale_product_number_of_stock < cart_product.cart_product_qty)">
                                {{ $t('category_goods.stock_quantity') }}：{{ cart_product.sale_product_number_of_stock }}
                                {{ $t('category_goods.set') }}
                              </template>

                              <template v-else-if="cart_product.cart_product_qty == 0">
                                {{ errors[0] }}
                              </template>

                              <template v-else>
                                {{ errors[0] }}
                              </template>
                            </p>
                          </li>
                          <li class="cart-table__note tb">
                            ※数量を変更すると自動的に変更されます
                          </li>
                        </ol>
                      </validation-provider>
                    </td>

                    <td>
                      <div class="flex between">
                        <span class="cart-table__note sp">※数量を変更すると自動的に変更されます</span>
                        <input
                          :value="$t('cart.delete')"
                          class="c-darkgray link-line bg-white"
                          name="delete"
                          type="button"
                          @click="onDeleteProductClick(cart_product)"
                        >
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </template>
                <tr :key="`cartSheetAmount-${cart.cartSheetAmount}-cartSubtotal-${cart.cartSubtotal}`">
                  <td class="al-r" colspan="5">
                    <div v-if="isHaveProduct" class="cart-table__al-r pb-10">
                      <input
                        class="c-darkgray link-line bg-white"
                        name="delete"
                        type="button"
                        value="カートの中身を空にする"
                        @click="$store.commit('SET_IS_REMOVE_ALL_PRODUCTS_POPUP', true)"
                      >
                    </div>

                    <p>
                      <span class="cart-table__label-wrapper bold">
                        <span class="cart-table__label">{{ $t('cart.number_of_products') }}</span>
                        <span class="large">{{ cartTotalQty }}</span>
                        {{ $t('cart.sheet') }}
                        <br>
                      </span>
                    </p>

                    <p>
                      <span class="cart-table__label-wrapper bold">
                        <span class="cart-table__label">{{ $t('cart.subtotal_amount') }}</span>
                        <span class="large">¥{{ cart.cartSubtotal }}</span>
                        <span class="c-darkgray">({{ $t('cart.tax_included') }})</span>
                      </span>
                    </p>
                  </td>
                </tr>
              </table>
            </div>

            <template v-if="isHaveProduct">
              <div class="mb-20">
                <button
                  :disabled="!cart.cartSheetAmount"
                  class="btn-100--red"
                  role="button"
                  @click="gotoPurchase"
                >
                  {{ $t('cart.to_purchase_procedure') }}
                </button>
              </div>

              <div class="al-c">
                <a :href="artistTopUrl" class="link-line large bold" @click="onContinueShoppingClick">
                  {{ $t('cart.continue_shopping') }}
                </a>
              </div>
            </template>

            <div v-else class="mb-20">
              <button
                :key="`cartSheetAmount-${cart.cartSheetAmount}-cartSubtotal-${cart.cartSubtotal}`"
                class="btn-100--red"
                role="button"
                @click="onContinueShoppingClick"
              >
                {{ $t('cart.continue_shopping') }}
              </button>
            </div>
          </validation-observer>
        </section>
      </div>
    </section>

    <Modal :modal="isShowError">
      <div slot="body">{{ $t('cart.some_products_have_not_reached_the_set_number') }}</div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="isShowError = false">{{ $t('cart.ok') }}</button>
      </div>
    </Modal>
  </article>
</template>

<style scoped>
.modal-footer {
  display: flex;
  justify-content: center;
}

.footer__close {
  border: 2px solid black;
  cursor: pointer;
  padding: 1rem 3rem;
  background-color: transparent;
}
</style>

<script>
import Constants from '@constants'
import { mapState } from 'vuex'
import {cloneDeep} from "lodash";
import KeepProportionContent from "@components/base/KeepProportionContent";

export default {
  data() {
    return {
      isShowError: false,
      window: {
        width: 0,
        height: 0,
      },
      localCart: this.cart
    }
  },
  mounted() {
    if (this.purchase.is_error) {
      this.$refs.cart_observe.validate()
    }
  },
  created() {
    this.$store.dispatch('getCartData')

    let newWidth = window.innerWidth - 40

    if (newWidth <= 767) {
      let defaultWidth = 170
      let defaultHeight = 121.28
      newWidth = newWidth / 2
      let newHeight = newWidth * defaultHeight / defaultWidth
      this.imageStyle = 'padding-top:0;width:' + newWidth + 'px;height:' + newHeight + 'px;'
    }
  },
  methods: {
    isPhone() {
      return !(this.window.width > 767)
    },
    isProductError(cart_product) {
      var cartProduct = this.purchase.errors.find(el => el.sale_product_id == cart_product.sale_product_id)
      if (cartProduct) {
        return cartProduct.sale_product_current_qty < cart_product.cart_product_qty
      }
      return false
    },
    onDeleteProductClick(cart_product) {
      const dispatchName = cart_product.is_combined_product ? 'removeCombinedProductFromCart' : 'removeProductFromCart'

      const params = {
        id: cart_product.cart_product_id,
        isOnlyOneProduct: this.cart.cartDatas[0].cart_products.length === 1,
      }

      if (cart_product.is_combined_product) {
        params.commodity_id = cart_product.sub_commodity.commodity_id
      }

      this.$store.dispatch(dispatchName, params)
    },
    onUpdateProductQuantity(index, cart_product_index, cart_product) {
      this.$refs.cart_observe.validate().then(res => {
        if (res) {
          this.$store.commit('UPDATE_PRODUCT_CART_QUANTITY', { index, cart_product_index, cart_product })
        }
      })
    },
    async onUpdateProductClick(cart_product) {
      const isValid = await this.$refs.cart_observe.validate()

      if (isValid) {
        const dispatchName = cart_product.is_combined_product ? 'updateCombinedProductOnCart' : 'updateProductOnCart'

        const params = {
          purchasable_type: cart_product.purchasable_type,
          cart_product_id: cart_product.cart_product_id,
          qty: parseInt(cart_product.cart_product_qty),
        }

        if (cart_product.is_combined_product) {
          params.commodity_id = cart_product.sub_commodity.commodity_id
        }

        this.$store.dispatch(dispatchName, params)
      }
    },
    async gotoPurchase() {
      if (await this.$store.dispatch('getCartData')) {
        return
      }

      if (!(await this.$refs.cart_observe.validate())) {
        this.scrollToFirstError()
        return
      }

      // 選択した全商品がそれぞれのセット枚数分の購入数を満たしているかどうか
      const cartCategoryTypePrintedProduct = this.cart.cartDatas.filter(el => el.category_type == 'printed_product')

      for (let index = 0; index < cartCategoryTypePrintedProduct.length; index++) {
        const el = cartCategoryTypePrintedProduct[index]

        if (el.cart_category_total_qty % el.purchasable_unit != 0) {
          return this.isShowError = true
        }
      }

      const purchaseStepRouteName = this.$cookies.get(Constants.PURCHASE_STEP)

      if (purchaseStepRouteName && !(purchaseStepRouteName == 'purchase.attention' && this.$store.state.isLogged)) {
        return this.$router.push({ name: purchaseStepRouteName })
      }

      if (this.$store.state.isLogged) {
        return this.$router.push({ name: 'purchase.confirmation' })
      } else {
        return this.$router.push({ name: 'purchase.login' })
      }
    },
    async onContinueShoppingClick(event) {
      event.preventDefault()

      await this.$store.dispatch('getCartData').then(res => {
        if (!res) {
          this.$router.push({ name: 'artist.home' })
        }
      })
    },
    subTotal(cart_category) {
      if (cart_category.print_sale_plan_type === 'bundled') {
        const unit_price = cart_category.cart_products ? cart_category.cart_products[0].unit_price : 0
        const bundled_unit = parseInt(cart_category.bundled_unit)
        if (unit_price === 0 || Number.isNaN(bundled_unit)) return 0
        const bundled_price = unit_price * bundled_unit
        const result = bundled_price * cart_category.cart_category_set_amount
        return Math.ceil(result)
      } else {
        return cart_category.cart_category_subtotal_include_tax
      }
    }
  },
  watch: {
    cart: {
      deep: true,
      handler() {
        this.localCart = cloneDeep(this.cart)
      }
    }
  },
  computed: {
    ...mapState([
      'cart',
      'purchase',
    ]),
    isHaveProduct() {
      return this.cart.cartDatas.length !== 0
    },
    artistTopUrl() {
      return this.$router.resolve({ name: 'artist.home' }).href
    },
    cartTotalQty() {
      if (!this.localCart) return ''
      const result = this.localCart.cartDatas.reduce( (total, cart) => {
        total += parseInt(cart.cart_category_total_qty)
        return total
      }, 0)
      return !Number.isNaN(result) ? result : ''
    },
  },
  components: {
    Title: () => import('@components/base/Title'),
    Modal: () => import('@components/base/Modal'),
    KeepProportionContent,
  },
}
</script>
