<template>
  <div class="content__inner">
    <Headline :title="$t('registration.title.confirmation')"></Headline>
    <p class="mb-30" v-html="$sanitize($t('registration.sub_title.confirmation'))"></p>
    <div v-if="isError">
      <ErrorAlert
        v-for="message in errorMessages"
        :key="message"
        class="mb-20"
      >
        {{ message }}
      </ErrorAlert>
    </div>
    <section class="mb-30">
      <h4 v-t="'registration.title.register_section_1'" class="main-title"></h4>
      <dl class="common-dl">
        <dt v-html="$sanitize($t('registration.label.name'))"></dt>
        <dd>{{ name }}</dd>
        <dt v-html="$sanitize($t('registration.label.name_hiragana'))"></dt>
        <dd>{{ name_hiragana }}</dd>
        <dt v-html="$sanitize($t('registration.label.email'))"></dt>
        <dd>{{ entity.email }}</dd>
        <dt v-html="$sanitize($t('registration.label.gender'))"></dt>
        <dd> {{ $t('registration.options.' + entity.gender) }}</dd>
        <dt v-html="$sanitize($t('registration.label.occupation'))"></dt>
        <dd> {{ $t(`registration.occupation.${entity.occupation}`) }}</dd>
        <dt v-html="$sanitize($t('registration.label.birthday'))"></dt>
        <dd>{{ entity.date_of_birth.split('-')[0] + $t('general.year') }} {{ monthName }}
          {{ entity.date_of_birth.split('-')[2] + $t('general.day') }}
        </dd>
        <dt v-html="$sanitize($t('registration.label.password'))"></dt>
        <dd>
          {{ hidden_password }}
          <br>
          <span v-t="'registration.label.password_hidden'" class="small c-darkgray"></span>
        </dd>

      </dl>
    </section>
    <section class="mb-30">
      <h4 v-t="'registration.title.register_section_2'" class="main-title"></h4>
      <dl class="common-dl">
        <dt v-html="$sanitize($t('registration.label.postal_code'))"></dt>
        <dd>{{ entity.postal_code }}</dd>
        <dt v-html="$sanitize($t('registration.label.prefecture'))"></dt>
        <dd>{{ entity.prefecture }}</dd>
        <dt v-html="$sanitize($t('registration.label.address'))"></dt>
        <dd>
          <div>{{ entity.address }}</div>
          <div>{{ entity.address_2 }}</div>
        </dd>
        <dt v-html="$sanitize($t('registration.label.phone_number'))"></dt>
        <dd> {{ entity.phone_number }}</dd>
      </dl>
    </section>
    <Button :title="$t('registration.btn.confirm')" isPrimary @onClick="onConfirm()"></Button>
    <Button :title="$t('registration.btn.back')" @onClick="onBack()"></Button>
  </div>
</template>

<script>

import MasterDataService from '@services/MasterDataService';
import RegisterService from '@services/RegisterService';

export default {
  name: "RegisterConfirmation",
  props: {
    entity: {
      required: true
    },
  },
  data() {
    return {
      isError: false,
      errorMessages: null,
      prefecture_options: []
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    onConfirm() {
      RegisterService.register({
        ...this.entity,
        redirect_url: this.$route.query.current_artist
      })
        .then(() => {
          this.$emit('clickNext');
        })
        .catch((e) => {
          console.log(e.data.data)
          this.isError = true;
          window.scrollTo({ top: 0 })
          if (e.data.data && e.data.data.errors) {
            this.errorMessages = Object.keys(e.data.data.errors).reduce( (messages, key) => {
              const message = e.data.data.errors[key].map((_error) => {
                return _error
              });
              messages = [...messages, ...message]
              return messages
            }, [])
          }
        });
    },
    onBack() {
      this.$emit('clickBack', this.entity);
    },
    getMasterData() {
      MasterDataService.index({is_prefecture: true}).then(res => {
        this.prefecture_options = res.data.prefectures.map(item => ({
          value: item.value,
          text: item.label,
        }))
      })
    }
  },
  computed: {
    name() {
      return this.entity.last_name + " " + this.entity.first_name;
    },
    name_hiragana() {
      return this.entity.last_name_hiragana + " " + this.entity.first_name_hiragana;
    },
    hidden_password() {
      return Array(this.entity.password.length + 1).join("•")
    },
    monthName() {
      let date = new Date(this.entity.date_of_birth)
      return this.$t(`general.months.${date.getMonth()}`)
    },
  },
  components: {
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button'),
    ErrorAlert: () => import("@components/base/ErrorAlert")
  },
  created() {
    this.getMasterData();
  }
}
</script>
