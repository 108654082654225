<template>
  <div class="rest-shopping-modal">
    <Modal :modal="isShowModal" width="700px" show-close @close="handleClose">
      <template #header>
        <Title title="Rest Shopping" subtitle="商品選定を休憩する"/>
      </template>
      <template #body>

        <template v-if="pubKey">
          <div class="rest-shopping-content">
            <p>下記のキーを「途中から始める」ボタンを押していただくと移動する画面にご入力ください。<br />現在お選びの商品がカートに入った状態から商品の選定を行うことができます</p>
            <p>メモ帳への保存、スクリーンショットなどで保存することをお勧めいたします。</p>

            <dl class="common-dl">
              <dt>発行されたキー</dt>
              <dd>
                <input
                  :value="pubKey"
                  class="form-text--100"
                  maxlength="11"
                  required
                >
              </dd>
            </dl>

            <Button title="閉じる" @onClick="handleClose" />
          </div>

        </template>

        <template v-else>
          <div class="rest-shopping-content">
            <p>商品の選定を休憩し、お買い物途中から購入の手続きを行うことができます。</p>
            <p>お使いのブラウザを閉じたり、時間経過によってお選びいただいた商品がカートから削除されてしまう恐れがあります。</p>
            <p>商品選定情報を保存するためにメールアドレスおよび電話番号をご入力ください。<br />万が一商品選定キーを忘れてしまった場合のお問合せの際に利用いたします。</p>
            <p>下記の「キーを発行する」ボタンを押していただいた後に表示されるキーを、「途中から始める」ボタンを押した時に表示されるフォームにご入力ください。<br />現在お選びの商品がカートに入った状態から商品の選定を行うことができます。</p>
          </div>
          <validation-observer ref="regist_observer">
            <dl class="common-dl">

              <dt>電話番号 <span class='c-red'>※</span></dt>
              <dd>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="`phone_number|max:11|min:10`"
                  vid="rest-shop-phone_number"
                >
                  <input
                    id="phone_number"
                    v-model="phone_number"
                    :name="$t('registration.label.phone_number')"
                    :placeholder="$t('registration.placeholder.phone_number')"
                    class="form-text--100"
                    maxlength="11"
                    required
                    type="tel"
                  >
                  <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :rules="`phone_number|confirmed:rest-shop-phone_number`"
                  vid="rest-shop-phone_number_confirmation"
                >
                  <input
                    id="phone_number_confirm"
                    v-model="phone_number_confirmation"
                    :name="$t('registration.label.phone_number')"
                    :placeholder="$t('registration.placeholder.phone_number_confirm')"
                    class="form-text--100"
                    maxlength="11"
                    required
                    type="tel"
                  >
                  <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
                </validation-provider>
              </dd>

              <dt>メールアドレス <span class='c-red'>※</span></dt>
              <dd>
                <validation-provider
                  v-slot="{ errors }"
                  :rules="`required|email`"
                  vid="rest-shop-email"
                >
                  <input
                    id="email"
                    ref="email"
                    v-model="email"
                    :name="$t('registration.label.email')"
                    :placeholder="$t('registration.placeholder.email')"
                    class='form-text--100'
                    required
                    type="text"
                    @keydown.space.prevent
                  />

                  <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>

                </validation-provider>

                <validation-provider
                  v-slot="{ errors }"
                  :rules="`required|email|confirmed:rest-shop-email`"
                  vid="rest-shop-email_confirmation"
                >
                  <input
                    id="email_confirm"
                    v-model="email_confirmation"
                    :name="$t('registration.label.email')"
                    :placeholder="$t('registration.placeholder.email_confirm')"
                    class='form-text--100'
                    required
                    type="text"
                    @keydown.space.prevent
                  />
                  <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
                </validation-provider>
              </dd>
            </dl>

            <Button title="キーを発行する" isPrimary @onClick="handlePublishKey" />
            <Button title="商品選定を続ける" @onClick="handleClose" />
          </validation-observer>
        </template>
      </template>
    </Modal>
  </div>
</template>
<script>

export default {
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    },
    pubKey: {
      type: String,
      default: ''
    },
    errors: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      phone_number: '',
      phone_number_confirmation: '',
      email: '',
      email_confirmation: ''
    }
  },
  components: {
    Title: () => import('@components/base/Title'),
    Button: () => import('@components/base/Button'),
    Modal: () => import('@components/base/Modal')
  },
  methods: {
    handleClose() {
      this.$emit('close')
    },
    async handlePublishKey() {

      const valid = await this.$refs.regist_observer.validate();

      if (valid) {
        const {
          phone_number,
          phone_number_confirmation,
          email,
          email_confirmation
        } = this

        this.$emit('publish-key', {
          phone_number,
          phone_number_confirmation,
          email,
          email_confirmation
        })
      }

    }
  },
}
</script>
<style lang="scss">
@import '../../../assets/scss/common/variables';
.rest-shopping-modal {
  .modal-mask {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow-y: scroll;
    margin: auto;
    &:before {
      content: '';
      display: block;
      height: 8px;
      width: 100%;
    }
  }
  .modal-wrapper {
    display: block;
  }
}
.rest-shopping-content {
  font-size: 13px;
  color: $C_BLUE
}
</style>
