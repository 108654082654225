<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit }">
    <article>
      <Title :subtitle="$t('registration.send_verification.subtitle')"
             :title="$t('registration.send_verification.title')"/>
      <section class="content pb-60">
        <div v-if="!successSendVerification" class="content__inner">
          <section>
            <HeadLine :title="$t('registration.send_verification.headline')"/>
            <ErrorAlert v-if="error" class="mb-20">{{ $t('registration.send_verification.error') }}</ErrorAlert>
            <p class="mb-30"> {{ $t('registration.send_verification.message') }} </p>
            <div class="bg-gray pt-30 pb-30 mb-30">
              <dl class="login-dl">
                <dt class="bold large mb-10"> {{ $t('registration.send_verification.email') }}</dt>
                <dd class="mb-10">
                  <ValidationProvider v-slot="{ errors }" rules="required|email">
                    <input v-model="email" :name="$t('registration.send_verification.email')" class="login-dl__input"
                           type="email" @input="clearError">
                    <span v-if="errors[0]" class="c-red">{{ errors[0] }}</span>
                  </ValidationProvider>
                </dd>
              </dl>
            </div>
            <Button :title="$t('registration.send_verification.btn')" isPrimary
                    @onClick="handleSubmit(sendVerification)"/>
          </section>
        </div>
        <div v-if="successSendVerification && !error" class="content__inner">
          <Headline :title="$t('registration.title.complete')"></Headline>
          <h4 class="main-title" v-html="$sanitize($t('registration.sub_title.complete'))"></h4>
          <p class="mb-20" v-html="$sanitize($t('registration.label.complete_content'))"></p>
          <p v-t="$t('registration.send_verification.email_not_arrive')" class="bold"></p>
          <p v-t="$t('registration.send_verification.resend_email')" class="mb-50 link" @click="resendVerification"></p>
          <div class="mb-50">
            <Button :title="$t('registration.btn.complete')" isPrimary @onClick="onComplete()"></Button>
            <Button v-if="currentArtist" :title="$t('registration.btn.back_artist')" @onClick="onBackArtist()"></Button>
          </div>
        </div>
      </section>
    </article>
  </validation-observer>
</template>
<script>
import RegisterService from '@services/RegisterService';

export default {
  data() {
    return {
      email: "",
      error: false,
      successSendVerification: false
    };
  },
  computed: {
    currentArtist() {
      return this.$route.query.redirect || this.$route.query.current_artist
    }
  },
  methods: {
    clearError() {
      this.error = false;
    },
    sendVerification() {
      this.clearError()
      RegisterService.reSendEmailVerification({
        email: this.email,
        redirect_url: this.currentArtist,
      }).then(res => {
        if (res.meta.code === 200) {
          this.successSendVerification = true;
        }
      })
        .catch(() => {
          this.error = true;
        })
    },
    resendVerification() {
      this.successSendVerification = false;
    },
    onComplete() {
      this.$router.push({name: "auth.login"})
    },
    onBackArtist() {
      this.$router.push({name: "artist.home", params:{designate_page: this.currentArtist} })
    }
  },
  created() {
    if (this.$route.query.email) {
      this.email = this.$route.query.email;
    }
  },
  components: {
    HeadLine: () => import("@components/base/Headline"),
    Title: () => import("@components/base/Title"),
    Button: () => import("@components/base/Button"),
    ErrorAlert: () => import("@components/base/ErrorAlert")
  },
}
</script>

<style scoped>
.resend-email {
  cursor: pointer;
}
</style>
