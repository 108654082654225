import BaseService from "./BaseService";

class StaticService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getTopPage(data) {
    return this.performRequest(BaseService.METHOD_GET, 'top-page', data);
  }

  getTerm(data) {
    return this.performRequest(BaseService.METHOD_GET, 'term', data);
  }

  getGuide(data) {
    return this.performRequest(BaseService.METHOD_GET, 'guide', data);
  }

  getQa(data) {
    return this.performRequest(BaseService.METHOD_GET, 'qa', data);
  }

  getPrivacy(data) {
    return this.performRequest(BaseService.METHOD_GET, 'privacy', data);
  }

  getTrading(data) {
    return this.performRequest(BaseService.METHOD_GET, 'trading', data, {}, {
      hideLayout: true
    });
  }

  getDelivery(data) {
    return this.performRequest(BaseService.METHOD_GET, 'delivery', data);
  }

  getRegisterTerm(data) {
    return this.performRequest(BaseService.METHOD_GET, 'register-term', data);
  }

  getPurchaseAttention(data) {
    // console.log('get static', data)
    return this.performRequest(BaseService.METHOD_GET, 'purchase-attention', data);
  }

  getRootStaticPage(slug, data) {
    return this.performRequest(BaseService.METHOD_GET, slug, data, {}, {
      is_ignore_interceptors_response_error: true
    });
  }
}

export default new StaticService('static-pages');
