export default [
  {
    path: "category/:category_id",
    name: "category.page",
    component: () => import("@pages/category/Category"),
  },
  {
    path: "category-goods/:category_id",
    name: "category_goods.page",
    component: () => import("@pages/category/CategoryGoods"),
  },
];
