<template>
  <div v-if="isShow" class="agreement">
    <p class="bold mb-30">
      必ずお読みいただき確認後、
      <br class="mb-elem"/>
      商品閲覧・購入にお進みください。
      <br/>
      （確認後、購入フローへ進むことが可能となります。）
    </p>

    <div class="al-c">
      <a class="btn-100--red" @click="attentionTermService">注意事項を確認する</a>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isShow: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    attentionTermService() {
      this.$emit('attention-term-service-click')
    },
  },
}
</script>
