import Vue from 'vue';
import VueGtag from "vue-gtag";

const mutations = {

  PAGE_INIT(state, val) {
    state.pageInit = val
    if (val === true) {
      state.isLoading = false
      state.hideLayout = false
    }
  },

  SET_GLOBAL_LOADING(state, val) {
    this.state.isLoading = val

    this.commit(!val ? 'SHOW_LAYOUT' : 'HIDE_LAYOUT');
  },
  SET_IS_MOBILE(state, val) {
    state.isMobile = val
  },
  SET_CURRENT_ARTIST(state, payload) {
    state.current_artist = payload;
  },
  SET_IS_LOGIN(state, val) {
    state.isLogged = val
  },
  REMOVE_ARTIST(state) {
    state.current_artist = null;
  },
  /**
   * D4C クソコード
   * mutationで実行する必要なし
   * @param state
   * @param trackingId
   * @constructor
   */
  SUBSCRIBE_TO_GOOGLE_ANALYTIC(state, trackingId) {
    if (trackingId) {
      Vue.use(VueGtag, {
        config: {id: trackingId}
      });
    }
  },
  HIDE_LAYOUT(state) {
    state.hideLayout = true;
  },
  SHOW_LAYOUT(state) {
    state.hideLayout = false;
  },
  SET_ARTIST_CONFIRMATION(state, payload) {
    state.artistConfirmation = payload
  },
  SHOW_EVENT_LIST_ATTENTION(state) {
    state.showEventListAttention = true
  },
  CLOSE_EVENT_LIST_ATTENTION(state) {
    state.showEventListAttention = false
  },
  SET_GLOBAL_ERROR(state, error) {
    state.error_message = error
    state.has_error = true
  },
  RESET_GLOBAL_ERROR(state) {
    state.error_message = ''
    state.has_error = false
  }
}

export default mutations
