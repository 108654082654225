<template>
  <BaseStatic
    :static-page="staticPage"
  />
</template>

<script>
import ArtistTmpService from "@services/ArtistTmpService";
import StaticPageTmpService from "../../services/StaticPageTmpService";
import { mapState, mapMutations } from "vuex";

export default {
  components: {
    BaseStatic: () => import('@pages/static/base/Static'),
  },
  data() {
    return {
      staticPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      }
    }
  },
  async created() {
    await this.getStaticPagePreview()
  },
  computed: {
    ...mapState([
      'current_artist'
    ]),
  },
  methods: {
    ...mapMutations([
      'SET_CURRENT_ARTIST'
    ]),
    async getArtist (designatePageId) {
      try {
        const artist = await ArtistTmpService.getArtistByDesignatePage(designatePageId)
        this.SET_CURRENT_ARTIST(artist.data)
      } catch (e) {
        console.error(e)
      }
    },
    async getStaticPagePreview() {
      try {
        const res = await StaticPageTmpService.getStaticPagePreview(this.$route.params.token)

        const { artist_designate_page_directory } = res.data

        if (artist_designate_page_directory) {
          await this.getArtist(artist_designate_page_directory)
        }

        this.staticPage = res.data
      } catch (e) {
        this.$router.push({name: '404'})
      }
    },
  }
}
</script>
