export default {
  credit_card_information_input: "クレジットカード情報入力",
  please_enter_your_card_information: "以下の項目に従って、カード情報をご入力ください。",
  is_a_required_item: "は入力必須項目です。",
  accepted_credit_cards: "利用可能クレジットカード",
  credit_card_information: "クレジットカード情報",
  card_number: "カード番号",
  nominee: "名義人",
  security_code: "セキュリティーコード",
  what_is_a_security_code_1: "セキュリティーコードとは？",
  expiration_date: "有効期限",
  confirmation_of_your_order: "ご注文内容の確認",
  to_fix: "修正する",
  what_is_a_security_code: "セキュリティコードとは？",
  refers_to_the_last_3_or_4_digits: "クレジットカードの裏面、ご署名欄上部に記載されている番号の末尾3桁または4桁の数字を指します。",
  some_credit_cards_are_listed_on_the_surface: "（一部のクレジットカードは表面に記載されています）",
  if_you_do_not_know_the_security_code_or_it_is_not_printed: "セキュリティコードがわからない場合、または印字されていない場合は、お手数ですが、カード発行会社（カード裏面に記載されている連絡先）にお問い合わせください。",
  month: "月/",
  credit_card_is_invalid: "クレジットカードが無効です"
}