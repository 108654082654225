<template>
  <div class="content__inner">
    <Headline :title="$t('registration.title.complete')"></Headline>
    <h4 class="main-title" v-html="$sanitize($t('registration.sub_title.complete'))"></h4>
    <h4 v-t="'registration.label.complete_click_for_link'" class="mb-20 c-red bold"></h4>
    <p class="mb-50" v-html="$sanitize($t('registration.label.complete_content'))"></p>

    <div class="mb-50">
<!--      <Button :title="$t('registration.btn.complete')" isPrimary @onClick="onComplete()"></Button>-->
      <Button v-if="currentArtist" :title="$t('registration.btn.back_artist')" @onClick="onBackArtist()"></Button>
    </div>
  </div>
</template>

<script>
export default {
  name: "RegisterComplete",
  computed: {
    currentArtist() {
      return this.$route.query.current_artist
    }
  },
  mounted() {
    window.scrollTo({ top: 0 })
  },
  methods: {
    onComplete() {
      this.$router.push({name: "auth.login", query: this.$route.query})
    },
    onBackArtist() {
      let current_artist = this.$route.query.current_artist
      this.$router.push({name: "artist.home", params:{designate_page: current_artist} })
    }
  },
  components: {
    Headline: () => import('@components/base/Headline'),
    Button: () => import('@components/base/Button')
  }
}
</script>
