export default {
  product_sales_and_product_delivery: "Product sales and product delivery Bromide.JP",
  bromide_co_ltd_will_do_its: "Bromide Co., Ltd. will do it.",
  notation_of_specified_commercial_transactions_law: "Notation of Specified Commercial Transactions Law",
  email_settings: "Email settings",
  if_the_customer_has_set_the_domain: "If the customer has set the domain specified reception as a measure against junk mail, the mail may not arrive correctly.\nI haven't received an important email regarding my purchase, and I haven't received a reply from Bromide.JP when I make an inquiry.",
  please_set_so_that_you_can_receive_the_following_domains: "Please set so that you can receive the following domains.",
  about_email_reception_settings_at_the_time_of_purchase: "About email reception settings at the time of purchase",
  when_paying_at_a_convenience_store: "When paying at a convenience store, the payment company will send you a payment number by email.",
  please_cancel_the_following_domains: "Please cancel the following domains from the junk mail settings or register as the designated receiving address.",
  about_the_contents_of_the_email_from_the_settlement_company: "About the contents of the email from the settlement company",
  you_will_receive_an_email_from_the_settlement: "You will receive an email from the settlement company with the following subject.",
  subject_notice_of_completion_of_convenience_store_payment_request: "[Subject] Notice of completion of convenience store payment request",
  address_address_dedicated_to_sending_payment_services: "[Address] Address dedicated to sending payment services",
  about_product_rights_after_purchase: "About product rights after purchase",
  ownership_of_the_purchased_product_will_be: "Ownership of the purchased product will be transferred from the Company to the customer upon delivery of the product.",
  however_this_is_only_for_photographic_products: "However, this is only for photographic products (solids), and does not include any fees related to the acquisition and license of various rights such as the copyright of the product, portrait rights and trademark rights of the subject, and other usage rights.",
  even_if_the_customer_purchases_the_product: "Even if the customer purchases the product, please keep in mind that the copyright of the product and the portrait right, trademark right, etc. of the subject are not transferred to the customer, and please keep those rights.",
  sales_conditions: "Sales conditions",
  about_payment_and_delivery: "About payment and delivery",
  selling_price: "Selling price",
  as_described_on_the_detail_page_of_each_product_indicated_with_tax: "",
  expenses_other_than_the_product_price: "Expenses other than the product price",
  consumption_tax_shipping_fee_packing_fee: "Consumption tax, shipping fee, packing fee, convenience store settlement fee (6000 yen or less)",
  method_of_payment: "method of payment",
  web_money_payment_service: "WebMoney payment service",
  credit_card_payment: "Credit card payment",
  docomo_carrier_payment: "DoCoMo carrier payment",
  softbank_carrier_settlement: "Softbank carrier settlement",
  au_easy_payment: "au Easy Payment",
  product_shipping_time: "Product shipping time",
  period_for_common_delivery_please_check: "■ Period for common delivery Please check the business days listed on each product page.\n Also, please note that manufacturing will be absent on Saturdays, Sundays, and holidays, Golden Week, and the year-end and New Year holidays.\n After shipping, the delivery of the courier may be delayed from the scheduled date depending on the area. After approving the order, you will receive an email notification of the estimated delivery date.",
  when_the_shipment_is_confirmed_we_will: "When the shipment is confirmed, we will send you a shipping email, so please check.",
  photo_sales_products_l_2_l_a_3_etc: "● Photo sales products (L, 2L-A3, etc.)",
  after_confirming_your_order_we_plan_to_deliver_it_in_about_5_business_days: "After confirming your order, we plan to deliver it in about 5 business days.",
  the_delivery_method_is_basic: "The delivery method is basic Yu-Mail [post mailing]. If home delivery is displayed in the selection display, you can also select home delivery. The cost will be the delivery cost.",
  notes_when_yu_mail_post_mailing_is_selected: "* Notes when Yu-Mail [Post-mailing] is selected",
  delivery_will_be_completed_by_post_mailing: "Delivery will be completed by post mailing.",
  we_cannot_confirm_receipt: "We cannot confirm receipt.",
  there_is_no_insurance: "There is no insurance.",
  we_cannot_make_arrangements_for_reshipment: "We cannot make arrangements for reshipment.",
  it_is_the_customer_s_own_responsibility: "It is the customer's own responsibility regardless of whether the receipt is completed or not.",
  please_choose_the_product_shipping: "Please choose the product shipping method with the above in mind.",
  goods_products_photo_poster_t_shirt_with_photo: "● Goods products (photo poster // T-shirt with photo poster frame / pillow, etc.)",
  we_plan_to_deliver_according_to_the_sales_period: "We plan to deliver according to the sales period, order deadline, and scheduled shipping date stated on the sales page. The delivery method will be basic Takkyubin.",
  artist_goods_official_goods_c_ds_paper_pop_etc: "● Artist goods (official goods, CDs, paper POP, etc.)",
  the_shipping_time_may_change_depending_on_the_manufacturing: "The shipping time may change depending on the manufacturing, inventory, and weather conditions of each product. In that case, we will inform you of the new shipping time by e-mail, web page, my page, etc.",
  returns_cancellations: "Returns / Cancellations",
  we_do_not_accept_returns_or_cancellations: "We do not accept returns or cancellations due to customer's convenience after ordering, but we will accept returns or exchanges only in the following cases.",
  however_if_any_of_the_following_items_apply_we: "",
  if_you_receive_a_damaged_soiled_or_other: "1.If you receive a damaged, soiled or other damaged item (limited to items other than those shown in the photo).",
  if_a_hidden_defect_is_found: "2.If a hidden defect is found (limited to products other than the photo).",
  when_you_receive_a_product: "3.When you receive a product / quantity that is different from the ordered product / quantity.",
  we_will_bear_the_return_shipping_fee: "We will bear the return shipping fee for returns and replacements due to our defects.",
  when_requesting_a_return_or_replacement: "When requesting a return or replacement, please return it in a form that retains the proof of shipment such as a courier service, and keep a copy at hand. We are not liable for any accidents such as loss or damage during shipping if the product is returned without proof of shipment.",
  shipping_cost: "Shipping cost",
  shipping_costs_include_packing_costs_material_costs_consumption_tax_etc: "Shipping costs include packing costs, material costs, consumption tax, etc.",
  for_the_delivery_method_yu_mail_post_mailing: "For the delivery method (Yu-mail [post mailing], courier service), select the delivery method displayed on the cart. Delivery method cannot be selected In the case of single display, the delivery method cannot be selected. It will be the displayed delivery method.",
  the_delivery_company_will_be_japan_post: "The delivery company will be Japan Post / Yamato Transport. Please note that he cannot select a delivery company.",
  if_the_shipping_cost_is_displayed_in_multiple_carts: "If the shipping cost is displayed in multiple carts, it will be displayed if it cannot be packed in the same package. In that case, please select the displayed delivery method (Yu-mail [post mailing], courier) and bear the delivery cost.",
  examples_that_cannot_be_bundled: "Examples that cannot be bundled",
  when_the_location_of_the_manufacturing_factory_is_different: "When the location of the manufacturing factory is different",
  combination_of_products_that_cannot_be_bundled_with_the_photo: "Combination of products that cannot be bundled with the photo",
  combination_of_products_such_as_photographs_and_poster_products: "Combination of products such as photographs and poster products",
  combination_of_products_with_different_shipping_timings: "Combination of products with different shipping timings",
  in_addition: "In addition, if there is a guide that can not be bundled in the product details, etc.",
  individual_cost_of_delivery_method_and_delivery_method: "● Individual cost of delivery method and delivery method",
  yu_mail_post_mailing_uniform_nationwide: "■ Yu-Mail [Post-mailing] Uniform nationwide",
  japan_post_yu_mail_post_mailing_216: "Japan Post Yu-Mail [Post mailing] 216 yen (tax included) <220 yen (tax included) from October 2019>",
  yu_mail_post_mailing_will_be_delivered_2_to_5: "Yu-Mail [Post Mailing] will be delivered 2 to 5 days after your order is confirmed.",
  please_note_that_yu_mail_post_mailing: "* Please note that Yu-Mail [Post Mailing] will be posted to the mailbox.",
  please_note_that_yu_mail_post_mailing_cannot_track: "* Please note that Yu-Mail [Post Mailing] cannot track the delivery status.",
  please_note_that_yu_mail_post_mailing_does_not_come_with_any_insurance: "* Please note that Yu-Mail [Post Mailing] does not come with any insurance.",
  the_estimated_delivery_date_of_the_product: "The estimated delivery date of the product does not guarantee a reliable delivery date.",
  please_note_that_delivery_may_be_delayed_due_to_weather: "Please note that delivery may be delayed due to weather, road traffic conditions, and the circumstances of the delivery company.",
  please_note_that_the_delivery_date_may_be_longer: "Please note that the delivery date may be longer during the year-end and New Year holidays and during the Golden Week holidays.",
  please_note_that_we_cannot_accept_requests: "Please note that we cannot accept requests for shortened delivery times.",
  please_note_that_we_do_not_accept_delivery_time_designation: "Please note that we do not accept delivery time designation.",
  yu_mail_post_mailing_will_be_delivered_on_the_next_weekday: "Yu-Mail [Post Mailing] will be delivered on the next weekday if the delivery date is Sunday or a public holiday. (There is no delivery from the post office on Sundays and public holidays.)",
  cash_on_delivery_is_not_supported: "Cash on delivery is not supported.",
  courier_service: "■ Courier service",
  shipping_center_tokyo: "Shipping Center Tokyo",
  yamato_transport_takkyubin_nationwide: "Yamato Transport Takkyubin nationwide uniform (excluding remote islands and Okinawa) up to 160 sizes 832 yen (tax included) <848 yen (tax included) from October 2019>",
  up_to_160_sizes_of_remote_islands_and_okinawa: "Up to 160 sizes of remote islands and Okinawa 2,736 yen (tax included) <2,790 yen (tax included) from October 2019>",
  i_checked_the_email_settings: "I checked the email settings",
  i_confirmed_the_rights_of_the_product: "I confirmed the rights of the product",
  i_confirmed_the_sales_conditions: "I confirmed the sales conditions",
  continue_shopping: "Continue shopping",
  to_purchase_procedure: "To purchase procedure",
  i_have_not_received_email_regarding_my_purchase_title: `
        I haven't received an important email regarding my purchase, and I haven't been able to deliver the answers to my inquiries. <br>
        Please check the following contents so that you can receive emails such as order confirmation, payment, and shipping as soon as possible.
    `,
  common_to_all_customers: `Common to all customers`,
  if_the_customer_is_set_to_receive_by_specifying_domain_description: `
        If the customer is set to receive by specifying the domain as a measure against junk mail, or if it is set unintentionally, <br>
        The email may not arrive correctly. <br>
        You may not receive a reply from Bromide.jp. <br> <br>
        Please set so that you can receive the following domains.
    `,
  customers_planning_to_use_combination_payment_title: `Customers planning to use combination payment`,
  after_selecting_convenience_store_payment_and_completing_the_purchase_procedure_description: `
        After selecting convenience store payment and completing the purchase procedure, you will receive an email from the payment company "Notice of completion of convenience store payment request". <br>
        If you do not get this information, you will not be able to pay. <br>
        Please cancel the junk e-mail setting or register as the specified receiving address. <br> <br>

        Please set so that you can receive the following domains.
    `,
  customers_using_free_mail: `Customers using free mail`,
  please_setup_so_that_you_can_receive_emails: `
        Please set up so that you can receive emails from @ bromide.jp of this service and payment company system@p01.mul-pay.comt@. <br>
        Depending on the settings of your free mail service, mail software, etc., it may be recognized as "junk mail" and you may not receive the mail. <br>
        If you do not receive the e-mail, please check your junk e-mail folder, your service, and software settings.
    `,
  the_customer_rights_to_the_photographic_product: `
        The customer's right to the photographic product is only the ownership of the product printed on the purchased photographic paper. <br>
        We do not acquire or license the copyrights, portrait rights, trademark rights, and other usage rights of photographic products. <br>
        Copying and duplication are subject to court punishment and civil compensation claims as infringement, so you must not do this. <br>
    `
}   