const member = {
  state: {
    member: null,
  },
  mutations: {
    SAVE_MEMBER_DATA(state, data) {
      state.member = data;
    }
  },
  actions: {
    saveMemberData({commit}, data) {
      commit('SAVE_MEMBER_DATA', data);
    }
  }
};

export default member;
