<template>
  <article class="email_registration">
    <Title subtitle="メールアドレス事前確認" title="Email Confirmation"/>

    <div class="content__inner">
      <Headline title="メールアドレスの事前確認" />

      <div slot="body" class="email_registration_modal_body">

        <div ref="content" class="content__inner--editor email_registration_content">
          <keep-proportion-content
            :proportion-target="() => $refs.content"
            :content="content"
            :p-width="1000"
            :p-sp-width="360"
            :gap="0"
            :gap-sp="0"
          />
        </div>
      </div>
    </div>
    <validation-observer ref="observer" tag="div">
    <div class="content__inner">
      <Headline :title="$t('registration.title.attention')"></Headline>

      <div class="attention mb-20">
        <div class="attention__title mb-10">
          <div class="attention__image"><img alt="注意" src="@assets/images/icon_attention.svg"></div>
          <div class="attention__title__text" v-html="$sanitize($t('registration.label.first_content_title'))"></div>
        </div>
        <div class="attention__text" ref="domain">
          <keep-proportion-content
            :proportion-target="() => $refs.domain"
            :p-sp-width="380"
            :gap-sp="0"
            :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
          >
            <p class="bold mb-2" v-html="$sanitize($t('registration.label.first_content_1'))"></p>
            <br>
            <p class="mb-10" v-html="$sanitize($t('registration.label.first_content_2'))"></p>
            <div class='form-text--100 mb-30'><span class='large bold'>@bromide.jp</span></div>
            <p v-html="$sanitize($t('registration.label.first_content_3'))"></p>
          </keep-proportion-content>
        </div>
      </div>
      <validation-provider ref="e1" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
        <label class="check">
          <input v-model="email_1_checked" :name="$t('registration.label.attention_check')" type="checkbox"
                 @input="resetValidate(0)">
          <span class="check--dummy"></span>
          <span v-t="'registration.label.attention_check'" class="check--text"></span>
        </label>
        <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
      </validation-provider>

      <div class="attention mb-20">
        <div class="attention__title mb-10">
          <div class="attention__image"><img alt="注意" src="@assets/images/icon_attention.svg"></div>
          <div class="attention__title__text" v-html="$sanitize($t('registration.label.second_content_title'))"></div>
        </div>
        <div class="attention__text" ref="convenienceStore">
          <keep-proportion-content
            :proportion-target="() => $refs.convenienceStore"
            :p-sp-width="380"
            :gap-sp="0"
            :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
          >
            <p class="mb-10" v-html="$sanitize($t('registration.label.second_content_1'))"></p>
            <div class='form-text--100 mb-20'><span class='large bold'>system@p01.mul-pay.com</span></div>
            <p class="bold mb-10" v-html="$sanitize($t('registration.label.second_content_2_title'))"></p>
            <p v-html="$sanitize($t('registration.label.second_content_2'))"></p>
          </keep-proportion-content>
        </div>
      </div>
      <validation-provider ref="e2" v-slot="{errors}" class="al-c mb-40 check--term" rules="required|checkbox" tag="div">
        <label class="check">
          <input v-model="email_2_checked" :name="$t('registration.label.attention_check')" type="checkbox"
                 @input="resetValidate(0)">
          <span class="check--dummy"></span>
          <span v-t="'registration.label.attention_check'" class="check--text"></span>
        </label>
        <div v-if="errors[0]" class="validate-error c-red mt-10">必須確認事項です</div>
      </validation-provider>

      <div class="base_tab_wrap">
        <ul class="base_tab">
          <li
            v-for="(tab, i) in TABS"
            :key="i"
            class="base_tab_item"
          >
            <a :href="tab.link" class="base_tab_item__link" target="_blank">
              <span class="font-en base_tab_item_title">{{ tab.provider }}</span>
              <span class="base_tab_item_sub_title">{{ tab.provider }}メールアカウントの<br>設定方法</span>
            </a>
          </li>
        </ul>
      </div>

      <div class="content__inner">
        <dl class="common-dl">
          <dt v-html="$sanitize($t('registration.input.email'))"></dt>
          <dd>
            <validation-provider
              v-slot="{ errors }"
              :rules="`required|email`"
              :vid="$t('mypage_edit.basic_information.email_address')"
            >
              <input id="email"
                     ref="email"
                     v-model="email"
                     :name="$t('registration.label.email')"
                     :placeholder="$t('registration.placeholder.email')"
                     class='form-text--100'
                     required
                     type="text"
                     @keydown.space.prevent
              />
              <p v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</p>
            </validation-provider>
          </dd>
          <dt v-html="$sanitize($t('registration.input.email_confirm'))"></dt>
          <dd>
            <validation-provider
              v-slot="{ errors }"
              :rules="`required|email|confirmed:${$t('mypage_edit.basic_information.email_address')}`"
              :vid="$t('mypage_edit.basic_information.email_address_confirmation')"
            >
              <input id="email_confirm"
                     v-model="email_confirmation"
                     :name="$t('registration.label.email')"
                     :placeholder="$t('registration.placeholder.email_confirm')"
                     class='form-text--100'
                     required
                     type="text"
                     @keydown.space.prevent
              />
              <span v-if="errors[0]" class="validate-error c-red"> {{ errors[0] }}</span>
            </validation-provider>
          </dd>
        </dl>
      </div>
      <Button title="送信する" isPrimary @onClick="onConfirm"></Button>
    </div>
    </validation-observer>
  </article>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
import Title from "@components/base/Title";
import Headline from "@components/base/Headline";

const TAB_ICLOUD = 'iCloud'
const TAB_DOCOMO = 'Docomo'
const TAB_AU = 'au'
const TAB_SOFTBANK = 'SoftBank'
const TAB_GMAIL = 'Gmail'
const TAB_YAHOO = 'Yahoo'

const TABS = [
  {
    provider: TAB_ICLOUD,
    link: 'https://support.apple.com/ja-jp/guide/icloud/mmdd8d1d47/icloud#:~:text=iPhone%E3%80%81iPad%E3%80%81%E3%81%BE%E3%81%9F%E3%81%AFiPod%20touch,Drive%E3%80%8D%E3%82%92%E3%82%AA%E3%83%B3%E3%81%AB%E3%81%97%E3%81%BE%E3%81%99%E3%80%82'
  },
  {
    provider: TAB_DOCOMO,
    link: 'https://www.docomo.ne.jp/mydocomo/settings/sp_mode/'
  },
  {
    provider: TAB_AU,
    link: 'https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/'
  },
  {
    provider: TAB_SOFTBANK,
    link: 'https://www.softbank.jp/mobile/support/mail/antispam/email-i/white/'
  },
  {
    provider: TAB_GMAIL,
    link: 'https://support.google.com/mail/answer/6562?hl=ja&co=GENIE.Platform%3DiOS&oco=2'
  },
  {
    provider: TAB_YAHOO,
    link: 'https://support.yahoo-net.jp/PccMail/s/article/H000007299'
  },
]
export default {
  components: {
    KeepProportionContent,
    Headline,
    Title,
    Button: () => import('@components/base/Button')
  },
  data() {
    return {
      TABS,
      email_1_checked: false,
      email_2_checked: false,
      email: '',
      email_confirmation: ''
    }
  },
  props: {
    isShowModal: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    }
  },
  methods: {
    async onConfirm() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError()
        return;
      }
      const { email, email_confirmation } = this;
      this.$emit('email-registration-confirm', { email_confirmation, email })
    },
    resetValidate(iCheckBox) {
      switch (iCheckBox) {
        case 0:
          this.$refs.e1.validate();
          break;
        case 1:
          this.$refs.e2.validate();
          break;
      }
    },
  }
}
</script>
<style lang="scss">
@import './email-registration-form.scss';
</style>
