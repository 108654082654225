<template>
  <div>
    <Header :logo="logo" :logoLink="logoLink"/>
    <main :class="`wrapper ${mainClass}`">
      <slot></slot>
    </main>
    <footer-layout :footer-area-color="footerAreaColor" :footerText="footerText"/>
  </div>
</template>
<script>

import Header from '@components/app/Header';
import FooterLayout from './Footer';

export default {
  name: "PCLayout",
  components: {Header, FooterLayout},
  data() {
    return {
      mClass: ''
    }
  },
  props: {
    logo: {
      type: String,
      required: false,
      default: require('@images/logo.png')
    },
    logoLink: {
      type: String,
      required: false,
      default: "/"
    },
    mainClass: {
      type: String,
      required: false,
      default: ""
    },
    footerText: {
      type: String,
      required: false,
      default: '(C) bromide.JP'
    },
    footerAreaColor: {
      type: String,
      required: false,
    },
    partner_id: {
      required: false
    }
  },
  created() {
    this.mClass = this.mainClass ? 'wrapper ' + this.mainClass : 'wrapper';
  },
}
</script>
