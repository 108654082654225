<template>
  <div>
    <tag :is="tag" class="page-title">
      <span class="page-title--en">{{ title }}</span>
      <span class="page-title--jp">{{ subtitle }}</span>
    </tag>
  </div>
</template>
<script>
export default {
  name: 'Title',
  props: {
    tag: {
      type: String,
      required: false,
      default: 'h2',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
}
</script>
<style scoped>
.page-title {
  padding: 0 20px 60px;
  text-align: center;
}

@media screen and (min-width: 768px) and (max-width: 834px) {
  .page-title {
    padding: 0 20px 40px;
  }
}

@media screen and (max-width: 767px) {
  .page-title {
    padding: 0 20px 30px;
  }
}

.page-title--en {
  font-family: 'Oswald', sans-serif;
  font-weight: normal;
  font-size: 5rem;
  width: 100%;
  display: block;
  line-height: 110%;
}

@media screen and (min-width: 768px) and (max-width: 834px) {
  .page-title--en {
    font-size: 4rem;
  }
}

@media screen and (max-width: 767px) {
  .page-title--en {
    font-size: 3rem;
  }
}

.page-title--jp {
  font-size: 1.4rem;
  font-weight: 700;
  width: 100%;
}
</style>
