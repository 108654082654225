<template>
  <article>
    <Title :subtitle="$t('mypage_confirmation.title.main_jp')" :title="$t('mypage_confirmation.title.main')"></Title>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                       class="d-block text-right mb-20"></router-link>
          <Headline :title="$t('mypage_confirmation.headline.title')"></Headline>
          <p>{{ $t('mypage_confirmation.headline.description') }}</p>
        </section>

        <section class="mb-30">
          <div v-if="isError">
            <ErrorAlert
              v-for="message in errorMessages"
              :key="message"
              class="mb-20"
            >
              {{ message }}
            </ErrorAlert>
          </div>
          <h4 class="main-title">{{ $t('mypage_confirmation.basic_information.title') }}</h4>
          <dl class="common-dl">
            <dt>{{ $t('mypage_confirmation.basic_information.name') }}</dt>
            <dd>{{ get_name }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.furigana') }}</dt>
            <dd>{{ get_name_hiragana }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.mail_address') }}</dt>
            <dd>{{ data.email }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.sex') }}</dt>
            <dd>{{ $t(`mypage_confirmation.gender.${data.gender}`) }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.profession') }}</dt>
            <dd>{{ $t(`mypage_confirmation.occupation.${data.occupation}`) }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.date_of_birth') }}</dt>
            <dd>{{ data.date_of_birth }}</dd>
            <dt>{{ $t('mypage_confirmation.basic_information.password') }}</dt>
            <dd>*********</dd>
          </dl>
        </section>
        <section v-for="index in 3" :key="index" class="mb-30">
          <h4 class="main-title">{{ $t('mypage_confirmation.product_delivery_address.title') }}{{ NOIndex(index) }}</h4>
          <dl class="common-dl">
            <dt>{{ $t('mypage_confirmation.product_delivery_address.postal_code') }}</dt>
            <dd v-if="data.shipping_addresses[index-1]">{{
                data.shipping_addresses[index - 1].postal_code != "" ? data.shipping_addresses[index - 1].postal_code : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
            <dt>{{ $t('mypage_confirmation.product_delivery_address.prefecture') }}</dt>
            <dd v-if="data.shipping_addresses[index-1]">{{
                data.shipping_addresses[index - 1].prefecture_id != "" ? getPrefectureLabelById(data.shipping_addresses[index - 1].prefecture_id) : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
            <dt>{{ $t('mypage_confirmation.product_delivery_address.street_address') }}</dt>
            <dd v-if="data.shipping_addresses[index-1]">
              <div>{{
                  data.shipping_addresses[index - 1].address != "" ? data.shipping_addresses[index - 1].address : $t('mypage_confirmation.product_delivery_address.unregistered')
                }}
              </div>
              <div>{{
                  data.shipping_addresses[index - 1].address_2 != "" ? data.shipping_addresses[index - 1].address_2 : $t('mypage_confirmation.product_delivery_address.unregistered')
                }}
              </div>
            </dd>
            <dt>{{ $t('mypage_confirmation.product_delivery_address.phone_number') }}</dt>
            <dd v-if="data.shipping_addresses[index-1]">{{
                data.shipping_addresses[index - 1].phone_number != "" ? data.shipping_addresses[index - 1].phone_number : $t('mypage_confirmation.product_delivery_address.unregistered')
              }}
            </dd>
          </dl>
        </section>
        <div class="mb-20">
          <form>
            <input :value="$t('mypage_confirmation.btn.update')" class="btn-100--red" type="button"
                   @click="onUpdateClick">
          </form>
          <form>
            <input :value="$t('mypage_confirmation.btn.to_fix')" class="btn-100--none" type="button"
                   @click="onBackClick">
          </form>
        </div>
      </div>
    </section>
  </article>
</template>
<script>
import MemberService from '@services/MemberService';
import MasterDataService from '@services/MasterDataService';
import {mapState} from 'vuex';

export default {
  data() {
    return {
      data: {
        id: "",
        first_name: "",
        last_name: "",
        first_name_hiragana: "",
        last_name_hiragana: "",
        email: "",
        email_confirmation: "",
        gender: "",
        occupation: "",
        date_of_birth: "",
        password: "",
        password_confirmation: "",
        shipping_addresses: []
      },
      prefecture_options: [],
      isError: false,
      errorMessages: null,
    }
  },
  methods: {
    getMemberEditInfo() {
      this.data = this.$session.get("member");
    },
    NOIndex(index) {
      if (index == 1) {
        return '①';
      } else if (index == 2) {
        return '②';
      } else {
        return '③';
      }
    },
    onUpdateClick() {
      this.data.shipping_addresses = this.data.shipping_addresses.filter(shipping_address => {
        if (shipping_address.address == '' || shipping_address.postal_code == '' || shipping_address.phone_number == '' || shipping_address.prefecture_id == '') {
          return false;
        }
        return true;
      });
      MemberService.updateCurrentMember(this.data).then(() => {
        this.$session.remove("member");
        this.$router.push({name: this.current_artist ? 'artist.mypage.index' : 'mypage.index'});
      })
        .catch((e) => {
          this.isError = true;
          if (e.data.data && e.data.data.errors) {
            this.errorMessages = Object.keys(e.data.data.errors).reduce( (messages, key) => {
              const message = e.data.data.errors[key].map((_error) => {
                return _error
              });
              messages = [...messages, ...message]
              return messages
            }, [])
          }
        });
    },
    onBackClick() {
      this.$router.push({name: this.current_artist ? 'artist.mypage.edit' : 'mypage.edit'});
    },
    getMasterData() {
      MasterDataService.index({
        is_prefecture: true
      }).then(res => {
        this.prefecture_options = res.data.prefectures;
      });
    },
    getPrefectureLabelById(id) {
      if (this.prefecture_options.length !== 0) {
        const pre = this.prefecture_options.find(prefecture => prefecture.value == id);
        return pre ? pre.label : '';
      }
      return '';
    }
  },
  mounted() {
    this.getMemberEditInfo();
    this.getMasterData();
  },
  computed: {
    get_name() {
      return this.data.last_name + " " + this.data.first_name;
    },
    get_name_hiragana() {
      return this.data.last_name_hiragana + ' ' + this.data.first_name_hiragana;
    },
    ...mapState([
      'current_artist'
    ])
  },
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline'),
    ErrorAlert: () => import("@components/base/ErrorAlert")
  }
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>
