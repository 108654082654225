export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'Unsubscribed',
    description: 'If you withdraw from the membership, all purchase history and delivery address information will be deleted.',
    do_you_want_to_unsubscribe: 'Do you want to unsubscribe?'
  },
  btn: {
    do_not_withdraw: 'Do not withdraw',
    withdraw: 'Withdraw'
  }
}