<template>
  <svg
    viewBox="0 0 25 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <path d="M12.6 15.9193C9.63712 15.9193 7.23869 13.6259 7.01798 10.7197L2.92033 7.55289C2.38165 8.22867 1.88595 8.94313 1.48595 9.72438C1.39659 9.90114 1.35003 10.0964 1.35003 10.2945C1.35003 10.4926 1.39659 10.6879 1.48595 10.8646C3.60431 14.9978 7.79806 17.7943 12.6 17.7943C13.6512 17.7943 14.6652 17.6381 15.6426 17.3857L13.6156 15.8173C13.2809 15.882 12.9409 15.9161 12.6 15.9193ZM24.8586 18.1888L20.5402 14.8513C21.852 13.7458 22.9309 12.3905 23.7141 10.8642C23.8034 10.6875 23.85 10.4922 23.85 10.2941C23.85 10.096 23.8034 9.90075 23.7141 9.72399C21.5957 5.59078 17.402 2.7943 12.6 2.7943C10.5889 2.79674 8.61041 3.30304 6.84532 4.26696L1.87579 0.42594C1.81099 0.375513 1.73689 0.338351 1.65771 0.316575C1.57854 0.2948 1.49586 0.288838 1.41438 0.299032C1.3329 0.309226 1.25423 0.335374 1.18287 0.375984C1.1115 0.416594 1.04883 0.470868 0.998451 0.535706L0.231654 1.52282C0.129934 1.65367 0.0843547 1.81957 0.104939 1.98402C0.125524 2.14848 0.210586 2.29802 0.341419 2.39977L23.3242 20.1627C23.389 20.2131 23.4631 20.2502 23.5423 20.272C23.6215 20.2938 23.7042 20.2998 23.7856 20.2896C23.8671 20.2794 23.9458 20.2532 24.0172 20.2126C24.0885 20.172 24.1512 20.1177 24.2016 20.0529L24.9688 19.0658C25.0704 18.9349 25.116 18.769 25.0953 18.6045C25.0746 18.44 24.9895 18.2905 24.8586 18.1888ZM17.682 12.642L16.1469 11.4552C16.2762 11.0816 16.3447 10.6896 16.35 10.2943C16.3576 9.7155 16.2294 9.14297 15.9756 8.62271C15.7218 8.10246 15.3496 7.64897 14.8887 7.29868C14.4279 6.94839 13.8913 6.71106 13.3222 6.60576C12.753 6.50046 12.167 6.53011 11.6113 6.69235C11.8469 7.01153 11.9743 7.39761 11.975 7.7943C11.9692 7.9263 11.949 8.05728 11.9149 8.18492L9.03947 5.96266C10.0383 5.12797 11.2983 4.67026 12.6 4.6693C13.3388 4.66889 14.0705 4.8141 14.7531 5.09664C15.4357 5.37918 16.056 5.7935 16.5784 6.31591C17.1008 6.83832 17.5151 7.45858 17.7977 8.14122C18.0802 8.82386 18.2254 9.5555 18.225 10.2943C18.225 11.1392 18.0184 11.9263 17.682 12.642Z" />
  </svg>
</template>

<script>
export default {
  name: 'IconEyeSlash',
}
</script>
