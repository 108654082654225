import auth from './auth';
import general from './general';
import validation from './validation';
import inquiry from './inquiry';
import purchase from './purchase';
import purchase_login from './purchase_login';
import purchase_attention from './purchase_attention';
import purchase_confirmation from './purchase_confirmation';
import purchase_confirmation2 from './purchase_confirmation2';
import purchase_fix from './purchase_fix';
import purchase_credit from './purchase_credit';
import category from './category';
import registration from './registration';
import artist from "./artist";
import mypage from './mypage';
import mypage_edit from './mypage_edit';
import mypage_confirmation from './mypage_confirmation';
import mypage_cancel from './mypage_cancel';
import mypage_cancel_fix from './mypage_cancel_fix';
import mypage_history from './mypage_history';
import cart from './cart';
import event from './event';
import category_goods from './category_goods';
import error404 from './error404';
import message_when_hidden from './message_when_hidden';
import reshipping from "../en/reshipping";

export default {
  auth,
  general,
  purchase,
  purchase_login,
  purchase_attention,
  purchase_confirmation,
  purchase_confirmation2,
  purchase_fix,
  purchase_credit,
  inquiry,
  registration,
  validation,
  category,
  artist,
  mypage,
  mypage_edit,
  mypage_confirmation,
  mypage_cancel,
  mypage_cancel_fix,
  mypage_history,
  cart,
  event,
  category_goods,
  error404,
  message_when_hidden,
  reshipping
}