<template>
  <BaseArtist
    :artist="artist"
    :artist-banners="artistBanners"
    :events="events"
    :is-mobile="isMobile"
    :is-msg-hidden="isMsgHidden"
    :msg-content="msgContent"
    :msg-subtitle="msgSubTitle"
    :msg-title="msgTitle"
  />
</template>

<script>

import EventService from '@services/EventService'
import ArtistTmpService from '@services/ArtistTmpService'
import MasterDataService from '@services/MasterDataService'
import ArtistBannerTmpService from '@services/ArtistBannerTmpService'

export default {
  components: {
    BaseArtist: () => import('@pages/artist/base/Artist'),
  },
  metaInfo() {
    return {
      title: this.titleTag,
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.from = from.fullPath
    })
  },
  data() {
    return {
      artistId: '',
      artist: {},
      events: [],
      artistBanners: [],
      partner_id: '',
      from: '',
      isMsgHidden: false,
      msgTitle: '',
      msgSubTitle: '',
      msgContent: '',
      isMobile: false,
    }
  },
  created() {
    this.render()
  },

  mounted() {
    MasterDataService.index({
      is_mobile: true,
    }).then((res) => {
      this.isMobile = res.data.is_mobile
    })
  },

  computed: {
    titleTag() {
      return this.artist ? this.artist.name : 'Bromide';
    },
  },

  methods: {
    async render() {

      const token = this.$route.params.token
      const res = await ArtistTmpService.index({ token })
      await this.$store.dispatch('setIsMsgWhenHidden', res.data.is_msg_when_hidden)

      if (res.data.is_msg_when_hidden) {

        this.isMsgHidden = true
        this.msgTitle = res.data.msg_when_hidden_title
        this.msgSubTitle = res.data.msg_when_hidden_date
        this.msgContent = res.data.msg_when_hidden
        this.$store.commit('SHOW_LAYOUT')
        this.$store.commit('SET_CURRENT_ARTIST', res.data)

      } else {

        this.artist = res.data
        this.partner_id = res.data.partner_id
        this.$store.commit('SET_CURRENT_ARTIST', res.data)

        //if artist was created then get event
        if (this.$route.query.artist_id) {
          this.artistId = this.$route.query.artist_id
          const eventResponse =  await EventService.getEventByArtistId(this.artistId)
          this.events = eventResponse.data
        }

        const artistBanners = await ArtistBannerTmpService.index({
          token: this.$route.params.token,
        })
        this.artistBanners = artistBanners.data

      }
    },
  }
}
</script>
