<template>
  <article class="landing-page">
    <Title :subtitle="landingPage.subtitle" :title="landingPage.title"></Title>
    <section class="content pb-120">
      <div
        v-if="landingPage.detail_type === 'image' && landingPage.image"
        class="event-main__photo"
      >
        <img
          v-if="landingPage.image.url"
          :src="landingPage.image.url"
          :alt="landingPage.title"
        >
      </div>
      <div ref="content" v-else class="content__inner">
        <keep-proportion-content
          class="landing__main--text"
          :content="landingPage.main_text"
          :proportion-target="() => $refs.content"
          :gap="0"
          :gap-sp="0"
        />
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "LandingTemplate",
  components: {
    Title: () => import('@components/base/Title'),
    KeepProportionContent: () => import('@components/base/KeepProportionContent')
  },
  props: {
    landingPage: {
      type: Object
    }
  }
}
</script>
<style lang="scss">
@import "./landing-page.scss";
</style>
