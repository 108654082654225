import day1Image from '../../assets/images/forBot2022/event_day1_1000.png'
import day2Image from '../../assets/images/forBot2022/event_day2_1000.png'
import day3Image from '../../assets/images/forBot2022/event_day3_1000.png'
import day4Image from '../../assets/images/forBot2022/event_day4_1000.png'
import eventInfoImageDay1 from '../../assets/images/forBot2022/bot_event_info_day1.png'
import eventInfoImageDay2 from '../../assets/images/forBot2022/bot_event_info_day2.png'
import eventInfoImageDay3 from '../../assets/images/forBot2022/bot_event_info_day3.png'
import eventInfoImageDay4 from '../../assets/images/forBot2022/bot_event_info_day4.png'
import eventInfoImageSda from '@images/forSda/SUPER_DRAGON.png'
import eventInfoImageKayamaYuzo from '@images/forKayamaYuzo/kayamayuzo.png'

const forBOTBanner = {
  'bot20220721': day1Image,
  'bot20220722': day2Image,
  'bot20220723': day3Image,
  'bot20220724': day4Image,
}

const eventInfoImages = {
  'ldh-bot': eventInfoImageDay1,
  'bot20220721': eventInfoImageDay1,
  'bot20220722': eventInfoImageDay2,
  'bot20220723': eventInfoImageDay3,
  'bot20220724': eventInfoImageDay4,
  'sda': eventInfoImageSda,
  'kayamayuzo': eventInfoImageKayamaYuzo
}

export default {

  computed: {
    banner() {
      return forBOTBanner[this.currentArtist.designate_page_directory]
    },
    eventInfo() {
      return eventInfoImages[this.currentArtist.designate_page_directory]
    }
  }

}
