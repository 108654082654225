import Vue from 'vue';
import Constants from '@constants';
import PurchaseServices from '@services/PurchaseService';
import store from '../store/store';
import {TYPE_CREDIT} from '../constants/payment'

function isLogged() {
  return store.state.isLogged
}

export function purchaseWithoutLogin({next}) {
  if (isLogged()) {
    return next({name: 'purchase.confirmation'})
  }
  return next();
}


export function purchaseLogin({next}) {
  if (isLogged()) {
    return next({name: 'purchase.confirmation'})
  }
  return next();
}

export function purchase({next}) {
  if (isLogged()) {
    return next({name: 'purchase.confirmation'})
  }
  return next()
}

export async function purchaseConfirmation({next}) {
  if (isLogged()) {
    await PurchaseServices.getMemberInfo().then(res => {
      Vue.$cookies.set(Constants.USER_PURCHASE_INFO, JSON.stringify(res.data), Constants.PURCHASE_INFO_DURATION_COOKIE)
      return next();
    })
    return next()
  }
  if (Vue.$cookies.get(Constants.USER_PURCHASE_INFO)) {
    return next()
  }
  return next({
    name: 'purchase.login'
  })
}

export function purchaseCredit({next}) {
  var userPaymentInfo = Vue.$cookies.get(Constants.USER_PAYMENT_INFO)
  //credit setlement
  if (userPaymentInfo && userPaymentInfo.parent_payment_method_id === TYPE_CREDIT) {
    return next()
  }
  return next({name: 'purchase.confirmation'})
}

export function purchaseConfirmation2({next}) {
  var userPaymentInfo = Vue.$cookies.get(Constants.USER_PAYMENT_INFO)
  var userPurchaseInfo = Vue.$cookies.get(Constants.USER_PURCHASE_INFO)
  if (userPurchaseInfo && userPaymentInfo) {
    if (userPaymentInfo.parent_payment_method_id === TYPE_CREDIT) { //credit_setlement
      if (userPaymentInfo.token) {
        return next()
      } else {
        return next({name: 'purchase.credit'})
      }
    } else if (userPaymentInfo.parent_payment_method_id && userPaymentInfo.parent_payment_method_id !== TYPE_CREDIT) {
      return next()
    }
  }
  return next({name: 'purchase.confirmation'})
}

export function purchaseFix({next}) {
  return next()
}

