export default {
  arrows: false,
  dots: true,
  centerMode: false,
  infinite: false,
  slidesToShow: 5,
  slidesToScroll: 1,
  speed: 500,
  swipeToSlide: true,
  responsive: [
    {
      breakpoint: 1023,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 864,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      }
    },
  ]
}