<template>
  <ul class="common-list--padding">
    <template v-for="(payment_method, index) in paymentMethods">
      <!-- プルダウンでの支払方法選択 -->
      <li
        v-if="payment_method.select_type === 'select'"
        :key="payment_method.name"
        class="conveni-select"
      >
        <input
          :id="`payment-type-${payment_method.type}`"
          :checked="currentPaymentType === payment_method.type"
          :value="payment_method.type"
          name="決済種別"
          type="radio"
          @change="selectPaymentType"
        >

        <label :for="`payment-type-${payment_method.type}`" class="radio--label">
          {{ PAYMENT_TYPE_LABELS[payment_method.type] }}
        </label>

        <div v-if="payment_method.children.length" class="form-select mb-20 ml-20">
          <select
            :disabled="currentPaymentType !== payment_method.type"
            :value="currentPaymentKey"
            name="支払方法"
            @change="selectChildPayment"
          >
            <option :value="null">選択してください</option>

            <option v-for="child in payment_method.children" :key="child.key" :value="child.key">
              {{ child.text }}
            </option>
          </select>
        </div>
      </li>

      <li v-else :key="payment_method.name">
        <!-- ラジオボタンでの支払方法選択 -->
        <input
          :id="`payment-type-${payment_method.type}`"
          :checked="currentPaymentType === payment_method.type"
          :value="payment_method.type"
          name="決済種別"
          type="radio"
        >

        <ul>
          <li v-for="child in payment_method.children" :key="child.key">
            <input
              :id="`payment-key-${child.key}`"
              :checked="currentPaymentKey === child.key"
              :value="child.key"
              name="支払方法"
              type="radio"
              @change="selectPaymentKey"
            >

            <label :for="`payment-key-${child.key}`" class="radio--label">{{ child.text }}</label>
          </li>
        </ul>
      </li>

      <li v-if="payment_method.is_note" :key="index+'content'">

        <div class="attention">

          <div class="attention__title mb-10">
            <div class="attention__image">
              <img
                alt="注意"
                :src="require('@assets/images/icon_attention.svg')"
              >
            </div>
            <div class="attention__title__text"><p class="bold">コンビニ決済ご利用のお客様</p>
            </div>
          </div>

          <div class="attention__text" ref="attention">
            <keep-proportion-content
              :content="payment_method.note_content"
              :proportion-target="() => $refs.attention"
              :gap-sp="0"
              :other-style="'.content__inner--editor .large{font-size :16.8px; line-height: 1.6;} .content__inner--editor .bold{font-weight: bold;}'"
            />
          </div>
        </div>
      </li>
    </template>

    <li>
      <ValidationProvider v-slot="{ errors }" rules="required">
        <input
          :value="currentPaymentKey"
          name="支払方法"
          type="hidden"
        >

        <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
      </ValidationProvider>
    </li>
  </ul>
</template>

<script>
import {TYPE_CARRIER, TYPE_CONVENIENCE_STORE, TYPE_CREDIT} from '../../constants/payment'
import KeepProportionContent from "@components/base/KeepProportionContent";

export default {
  name: 'PaymentSelectList',
  components: {
    KeepProportionContent
  },
  props: {
    paymentMethods: {
      type: Array,
      default: () => [],
    },
    currentPaymentType: {
      type: String,
      default: null,
    },
    currentPaymentKey: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      PAYMENT_TYPE_LABELS: {
        [TYPE_CREDIT]: 'クレジット決済',
        [TYPE_CONVENIENCE_STORE]: 'コンビニ決済',
        [TYPE_CARRIER]: 'キャリア決済',
      },
    }
  },
  methods: {
    selectChildPayment(e) {
      this.$emit('select-child-payment', e.target.value)
    },
    selectPaymentType(e) {
      this.$emit('select-payment-type', e.target.value)
    },
    selectPaymentKey(e) {
      this.$emit('select-payment-key', e.target.value)
    }
  }
}
</script>

<style scoped>

</style>
