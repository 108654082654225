<template>
  <div>
    <slot/>

    <Modal :modal="cart.isShowPrintedSetPopup">
      <div slot="body">
        選択されている画像数が<br />
        セット枚数に達していません。
        <br /><br />
        購入できるセット数まで<br />
        のこり<strong style="font-weight: bold;">{{ cart.suggestQtyAmount }}</strong>枚画像を選択してください。
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="setPrintSetup">閉じる</button>
      </div>
    </Modal>

    <Modal :modal="cart.isProductOutOfStock">
      <div slot="body">
        大変申し訳ございません。
        <br>
        商品の限定品在庫、または在庫数が不足、
        <br>
        または完売しているため購入することができません。
        <br>
        誠に申し訳ございませんが、他の商品をお選びください。
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="setIsProductOutOfStock">閉じる</button>
      </div>
    </Modal>

    <Modal :modal="cart.isRemoveAllProductsPopup" width="300px">
      <div slot="body" class="body">
        カート内の全商品を削除します。
        <br>
        本当に削除してよろしいですか？
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close mr-5" @click="setIsRemoveAllProductsPopup">キャンセル</button>
        <button class="footer__ok" @click="removeAllProductsFromCart">OK</button>
      </div>
    </Modal>

    <Modal :modal="cart.isEventSaleTermExpired">
      <div slot="body">
        販売を終了した商品があるため、購入ができません。
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="setIsEventSaleTermExpired">OK</button>
      </div>
    </Modal>

    <Modal :modal="cart.isCartDataChanged">
      <div slot="body">
        カート内の商品が更新されています。
        <br>
        再度、カート内の商品をご確認ください。
      </div>

      <div slot="footer" class="modal-footer">
        <button class="footer__close" @click="setIsCartDataChanged">OK</button>
      </div>
    </Modal>


  </div>
</template>

<script>
export default {
  components: {
    Modal: () => import('@components/base/Modal')
  },
  props: {
    cart: {
      type: Object,
      required: true
    },
  },
  methods: {
    setPrintSetup() {
      this.$emit('set-printed-set-popup')
    },
    setIsProductOutOfStock() {
      this.$emit('set-is-product-out-of-stock')
    },
    setIsRemoveAllProductsPopup() {
      this.$emit('set-is-remove-all-products-popup')
    },
    removeAllProductsFromCart() {
      this.$emit('remove-all-products-from-cart')
    },
    setIsEventSaleTermExpired() {
      this.$emit('set-is-event-sale-term-expire')
    },
    setIsCartDataChanged() {
      this.$emit('set-is-cart-data-changed')
    }
  }
};
</script>
