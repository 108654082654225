import purchaseImage from '../dummy/purchase_image.jpeg'
import eventTopPage from '../dummy/event_top_image.jpeg'
import headerLogo from '../dummy/header_logo.png'
import artistTopPage from '../dummy/artist_top_image.jpeg'
import categoryThumbnail from '../dummy/category_thumbnail.jpeg'
// axios
import axios from 'axios';
import Vue from 'vue';
import Constants from '@constants';
import store from './store/store';
import router from './routes/index';
import i18n from './i18n';

const baseURL = process.env.VUE_APP_API_BASE_URL

const http = axios.create({
  baseURL,
  // define default headers here
  headers: {
    'Accept': 'application/json',
    'X-Bromide-Locale': process.env.VUE_APP_LOCALE || 'ja',
    'X-Cache': 0
  }
});
var isShowLoading = false;
// request interceptor
http.interceptors.request.use(
  config => {
    if (config.hideLayout) {
      store.commit('HIDE_LAYOUT');
    }
    // headers
    const token = Vue.$cookies.get(Constants.ACCESS_TOKEN);
    if (token) {
      config.headers.common["Authorization"] = `Bearer ${token}`
    }

    //set loading
    isShowLoading = config.is_show_loading;
    if (isShowLoading) {
      store.commit('SET_GLOBAL_LOADING', true);
      delete config.is_loading;
    }

    return config;
  },
  e => {
    if (isShowLoading) {
      store.commit('SET_GLOBAL_LOADING', false);
    }
    return Promise.reject(e);
  }
);

// response interceptor
http.interceptors.response.use(
  res => {
    console.log(res)
    if (isShowLoading) {
      setTimeout(() => store.commit('SET_GLOBAL_LOADING', false), 100);
    }


    if (res.config.url.match(/sale-products\/get-by-category/)) {
      res.data.data.forEach((_data, i) => {
        _data.main_image_url = purchaseImage + `?img=im_${i}`
        _data.full_main_image_url = purchaseImage + `?img=fim_${i}`
      })
    }

    if (res.config.url.match(/events\/get-by-artist-id/)) {
      res.data.data.forEach((_data, i) => {
        _data.main_image_url = eventTopPage + `?img=im_${i}`
      })
      console.log('/events/get-by-artist-id', res.data.data)
    }

    if (res.config.url.match(/sale-products\/get-by-event-product-standard/)) {
      res.data.data.forEach((_data, i) => {
        _data.main_image_url = categoryThumbnail + `?img=im_${i}`
      })
    }

    if (res.config.url.match(/events\/([1-9]+)$/)) {
      res.data.data.event_main_visual_image_url = eventTopPage
      console.log('event Detail!!!', res.data, res.config)
    }

    if (res.config.url.match(/artists\/get-by-designate-page/)) {
      res.data.data.header_logo_image_url = headerLogo
      res.data.data.main_visual_image_url = artistTopPage
    }

    if (res.status === 200 || res.status === 201) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res);
    }



  },
  e => {
    const ignoreErorrStatus = e.config.is_ignore_interceptors_response_error;
    if (isShowLoading) {
      store.commit('SET_GLOBAL_LOADING', false);
    }
    if (e.response) {
      if (e.response.status === 599 && e.response.data && e.response.data.is_base_setting_maintenance) {
        store.state.has_maintenance = true;
        return Promise.reject(e.response);
      }

      if (e.response.status === 503 || e.response.status == 500) {
        store.state.error_message = e.response.data.meta.message;
        store.state.has_error = true;
        return Promise.reject(e.response);
      }

      if (e.response.status === 404 && !ignoreErorrStatus) {
        router.push({path: '/404'});
        return Promise.reject(e.response);
      }

      if (e.response.status) {
        return Promise.reject(e.response);
      }
    } else {
      store.state.error_message = i18n.t('general.api_server_busy');
      store.state.has_error = true;
      return Promise.reject(i18n.t('general.api_server_busy'));
    }
  }
);

export default http;
