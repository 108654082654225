<template>
  <article>
    <Title :subtitle="$t('registration.title.main_jp')" :title="$t('registration.title.main')"></Title>
    <section class="content pb-80">
      <RegisterPre v-if="currentStep == 0" @clickNext="onUpdateStep"></RegisterPre>
      <RegisterAttention v-if="currentStep == 1" @clickNext="onUpdateStep"></RegisterAttention>
      <RegisterForm v-if="currentStep == 2" :data="entity" @clickNext="onUpdateStep"></RegisterForm>
      <RegisterConfirmation v-if="currentStep == 3" :entity="entity" @clickBack="onBack"
                            @clickNext="onUpdateStep"></RegisterConfirmation>
      <RegisterComplete v-if="currentStep == 4"></RegisterComplete>
    </section>
  </article>
</template>
<script>

import Constants from '@constants'

export default {
  components: {
    RegisterAttention: () => import('./RegisterAttention'),
    RegisterComplete: () => import('./RegisterComplete'),
    RegisterConfirmation: () => import('./RegisterConfirmation'),
    RegisterPre: () => import('./RegisterPre'),
    RegisterForm: () => import('./RegisterForm'),
    Title: () => import('@components/base/Title'),
  },
  data() {
    return {
      currentStep: 0,
      entity: {},
    }
  },
  methods: {
    onUpdateStep(data) {
      this.currentStep++;
      if (data) this.entity = data;
    },

    onBack(data) {
      this.currentStep--;
      if (data) this.entity = data;
    }
  },
  beforeRouteEnter(to, from, next) {
    const fromRouteName = from.name;
    if (fromRouteName === 'purchase.login') {
      next((vm) => {
        vm.$cookies.set(Constants.PURCHASE_PAGE, true);
      })
    } else if (fromRouteName && fromRouteName.slice(0, 5) !== "auth." && fromRouteName.slice(0, 7) !== "member.") {
      next((vm) => {
        vm.$cookies.set(Constants.REDIRECT_URL, from.fullPath);
      })
    }
    next()
  },
}
</script>