export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ',
    purchase_history: "Purchase history"
  },
  purchase_detail: "■購入明細",
  purchase_info: "※行をクリックすると詳細が閲覧できます。",
  purchase_detail_info: "※商品画像は、「販売期間終了後、6カ月程度」で削除されます。",
  purchase_detail_info_2: "下記イベント名をクリックすると、ご購入いただいた商品の詳細がご確認いただけます。",
  sheet: '点',
  id: "ID:",
  qty: 'Quantity:',
  include_tax: "税込",
  event_being_display: "Event Being Display",
  column: {
    purchase_number: "Order ID",
    order_date_and_time: "Order date and time",
    payment_method: "Payment Method",
    product_type: "Product Type",
    shipping_date: "Shipping Date",
    shipping_status: "Shipping Status",
    artist_name: "Artist",
    event_name: "Event",
    quantity: "Quantity",
    total_amount_included_tax: "Total Amount (Including Tax)",
    subtotal: "Subtotal",
    shipping_fee: "Shipping Fee",
    payment_fee: "Payment Fee",
    total_amount: "Total",
    total_qty: "Qty",
    total: "Total",
    settlement_amount: 'Settlement Amount'
  },
  product_type: {
    normal: 'Normal',
    printed_product: 'Printed Product'
  },
  shipping_status: {
    order_not_placed_to_supplier: '未発注',
    not_shipped: '未発送(発注済みで未発送)',
    payed_cancellation: '有料キャンセル',
    free_cancellation: '無料キャンセル',
    complete: '完了',
    return: '返品',
    error: 'エラー',
    capture_actual_paid: '実売上',
    capture_actual_paid_failed: '実売上失敗',
    cancel: 'キャンセル',
    not_delivered: '未納品 (未発注で発注期限(3日)を過ぎたもの)'
  },
  order_status: {
    unapproved: "Unapproved",
    approved: "Approved",
    on_hold: "On hold",
    waiting_for_payment: "Waiting for payment",
    completed: "Completed",
    cancelled: "Cancelled",
    blacklist: "Blacklist"
  },
  payment_method_type: {
    credit_settlement: "Credit settlement",
    minimart_payment: "Convenience store settlement",
    seven_eleven: "Seven eleven",
    lawson: "Lawson",
    family_mart: "Family mart",
    mobile_payment: "Mobile payment",
    docomo_carrier_payment: "Docomo carrier payment",
    softbank_carrier_settlement: "Softbank carrier settlement",
    au_easy_payment: "Au easy payment",
    pay_later: "Pay later"
  }
}
