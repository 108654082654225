<template>
  <article class="static-page">
    <Title :subtitle="staticPage.subtitle" :title="staticPage.title"></Title>
    <section class="content pb-120">
      <div
        v-if="staticPage.detail_type === 'image' && staticPage.image"
        class="event-main__photo box-shadow"
      >
        <img
          v-if="staticPage.image.url"
          :src="staticPage.image.url"
          :alt="staticPage.title">
      </div>
      <div ref="content" v-else class="content__inner">
        <keep-proportion-content
          class="static__main--text"
          :content="staticPage.main_text"
          :proportion-target="() => $refs.content"
          :gap="0"
          :gap-sp="0"
        />
      </div>
    </section>
  </article>
</template>

<script>
export default {
  name: "StaticTemplate",
  components: {
    Title: () => import('@components/base/Title'),
    KeepProportionContent: () => import('@components/base/KeepProportionContent')
  },
  props: {
    staticPage: {
      type: Object
    }
  }
}
</script>
