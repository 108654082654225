<template>
  <article>
    <InformationListAccordion
      v-if="event.whole_information_list && event.whole_information_list.length"
      :informations="event.whole_information_list"
      :content-class="'whole-information'"
      title="重要なお知らせ"
      noticeText="重要"
    />

    <section class="content bg-white event-top">
      <div ref="eventMain" class="content__inner event-main">
        <div :class="['event-main__photo', 'box-shadow', { 'event-main__no-margin': !!banner }]">
          <img
            v-if="event.event_main_visual_image_url"
            :alt="event.event_name"
            :src="event.event_main_visual_image_url"
            height="400"
          >
        </div>

        <div v-if="banner" class="ldh-banner-image">
          <img :src="banner" alt="">
        </div>

        <keep-proportion-content
          root="h2"
          :proportion-target="() => $refs.eventMain"
          class="event-main__name bold"
          :style="{color: event.event_name_text_color}"
          :content="event.event_name_top_page"
          :p-width="1000"
          :p-sp-width="360"
          :gap="0"
          :gap-sp="0"
          blank-to-br
        />

        <div class="event-main__detail">
          <div class="event-main__label right event-main__label">
            <span class="event-main__label--period">販売期間</span>
            <span class="event-main__label--date">{{ salePeriod }}</span>
          </div>

          <div class="event-main__label left event-main__label">
            <span v-if="event.event_sale_new" class="event-main__label--new">NEW</span>

            <span v-else-if="isAlmostClosing" class="event-main__label--3day">
              販売終了残り{{ event.event_remaining_sale_day }}日
            </span>

            <span v-else-if="isClosingToday" class="event-main__label--today">本日販売終了</span>
          </div>
        </div>

        <div v-if="event.news && event.news.length">
          <InformationListAccordion
            :informations="event.news"
            :content-class="'event-main__news'"
            title="お知らせ"
            noticeText="INFO"
          />
        </div>

        <div
          v-if="event.event_main_text"
          ref="eventMainText"
          class="event-main__organizer content__inner--editor"
        >
          <keep-proportion-content
            :proportion-target="() => $refs.eventMainText"
            :style="{color: event.event_main_text_color}"
            :content="event.event_main_text"
            :p-width="1000"
            :p-sp-width="360"
            :gap="0"
            :gap-sp="0"
          />
        </div>

        <div class="al-r" style="position: relative; z-index: 10;">
          <a class="link mt-10" @click="onClickReturnToArtistTop">{{ label.TO_TOP }}</a>
        </div>
      </div>
    </section>

    <ChildUnderEvent
      v-if="hasSaleProduct"
      :title="label.CATEGORY_EN"
      :subtitle="label.CATEGORY_JP"
      :filters="filters"
      :product-standard="productStandard"
      :sale-products="saleProducts"
      @filter-change="handleFilterChange"
    >
      <template #accordion>
        <Accordion
          v-if="event.is_event_emergency_msg"
          :contentClass="'mb-60'"
          :description="event.event_emergency_msg_content"
          :subTitle="event.event_emergency_msg_title"
          :title="event.event_emergency_msg_date"
          noticeText="INFO"
          style="margin-bottom: 5rem"
        />
      </template>
    </ChildUnderEvent>

    <EventList
      v-if="otherEvents.length"
      :event-link="{ name: 'event.page' }"
      :events="otherEvents"
      :is_mobile="isMobile"
      :subtitle="label.OTHER_EVENT_JP"
      :title="label.OTHER_EVENT_EN"
    />


    <!--  LDH対応 LDH終了後に要削除  -->
    <section v-if="isAuditionMode" class="content bg-gray" style="background-color: rgb(229, 0, 17);">
      <div class="content__inner top-banner">
        <div class="top-banner__item  box-shadow">
          <a href="https://www.exiletribestation.jp/news/436" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/rpTWklfZ3WmCDuqfBjZaXa7E2SgIMsAZPX4yz3uD/tribe_news.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220721" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/USWKsdbdI0fA3pkYmOhOTNfYNNkNbH1MWOFeu9ck/day1.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220722" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/5PybwQjScIhdEkyS30zmHum6TqZD5NEC7szA0a4N/day2.png?t=20220722110254" alt="">
          </a>
        </div>
        <div class="top-banner__item  box-shadow">
          <a href="https://bromide.jp/bot20220723" rel="noopener noreferrer">
            <img src="https://img.bromide.jp/public/artist_banner/SWG4Jv2alL7B944k8cfE1yxu6WMxkJ0aMSUczNyj/day3.png?t=20220722110254" alt="">
          </a>
        </div>
      </div>
    </section>
    <!--  LDH対応 LDH終了後に要削除ここまで  -->
  </article>
</template>

<script>
import KeepProportionContent from '../base/KeepProportionContent'
import auditionModeSwitcherMixin from "../../helpers/auditionModeSwitcher.mixin";
import forBotMixins from "@components/event/forBot.mixins";

export default {
  created() {
    window.scrollTo(0, 0)
  },
  components: {
    KeepProportionContent,
    ChildUnderEvent: () => import('@pages/event/ChildUnderEvent'),
    InformationListAccordion: () => import('@components/base/InformationListAccordion'),
    Accordion: () => import('@components/base/Accordion'),
    EventList: () => import('@components/event/EventList'),
  },
  mixins: [ auditionModeSwitcherMixin, forBotMixins ],
  props: {
    event: {
      type: Object,
      required: true
    },
    otherEvents: {
      type: Array,
      default: () => []
    },
    isMobile: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    saleProducts: {
      type: Array
    },
    currentArtist: {
      type: Object,
      required: true
    }
  },
  computed: {
    salePeriod() {
      return (this.event.event_sale_period_start_date ?? '') + ' - ' + (this.event.event_sale_period_end_date ?? '')
    },
    isAlmostClosing() {
      return this.event.event_remaining_sale_day <= 3 && this.event.event_remaining_sale_day > 0
    },
    isClosingToday() {
      return !!this.event.event_sale_finish_today
    },
    hasSaleProduct() {
      if (!this.saleProducts) return false
      return Object.keys(this.saleProducts).length
    },
    hasProductStandard() {
      if (!this.productStandard) return false
      const { normal, printed } = this.productStandard
      return (Array.isArray(normal) && normal.length) || (Array.isArray(printed) && printed.length)
    },
  },
  methods: {
    onClickReturnToArtistTop() {
      this.$router.push({name: 'artist.home', params: {designate_page: this.event.artist_designate_page_directory}})
    },
    handleFilterChange({key, value}) {
      this.$emit('filter-change', { key, value})
    }
  },
}
</script>
