import BaseService from "./BaseService";

class LandingPageTmpService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getLandingPagePreview(token) {
    return this.performRequest(BaseService.METHOD_GET, `${token}`);
  }
}

export default new LandingPageTmpService('landing-page-previews');
