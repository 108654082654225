<template>
  <article>
    <PostalCodeSelectModal
      :is-show-modal="isShowPostalCodeSelectModal"
      :is-mobile="is_mobile"
      :addresses="addresses"
      @select="settingAddress"
      @close="isShowPostalCodeSelectModal = false"
    />

    <div v-if="errorMessages" class="alert">
      <span class="closebtn" @click="errorMessages=''">&times;</span>
      {{ errorMessages }}
    </div>

    <h2 class="page-title">
      <span class="page-title--en">Purchase</span>
      <span class="page-title--jp">購入手続き</span>
    </h2>
    <section class="content pb-80" @click="showAutoComplete = false">
      <div class="content__inner">
        <validation-observer ref="observer">
          <section class="mb-60">
            <h3 class="section-title">{{ $t('purchase.enter_delivery_address') }}</h3>
            <p>{{ $t('purchase.please_register_the_following') }}<br><span
              class="c-red">{{ $t('purchase.is_a_required_item') }}</span></p>
          </section>
          <section class="mb-30">
            <h4 class="main-title">{{ $t('purchase.basic_information') }}</h4>
            <dl class="common-dl">
              <dt>{{ $t('purchase.name') }}<span class="c-red">※</span></dt>
              <dd class="input-group-row">
                <ValidationProvider v-slot="{ errors }" class="input" rules="required|max:15">
                  <input v-model="userPurchaseInfo.last_name" :name="$t('purchase.lastname')" :placeholder="$t('purchase.lastname')" class="form-text--100"
                         maxlength="15" type="text">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="input" rules="required|max:15">
                  <input v-model="userPurchaseInfo.first_name" :name="$t('purchase.firstname')" :placeholder="$t('purchase.firstname')" class="form-text--100"
                         maxlength="15" type="text"/>
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.furigana') }}<span class="c-red">※</span></dt>
              <dd class="input-group-row">
                <ValidationProvider v-slot="{ errors }" class="input" rules="required|max:15">
                  <input v-model="userPurchaseInfo.last_name_hiragana" :name="$t('purchase.lastname_furigana')" :placeholder="$t('purchase.lastname_furigana')" class="form-text--100"
                         maxlength="15" type="text">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors }" class="input" rules="required|max:15">
                  <input v-model="userPurchaseInfo.first_name_hiragana" :name="$t('purchase.firstname_furigana')" :placeholder="$t('purchase.firstname_furigana')" class="form-text--100"
                         maxlength="15"
                         type="text">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.mail_address') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" :vid="$t('purchase.mail_address')" rules="required|email">
                  <input v-model="userPurchaseInfo.email" :name="$t('purchase.mail_address')" :placeholder="$t('purchase.please_enter_your_e_mail_address')"
                         class="form-text--100"
                         type="text">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.for_email_address_confirmation') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" :rules="`required|confirmed:${$t('purchase.mail_address') }`">
                  <input v-model="userPurchaseInfo.email_confirmation" :name="$t('purchase.mail_address')" :placeholder="$t('purchase.please_enter_your_email_address_again')"
                         class="form-text--100"
                         type="text">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.sex') }}<span class="c-red">※</span></dt>
              <dd>
                <ul class="flex-list no-bullet">
                  <li v-for="(gender, index) in genders" v-bind:key="index">
                    <input :id="gender.value" v-model="userPurchaseInfo.gender" :name="gender.value" type="radio"
                           v-bind:value="gender.value">
                    <label :for="gender.value" class="radio--label">{{
                        $t(`registration.options.${gender.value}`)
                      }}</label>
                  </li>
                </ul>
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <input v-model="userPurchaseInfo.gender" :name="$t('purchase.sex')" class="form-text--100"
                         type="hidden">
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.profession') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <div class="form-select">
                    <select v-model="userPurchaseInfo.occupation" :name="$t('purchase.profession')">
                      <option :value="null" hidden>{{ $t('purchase.please_select') }}</option>
                      <option v-for="(item,index) in occupations" :key="index" :value="item.value">
                        {{ $t(`purchase.occupations.${item.value}`) }}
                      </option>
                    </select>
                  </div>
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.date_of_birth') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" rules="required|date_format:yyyy-mm-dd">
                  <input v-model="userPurchaseInfo.date_of_birth" :name="$t('purchase.date_of_birth')" class="form-text--100"
                         type="hidden">
                  <ul class="flex-list">
                    <li class="flex-list--item">
                      <div class="form-select">
                        <select id="year" v-model="dob.year" :for="$t('purchase.date_of_birth')"
                                :name="$t('purchase.date_of_birth')" @change="onMonthChange()">
                          <option v-t="'purchase.year'" hidden value=""></option>
                          <option v-for="(value, index) in year_list" v-bind:key="index" v-bind:value="value">
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <p class="pl-5">{{ $t('purchase.year') }}</p>
                    </li>
                    <li class="flex-list--item">
                      <div class="form-select">
                        <select id="month" v-model="dob.month" :for="$t('purchase.date_of_birth')"
                                :name="$t('purchase.date_of_birth')" @change="onMonthChange()">
                          <option hidden selected value="">{{ $t('purchase.month') }}</option>
                          <option v-for="(value, index) in month_list" v-bind:key="index + 1" v-bind:value="index + 1">
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <p class="pl-5">{{ $t('purchase.month') }}</p>
                    </li>
                    <li class="flex-list--item pr-0">
                      <div class="form-select">
                        <select id="day" v-model="dob.day" :for="$t('purchase.date_of_birth')"
                                :name="$t('purchase.date_of_birth')">
                          <option v-t="'purchase.day'" hidden value=""></option>
                          <option v-for="(value, index) in day_list" v-bind:key="index + 1" v-bind:value="index + 1">
                            {{ value }}
                          </option>
                        </select>
                      </div>
                      <p class="pl-5">{{ $t('purchase.day') }}</p>
                    </li>
                  </ul>
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
            </dl>
          </section>
          <section class="mb-30">
            <h4 class="main-title">{{ $t('purchase.product_delivery_address') }}</h4>
            <dl class="common-dl">
              <dt>{{ $t('purchase.postal_code') }}<span class="c-red">※</span></dt>
              <dd>
                <PostalCodeInput
                  v-model="userPurchaseInfo.zip_code"
                  input_class="form-text--100"
                  :input_name="$t('mypage_edit.product_delivery_address.postal_code')"
                  :placeholder="$t('registration.placeholder.postal_code')"
                  rule="required|numeric|digits:7"
                  autocomplete="off"
                  @input="onSearchZipCode"
                />
              </dd>
              <dt>{{ $t('purchase.prefectures') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" rules="required">
                  <div class="form-select">
                    <select v-model="userPurchaseInfo.prefecture_id" :name="$t('purchase.prefectures')"
                            @change="onPrefectureChange">
                      <option :value="null" hidden>{{ $t('purchase.please_select') }}</option>
                      <option v-for="(item, index) in prefectures" :key="index" :value="item.value">
                        {{ item.label }}
                      </option>
                    </select>
                  </div>
                  <span v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</span>
                </ValidationProvider>
              </dd>
              <dt>
                {{ $t('purchase.street_address') }}
                <span class="c-red">※</span>
                <span v-if="!is_mobile" class="pl-1 c-red">
                  {{ $t('purchase.text_address_pc') }}
                </span>
              </dt>
              <dd>
                <ValidationProvider v-slot="{ errors, failedRules }" rules="required|max:30">
                  <input
                    ref="address1"
                    type="text"
                    v-model="userPurchaseInfo.address"
                    :name="$t('purchase.city_address')"
                    :placeholder="$t('purchase.city_address')"
                    class="form-text--100"
                  >
                  <p v-if="errors[0] && failedRules.max" class="validate-error c-red mb-0">
                    {{$t('general.error_message_address_custom')}}
                  </p>
                  <p v-if="is_mobile" class="validate-error c-red">{{ $t('purchase.address_validate') }}</p>
                </ValidationProvider>
                <ValidationProvider v-slot="{ errors, failedRules }" rules="max:50">
                  <input v-model="userPurchaseInfo.address_2" :name="$t('purchase.building_name_room_number')" :placeholder="$t('purchase.building_name_room_number')"
                         class="form-text--100" type="text">
                  <p v-if="errors[0]" class="validate-error c-red">
                    {{ failedRules.max ? $t('general.error_message_address_2_custom') : errors[0] }}
                  </p>
                  <span v-if="is_mobile" class="validate-error c-red">建物名と部屋番号が未入力の場合、<br class="mb-elem" />荷物が届かない可能性がございます。</span>
                </ValidationProvider>
              </dd>
              <dt>{{ $t('purchase.phone_number') }}<span class="c-red">※</span></dt>
              <dd>
                <ValidationProvider v-slot="{ errors }" :vid="$t('purchase.half_width_numbers_and_no_hyphens')"
                                    rules="required|phone_number|max:11|min:10">
                  <input v-model="userPurchaseInfo.phone_number" :name="$t('purchase.phone_number')" :placeholder="$t('purchase.half_width_numbers_and_no_hyphens')" class="form-text--80"
                         maxlength="11"
                         type="tel">
                  <p v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</p>
                </ValidationProvider>
                <ValidationProvider
                  v-slot="{ errors }"
                  :rules="`required|phone_number|confirmed:${$t('purchase.half_width_numbers_and_no_hyphens')}`">
                  <input v-model="userPurchaseInfo.phone_number_confirmation" :name="$t('purchase.phone_number')" :placeholder="$t('purchase.please_enter_your_phone_number_again')" class="form-text--80"
                         maxlength="11"
                         type="tel">
                  <p v-if="errors[0]" class="validate-error c-red">{{ errors[0] }}</p>
                </ValidationProvider>
              </dd>
            </dl>
          </section>
          <div>
            <button class="btn-100--red" role="button" @click="handleShowAddressConfirmModal">
              {{ $t('purchase.confirm_registration_details') }}
            </button>
          </div>
        </validation-observer>
      </div>
    </section>
    <DeliveryAddressConfirmModal
      :is-show-modal="isShowAddressModal"
      :user-info="userPurchaseInfo"
      @address-confirm="handleAddressConfirm"
      @address-cancel="handleAddressCancel"
    />

    <TermOfIncompleteAddressConfirmModal
      :is-show-modal="isShowTermModal"
      :user-info="userPurchaseInfo"
      @term-confirm="handleTermConfirm"
      @term-cancel="handleTermCancel"
    />
  </article>
</template>
<script>
import MasterDataService from '@services/MasterDataService';
import Constants from '@constants';
import PurchaseService from '@services/PurchaseService';
import store from "../../store/store";

export default {
  components: {
    DeliveryAddressConfirmModal: () => import('./Modal/DeliveryAddressConfirmModal'),
    TermOfIncompleteAddressConfirmModal: () => import('./Modal/TermOfIncompleteAddressConfirmModal'),
    PostalCodeInput: () => import('@components/base/PostalCodeInput'),
    PostalCodeSelectModal: () => import('@components/base/PostalCodeSelectModal'),
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.cart.cartDatas.length) {
      next({name: 'cart.page', params: { designate_page: to.params.designate_page}})
    } else {
      next()
    }
  },
  created() {
    this.$cookies.set(Constants.PURCHASE_STEP, 'purchase', Constants.PURCHASE_INFO_DURATION_COOKIE)
    this.getPrefecture();

    const userPurchaseInfo = this.$cookies.get(Constants.USER_PURCHASE_INFO);

    if (userPurchaseInfo) {
      this.userPurchaseInfo = userPurchaseInfo

      const dateOfBirth = this.userPurchaseInfo.date_of_birth.split('-')

      this.dob = {
        year: dateOfBirth[0] - 0,
        month: dateOfBirth[1] - 0,
        day: dateOfBirth[2] - 0
      }
    }

    this.year_list = this.generateArrayOfYears();
    this.month_list = this.generateArrayOfMonth();
    this.day_list = this.generateArrayOfDay(this.dob.month, this.dob.year);
  },
  watch: {
    dob: {
      handler(val) {
        this.userPurchaseInfo.date_of_birth = `${val.year}-${val.month > 9 ? val.month : '0' + val.month}-${val.day > 9 ? val.day : '0' + val.day}`
      },
      deep: true
    }
  },
  data() {
    return {
      showAutoComplete: false,
      errorMessages: '',
      prefectures: [],
      occupations: [],
      genders: [],
      test: null,
      year_list: [],
      month_list: [],
      day_list: [],
      dob: {
        year: null,
        month: null,
        day: null
      },
      userPurchaseInfo: {
        first_name: null,
        last_name: null,
        first_name_hiragana: null,
        last_name_hiragana: null,
        email: null,
        gender: null,
        occupation: null,
        date_of_birth: null,
        zip_code: null,
        prefecture_id: null,
        prefecture: null,
        address: null,
        address_2: null,
        phone_number: null,
        phone_number_confirmation: null,
        email_confirmation: null,
        with_registration: false,
        password: null,
        password_confirmation: null,
      },
      is_mobile: '',
      isShowPostalCodeSelectModal: false,
      addresses: [],
      isShowAddressModal: false,
      isShowTermModal: false
    }
  },
  methods: {
    onMonthChange() {
      this.day_list = this.generateArrayOfDay(this.dob.month, this.dob.year);
    },
    onPrefectureChange() {
      this.userPurchaseInfo.prefecture = this.prefectures.find(el => el.value == this.userPurchaseInfo.prefecture_id).label
    },
    getPrefecture() {
      MasterDataService.index({
        is_prefecture: true,
        is_occupation: true,
        is_gender: true,
        is_mobile: true
      }).then(res => {
        this.prefectures = res.data.prefectures
        this.occupations = res.data.occupations
        this.genders = res.data.genders
        this.is_mobile = res.data.is_mobile
      })
    },
    async submit() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError();
        return;
      }

      PurchaseService.valationGuestUserInformation({...this.userPurchaseInfo, address_2: this.userPurchaseInfo.address_2 || ""}).then(() => {
        this.$cookies.set(Constants.USER_PURCHASE_INFO, JSON.stringify({...this.userPurchaseInfo, address_2: this.userPurchaseInfo.address_2 || ""}), Constants.PURCHASE_INFO_DURATION_COOKIE)
        this.$router.push({name: 'purchase.confirmation'})
      }).catch(e => {
        if (e.data.meta.code == 422) {
          const { errors } = e.data.meta
          Object.keys(errors).forEach((el, index) => {
            this.errorMessages += (index ? ',' : '') + errors[el][0]
            return setTimeout(() => {
              this.errorMessages = ''
            }, 5000)
          })
        }
      })
    },
    async onSearchZipCode() {
      const regexp = /[0-9]{7}/;

      if (regexp.test(this.userPurchaseInfo.zip_code)) {
        const res = await MasterDataService.index({
          is_postal_code: true,
          postal_code: this.userPurchaseInfo.zip_code
        })

        const { postal_codes } = res.data

        if (postal_codes.length === 1) {
          const definite_postal_code = postal_codes[0]

          const { city, street_address, state } = definite_postal_code
          const prefecture = this.prefectures.find(prefecture => prefecture.label == state);

          this.userPurchaseInfo.address = city.concat(street_address);
          this.userPurchaseInfo.prefecture_id = prefecture ? prefecture.value : null;
          this.userPurchaseInfo.prefecture = prefecture ? prefecture.label : null;
        } else if (postal_codes.length !== 0) {
          this.addresses = postal_codes.map((postal_code, index) => {
            const address = postal_code.state.concat(postal_code.city.concat(postal_code.street_address));

            return { id: index, name: address, postal_code: postal_code };
          });

          this.isShowPostalCodeSelectModal = true;
        }
      }
    },
    settingAddress(address) {
      if (address) {
        const { postal_code } = address
        const { city, street_address, state } = postal_code
        const prefecture = this.prefectures.find(prefecture => prefecture.label == state);

        this.userPurchaseInfo.address = city.concat(street_address);
        this.userPurchaseInfo.prefecture_id = prefecture ? prefecture.value : null;
        this.userPurchaseInfo.prefecture = prefecture ? prefecture.label : null;
      }

      this.addresses = []
      this.isShowPostalCodeSelectModal = false

      setTimeout(()=>{
        this.$refs.address1.focus();
      }, 500);
    },
    async handleShowAddressConfirmModal() {
      if (!(await this.$refs.observer.validate())) {
        this.scrollToFirstError();
        return;
      }
      this.isShowAddressModal = true
    },
    handleAddressConfirm() {
      this.isShowAddressModal = false
      setTimeout(() => {
        this.$nextTick(() => {
          this.isShowTermModal = true
        })
      }, 500)
    },
    handleAddressCancel() {
      this.isShowAddressModal = false;
    },
    handleTermConfirm() {
      this.isShowTermModal = false
      this.submit()
    },
    handleTermCancel() {
      this.isShowTermModal = false
    }
  }
}
</script>

<style>
.form-select select {
  padding: 10px 28px 10px 10px;
}
</style>
