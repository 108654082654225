<template>
  <div>
    <h2 class="page-title"><span class="page-title--en">Purchase</span><span class="page-title--jp">購入手続き</span></h2>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <h3 class="section-title">{{ $t('purchase_fix.your_order_is_complete') }}</h3>
          <p class="mb-50">
            <span v-html="$sanitize($t('purchase_fix.the_order_procedure_is_complete'))" /> <br>
            <span v-html="$sanitize($t('purchase_fix.please_wait_for_a_while_until_the_product_arrives'))" />
          </p>
          <button class="btn-100" @click="gotoArtistTop()">
            {{ $t('purchase_fix.return_to_artist_top') }}
          </button>
        </section>
      </div>
    </section>
  </div>
</template>
<script>
import Constants from '@constants';

export default {
  created() {
    this.$cookies.remove(Constants.USER_REPURCHASE_INFO)
    this.$cookies.remove(Constants.USER_REPURCHASE_PAYMENT_INFO)
    this.$cookies.remove(Constants.REPURCHASE_STEP)
  },
  methods: {
    gotoArtistTop() {
      this.$router.push({ name: 'artist.home' })
    }
  }
}
</script>
