<template>
  <article>
    <Title :subtitle="$t('mypage_cancel.title.main_jp')" :title="$t('mypage_cancel.title.main')"></Title>
    <section class="content pb-80">
      <div class="content__inner">
        <section class="mb-60">
          <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                       class="d-block text-right mb-20"></router-link>
          <Headline :title="$t('mypage_cancel.headline.title')"></Headline>
          <p class="mb-50">{{ $t('mypage_cancel.headline.description') }}<br><span
            class="c-red large">{{ $t('mypage_cancel.headline.do_you_want_to_unsubscribe') }}</span></p>
          <div class="al-c mb-10">
            <router-link :to="{ name: current_artist ? 'artist.mypage.index' : 'mypage.index' }" class="btn-50" tag="a">
              {{ $t('mypage_cancel.btn.do_not_withdraw') }}
            </router-link>
          </div>
          <div class="al-c">
            <a class="link-line" @click="withdrawMembership">
              {{ $t('mypage_cancel.btn.withdraw') }}
            </a>
          </div>
        </section>
      </div>
    </section>
  </article>
</template>
<script>
import MemberService from '@services/MemberService';
import {mapState} from 'vuex';
import Vue from "vue";
import Constants from "@/constants";

export default {
  methods: {
    withdrawMembership() {
      MemberService.withdrawMembership().then(() => {
        this.$store.dispatch("logout");
        Vue.$cookies.remove(Constants.ACCESS_TOKEN);
        Vue.$cookies.remove(Constants.MEMBER);
        Vue.$cookies.remove(Constants.USER_PURCHASE_INFO)
        Vue.$cookies.remove(Constants.USER_PAYMENT_INFO)
        Vue.$cookies.remove(Constants.PURCHASE_STEP)
        this.$router.push({name: this.current_artist ? 'artist.mypage.cancel.fix' : 'mypage.cancel.fix'});
      });
    }
  },
  components: {
    Title: () => import('@components/base/Title'),
    Headline: () => import('@components/base/Headline')
  },
  computed: {
    ...mapState([
      'current_artist'
    ])
  }
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>
