<template>
  <article>
    <section class="content pb-80">
      <div class="content__inner">
        <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                     class="d-block text-right mb-20"></router-link>
        <Headline :title="$t('mypage_history.title.purchase_history')"></Headline>
        <p v-t="'mypage_history.purchase_info'" class="mb-30"></p>
        <table class="history-page__table mb-50">
          <thead>
          <tr>
            <th v-t="'mypage_history.column.purchase_number'"></th>
            <th v-t="'mypage_history.column.order_date_and_time'"></th>
            <th v-t="'mypage_history.column.artist_name'"></th>
            <th v-t="'mypage_history.column.event_name'" class="w-min-80"></th>
            <th v-t="'mypage_history.column.shipping_date'"></th>
          </tr>
          </thead>
          <tbody v-for="(data, index) in entity.data" :key="index">
          <tr class="link-tr" @click="orderDetail(data.order_id)">
            <td class="al-c">
              {{ data.order_id }}
            </td>
            <td class="al-c">{{ orderDataTimeFormat(data.order_date_time) }}</td>
            <td class="al-c"> {{ data.artist_name }}</td>
            <td>
              <template v-for="(eachEventName, eventIndex) in data.event_category_names">
                <span :key="`${eachEventName.event_name}-${eventIndex}`">
                  {{ eachEventName.event_name }}
                </span>

                <br :key="`middle-br-${eachEventName.event_name}-${eventIndex}`">

                <template v-for="(eachCategoryName, categoryIndex) in eachEventName.category_names">
                  <span :key="`${eachEventName.event_name}-${eventIndex}-${eachCategoryName}-${categoryIndex}`">
                    {{ eachCategoryName }}
                  </span>

                  <br v-if="eachEventName.category_names.length === categoryIndex + 1" :key="`br-${eachEventName.event_name}-${eventIndex}-${eachCategoryName}-${categoryIndex}`">
                </template>

                <br v-if="data.event_category_names.length === eventIndex + 1" :key="`last1-br-${eachEventName.event_name}-${eventIndex}`">
                <br v-if="data.event_category_names.length === eventIndex + 1" :key="`last2-br-${eachEventName.event_name}-${eventIndex}`">
              </template>
            </td>
            <td class="al-c">
              <template v-for="(eachShippingDate, shippingIndex) in data.shipping_dates">
                <span :key="`shipping-date-${eachShippingDate}-${shippingIndex}`">
                  {{ orderDataTimeFormat(eachShippingDate) }}
                </span>

                <br v-if="data.shipping_dates.length === shippingIndex + 1" :key="`shipping-date-br-${eachShippingDate}-${shippingIndex}`">
              </template>
            </td>
          </tr>
          </tbody>
        </table>
        <p class="mb-10 al-c font-en">
          {{ entity.meta.pagination.current }} / {{ entity.meta.pagination.last }}
          page [total {{ entity.meta.pagination.total }}]
        </p>
        <app-pagination
          v-if="entity.data.length > 0"
          id="first-pagination"
          :current_page="entity.meta.pagination.current"
          :interval="5"
          :total_pages="entity.meta.pagination.last"
          @onPageChange="(page) => getPurchaseHistory(page ,true)"
        />
      </div>
    </section>
  </article>
</template>
<script>
import MemberService from '@/services/MemberService';

export default {
  data() {
    return {
      entity: {
        data: [],
        meta: {
          pagination: {
            current: '',
            last: '',
            per: '',
            from: '',
            to: '',
            count: '',
            total: ''
          }
        }
      }
    }
  },
  methods: {
    orderDetail(id) {
      this.$router.push({
        name: this.$store.state.current_artist ? 'artist.mypage.history_detail' : 'mypage.history_detail',
        params: {
          history_id: id
        }
      });
    },
    getPurchaseHistory(page = 1, scrollIntoView = false) {
      this.entity.meta.pagination.current = page;
      MemberService.getPurchaseHistory({page: page}).then(res => {
        this.entity = res;
        if (scrollIntoView) {
          document.getElementById('first-pagination').scrollIntoView({behavior: 'smooth'});
        }
      });
    },
    orderDataTimeFormat(order_date_time) {
      return order_date_time ? order_date_time.replaceAll('-', '/') : '';
    },
    paymentMethodType(payment_method) {
      return payment_method ? this.$t(`mypage_history.payment_method_type.${payment_method}`) : '';
    },
    orderStatus(order_status) {
      return order_status ? this.$t(`mypage_history.order_status.${order_status}`) : '';
    }
  },
  created() {
    this.getPurchaseHistory();
  },
  components: {
    Headline: () => import('@components/base/Headline'),
    AppPagination: () => import('@components/base/Pagination')
  }
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}
</style>
