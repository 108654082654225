export default {
  default_select: "Please Select",
  login: "Login",
  register: "Register",
  mypage: "My page",
  history: "History",
  profile: "Profile",
  logout: "Logout",
  mypage_cancel: "Delete Account",
  cart: "Cart",
  guide: "Guide",
  delivery: "Delivery",
  trading: "Trading",
  privacy: "Privacy",
  terms: "Terms",
  inquiry: "Inquiry",
  notice: "NOTICE",
  info: "INFO",
  year: "Year",
  month: "Month",
  day: "Day",
  months: {
    "0": "January",
    "1": "February",
    "2": "March",
    "3": "April",
    "4": "May",
    "5": "June",
    "6": "July",
    "7": "August",
    "8": "September",
    "9": "October",
    "10": "November",
    "11": "December",
  },
  day_name: {
    Monday: 'Monday',
    Tuesday: 'Tuesday',
    Wednesday: 'Wednesday',
    Thursday: 'Thursday',
    Friday: 'Friday',
    Saturday: 'Saturday',
    Sunday: 'Sunday'
  },
  technical: "Technical Error, Please contact to Bromide Administrator",
  ok: 'OK',
  postal_code_error_msq: 'Please enter the postal code as a 7-digit number',
  api_server_busy: "Server is busy now.<br />Please try again later.",
  error_message_address_custom: 'The Address Field may not be greater than 30 characters',
  error_message_address_2_custom: 'The Address 2 Field may not be greater than 50 characters'
}