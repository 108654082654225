<template>
  <div>
    <p class="mb-10 al-c font-en">
      {{ meta.pagination.current }} /
      {{ meta.pagination.last }} page [total {{ meta.pagination.total }}]</p>
    <app-pagination
      v-if="total > 0"
      id="first-pagination"
      :current_page="meta.pagination.current"
      :interval="5"
      :total_pages="meta.pagination.last"
      @onPageChange="handleOnPage"
    />
  </div>

</template>
<script>
export default {
  components: {
    AppPagination: () => import('@components/base/Pagination'),
  },
  props: {
    meta: {
      type: Object,
      required: true
    },
    total: {
      type: Number,
      default: 0
    }
  },
  methods: {
    handleOnPage(page) {
      this.$emit('page', page)
    }
  }
}
</script>
<style>

</style>
