const LABELS_NORMAL = {
  ATTENTION_TITLE_EN: 'Attention',
  ATTENTION_TITLE_JP: '注意事項',
  ARTIST_LABEL: 'アーティスト',
  TO_TOP: 'アーティストTOPに戻る',
  CATEGORY_EN: 'CATEGORY',
  CATEGORY_JP: 'カテゴリー',
  OTHER_EVENT_EN: 'OTHER EVENT',
  OTHER_EVENT_JP: 'その他のイベント',
  TO_EVENT: 'イベントTOPに戻る',
  RELATION_EN: 'RELATION',
  RELATION_JP: '関連商品',
}

const LABELS_AUDITION = {
  ATTENTION_TITLE_EN: 'Information',
  ATTENTION_TITLE_JP: 'ご案内',
  ARTIST_LABEL: 'イベント',
  TO_TOP: 'TOPページに戻る',
  CATEGORY_EN: 'MEMBER',
  CATEGORY_JP: 'メンバー',
  OTHER_EVENT_EN: 'OTHER ARTIST',
  OTHER_EVENT_JP: 'その他のアーティスト',
  TO_EVENT: 'アーティストTOPに戻る',
  RELATION_EN: 'OTHER MEMBER',
  RELATION_JP: 'その他のメンバー',
}

export default {
  computed: {
    isAuditionMode() {
      return this.currentArtist.arrange_template === 'arrange_template'
    },
    label() {
      return this.isAuditionMode ? LABELS_AUDITION : LABELS_NORMAL
    }
  }
}
