<template>
  <keep-proportion-content
    v-bind="$attrs"
  >
    <span v-if="!!startDate" class='article__sale-term--first'>
      販売期間:
      <template v-if="!!startDate">
        <br>
        {{ startDate.year }}年
        {{ startDate.month }}月
        {{ startDate.day }}日
        {{ day_name[startDate.day_name] }}
        {{ startDate.time }}
        〜
      </template>
    </span>

    <span v-if="!!endDate" class='article__sale-term--second'>
      {{ endDate.year }}年
      {{ endDate.month }}月
      {{ endDate.day }}日
        {{ day_name[endDate.day_name] }}
      {{ endDate.time }}迄
    </span>
  </keep-proportion-content>
</template>

<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  name: 'SaleTerm',
  components: {KeepProportionContent},
  props: {
    startDate: {
      type: [ Object, String ],
      default: () => ({
        year: '',
        month: '',
        day: '',
        day_name: '',
        time: ''
      })
    },
    endDate: {
      type: [ Object, String ],
      default: () => ({
        year: '',
        month: '',
        day: '',
        day_name: '',
        time: ''
      })
    }
  },
  data() {
    return {
      day_name: {
        Monday: '月',
        Tuesday: '火',
        Wednesday: '水',
        Thursday: '木',
        Friday: '金',
        Saturday: '土',
        Sunday: '日',
      }
    }
  }
}
</script>

<style scoped>

</style>
