<template>
  <div class="error mt-15">
    <i :class="`icon ${fontAwsomeIconClass}`"></i>
    <div class="error-message">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorAlert',
  props: {
    fontAwsomeIconClass: {
      type: String,
      require: false,
      default: 'fas fa-exclamation-triangle',
    },
  },
}
</script>

<style scoped>
.error {
  padding: 2rem 1rem;
  background-color: #fdedec;
  border: solid 1px rgb(242, 76, 77);
  display: flex;
}

.error-message {
  margin-left: 2rem;
}

.icon {
  font-size: 2rem;
  padding-left: 1rem;
}
</style>
>
