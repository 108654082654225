import Vue from 'vue';
import {extend, localize, setInteractionMode, ValidationObserver, ValidationProvider} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '../i18n';

function loadLocale(code) {
  return import(`vee-validate/dist/locale/${code}.json`).then(locale => {
    localize(code, locale);
  });
}

// Validate input field after caret leave from input box
setInteractionMode('lazy');

const currentLang = process.env.VUE_APP_LOCALE || 'ja';
// set default language
loadLocale(currentLang);
// Install components globally
// install rules and localization
Object.keys(rules).forEach(rule => {
  extend(rule, rules[rule]);
});

extend('before', {
  params: ['target'],
  validate(value, {target}) {
    return value < target;
  },
  message: i18n.t('validation.before', {field: '{_field_}', target: '{target}'})
});

extend('after', {
  params: ['target'],
  validate(value, {target}) {
    return value > target;
  },
  message: i18n.t('validation.after', {field: '{_field_}', target: '{target}'})
});

extend('beforeDateTime', {
  params: ['end_date', 'start_time', 'end_time'],
  validate(value, {end_date, start_time, end_time}) {
    if (value == end_date) {
      return start_time < end_time;
    }
    return value <= end_date;
  },
  message: i18n.t('validation.before', {field: '{_field_}', target: '{target}'})
});

extend('afterDateTime', {
  params: ['start_date', 'start_time', 'end_time'],
  validate(value, {start_date, start_time, end_time}) {
    if (value == start_date) {
      return start_time < end_time;
    }
    return value >= start_time;
  },
  message: i18n.t('validation.before', {field: '{_field_}', target: '{target}'})
});

extend('beforeDate', {
  params: ['target', 'noTime'],
  validate(value, {target, noTime}) {
    if (noTime) {
      return !value | value == "0000-00-00" ? true : value <= target;
    }
    return !value | value == "0000-00-00" ? true : value < target;
  },
  message: i18n.t('validation.before', {field: '{_field_}', target: '{target}'})
});

extend('afterDate', {
  params: ['target', 'noTime'],
  validate(value, {target, noTime}) {
    if (noTime) {
      return !value | value == "0000-00-00" ? true : value >= target;
    }
    return !value | value == "0000-00-00" ? true : value > target;
  },
  message: i18n.t('validation.after', {field: '{_field_}', target: '{target}'})
});

extend('not_empty', (value) => {
  return value.replace(/<[^>]+>/g, '') === ''
});

extend("decimal", {
  validate: (value, {decimals = '*', separator = '.'} = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(`^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`);

    return {
      valid: regex.test(value),
    };
  },
  message: i18n.t('validation.decimal', {field: '{_field_}'})
});

extend("phone_number", {
  validate(value) {
    const regex = new RegExp('^[0-9]*$');
    return {
      valid: regex.test(value)
    }
  },
  message: i18n.t('validation.phone_number')
});

extend("email", {
  message: i18n.t('validation.email', {field: '{_field_}'})
});

extend("zip_code", {
  validate(value) {
    const regex = new RegExp('^[0-9]{7}|(^[0-9]{3}-[0-9]{4})|(^[0-9]{4}-[0-9]{3})');
    return {
      valid: regex.test(value)
    }
  },
  message: i18n.t('validation.zip_code', {field: '{_field_}'})
});

extend("trim_space", {
  validate(value) {
    const regex = new RegExp('^[^\\s]+(\\s+[^\\s]+)*$');
    return {
      valid: regex.test(value)
    }
  },
  message: i18n.t('validation.trim_space', {field: '{_field_}'})
});

extend("trim_left_space", {
  validate(value) {
    const regex = new RegExp('^[^\\s]+(.|\\s)*$');
    return {
      valid: regex.test(value)
    }
  },
  message: i18n.t('validation.trim_left_space', {field: '{_field_}'})
});

extend('checkbox', {
  params: ['required_value'],
  validate(value, {required_value = true}) {
    return value === required_value;
  },
  message: i18n.t('validation.checkbox', {field: '{_field_}'})
});

extend("date_format", {
  params: ['format'],
  validate(value, {format}) {
    value = value + '';
    var delimeter = ['/', '-'];
    var expressions = {
      y: '[0-9]',
      m: '[0-9]',
      d: '[0-9]'
    };
    var command = '^'
    for (let i = 0; i < format.length; i++) {
      if (delimeter.includes(format[i])) {
        command = command + format[i]
      } else {
        command = command + expressions[format[i]]
      }
    }
    const regex = new RegExp(command);
    return {
      valid: regex.test(value)
    }
  },
  message: i18n.t('validation.date_format', {field: '{_field_}', format: '{format}'})
})

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);