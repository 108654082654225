export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: '変更内容の確認',
    description: '以下の内容に誤りがなければ、「登録」ボタンをクリックしてください。'
  },
  basic_information: {
    title: "基本情報",
    name: "お名前",
    furigana: "ふりがな",
    mail_address: "メールアドレス",
    sex: "性別",
    profession: "職業",
    date_of_birth: "生年月日",
    password: "パスワード",
    send_email_newsletter: "メールマガジン送付"
  },
  product_delivery_address: {
    title: "商品お届け先",
    postal_code: "郵便番号",
    prefecture: "都道府県",
    street_address: "住所",
    phone_number: "電話番号",
    unregistered: "未登録",
  },
  gender: {
    male: '男性',
    female: '女性',
    anonymous: '答えない'
  },
  occupation: {
    employee: '会社員',
    student: '学生',
    self_employee: '自営業',
    other: 'その他',
  },
  btn: {
    update: '更新',
    to_fix: '修正する'
  }
}
