import ArtistService from "@/services/ArtistService";
import {mapActions, mapState} from "vuex";

export default {
  computed: {
    ...mapState([
      'current_artist'
    ])
  },
  methods: {
    ...mapActions([
      'getCartData'
    ]),
    async handlePublishKey(data) {
      const artistId = this.current_artist.id
      return ArtistService.savePreCart(artistId, data)
    },
    async handleRestoreCart(pubKey) {
      const artistId = this.current_artist.id
      await ArtistService.restorePreCart(artistId, pubKey)
      await this.getCartData()
    },
  }
}
