import Vue from 'vue'
import VueI18n from 'vue-i18n'

import en from './locales/en';
import ja from './locales/ja';

Vue.use(VueI18n);

const i18nData = {
  en, ja
}

const currentLang = process.env.VUE_APP_LOCALE || 'ja';

const i18n = new VueI18n({
  //set default language here
  locale: currentLang,
  messages: i18nData,
  silentTranslationWarn: true
});


export default i18n;

