export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ'
  },
  headline: {
    title: 'Withdrawal completed',
    description: 'The withdrawal procedure has been completed.',
    thank_you: 'Thank you for using Bromide.JP My Page.'
  },
  btn: {
    return_to_top: 'Return to top'
  }
}