export default {
  'notice': 'NOTICE',
  'sales_period': '販売期間',
  'remaining_sale_day': '販売終了残り{remaining_days}日',
  'new': 'NEW',
  'end_of_sale_today': '本日販売終了',
  'return_to_artist_top': 'アーティストTOPに戻る',
  'info': 'INFO',
  'set': '{amount} 個',
  'tax_included': '税込',
  'relation': {
    'title': 'CATEGORY',
    'subtitle': 'カテゴリー',
    'print_service_title': 'Print Service',
    'print_service_subtitle': 'プリントサービス商品',
    'goods_title': 'Goods',
    'goods_subtitle': '物販商品',
    'all': 'ALL',
    'click_here_for_details': '詳細はこちら'
  },
  'up_to': "迄",
  'sale_term': "販売期間:"
}