<template>
  <div ref="container" class="mt-30">

    <Headline title="額装のスペック"/>

    <template v-if="currentCommodity">

      <div class="sub_commodity_name">{{ currentCommodity.commodity_name }}</div>

      <keep-proportion-content
        :proportion-target="() => $refs.container"
        :content="currentCommodity[`description_for_category_page_${isMobile ? 'sp' : 'pc'}`]"
        :p-sp-width="380"
        :gap="0"
        :gap-sp="0"
      />

      <keep-proportion-content
        :proportion-target="() => $refs.container"
        :p-sp-width="380"
        :gap="0"
        :gap-sp="0"
      >
        <p class="spec_slider_description">額装商品のイメージはこちらでご確認いただけます。<br>矢印をクリックすると他の額装のイメージをご確認いただけます。</p>
      </keep-proportion-content>

      <div class="spec_slider_container">

        <span
          class="spec_slider_arrow_left_wrap"
          @click="handlePrev"
        >
          <img
            v-if="enablePrev"
            :src="require('@assets/images/arw-left.svg')"
            class="spec_slider_arrow_left"
            alt="<"
          />
        </span>

        <div class="spec_slider_carousel_container">

          <vue-slick-carousel
            v-if="showCarousel && currentCommodity.sample_images.length"
            ref="saleProductCarousel"
            class="spec_slider_carousel"
            v-bind="carouselSetting"
          >
            <div
              v-for="(sampleImage, index) in currentCommodity.sample_images"
              :key="`sale_product_carousel_${index}`"
              class="spec_slider_carousel_image_wrapper"
            >
              <img
                class="spec_slider_carousel_image"
                :src="sampleImage.url"
                draggable="false"
                alt=""
              />
            </div>
          </vue-slick-carousel>

        </div>

        <span
          class="spec_slider_arrow_right_wrap"
          @click="handleNext"
        >
          <img
          v-if="enableNext"
          :src="require('@assets/images/arw-right.svg')"
          class="spec_slider_arrow_right"
          alt=">"
        />
        </span>
      </div>
    </template>

  </div>
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import KeepProportionContent from "../base/KeepProportionContent";

const carouselSetting = {
  arrows: false,
  dots: true,
  centerMode: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  speed: 500,
  swipeToSlide: true,
}

export default {
  name: "CategorySubCommoditySpecSlider",
  components: {
    VueSlickCarousel,
    KeepProportionContent,
    Headline: () => import('@components/base/Headline'),
  },
  props: {
    subCommodities: {
      type: Array,
      default: () => [],
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      currentCommodityIndex: 0,
      carouselSetting,
      showCarousel: true
    }
  },
  computed: {
    currentCommodity() {
      if (this.subCommodities.length < 0) return null
      return this.subCommodities[this.currentCommodityIndex]
    },
    enablePrev() {
      return this.currentCommodityIndex > 0
    },
    enableNext() {
      return this.currentCommodityIndex + 1 < this.subCommodities.length
    },
  },
  methods: {
    handlePrev() {
      if (this.enablePrev) this.currentCommodityIndex = this.currentCommodityIndex - 1
    },
    handleNext() {
      if (this.enableNext) this.currentCommodityIndex = this.currentCommodityIndex + 1
    },
    reInitCarousel() {
      this.showCarousel = false
      this.$nextTick(() => this.showCarousel = true)
    }
  },
  watch: {
    currentCommodityIndex: {
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.reInitCarousel()
        }
      }
    }
  }
}
</script>