<template>
  <article>
    <section class="content pb-80">
      <div class="content__inner">
        <router-link v-if="$store.state.current_artist" v-t="'mypage.return_to_artist_page'" :to="{ name: 'artist.home' }"
                     class="d-block text-right mb-20"></router-link>
        <Headline :title="$t('mypage_history.title.purchase_history')"></Headline>
        <p v-html="$sanitize($t('mypage_history.purchase_detail_expired_info'))" class="mb-30)"></p>
        <table class="history-page__table">
          <thead>
          <tr>
            <th v-t="'mypage_history.column.purchase_number'" class="al-c"></th>
            <th v-t="'mypage_history.column.order_date_and_time'" class="al-c"></th>
            <th v-t="'mypage_history.column.payment_method'" class="al-c"></th>
            <th v-t="'mypage_history.column.product_type'" class="al-c"></th>
            <th v-t="'mypage_history.column.shipping_status'" class="al-c"></th>
            <th v-t="'mypage_history.column.settlement_amount'" class="al-c"></th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td class="al-c">{{ history_data.order_id }}</td>
            <td class="al-c w-max-50">{{ orderDataTimeFormat(history_data.order_date_time) }}</td>
            <td class="al-c"> {{ paymentMethodType(history_data.payment_method) }}</td>
            <td class="al-c">
              <template v-for="(productType, index) in productTypes">
                <span :key="productType">{{ productType }}</span>
                <br v-if="productTypes.length === index + 1" :key="`br-${productType}`">
              </template>
            </td>
            <td class="al-c" v-html="$sanitize(history_data.shipping_status)"></td>
            <td class="al-c">
              <dl class='align-dl'>
                <dt v-t="'mypage_history.column.subtotal'"></dt>
                <dd>¥{{ roundUp(history_data.subtotal) }}</dd>
              </dl>
              <dl class='align-dl'>
                <dt v-t="'mypage_history.column.shipping_fee'"></dt>
                <dd>¥{{ roundUp(history_data.shipping_fee) }}</dd>
              </dl>
              <dl v-if='is_payment_fee' class="align-dl">
                <dt v-t="'mypage_history.column.payment_fee'"></dt>
                <dd>¥{{ roundUp(history_data.payment_fee) }}</dd>
              </dl>
              <dl class="align-dl">
                <dt v-t="'mypage_history.column.total_amount'"></dt>
                <dd>¥{{ roundUp(history_data.total_payment_amount) }}</dd>
              </dl>
            </td>
          </tr>
          </tbody>
        </table>

        <p class="my_page_qualified_invoice_issuer_number_sp">
          株式会社Bromide 適格請求書発行事業者
          <br>
          登録番号:{{qualifiedInvoiceIssuerNumber}}
        </p>

        <div v-t="$t('mypage_history.purchase_detail')" class="main-title"></div>

        <p v-t="'mypage_history.purchase_detail_info_2'" class="mb-30"></p>
        <div v-if="event_category_data.length > 0" class="form-select mb-30">
          <select v-model="selected_event_category"
                  @change="onEventCategoryChange(selected_event_category)">
            <template v-for="(item, index) in event_category_data">
              <optgroup v-if="!checkIfSameAsPreviousEvent(index)" :key="'event-' + index"
                        :label="event_category_data[index].event_name">
              </optgroup>
              <option :key="index" :value="index" style="padding-left: 5px;">
                {{ event_category_data[index].category_name }}
              </option>
            </template>
          </select>
        </div>

        <p
          v-if="event_category_view_limit"
          v-html="$sanitize($t(`mypage_history.purchase_detail_${event_category_view_limit}_info`))"
          style="padding: 30px; text-align: center"
        ></p>
        <OrderProductList v-show="!event_category_view_limit" :category_id="category_id" :event_id="event_id"/>

        <p v-html="$sanitize(display_selected_event_category)"></p>

        <dl class="history-page__total mt-50">
          <dt>
            <span v-t="'mypage_history.column.total_qty'" class="total-label"/>
            <template v-if="isRandomOrGacha || event_category_sale_plan_type === 'bundled'">
              <span class="total-value">
               <span class="price-bold">
                 {{ event_category_set_qty }}
               </span>
                {{ $t('mypage_history.set') }}
              </span>
            </template>
            <template v-else>
              <span class="total-value">
              <span class="price-bold">
                {{ event_category_product_qty }}
              </span>

              {{ $t('mypage_history.sheet') }}
             </span>
            </template>
          </dt>
          <dt>
            <span v-t="'mypage_history.column.total'" class="total-label"/>
            <span class="total-value">
              <span class="price-bold">
                ¥{{ roundUp(event_category_product_total_amount) }}
              </span>

              {{ $t('mypage_history.include_tax') }}
            </span>
          </dt>
        </dl>
      </div>
    </section>
  </article>
</template>

<script>
import MemberService from '@/services/MemberService';

export default {
  name: "MyPageHistoryDetailSP",
  components: {
    Headline: () => import('@components/base/Headline'),
    OrderProductList: () => import('./OrderProductList.vue')
  },
  data() {
    return {
      history_data: {},
      event_category_data: [],
      selected_event_category: '',
      selected_event_id: '',
    }
  },
  created() {
    this.getOrderHistoryByOrderId();
    this.getPurchaseEventCategoryByOrderID();
  },
  computed: {
    is_payment_fee() {
      return this.history_data.payment_fee ?? true;
    },
    productTypes() {
      const productTypes = Array.isArray(this.history_data.product_types) ?  this.history_data.product_types : []

      return productTypes.map((productType) => this.$t(`mypage_history.product_type.${productType}`));
    },
    event_id() {
      return this.event_category_data[this.selected_event_category] ? String(this.event_category_data[this.selected_event_category].event_id) : '';
    },
    category_id() {
      return this.event_category_data[this.selected_event_category] ? String(this.event_category_data[this.selected_event_category].category_id) : '';
    },
    display_selected_event_category() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].event_category_name : '';
    },
    event_category_product_qty() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].qty : '';
    },
    event_category_product_total_amount() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].total_amount : '';
    },
    event_category_sale_plan_type() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].sale_plan_type: ''
    },
    event_category_set_qty() {
      return this.event_category_data[this.selected_event_category]
        ? Math.floor(( this.event_category_data[this.selected_event_category].qty / this.event_category_data[this.selected_event_category].bundle_unit ) * 100) / 100
        : ''
    },
    event_category_view_limit() {
      return this.event_category_data[this.selected_event_category] ? this.event_category_data[this.selected_event_category].view_limit : false
    },
    isRandomOrGacha() {
      return this.event_category_sale_plan_type === 'bundled_random' || this.event_category_sale_plan_type === 'gacha'
    },
    qualifiedInvoiceIssuerNumber() {
      return process.env.VUE_APP_QUALIFIED_INVOICE_ISSUER_NUMBER
    },
  },
  methods: {
    getOrderHistoryByOrderId() {
      MemberService.getOrderHistoryByOrderId(this.$route.params.history_id).then(res => {
        this.history_data = res.data;
        this.history_data.shipping_status = this.history_data.shipping_status.split('</br>').map((val) => {
          return val ? this.$t('mypage_history.shipping_status.' + val) : '</br></br>';
        }).join('');
      });
    },
    getPurchaseEventCategoryByOrderID() {
      MemberService.getPurchaseEventCategoryByOrderID(this.$route.params.history_id).then(res => {
        this.event_category_data = res.data;
        this.onEventCategoryChange(0);
      })
    },
    orderDataTimeFormat(order_date_time) {
      return order_date_time ? order_date_time.replaceAll('-', '/') : '';
    },
    paymentMethodType(payment_method) {
      return payment_method ? this.$t(`mypage_history.payment_method_type.${payment_method}`) : '';
    },
    roundUp(value) {
      return value ? Math.ceil(Number(value)) : 0;
    },
    onEventCategoryChange(id) {
      this.$set(this, 'selected_event_category', id)
    },
    checkIfSameAsPreviousEvent(index) {
      return this.event_category_data[index - 1] ?
        (this.event_category_data[index - 1].event_id == this.event_category_data[index].event_id) : false;
    }
  }
}
</script>
<style>
.d-block {
  display: block;
}

.text-right {
  text-align: right;
}

.price-bold {
  font-size: 1.8rem;
  font-weight: 700;
}

.event-category-checked {
  background-color: #000;
  color: #FFF;
}

#event_being_display {
  text-decoration: underline;
}

.my_page_qualified_invoice_issuer_number_sp {
  text-align: right;
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}
</style>
