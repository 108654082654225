<template>
  <section
    class="information__detail__inner"
  >
    <keep-proportion-content
      root="p"
      class="information__detail__inner_date"
      :p-width="950"
      :gap-sp="40"
      :gap="70"
      :content="information.date"
    />
    <keep-proportion-content
      root="h2"
      class="information__detail__inner_title"
      :p-width="950"
      :gap-sp="40"
      :gap="70"
      :content="information.title"
      @click="isActive = !isActive"
    />
    <div v-if="isActive" class="information__detail__inner_content">
      <keep-proportion-content
        :p-width="950"
        :gap-sp="40"
        :gap="70"
        :content="information.message"
      />
    </div>
  </section>
</template>
<script>
import KeepProportionContent from "@components/base/KeepProportionContent";
export default {
  components: {KeepProportionContent},
  props: {
    contentClass: {
      type: String,
      require: false,
      default: '',
    },
    active: {
      type: Boolean,
      require: false,
      default: false,
    },
    information: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isActive: this.active,
    }
  },
}
</script>
