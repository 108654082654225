export default [
  {
    path: 'reorder',
    name: 'repurchase.reorder',
    component: () => import("@pages/repurchase/ReorderIndex")
  },
  {
    path: 'reorder/attention',
    name: 'repurchase.reorder.attention',
    meta: { type: 'reorder' },
    component: () => import("@pages/repurchase/RepurchaseAttention")
  },
  {
    path: 'reorder/purchase',
    name: 'repurchase.reorder.purchase',
    meta: { type: 'reorder' },
    component: () => import("@pages/repurchase/Repurchase")
  },
  {
    path: 'reorder/confirmation',
    name: 'repurchase.reorder.confirmation',
    meta: { type: 'reorder' },
    component: () => import('@pages/repurchase/RepurchaseConfirmation')
  },
  {
    path: 'reorder/confirmation2',
    name: 'repurchase.reorder.confirmation2',
    meta: { type: 'reorder' },
    component: () => import('@pages/repurchase/RepurchaseConfirmation2')
  },
  {
    path: 'reorder/credit',
    name: 'repurchase.reorder.credit',
    meta: { type: 'reorder' },
    component: () => import('@pages/repurchase/RepurchaseCredit')
  },
  {
    path: 'reorder/fix',
    name: 'repurchase.reorder.fix',
    meta: { type: 'reorder' },
    component: () => import('@pages/repurchase/RepurchaseFix')
  },
  {
    path: "reshipping",
    name: "repurchase.reshipping",
    component: () => import("@pages/repurchase/ReshippingIndex")
  },
  {
    path: 'reshipping/attention',
    name: 'repurchase.reshipping.attention',
    meta: { type: 'reshipping' },
    component: () => import("@pages/repurchase/RepurchaseAttention")
  },
  {
    path: 'reshipping/purchase',
    name: 'repurchase.reshipping.purchase',
    meta: { type: 'reshipping' },
    component: () => import("@pages/repurchase/Repurchase")
  },
  {
    path: 'reshipping/confirmation',
    name: 'repurchase.reshipping.confirmation',
    meta: { type: 'reshipping' },
    component: () => import('@pages/repurchase/RepurchaseConfirmation')
  },
  {
    path: 'reshipping/confirmation2',
    name: 'repurchase.reshipping.confirmation2',
    meta: { type: 'reshipping' },
    component: () => import('@pages/repurchase/RepurchaseConfirmation2')
  },
  {
    path: 'reshipping/credit',
    name: 'repurchase.reshipping.credit',
    meta: { type: 'reshipping' },
    component: () => import('@pages/repurchase/RepurchaseCredit')
  },
  {
    path: 'reshipping/fix',
    name: 'repurchase.reshipping.fix',
    meta: { type: 'reshipping' },
    component: () => import('@pages/repurchase/RepurchaseFix')
  }
]