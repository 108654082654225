<template>
  <div ref="slideModal" class="slide-modal modal-hide">
    <div id="slide-modal-close" class="slide-modal--bg" @click="onClose"></div>
    <div class="slide-modal--container">
      <div class="slide-modal--content">
        <div class="modal-content">
          <slot></slot>
        </div>
      </div>
      <span class="modal-close" @click="onClose">×</span>
      <div class="slide-modal--arrow">
        <span
          v-show="previousVisible"
          class="modal-arrow arrow-left"
          @click="onPrevious"
        >
         <svg
           class="bi bi-chevron-left"
           fill="currentColor"
           height="16"
           viewBox="0 0 16 16"
           width="16"
           xmlns="http://www.w3.org/2000/svg"
         >
            <path
              d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"
              fill-rule="evenodd"
            />
          </svg>
        </span>
        <span
          v-show="nextVisible"
          class="modal-arrow arrow-right"
          @click="onNext"
        >
          <svg
            class="bi bi-chevron-right"
            fill="currentColor"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"
              fill-rule="evenodd"
            />
          </svg>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      previousVisible: true,
      nextVisible: true,
      couldGoNext: true,
      couldGoPrevious: true,
    }
  },
  computed: {
    isExtraSmallDevice() {
      return window.innerWidth < 576
    },
  },
  destroyed() {
    document.body.classList.remove('no_scroll')
  },
  methods: {
    show() {
      this.$refs.slideModal.classList.remove('modal-hide')
      // disable scroll
      document.body.classList.add('no_scroll')
    },
    hide() {
      this.$refs.slideModal.classList.add('modal-hide')
      // enable scroll
      document.body.classList.remove('no_scroll')
    },
    disableNext(disable) {
      this.nextVisible = !disable
    },
    disablePrevious(disable) {
      this.previousVisible = !disable
    },
    onClose() {
      this.$refs.slideModal.classList.add('modal-hide')
      // enable scroll
      document.body.classList.remove('no_scroll')
      this.$emit('onClose')
    },
    onNext() {
      this.$emit('onNext')
    },
    onPrevious() {
      setTimeout(() => this.$emit('onPrevious'), 200)
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./slide-modal.scss";
</style>
