/**
 * フォントサイズを自動調節して特定のフォントサイズを画面幅が変わっても維持するスタイル文字列を生成する
 * @param selector
 * @param fontSize
 * @param spFontSize
 * @param pcFontSizeBasePoint
 * @param spFontSizeBasePoint
 * @param pcAroundWidth
 * @param spAroundWidth
 * @returns {string}
 */
export default (
  selector = '',
  fontSize = 13,
  spFontSize = 13,
  pcFontSizeBasePoint = 1000,
  spFontSizeBasePoint = 360,
  pcAroundWidth = 0,
  spAroundWidth = 0,
) => {
  let result = ''

  result += `
        ${selector} {
          word-break: break-all;
          line-break: anywhere;
          font-size: ${fontSize}px;
        }
        @media screen and (max-width: 1000px) {
          ${selector} {
            font-size: calc(calc(100vw - ${pcAroundWidth}px) / ${pcFontSizeBasePoint - pcAroundWidth} * ${fontSize});
          }
        }
      `

  if (spFontSize) {
    result += `
        @media screen and (max-width: 767px) {
          ${selector} {
            font-size: calc(calc(100vw - ${spAroundWidth}px) / ${spFontSizeBasePoint - spAroundWidth} * ${spFontSize}) !important;
          }
        }
        `
  }

  return result
}
