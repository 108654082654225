<template>
  <div>
    <a href="" @click.prevent="gotoCart">
      <img :src="require('@images/icon_shopping.svg')" alt="">
      <span :key="cartSheetAmount" class="purchase_count">{{ cartSheetAmount }}</span>
      <span class="c-black header__nav-menu">
                {{ $t('general.cart') }}
            </span>
    </a>
  </div>
</template>
<script>
export default {
  props: {
    cartCategory: {
      type: Object,
      default: null
    },
    cartSheetAmount: {
      type: [String, Number],
      default: 0
    }
  },
  methods: {
    gotoCart() {
      this.$emit('to-cart')
    }
  },
}
</script>
<style scoped>
.js-cart_button {
  cursor: pointer;
}

.attention {
  display: flex;
  justify-content: center;
}
</style>
