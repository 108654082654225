export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ',
    create: '登録情報',
    create_subtitle: 'お届け先の追加や変更を行うことができます。',
    shipping_cost_address: "Shipping Cost Address",
    purchase_history: "Purchase History",
  },
  basic_information: {
    title: "基本情報",
    name: "お名前",
    furigana: "ふりがな",
    mail_address: "メールアドレス",
    sex: "性別",
    profession: "職業",
    date_of_birth: "生年月日",
    password: "パスワード",
    send_email_newsletter: "メールマガジン送付"
  },
  product_delivery_address: {
    title: "商品お届け先",
    postal_code: "郵便番号",
    prefecture: "都道府県",
    street_address: "住所",
    phone_number: "電話番号",
    edit: "編集",
    add: "追加"
  },
  btn: {
    submit: "checking the content",
    edit: "Update",
    confirm: "Confirmation",
    back: "Back"
  },
  gender: {
    male: '男性',
    female: '女性',
    anonymous: '答えない'
  },
  occupation: {
    employee: '会社員',
    student: '学生',
    self_employee: '自営業',
    other: 'その他',
  },
  year: '年',
  month: '月',
  day: '日',
  return_to_artist_page: 'アーティストTOPに戻る >'
}
