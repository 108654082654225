export default {
  title_login_en: "Login",
  title_login_jp: "Login",
  please_log_in_here_to_complete_the_purchase_procedure: "Please log in here to complete the purchase procedure.",
  if_you_forget_your_password: "* If you forget your password",
  here: "Here",
  please_reset_your_new_password_from: "Please reset your new password from.",
  email_address: "mail address",
  password: "パスワード",
  save_login_status_for_one_month: "Save login status for one month",
  register_for_membership: "register for membership",
  here_you_will_find_information: `
        <span style="font-weight: bold">It is convenient to register to use My Page.</span><br>
        <br>

        ・You can proceed without entering the information required for purchase such as shipping address information.
        <br>
        ※ Credit card information cannot be retained or registered with this service.
        <br>
        <br>
        ・You can check the purchase information and history.
        <br>
        ※ Since the thumbnail image of the purchased photo is not attached to the delivery note of our service, it is not possible to confirm whether it is the purchased content.
        <br>
        <br>
        <span style="font-weight: bold">It is convenient to register to use My Page.</span><br><br>
        ・If you do not wish to register on My Page, please select "Purchase without registering on My Page".<br>
            Proceed to purchase procedure
    `,
  dummy_text: "Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text\nn Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text Dummy text",
  purchase_without_registering_as_a_member: "Purchase without registering as a member",
  continue_shopping: "Continue shopping",
  login: "log in"
}