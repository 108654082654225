<template>
  <article>
    <h2 class="page-title">
      <span class="page-title--en">Purchase</span>
      <span class="page-title--jp">購入手続き</span>
    </h2>
    <section class="content pb-80">
      <validation-observer ref="observer">
        <div class="content__inner" ref="contentInner">

          <section v-if="isShowConsent">
            <div class="mb-60">
              <h3 class="section-title">未成年者購入に関する同意確認</h3>
              <keep-proportion-content
                class="mb-30"
                :proportion-target="() => $refs.contentInner"
                :gap-sp="0"
              >
                <span>未成年者様が商品を購入するには、<br class="s-elem"/>保護者の同意が必要です。</span>
                <br class="s-elem"/>
                <span class="bold">保護者から今回の商品購入の同意を得ていますか?</span>
              </keep-proportion-content>
              <div :class="{ 'validate-error': !agreement.iHaveChosen}" class="border-red al-c">
                <label class="check">
                  <input v-model="agreement.iHaveChosen" class="js-child-check" name="test" type="checkbox">
                  <span class="check--dummy"></span>
                  <span class="check--text">はい、私は同意を得ています。</span>
                </label>
              </div>
            </div>
          </section>

          <!-- render payment method -->
          <section>
            <div class="mb-60">
              <h3 class="section-title">お支払い方法選択</h3>
              <keep-proportion-content
                class="mb-30"
                :content="'下記より、ご希望のお支払い方法を選択してください。'"
                :proportion-target="() => $refs.contentInner"
                :gap-sp="0"
              />

              <PaymentSelectList
                :current-payment-key="userPaymentInfo.payment_method_id"
                :current-payment-type="userPaymentInfo.parent_payment_method_id"
                :payment-methods="payment_methods"
                @select-child-payment="selectChildPayment"
                @select-payment-type="selectPaymentType"
                @select-payment-key="selectPaymentKey"
              />
            </div>
          </section>

          <!-- render user purchase product -->
          <PurchaseProduct ref="purchaseProduct" :selected_payment_id="userPaymentInfo.parent_payment_method_id"
                           @re-validate="checkValidation()"
                           @edit-address-click="$refs.editAddress.init(userPurchaseInfo)"/>

          <ErrorAlert v-if="!!isDuplicateEmail" class="mb-30"><p v-html="$sanitize($t(duplicateEmailError.meta.message))"/></ErrorAlert>
          <div v-if="!isLoggedIn" class="al-c mt-20 mb-20">
            <div @click.prevent="onCheckWithRegistrationMypage">
              <label class="check">
                <input
                  :checked="registeredMypage"
                  name="confirmed"
                  type="checkbox"
                >
                <span class="check--dummy"></span>
                <span class="check--text">マイページにも登録する</span>
              </label>
            </div>
          </div>

          <div class="mb-20">
            <div>
              <button :disabled="disabledSubmit" class="btn-100--red" @click="submit">
                {{ selectedCreditPayment ? 'カード情報入力へ' : '注文確認へ' }}
              </button>
            </div>
            <div action="purchase.html">
              <button class="btn-100--none" @click="goBack()">
                {{ $t('purchase_credit.to_fix') }}
              </button>
            </div>
            <div class="confirm_footer pt-10">
              <input :value="$t('purchase_confirmation.add_another_product')" class="c-darkgray link-line bg-white" name="delete"
                     type="button" @click="backToArtist">
            </div>
            <div class="confirm_footer pt-10">
              <input :value="$t('purchase_confirmation.cancel_purchase')" class="c-darkgray link-line bg-white" name="delete"
                     type="button" @click="onCancelPurchase">
            </div>
          </div>
        </div>
      </validation-observer>
    </section>

    <WithRegisterModal
      v-if="isShowWithRegisterModal"
      :is-show-modal="isShowWithRegisterModal"
      :entity="userPurchaseInfo"
      @register-confirm="handleRegisterConfirm"
      @register-cancel="handleRegisterCancel"
    />

    <CancelPurchaseModal
      :is-show-modal="isShowCancelModal"
      @on-cancel="onCancelModal"
    />

    <EditAddressModal ref="editAddress" @on-save="onEditAddressSave"/>

    <DeliveryAddressConfirmModal
      :is-show-modal="isShowAddressModal"
      :user-info="userPurchaseInfo"
      @address-confirm="handleAddressConfirm"
      @address-cancel="handleAddressCancel"
    />

    <TermOfIncompleteAddressConfirmModal
      :is-show-modal="isShowTermModal"
      :user-info="userPurchaseInfo"
      @term-confirm="handleTermConfirm"
      @term-cancel="handleTermCancel"
    />
  </article>
</template>
<script>
import Constants from '@constants';
import MemberService from '@services/MemberService';
import PurchaseService from '@services/PurchaseService';
import {PAYMENT_TYPES} from '../../constants/payment'
import KeepProportionContent from "@components/base/KeepProportionContent";
import store from "../../store/store";

export default {
  components: {
    PaymentSelectList: () => import('@components/payment/PaymentSelectList'),
    PurchaseProduct: () => import('./PurchaseProduct'),
    WithRegisterModal: () => import('./Modal/WithRegisterModal'),
    CancelPurchaseModal: () => import('./Modal/CancelPurchaseModal'),
    EditAddressModal: () => import('./Modal/EditAddressModal.vue'),
    DeliveryAddressConfirmModal: () => import('@pages/purchase/Modal/DeliveryAddressConfirmModal'),
    TermOfIncompleteAddressConfirmModal: () => import('@pages/purchase/Modal/TermOfIncompleteAddressConfirmModal'),
    ErrorAlert: () => import("@components/base/ErrorAlert"),
    KeepProportionContent
  },
  beforeRouteEnter(to, from, next) {
    if (!store.state.cart.cartDatas.length) {
      next({name: 'cart.page', params: { designate_page: to.params.designate_page}})
    } else {
      next()
    }
  },
  created() {
    this.$cookies.set(Constants.PURCHASE_STEP, 'purchase.confirmation', Constants.PURCHASE_INFO_DURATION_COOKIE)
    this.getArtistPaymentMethod()

    const userPaymentInfo = this.$cookies.get(Constants.USER_PAYMENT_INFO);

    if (userPaymentInfo) {
      this.userPaymentInfo = userPaymentInfo
    }

    this.userPurchaseInfo = this.$cookies.get(Constants.USER_PURCHASE_INFO);

    if (this.userPurchaseInfo.with_registration) {
      this.registeredMypage = true
    }
  },
  computed: {
    isShowConsent() {
      let dob = this.getAge(this.userPurchaseInfo.date_of_birth)
      return dob < 20;
    },
    isLoggedIn() {
      return this.$store.state.isLogged
    },
    selectedCreditPayment() {
      return this.userPaymentInfo && this.userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT
    }
  },
  data() {
    return {
      PAYMENT_TYPES,
      agreement: {
        iHaveChosen: false,
      },
      userPurchaseInfo: {},
      userPaymentInfo: {
        payment_method_id: null,
        parent_payment_method_id: null,
        card_number: null,
        holder_name: null,
        security_code: null,
        expiration_date: null,
      },
      disabledSubmit: false,
      payment_methods: [],
      isShowWithRegisterModal: false,
      registeredMypage: false,
      isShowCancelModal: false,

      isShowAddressModal: false,
      isShowTermModal: false,

      isDuplicateEmail: false,
      duplicateEmailError: null
    }
  },
  methods: {
    onEditAddressSave(val) {
      this.userPurchaseInfo = val
      this.$cookies.set(Constants.USER_PURCHASE_INFO, JSON.stringify(val), Constants.PURCHASE_INFO_DURATION_COOKIE)
      this.$refs.purchaseProduct.updateAddress(val)
    },

    selectChildPayment(paymentKey) {
      this.userPaymentInfo.payment_method_id = paymentKey
    },
    selectPaymentType(paymentType) {
      this.userPaymentInfo.parent_payment_method_id = paymentType
      this.userPaymentInfo.payment_method_id = null
    },
    selectPaymentKey(paymentKey) {
      this.userPaymentInfo.payment_method_id = paymentKey

      const targetPaymentType = this.payment_methods.find((paymentMethodType) => {
        return paymentMethodType.children.find((paymentMethod) => paymentMethod.key === paymentKey)
      })

      if (targetPaymentType) {
        this.userPaymentInfo.parent_payment_method_id = targetPaymentType.type
      }
    },
    goBack() {
      var routeName = 'purchase'
      if (this.isLoggedIn) {
        routeName = 'cart.page'
      }
      return this.$router.push({name: routeName})
    },
    async checkValidation() {
      if (!(await this.$refs.observer.validate()) || (this.isShowConsent && !this.agreement.iHaveChosen)) {
        return false;
      }
      return true;
    },
    getArtistPaymentMethod(designate_page) {
      PurchaseService.getArtistPaymentMethod(designate_page).then(res => {
        this.payment_methods = res.data
      })
    },
    async submit() {
      this.disabledSubmit = true

      if (!(await this.checkValidation())) {
        this.scrollToFirstError();
        this.disabledSubmit = false

        return;
      }

      if (await this.$store.dispatch('getCartData')) {
        this.disabledSubmit = false

        return;
      }

      this.$cookies.set(Constants.USER_PURCHASE_INFO, JSON.stringify(this.userPurchaseInfo), Constants.PURCHASE_INFO_DURATION_COOKIE)
      this.$cookies.set(Constants.USER_PAYMENT_INFO, JSON.stringify(this.userPaymentInfo), Constants.PURCHASE_INFO_DURATION_COOKIE)

      //selected credit settlement
      if (this.userPaymentInfo.parent_payment_method_id === PAYMENT_TYPES.TYPE_CREDIT) {
        this.disabledSubmit = false

        return this.$router.push({name: 'purchase.credit'})
      }

      this.disabledSubmit = false

      return this.$router.push({name: 'purchase.confirmation2'})
    },
    backToArtist() {
      this.$router.push({name: 'artist.home'});
    },
    onCancelPurchase() {
      return this.isShowCancelModal = true;
    },
    onCheckWithRegistrationMypage() {
      if (!this.registeredMypage) {
        this.isShowWithRegisterModal = true
      } else {
        this.registeredMypage = false
        this.$set(this.userPurchaseInfo, 'password', null)
        this.$set(this.userPurchaseInfo, 'password_confirmation', null)
        this.$set(this.userPurchaseInfo, 'with_registration', false)
      }
    },
    handleRegisterConfirm() {
      this.isShowWithRegisterModal = false
      this.checkMyPageUserEmailDuplicate()
    },
    checkMyPageUserEmailDuplicate() {
      MemberService.checkMyPageUserEmailDuplicate({'email': this.userPurchaseInfo.email}).then(() => {
        setTimeout(() => {
          this.$nextTick(() => {
            this.isShowAddressModal = true
          })
        }, 500)
      }).catch((e) => {
        this.duplicateEmailError = e.data
        this.isDuplicateEmail = true
        this.$set(this.userPurchaseInfo, 'password', null)
        this.$set(this.userPurchaseInfo, 'password_confirmation', null)
        this.$set(this.userPurchaseInfo, 'with_registration', false)
      })
    },
    handleRegisterCancel() {
      this.isShowWithRegisterModal = false
      this.$set(this.userPurchaseInfo, 'password', null)
      this.$set(this.userPurchaseInfo, 'password_confirmation', null)
    },
    handleAddressConfirm() {
      this.isShowAddressModal = false
      setTimeout(() => {
        this.$nextTick(() => {
          this.isShowTermModal = true
        })
      }, 500)
    },
    handleAddressCancel() {
      this.isShowAddressModal = false
      this.$set(this.userPurchaseInfo, 'password', null)
      this.$set(this.userPurchaseInfo, 'password_confirmation', null)
    },
    handleTermConfirm() {
      this.isShowTermModal = false
      this.registeredMypage = true
    },
    handleTermCancel() {
      this.isShowTermModal = false
      this.$set(this.userPurchaseInfo, 'password', null)
      this.$set(this.userPurchaseInfo, 'password_confirmation', null)
    },

    onCancelModal() {
      this.isShowCancelModal = false;
    },
  }
}
</script>
<style scoped>
.confirm_footer {
  display: flex;
  justify-content: center;
}
</style>
