export default {
  title: {
    main: 'My Page',
    main_jp: 'マイページ',
    create: 'Registration Information',
    create_subtitle: 'You can add or change the delivery address.',
    shipping_cost_address: "Shipping Cost Address",
    purchase_history: "Purchase History",
  },
  basic_information: {
    title: "Basic information",
    name: "Name",
    furigana: "Furigana",
    mail_address: "Mail address",
    sex: "Sex",
    profession: "Profession",
    date_of_birth: "Date of birth",
    password: "Password",
    send_email_newsletter: "Send e-mail newsletter"
  },
  product_delivery_address: {
    title: "Product delivery address",
    postal_code: "Postal code",
    prefecture: "Prefecture",
    street_address: "Street address",
    phone_number: "Phone number",
    edit: "Edit",
    add: "Add"
  },
  btn: {
    submit: "checking the content",
    edit: "Update",
    confirm: "Confirmation",
    back: "Back"
  },
  gender: {
    male: "Male",
    female: "Female",
    anonymous: "Do not answer ",
  },
  occupation: {
    employee: 'Employee',
    student: 'Student',
    self_employee: 'Self employed',
    other: 'Other',
  },
  year: "Year",
  month: "Month",
  day: "Day",
  return_to_artist_page: 'Return to artist top page >'
}
