export default {
  enter_delivery_address: "お届け先入力",
  please_register_the_following: "商品発送に必要な以下の情報の登録を行ってください。",
  is_a_required_item: "※ は入力必須項目です。",
  basic_information: "基本情報",
  name: "お名前",
  firstname: "名",
  lastname: "姓",
  furigana: "ふりがな",
  lastname_furigana: "せい",
  firstname_furigana: "めい",
  mail_address: "メールアドレス",
  please_enter_your_e_mail_address: "メールアドレスを入力してください。",
  for_email_address_confirmation: "メールアドレス確認用",
  please_enter_your_email_address_again: "メールアドレスを再度入力してください。",
  sex: "性別",
  male: "男性",
  female: "女性",
  anonymous: "答えない",
  profession: "職業",
  occupations: {
    employee: "会社員",
    student: "学生",
    self_employee: "自営業",
    other: "その他"
  },
  please_select: "選択してください",
  date_of_birth: "生年月日",
  year: "年",
  month: "月",
  day: "日",
  desired_password: "希望パスワード",
  please_enter_your_password: "パスワードを入力してください。",
  "8_to_16_single_byte_alphanumeric_characters": "半角英数字8文字以上32文字以内で大文字・小文字・数字・記号（#?!@$%^&*-_ のいずれか）を含んだ文字列",
  repeat_password: "パスワードを再度入力してください。",
  send_e_mail_newsletter: "メールマガジン送付",
  do_not_want: "希望しない",
  hope: "希望する",
  product_delivery_address: "商品お届け先",
  postal_code: "郵便番号",
  half_width_numbers_and_no_hyphens: "半角数字・ハイフン無し",
  enter_address_from_zip_code: "郵便番号から住所入力",
  prefectures: "都道府県",
  street_address: "住所",
  city_address: "市区町村・番地",
  building_name_room_number: "建物名・部屋番号",
  phone_number: "電話番号",
  please_enter_your_phone_number_again: "電話番号を再度入力してください。",
  confirm_registration_details: "登録内容を確認する",
  text_address_pc: "お届け先や電話番号が間違っていると商品をお届けできません。",
  address_validate: "市区町村・番地は必須項目です",
  address2_validate: "建物名と部屋番号が未入力の場合、荷物が届かない可能性がございます。"
}
