import BaseService from "./BaseService";
import lscache from 'lscache'
import {CONFIRMATION_NAMES, CONFIRMATION_STATUS} from "@constants/ArtistConfirmation";
import Constants from "@constants";
import Vue from "vue";

class ArtistService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  getArtistByDesignatePage(designate_page, data = {}) {
    let url = `get-by-designate-page/${designate_page}`
    return this.performRequest(BaseService.METHOD_GET, url, data, {}, {
      hideLayout: true
    });
  }

  getArtistLandingPage(artistId, data = {}) {
    let url = `${artistId}/lp`
    return this.performRequest(BaseService.METHOD_GET, url, data)
  }

  getArtistStaticPage(artistId, urlSlug, data = {}) {
    let url = `${artistId}/static-pages/${urlSlug}`
    return this.performRequest(BaseService.METHOD_GET, url, data)
  }

  checkEmailRegistration(artistId, data = {}) {
    return this.performRequest(BaseService.METHOD_POST, `${artistId}/prior-email-registrations`, data)
  }

  verifyEmailRegistration(token) {
    return this.performRequest(BaseService.METHOD_POST, `/prior-email-registrations/${token}`)
  }

  /**
   *
   * @param designatePageId
   * @param agreementName {string}
   * @param expiration {number} 有効期限
   */
  setArtistConfirmationStatus(
    designatePageId,
    agreementName,
    expiration = 60 * 24 * 7
  ) {
    lscache.set(`${designatePageId}-${agreementName}`, `confirmed`, expiration)
    return Promise.resolve(true)
  }

  /**
   *
   * returns like {
   *  'email_registration': 'confirmed',
   *  'terms_agreement': 'not_demand'
   *  }
   * @param artistModel {object}
   * @returns {*}
   */
  getArtistConfirmationStatus(artistModel) {

    // prior Display type
    // none || terms_agreement || my_page_registration || email_registration
    const priorType = artistModel.prior_display.type
    // event_list_attention_type
    // not_display || display
    const eventListAttentionType = artistModel.event_list_attention_display.type
    // console.log('priorType::::::', priorType)
    const designatePageId = artistModel.designate_page_directory

    return Object.keys(CONFIRMATION_NAMES).reduce((acc, agreementKeyName) => {
      const agreementName = CONFIRMATION_NAMES[agreementKeyName]
      // console.log('agreementName:::::', agreementName)
      if (priorType === agreementName) {
        const status = lscache.get(`${designatePageId}-${agreementName}`)
        // console.log(`${agreementName} is demand and status is`, status)
        if (status && status === 'confirmed') {
          acc[agreementName] = CONFIRMATION_STATUS.CONFIRMED
        } else {
          acc[agreementName] = CONFIRMATION_STATUS.NOT_CONFIRMED
        }
      } else if (agreementName === CONFIRMATION_NAMES.EVENT_LIST_ATTENTION && eventListAttentionType !== 'not_display') {
        const status = lscache.get(`${designatePageId}-${agreementName}`)
        if (status && status === 'confirmed') {
          acc[agreementName] = CONFIRMATION_STATUS.CONFIRMED
        } else {
          acc[agreementName] = CONFIRMATION_STATUS.NOT_CONFIRMED
        }
      } else {
        acc[agreementName] = CONFIRMATION_STATUS.NOT_DEMAND
      }
      return acc

    }, {})

  }

  savePreCart(artistId, data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    return this.performRequest(BaseService.METHOD_POST, `${artistId}/pre-carts`, data)
  }

  restorePreCart(artistId, token) {
    const data = {}
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)
    return this.performRequest(BaseService.METHOD_POST, `${artistId}/pre-carts/${token}`, data)
  }

}

export default new ArtistService('artists');
