import BaseService from './BaseService'
import Constants from '@constants'
import Vue from 'vue'

class CartService extends BaseService {
  index(is_show_loading = false) {
    return super.index(
      {
        [Constants.CLIENT_UUID]: Vue.$cookies.get(Constants.CLIENT_UUID)
      },
      {},
      false,
      is_show_loading,
      false
    )
  }

  removeAllProductsFromCart() {
    return this.performRequest(
      BaseService.METHOD_DELETE,
      '',
      {
        [Constants.CLIENT_UUID]: Vue.$cookies.get(Constants.CLIENT_UUID)
      }
    )
  }

  addProductToCart(data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)

    return this.performRequest(BaseService.METHOD_POST, 'cart-products', data, {}, { showLoadingInterval: 0 })
  }

  addCombinedProductToCart(data) {
    data[Constants.CLIENT_UUID] = Vue.$cookies.get(Constants.CLIENT_UUID)

    return this.performRequest(BaseService.METHOD_POST, 'combined-cart-products', data, {}, { showLoadingInterval: 0 })
  }

  updateProductOnCart(cart_product_id, data = {}) {
    return this.performRequest(
      BaseService.METHOD_PUT,
      `cart-products/${cart_product_id}`,
      data,
      {},
      { is_ignore_interceptors_response_error: true }
    );
  }

  updateCombinedProductOnCart(cart_product_id, data = {}) {
    return this.performRequest(
      BaseService.METHOD_PUT,
      `combined-cart-products/${cart_product_id}`,
      data,
      {},
      { is_ignore_interceptors_response_error: true }
    );
  }

  removeProductFromCart(cart_product_id, data = {}) {
    return this.performRequest(
      BaseService.METHOD_DELETE,
      `cart-products/${cart_product_id}`,
      {
        [Constants.CLIENT_UUID]: Vue.$cookies.get(Constants.CLIENT_UUID),
        ...data
      },
      {},
      { is_show_loading: false, is_ignore_interceptors_response_error: true }
    )
  }

  removeCombinedProductFromCart(cart_product_id, commodity_id, data = {}) {
    return this.performRequest(
      BaseService.METHOD_DELETE,
      `combined-cart-products/${cart_product_id}/${commodity_id}`,
      {
        [Constants.CLIENT_UUID]: Vue.$cookies.get(Constants.CLIENT_UUID),
        ...data
      },
      {},
      { is_show_loading: false, is_ignore_interceptors_response_error: true }
    )
  }

  removeExpiredProductsFromCart() {
    return this.performRequest(
      BaseService.METHOD_DELETE,
      'cart-products/expired',
      {
        [Constants.CLIENT_UUID]: Vue.$cookies.get(Constants.CLIENT_UUID)
      },
      {},
      { is_show_loading: false }
    )
  }
}

export default new CartService('carts')
