import Vue from 'vue';
import UUID from "vue-uuid";
import VueSessionStorage from "vue-sessionstorage";
import VueMeta from 'vue-meta';
import VueGtag from "vue-gtag";
import sanitizeHtml from 'sanitize-html'

sanitizeHtml.defaults.allowedTags.push('img')
sanitizeHtml.defaults.allowedSchemes.push('data')
sanitizeHtml.defaults.allowedAttributes['*'] = [ 'style', 'class' ]

// import '@assets/scss/plugins/slick-theme.scss';
// import '@assets/scss/plugins/slick.scss';
import '@assets/scss/main.scss';
import Constants from './constants/index';
import '../node_modules/jquery/dist/jquery.js';
import '../node_modules/lity/dist/lity.js';

import '@assets/js/security.js';
// i18n
import i18n from './i18n';

// Store
import store from './store/store';

// Route
import router from './routes';
// Mixins
import helpers from './helpers';
import Main from './Main.vue';
import blankToBr from "@/helpers/blankToBr";

import isMobile from "@/helpers/isMobile";
import viewportControl from "@/helpers/viewportControl";

isMobile(store)
viewportControl(window)()

Vue.use(UUID);
Vue.use(VueSessionStorage);
Vue.use(VueMeta);

// custom Directive
Vue.directive('blank-to-br', {
  bind(el) {
    el.innerHTML = blankToBr(el.innerHTML)
  },
  update(el) {
    el.innerHTML = blankToBr(el.innerHTML)
  },
})


Vue.prototype.$sanitize = sanitizeHtml

Vue.config.productionTip = false;
Vue.use(require('vue-cookies'))
Vue.prototype.$is_preview = false;
//register base components
require('./components/layouts');

// vee validate
require('./vee-validate');

if (process.env.VUE_APP_GOOGLE_ANALYTIC_TRACKING_ID && process.env.VUE_APP_OLD_GOOGLE_ANALYTIC_TRACKING_ID) {
  Vue.use(VueGtag, {
    config: {
      id: process.env.VUE_APP_GOOGLE_ANALYTIC_TRACKING_ID,
      params: {
        send_page_view: true
      }
    },
    includes: [
      {
        id: process.env.VUE_APP_OLD_GOOGLE_ANALYTIC_TRACKING_ID,
        params: {
          send_page_view: true
        }
      }
    ],
    pageTrackerTemplate(to) {
      return {
        page_title: document.title,
        page_path: to.path
      }
    }
  }, router);
}

Vue.mixin(helpers);

var vueInstance = new Vue({
  store,
  i18n,
  router,
  render: h => h(Main)
}).$mount('#app');
if (!vueInstance.$cookies.isKey(Constants.CLIENT_UUID)) {
  vueInstance.$cookies.set(Constants.CLIENT_UUID, vueInstance.$uuid.v4(), -1)
}
