<template>
  <article>

    <InformationListAccordion
      v-if="category.whole_information_list && category.whole_information_list.length"
      :informations="category.whole_information_list"
      :content-class="'whole-information'"
      title="重要なお知らせ"
      noticeText="重要"
    />

    <category-content-section
      :data="category"
      :artist_page_url="artist_page_url"
      :event_page_url="event_page_url"
      :current-artist="currentArtist"
    />

    <div v-if="category.news && category.news.length" class="content__inner">
      <InformationListAccordion
        :informations="category.news"
        :content-class="'category-main__news'"
        title="お知らせ"
        noticeText="INFO"
      />
    </div>

    <section class="content pt-50 bg-white">

      <div class="content__inner pb-50">

        <dl class="category-dl mb-50">
          <dt class="category-dl__name">
            <div ref="categoryMain" class="category-dl__name__container">
              <keep-proportion-content
                :proportion-target="() => $refs.categoryMain"
                :style="{ color: category.category_name_text_color }"
                :content="category.category_name_top_page"
                :p-width="607"
                :gap="0"
                :gap-sp="0"
                blank-to-br
              />
            </div>
          </dt>
          <dd class="category-dl__price">
            <div class="price-title">{{ $t('category.selling_price') }}</div>
            <div class="price-detail">
              <p>
                ¥{{ category.product_standard_total_amount_with_tax }}
                <span class="small">({{$t('category_goods.tax_included') }})/1{{ $t('category_goods.set') }}</span>
              </p>
            </div>
          </dd>
        </dl>

        <!--    #### コメント     -->
        <div v-if="category.category_main_text" ref="categoryComment" class="mb-40 content__inner--editor">
          <Headline :title="$t('category.comment')"/>
          <keep-proportion-content
            :proportion-target="() => $refs.categoryComment"
            :content="category.category_main_text"
            :p-sp-width="380"
            :gap="0"
            :gap-sp="0"
          />
        </div>

        <!--    #### スペック      -->
        <div ref="categorySpec" class="category-spec">
          <Headline :title="$t('category.spec')"/>

          <template v-if="category.product_standard_display_attribute">

            <keep-proportion-content
              :proportion-target="() => $refs.categorySpec"
              :content="category.product_standard_display_attribute"
              :p-sp-width="380"
              :gap="0"
              :gap-sp="0"
            />

          </template>

          <CategorySubCommoditySpecSlider
            :sub-commodities="subCommodities"
            :is-mobile="isMobile"
          />

        </div>

      </div>

      <div class="content__inner mb-40">

        <category-cart-status-bar
          :class="[!isMobile ? '' : 'category-dl__space-bottom']"
          :total="0"
          :amount="product_current_total_include_tax"
          :set-total="product_current_qty_in_cart"
          :is_mobile="isMobile"
          is-combined-product
          @to-cart="onToCart"
        />

        <category-pagination
          :meta="combinedSaleProducts.meta"
          :total="combinedSaleProducts.data.length"
          @page="(page) => handleOnPage(page)"
        />

        <div class="mb-30 mt-10 large bold al-c set-sell__annotation">
          <p>写真を選択後<br>続いてフォトフレームを選択してください。</p>
        </div>

        <div class="category__list mb-50">

          <category-combined-item
            v-for="(sale_product) in combinedSaleProducts.data"
            :key="sale_product.id"
            :sale_product="sale_product"
            :is-in-cart="false"
            :has-caption="combinedSaleProducts.meta.has_caption"
            @choose-click="handleChooseClick"
          />

        </div>

        <category-pagination
          :meta="combinedSaleProducts.meta"
          :total="combinedSaleProducts.data.length"
          @page="(page) => handleOnPage(page)"
        />

        <category-cart-status-bar
          :class="[!isMobile ? '' : 'category-dl__space-bottom']"
          :total="0"
          :amount="product_current_total_include_tax"
          :set-total="product_current_qty_in_cart"
          :is_mobile="isMobile"
          is-combined-product
          @to-cart="onToCart"
        />
      </div>

    </section>

    <ChildUnderEvent
      v-if="category.event_id && hasSaleProduct"
      :event_id="category.event_id"
      :except_category_id="except_category_id"
      :filters="filters"
      :product-standard="productStandard"
      :sale-products="saleProducts"
      :categories="categories"
      :subtitle="$t('category_goods.relation.subtitle')"
      :title="$t('category_goods.relation.title')"
      @filter-change="handleFilterChange"
    />

    <CategoryCombinedChooseFrameModal
      :is-show-modal="isShowChooseFrameModal"
      :sale_product="selectedSaleProduct"
      :sub-commodities="subCommodities"
      @choose-frame="handleChooseFrame"
      @close="handleCloseChooseFrameModal"
    />


  </article>
</template>

<script>
import KeepProportionContent from '../base/KeepProportionContent'

export default {
  name: "CategoryCombinedTemplate",
  components: {
    InformationListAccordion: () => import('@components/base/InformationListAccordion'),
    CategoryContentSection: () => import('@components/category/CategoryContentSection'),
    KeepProportionContent,
    Headline: () => import('@components/base/Headline'),
    CategorySubCommoditySpecSlider: () => import('@components/category/CategorySubCommoditySpecSlider'),
    CategoryCombinedItem: () => import('@components/category/CategoryCombinedItem'),
    CategoryCartStatusBar: () => import('@components/category/CategoryCartStatusBar'),
    CategoryPagination: () => import('@components/category/CategoryPagination'),
    ChildUnderEvent: () => import('@pages/event/ChildUnderEvent'),
    CategoryCombinedChooseFrameModal: () => import('@components/category/CategoryCombinedChooseFrameModal')
  },
  props: {
    isMobile: {
      type: Boolean,
      default: false
    },
    category: {
      type: Object,
      required: true
    },
    artist_page_url: {
      type: String,
      default: ''
    },
    event_page_url: {
      type: String,
      default: ''
    },

    purchaseItem: {
      type: Object,
    },

    product_current_qty_in_cart: {
      type: [ Number, String ],
      default: 0
    },

    product_current_total_include_tax: {
      type: [ Number, String ],
      default: 0
    },

    except_category_id: {
      type: String,
    },
    filters: {
      type: Object
    },
    productStandard: {
      type: Object
    },
    categories: {
      type: Array
    },
    saleProducts: {
      type: Array
    },
    carouselSetting: {
      type: Object,
      default:() => {}
    },
    currentArtist: {
      type: Object,
      required: true
    },
    subCommodities: {
      type: Array,
      required: true
    },
    combinedSaleProducts: {
      type: Object
    }
  },
  data() {
    return {
      selectedSaleProduct: null,
      isShowChooseFrameModal: false
    }
  },
  computed: {
    hasSaleProduct() {
      if (!this.saleProducts) return false
      return Object.keys(this.saleProducts).length
    },
  },
  methods: {
    onToCart() {
      this.$emit('to-cart')
    },
    handleOnPage(page) {
      this.$emit('page', page)
    },
    handleFilterChange({key, value}) {
      this.$emit('filter-change', { key, value})
    },
    handleChooseClick(sale_product) {
      this.selectedSaleProduct = sale_product
      this.isShowChooseFrameModal = true
    },
    handleChooseFrame(data) {
      this.selectedSaleProduct = null
      this.isShowChooseFrameModal = false
      this.$emit('add-combined-product-to-cart', data)
    },
    handleCloseChooseFrameModal() {
      this.selectedSaleProduct = null
      this.isShowChooseFrameModal = false
    }
  }

}
</script>

<style scoped>
.separator {
  border: none;
  height: 1px;
  background-color: #BEBEBE;
  margin: 18px 10px;
}
</style>
