import BaseService from "./BaseService";

class RegisterService extends BaseService {
  constructor(prefix) {
    super(prefix);
  }

  verify(token) {
    return this.performRequest(BaseService.METHOD_POST, `verify/${token}`, {}, {}, {
      is_ignore_interceptors_response_error: true
    });
  }

  zipCode(data) {
    return this.performRequest(BaseService.METHOD_GET, 'zipcode', data);
  }

  reSendEmailVerification(data) {
    return this.performRequest(BaseService.METHOD_POST, 'resend-email-verification', data, {}, {
      is_ignore_interceptors_response_error: true
    });
  }

  register(data) {
    return this.performRequest(BaseService.METHOD_POST, '', data);
  }
}

export default new RegisterService('members');