import CartService from '@services/CartService';
import router from "../../routes/index";

export default {
  state: {
    vm: null,
    cartOldEvent: [],
    cartEvent: [],
    cartDatas: [],
    cartSummaryData: null,
    isShowPrintedSetPopup: false,
    isProductOutOfStock: false,
    isRemoveAllProductsPopup: false,
    cartSheetAmount: 0,
    cartSubtotal: 0,
    suggestQtyAmount: 0,
    isEventSaleTermExpired: false,
    isUserUpdateCartProduct: false,
    isCartDataChanged: false,
  },
  mutations: {
    SET_IS_USER_UPDATE_CART_PRODUCT(state, val) {
      state.isUserUpdateCartProduct = val
    },
    SET_CART_DATA(state, data) {
      state.cartSummaryData = data.summary_data
      state.cartOldEvent = data.old_event;
      state.cartEvent = data.events;
      state.cartDatas = data.cart_categories;
      state.cartSheetAmount = data.cart_sheet_amount
      state.cartSubtotal = data.cart_subtotal_included_tax
    },
    SET_IS_PRINTED_SET_POPUP(state, val) {
      state.isShowPrintedSetPopup = val
    },
    SET_IS_PRODUCT_OUT_OF_STOCK(state, val) {
      state.isProductOutOfStock = val
    },
    SET_SUGGEST_QTY_AMOUNT(state, val) {
      state.suggestQtyAmount = val
    },
    SET_IS_REMOVE_ALL_PRODUCTS_POPUP(state, isShow) {
      state.isRemoveAllProductsPopup = isShow
    },
    SET_IS_EVENT_SALE_TERM_EXPIRED(state, val) {
      state.isEventSaleTermExpired = val
      if (!val) {
        /**
         * D4C Fuckin クソコード
         */
        this.dispatch('removeExpiredProductsFromCart');
        router.push({name: 'artist.home'})
      }
    },
    SET_IS_CART_DATA_CHANGED(state, val) {
      state.isCartDataChanged = val && !state.isUserUpdateCartProduct
      if (!val) {
        if (router.currentRoute.name != 'cart.page') {
          state.cartSummaryData = null
          router.push({name: 'cart.page'})
        } else {
          this.commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)
          /**
           * D4C Fuckin クソコード
           * mutationで非同期処理を行うべきではない
           */
          this.dispatch('getCartData')
        }
      }
    },
    SET_IS_TOO_MANY_SHIPPING_PRODUCTS(state, val) {
      state.isTooManyShippingProducts = val
    },
    UPDATE_PRODUCT_CART_QUANTITY(state, { index, cart_product_index, cart_product }) {
      state.cartDatas[index].cart_products[cart_product_index] = cart_product
    }
  },
  getters: {
    cartDatas: state => {
      return Object.values(Object.assign({}, state.cartDatas))
    },
  },
  actions: {
    setSuggestQtyAmount({commit}, val) {
      commit('SET_SUGGEST_QTY_AMOUNT', val)
    },
    setIsProductOutOfStock({commit}, val) {
      commit('SET_IS_PRODUCT_OUT_OF_STOCK', val)
    },
    setPrintedSetPopup({commit}, val) {
      commit('SET_IS_PRINTED_SET_POPUP', val)
    },

    prepareCartData({ commit, state }, data) {
      let result = false;

      const targetPage = [
        'purchase.login',
        'purchase',
        'purchase.attention',
        'purchase.confirmation',
        'purchase.confirmation2',
        'purchase.credit',
        'cart.page'
      ];

      if (data.is_sale_term_expired && targetPage.includes(router.currentRoute.name)) {
        commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true);
        result = true
      }

      if (state.cartSummaryData && (state.cartSummaryData != data.summary_data)) {
        commit('SET_IS_CART_DATA_CHANGED', true)
        result = !state.isUserUpdateCartProduct
      }

      if (!result) {
        commit('SET_CART_DATA', data)
      }

      commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)

      return result
    },

    async getCartData({ dispatch }, is_show_loading = false) {
      const res = await CartService.index(is_show_loading)

      return await dispatch('prepareCartData', res.data)
    },

    /**
     * TODO::  たぶん共通化した方がいいが、影響範囲を考え一旦対応しない
     * @param commit
     * @param state
     * @param slug
     * @returns {Promise<null>}
     */
    async getCartDataWithResult({commit, state}, slug) {
      let result = null

      await CartService.index().then(async (res) => {

        result = res.data

        let is_cart_data_updated = false

        const targetPage = [
          'purchase.login',
          'purchase',
          'purchase.attention',
          'purchase.confirmation',
          'purchase.confirmation2',
          'purchase.credit',
          'cart.page'
        ];

        if (res.data.is_sale_term_expired && targetPage.includes(slug)) {
          // console.log("SET_IS_EVENT_SALE_TERM_EXPIRED!!")
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)

          is_cart_data_updated = true
        }

        if (!!state.cartSummaryData && (state.cartSummaryData !== res.data.summary_data)) {
          // console.log("SET_IS_CART_DATA_CHANGED!!")
          commit('SET_IS_CART_DATA_CHANGED', true)

          is_cart_data_updated = !state.isUserUpdateCartProduct
        }

        if (!is_cart_data_updated) {
          // console.log("SET CART DARA")
          commit('SET_CART_DATA', res.data)
        }

        return commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)
      })

      return result
    },

    async storeCartData({dispatch, commit}, data) {
      try {
        commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

        const is_combined_product = 'commodity_id' in data

        const res = is_combined_product ? await CartService.addCombinedProductToCart(data) : await CartService.addProductToCart(data)

        if (res.data.is_sale_term_expired) {
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)
        } else {
          commit('SET_GLOBAL_LOADING', true)

          if (!res.data.cartData) {
            await dispatch('getCartData')
          } else {
            dispatch('prepareCartData', res.data.cartData)
          }

          commit('SET_GLOBAL_LOADING', false)
        }
      } catch (e) {
        if (e.status == 422) {
          commit('SET_IS_PRODUCT_OUT_OF_STOCK', true)
          commit('SET_GLOBAL_LOADING', false)
        }
      }
    },

    async removeProductFromCart({dispatch, commit}, { id, isOnlyOneProduct = false, ...rest }) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      const top = document.querySelector('.page-title')

      if (top && isOnlyOneProduct) {
        top.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }

      commit('SET_GLOBAL_LOADING', true)

      try {
        const res = await CartService.removeProductFromCart(id, rest)

        if (res.data.is_sale_term_expired) {
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)
        } else {
          commit('SET_GLOBAL_LOADING', true)

          if (!res.data.cartData) {
            await dispatch('getCartData')
          } else {
            dispatch('prepareCartData', res.data.cartData)
          }

          commit('SET_GLOBAL_LOADING', false)
        }
      } catch(e) {
        console.error(e)
        commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)
        commit('SET_IS_CART_DATA_CHANGED', true)
      }

      commit('SET_GLOBAL_LOADING', false)
    },

    async removeCombinedProductFromCart({dispatch, commit}, { id, commodity_id, isOnlyOneProduct = false, ...rest }) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      const top = document.querySelector('.page-title')

      if (top && isOnlyOneProduct) {
        top.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }

      commit('SET_GLOBAL_LOADING', true)

      try {
        const res = await CartService.removeCombinedProductFromCart(id, commodity_id, rest)

        if (res.data.is_sale_term_expired) {
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)
        } else {
          commit('SET_GLOBAL_LOADING', true)

          if (!res.data.cartData) {
            await dispatch('getCartData')
          } else {
            dispatch('prepareCartData', res.data.cartData)
          }

          commit('SET_GLOBAL_LOADING', false)
        }
      } catch(e) {
        console.error(e)

        commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)
        commit('SET_IS_CART_DATA_CHANGED', true)
      }

      commit('SET_GLOBAL_LOADING', false)
    },

    async updateProductOnCart({dispatch, commit}, cart) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      const data = {
        qty: cart.qty,
        purchasable_type: cart.purchasable_type
      }

      commit('SET_GLOBAL_LOADING', true)

      try {
        const res = await CartService.updateProductOnCart(cart.cart_product_id, data)

        if (res.data.is_sale_term_expired) {
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)
        } else {
          commit('SET_GLOBAL_LOADING', true)

          if (!res.data.cartData) {
            await dispatch('getCartData')
          } else {
            dispatch('prepareCartData', res.data.cartData)
          }

          commit('SET_GLOBAL_LOADING', false)
        }
      } catch(e) {
        console.error(e)

        if (e.status == 404) {
          commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)
          commit('SET_IS_CART_DATA_CHANGED', true)
        } else if (e.status == 422) {
          commit('SET_IS_PRODUCT_OUT_OF_STOCK', true)
        }
      }

      commit('SET_GLOBAL_LOADING', false)
    },

    async updateCombinedProductOnCart({dispatch, commit}, cartProduct) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      const data = {
        qty: cartProduct.qty,
        purchasable_type: cartProduct.purchasable_type,
        commodity_id: cartProduct.commodity_id,
      }

      commit('SET_GLOBAL_LOADING', true)

      try {
        const res = await CartService.updateCombinedProductOnCart(cartProduct.cart_product_id, data)

        if (res.data.is_sale_term_expired) {
          commit('SET_IS_EVENT_SALE_TERM_EXPIRED', true)
        } else {
          commit('SET_GLOBAL_LOADING', true)

          if (!res.data.cartData) {
            await dispatch('getCartData')
          } else {
            dispatch('prepareCartData', res.data.cartData)
          }

          commit('SET_GLOBAL_LOADING', false)
        }
      } catch(e) {
        console.error(e)

        if (e.status == 404) {
          commit('SET_IS_USER_UPDATE_CART_PRODUCT', false)
          commit('SET_IS_CART_DATA_CHANGED', true)
        } else if (e.status == 422) {
          commit('SET_IS_PRODUCT_OUT_OF_STOCK', true)
        }
      }

      commit('SET_GLOBAL_LOADING', false)
    },

    removeExpiredProductsFromCart({dispatch, commit}) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      CartService.removeExpiredProductsFromCart().then(() => {
        dispatch('getCartData')
      })
    },

    async removeAllProductsFromCart({commit, dispatch}) {
      commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)

      await CartService.removeAllProductsFromCart()

      dispatch('getCartData')

      const top = document.querySelector('.page-title')

      if (top) {
        top.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }

      commit('SET_IS_REMOVE_ALL_PRODUCTS_POPUP', false)
    },
  }
}
