export default {
  if_the_purchase_details: "If the purchase details, shipping address and payment method information below are correct, please confirm your order.",
  time_designation: "Time designation",
  unspecified: "unspecified",
  in_the_morning: "in the morning",
  "14_00_〜16_00": "14:00〜16:00",
  payment_method: "Payment method",
  card_number: "Card number",
  order_confirmed: "Order confirmed",
  store_name: "Store Name",
  message: "Message",
  month: {
    "0": "January",
    "1": "February",
    "2": "March",
    "3": "April",
    "4": "May",
    "5": "June",
    "6": "July",
    "7": "August",
    "8": "September",
    "9": "October",
    "10": "November",
    "11": "December",
  },
  error_title: 'Title',
  error_code: 'Error code',
  error_message: 'Error message',
  error_type: {
    payment_fail: 'Payment Fail'
  }
}