<template>
  <BaseStatic
    :static-page="staticPage"
  />
</template>

<script>
import ArtistService from "@services/ArtistService";
import StaticService from "@services/StaticService";

export default {
  components: {
    BaseStatic: () => import('@pages/static/base/Static'),
  },
  data() {
    return {
      staticPage: {
        title: '',
        subtitle: '',
        detail_type: 'image',
        image_url: '',
        main_text: ''
      }
    }
  },
  created() {
    const currentArtists = this.$store.state.current_artist
    if (currentArtists && currentArtists.id) {
      ArtistService.getArtistStaticPage(
        currentArtists.id,
        this.$route.params.url_slug
      ).then(res => {
        this.staticPage = res.data
      }).catch(()=> {
        this.$router.push({name: '404'})
      })
    } else {
      StaticService.getRootStaticPage(
        this.$route.params.url_slug
      ).then(res => {
        this.staticPage = res.data
      }).catch(()=> {
        this.$router.push({name: '404'})
      })
    }
  }
}
</script>
