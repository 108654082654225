<template>
  <div>
    <Modal :modal="isShowModal" width="380px">
      <div slot="body" class="body" v-html="$sanitize($t('purchase_confirmation.cancel_purchase_msg'))"></div>
      <div slot="footer" class="modal-footer">
        <button class="footer__close mr-5" @click="() => onCancel()">
          {{ $t('cart.cancel') }}
        </button>
        <button class="footer__ok" @click="() => onConfirm()">
          {{ $t('cart.ok') }}
        </button>
      </div>
    </Modal>
  </div>
</template>
<script>
import CartService from "@services/CartService";

export default {
  name: "cancel-purchase-modal",
  props: {
    isShowModal: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  components: {
    Modal: () => import('@components/base/Modal')
  },
  methods: {
    onCancel() {
      this.$emit('on-cancel', true);
    },
    onConfirm() {
      this.$store.commit('SET_IS_USER_UPDATE_CART_PRODUCT', true)
      CartService.removeAllProductsFromCart().then(() => {
        this.$emit('on-cancel', true);
        this.$store.dispatch('getCartData');
        this.$router.push({name: 'artist.home'})
      });
    },
  },
}
</script>
<style scoped>
.js-cart_button {
  cursor: pointer;
}

.attention {
  display: flex;
  justify-content: center;
}

.modal-footer {
  display: flex;
  justify-content: center;
}

.footer__close {
  border: 2px solid black;
  cursor: pointer;
  padding: 1rem 3rem;
  background-color: transparent;
}

.footer__ok {
  cursor: pointer;
  padding: 1rem 3rem;
}


</style>
