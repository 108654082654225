<template>
  <section class="content pt-50 pb-80">
    <slot name="header"/>

    <div class="content__inner">
      <Title :subtitle="subtitle" :title="title"/>

      <div
        v-show="events.length"
        :class="{'three-column': true, 'scroll-border': needsScrollBorder}"
      >
        <slot name="term_service"/>

        <EventListItem
          v-for="(event, index) in events"
          ref="eventListItem"
          :key="index"
          :event="event"
          :event-link="eventLink"
          :is-audition="isAudition"
          :height="highest"
          class="three-column__item"
        />
      </div>
    </div>
  </section>
</template>

<script>
import EventListItem from "@components/event/EventListItem";
export default {
  name: 'EventList',
  components: {
    EventListItem,
    Title: () => import('@components/base/Title'),
  },
  props: {
    events: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    event_list_scroll: {
      type: Boolean,
      required: false,
      default: false,
    },
    is_mobile: {
      type: Boolean,
      required: true,
    },
    eventLink: {
      type: [String, Object, Function],
      default: '',
    },
    isAudition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      highest: 0
    }
  },
  mounted() {
    this.$nextTick(function () {

      this.eventListResize()

    })
  },
  computed: {
    needsScrollBorder() {
      return !!this.is_mobile && !!this.event_list_scroll && this.events.length > 2
    },
  },
  methods: {
    eventListResize() {

      const resizeObserver = new ResizeObserver( entries => {

        const maxHeight = entries.reduce( (max_height, entry) => {
          max_height = max_height < entry.contentRect.height ? entry.contentRect.height : max_height
          return max_height
        }, 0)
        this.highest = maxHeight

      })

      this.$refs.eventListItem.forEach( item => {

        resizeObserver.observe(item.$el)

      })
    },
  },
}
</script>

<style scoped>
.scroll-border {
  border: 1px solid black;
  padding: 5px;
  height: 30rem;
  overflow: scroll;
}
</style>
